import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'brazeEventsEnabled',
  initialState: null as string[] | null,
  reducers: {
    setBrazeEventsEnabled(state, action) {
      return action.payload || null;
    },
  },
});

export const { setBrazeEventsEnabled } = slice.actions;

export default slice.reducer;
