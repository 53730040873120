import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import { RiAlertFill } from 'react-icons/ri';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import getShowMinExcludesSurchargeMessage from '../selectors/getShowMinExcludesSurchargeMessage';
import getInvalidStagedPurchasePresent from '../selectors/getInvalidStagedPurchasePresent';
import getMinimumOrderAmountNotMet from '../selectors/getMinimumOrderAmountNotMet';
import getInvalidOfferPresent from '../selectors/getInvalidOfferPresent';
import { useAppSelector } from '../app/hooks';

import Text from './Text';

const {
  getStagedPurchaseCount,
  getSelectedOffer,
  getSelectedOfferMiniSpendingDiffTotalPrice,
  getCurrencySymbol,
  getMaxOrderValue,
  getShowMaxValue,
} = OrderingSelectors;
const { useFormattedCurrency } = OrderingHooks;

export const Message = ({
  message,
  showAlertIcon,
}: {
  message: string;
  showAlertIcon?: boolean;
}) => {
  const p = useAppSelector(getThemeLookup);

  return (
    <div style={styles.messageContainer}>
      {showAlertIcon && (
        <RiAlertFill
          style={combineStyles(
            styles.messageIcon,
            p('orderMessage', ['color', 'fontSize']),
            p('orderMessageIcon', ['color', 'fontSize']),
          )}
        />
      )}

      <Text themeKey="orderMessage" style={styles.messageText}>
        {message}
      </Text>
    </div>
  );
};

const OrderMessages = () => {
  const { t } = useTranslation();
  const minimumOrderAmountNotMet = useAppSelector(getMinimumOrderAmountNotMet);
  const invalidOfferPresent = useAppSelector(getInvalidOfferPresent);
  const stagedPurchaseCount = useAppSelector(getStagedPurchaseCount);
  const invalidStagedPurchasePresent = useAppSelector(getInvalidStagedPurchasePresent);
  const showMinExcludesSurchargeMessage = useAppSelector(getShowMinExcludesSurchargeMessage);
  //minimum order with coupon
  const selectedOffer = useAppSelector(getSelectedOffer);
  const minimumCouponAmountNotMet = useAppSelector(getSelectedOfferMiniSpendingDiffTotalPrice);
  const currency = useAppSelector(getCurrencySymbol);
  const maxOrderValueRule = useAppSelector(getMaxOrderValue);
  const showWarning = useAppSelector(getShowMaxValue);
  const maxOrderValuePrice = useFormattedCurrency({
    cents: maxOrderValueRule?.MaximumOrder,
    hideZeroCents: true,
  });

  return (
    <div style={{ textAlign: 'center', marginRight: 24, marginLeft: 24 }}>
      {minimumOrderAmountNotMet && Boolean(stagedPurchaseCount) && (
        <Message message={t('minimumOrderWarningMessage')} showAlertIcon />
      )}

      {invalidStagedPurchasePresent && (
        <Message message={t('invalidStagedPurchasePresentMessage')} showAlertIcon />
      )}

      {/* {showMinExcludesSurchargeMessage && (
        <Message message={t('minimumExcludesSurchargeMessage')} />
      )} */}

      {invalidOfferPresent && <Message message={t('incompatibleOfferMessage')} showAlertIcon />}

      {showWarning && (
        <Message
          showAlertIcon
          message={
            maxOrderValueRule!.Message
              ? maxOrderValueRule!.Message
              : t('maxValueWarning', {
                  amount: `${maxOrderValuePrice}`,
                })
          }
        />
      )}

      {minimumCouponAmountNotMet > 0 && Boolean(stagedPurchaseCount) && (
        <Message
          message={
            [1, 2].includes(selectedOffer?.type) && selectedOffer?.MinOrderValue
              ? t('minimumOrderWithCouponMessageDiscount', {
                  amount: selectedOffer?.MinOrderValue,
                  currency,
                  discount:
                    selectedOffer?.type === 1
                      ? `${selectedOffer?.amount}%`
                      : `${currency}${selectedOffer?.amount}`,
                })
              : t('minimumOrderWithCouponMessage', {
                  amount: minimumCouponAmountNotMet / 100.0,
                  minimum: selectedOffer?.MinOrderValue,
                  currency,
                })
          }
          showAlertIcon
        />
      )}
    </div>
  );
};

const styles: Styles = {
  messageContainer: {
    marginBottom: 15,
  },
  messageIcon: {
    marginRight: 6,
  },
  messageText: {},
};

export default OrderMessages;
