import { createSelector } from 'reselect';
import getAllCategoryInfos from './getAllCategoryInfos';
import getMenu from './getMenu';
import getItems from './getItems';
import getNestedItemStockBalancesData from './getNestedItemStockBalancesData';
import {
  getChoiceSetDiscountConfig,
  getUpsellDisplayLimit,
  getUpsellUnavailableDisplayType,
} from './config';
import {
  checkTimeAvailability,
  itemCategoryAvailibilityValidate,
  validateUpsellItems,
} from '../utils/ordering';
import { UPSELL_ITEM_DISPLAY_TYPE } from '../constants';

export default createSelector(
  [
    getMenu,
    getItems,
    getUpsellDisplayLimit,
    getNestedItemStockBalancesData(undefined),
    getUpsellUnavailableDisplayType,
    getAllCategoryInfos,
    getChoiceSetDiscountConfig,
  ],
  (
    orderMenu,
    items,
    upsellDisplayLimit = 3,
    [stockBalanceDataMap],
    unavailableItemDisplay,
    categories,
    choiceSetDiscountConfig,
  ): (Item & { upsellAvailable: boolean })[] => {
    if (!orderMenu) {
      return [];
    }
    const {
      upsells,
      rootMenuNode: { subMenus },
    } = orderMenu;

    if (!items || !upsells) return [];
    const filteredUpsells = (upsells.filter(u =>
      checkTimeAvailability(u.availability, u.days as Day[], u.start, u.end),
    ) || [])[0];
    if (!filteredUpsells) return [];

    const upsellItems = filteredUpsells.choices
      .map(choice => items[choice.id])
      .filter(
        c =>
          c &&
          itemCategoryAvailibilityValidate(c.id, subMenus, categories || []),
      );
    const validatedUpSellItems = validateUpsellItems(
      upsellItems,
      stockBalanceDataMap,
      choiceSetDiscountConfig,
    );

    const result =
      unavailableItemDisplay === UPSELL_ITEM_DISPLAY_TYPE.GREYOUT
        ? validatedUpSellItems
        : validatedUpSellItems?.filter(item => item.upsellAvailable);
    return (result || []).slice(0, upsellDisplayLimit);
  },
);
