import React, { useState, useEffect, useContext } from 'react';
import * as lodash from 'lodash';
//import { RiCheckboxCircleFill } from 'react-icons/ri';
import {
  OrderingSelectors,
  tallyAndValidateChoiceSets,
  choiceSetsWithQuantities,
} from 'polygon-ordering';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import NestedChoiceSetTags from './NestedChoiceSetTags';
import NestedChoiceSetBanner from './NestedChoiceSetBanner';
import RedcatImage from '../components/RedcatImage';
import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';
import {
  isPurchasedChoiceSet,
  NestedChoiceSetsContext,
  INestedChoiceSetsContext,
} from './NestedChoiceSetsContext';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import ItemTopHeader from './ItemTopHeader';
import { useTranslation } from 'react-i18next';
import RadioCheck from './RadioCheck';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { computeTagsForItem } from '../libs/polygon-ordering/src/utils/ordering/computeTagsForItem';
import enhanceItem from '../libs/polygon-ordering/src/utils/ordering/enhanceItem';
import { enqueueWarningSnackbar } from '../utils/snackbar';

const {
  getOpenPurchasePreviouslyStaged,
  getAvailableItems,
  getNestedItemStockBalancesData,
  getOpenPurchase,
  getAllergenDietaryDetails,
  getMenu,
  getChoiceSetDiscountConfig,
  getAvailableChoiceSets,
} = OrderingSelectors;
const NestedChoiceSet: React.FC<{
  adjustChoice: (params: AdjustChoice) => void;
  choiceSet: ValidatedChoiceSet;
  rootItem: Item;
  setImage: (imageurl?: string) => void;
  onModify: () => void;
}> = ({ adjustChoice, choiceSet, rootItem, setImage, onModify }) => {
  const [selectedItems, setSelectItems] = useState<ValidatedChoiceSet[] | undefined>(undefined);
  const {
    showChoices,
    setShowChoices,
    scrollDownIndicator,
    stages,
    setStages,
    purchased,
    dataForAdjust,
    setDataForAdjust,
  } = useContext(NestedChoiceSetsContext) as INestedChoiceSetsContext;

  const previouslyStaged = useAppSelector(getOpenPurchasePreviouslyStaged);
  const choiceOrderingMethod = useAppSelector(state => state.ordering.config.choiceOrderingMethod);
  const allTags = useAppSelector(getAllergenDietaryDetails);
  const menu = useAppSelector(getMenu);
  const openPurchase = useAppSelector(getOpenPurchase) as PurchaseWithTotals;
  const [stockBalanceDataMap] = useAppSelector(
    getNestedItemStockBalancesData(openPurchase, purchased),
  );

  useEffect(() => {
    if (!('nestedIngredients' in choiceSet)) {
      setSelectItems([choiceSet]);
      setShowChoices(true);
    }
  }, [choiceSet]);

  // useEffect(() => scrollDownIndicator());
  const allItems = useAppSelector(getAvailableItems);
  const allChoiceSets = useAppSelector(getAvailableChoiceSets);
  const choiceSetDiscountConfig = useAppSelector(getChoiceSetDiscountConfig);
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  if (!choiceSet) return null;
  const { choices } = choiceSet;
  const [item, setItem] = useState<Item | undefined>();
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);
  const tags =
    item &&
    computeTagsForItem(
      item,
      (stages && (stages['2'] as unknown as NestedChoiceSelections)) || {},
      allTags,
    );
  const atleastOneTagExists = !!tags?.length;

  const handleSelect = (choice: ChoiceWithQuantity, selected: boolean | undefined) => {
    if (
      stockBalanceDataMap &&
      stockBalanceDataMap[choice.plucode] &&
      stockBalanceDataMap[choice.plucode].cartAdjustedBalance !== undefined &&
      !((stockBalanceDataMap[choice.plucode].cartAdjustedBalance as number) > 0) &&
      !lodash.has(purchased, ((stages || {})[0] as string).concat('.', choice.id))
    ) {
      enqueueWarningSnackbar(t('stagedChoiceSetItemQuantityWarningMessage'));
      return;
    }
    const { id } = choice;
    const newItem = (allItems && menu && enhanceItem(allItems[id], menu)) || undefined;
    if (newItem) {
      newItem.baseMoneyPrice = choice.baseMoneyPrice;
      if (choice.name) newItem.name = choice.name;
    }

    const selectionsForThisChoiceSet = openPurchase?.choiceSelections?.[choiceSet?.key];

    if (
      !selected &&
      typeof selectionsForThisChoiceSet === 'object' &&
      Object.keys(selectionsForThisChoiceSet).length
    ) {
      setDataForAdjust([
        ...dataForAdjust,
        {
          targetChoiceSetId: choiceSet?.key,
          targetChoiceId: undefined,
          clear: undefined,
          decrease: undefined,
          parentChoiceSetId: undefined,
          currentItemId: undefined,
          clearParent: true,
          targetChoiceSetIdForModify: choiceSet?.id,
        },
      ]);
    }

    setItem(newItem);

    setSelectItems(
      tallyAndValidateChoiceSets(
        choiceSetsWithQuantities(
          allItems![id].choiceSets.map(e => allChoiceSets![e]),
          {},
        ),
        choiceSetDiscountConfig,
      ),
    );

    setShowChoices(true);
    // scrollDownIndicator();

    if (!('nestedIngredients' in choiceSet)) {
      if (!('1' in stages!)) {
        setStages({ ...stages, '1': { [choiceSet.id]: [] } });
      }
    } else {
      if (!('2' in stages!)) {
        setStages({ ...stages, '1': id, '2': {} });
      }
    }
  };

  const baseTextSize = { fontSize: isMobileDevice ? 14 : 16 };
  const titleTextSize = { fontSize: isMobileDevice ? 20 : 22 };

  const baseContainerStyle = combineStyles(styles.container, { padding: isMobileDevice ? 10 : 30 });

  const choiceSetContainerStyle = combineStyles(
    baseContainerStyle,
    (atleastOneTagExists || item) && p('defaultBorder', ['borderTop']),
    // very hacky, a symptom of legacy purchase modal code, please don't judge
    isMobileDevice ? { paddingTop: 20, marginTop: 10 } : {},
  );

  // change the image to the nested item if looking at the nested choice set for that item
  useEffect(() => {
    if (showChoices && item) {
      setImage(item.images?.default ?? rootItem?.images?.default);
    } else {
      setImage(rootItem?.images?.default);
    }
  }, [showChoices, item]);

  const collapseEnabled = useAppSelector(state => state.config.collapsableChoiceSetsEnabled);
  const collapseOptionalByDefault = useAppSelector(
    state => state.config.collapseOptionalChoiceSetsByDefault,
  );
  const [collapsedSets, setCollapsedSets] = useState<SDict<boolean>>();

  useEffect(() => {
    if (!selectedItems || !collapseEnabled || !collapseOptionalByDefault) return;
    setCollapsedSets(
      selectedItems.reduce<SDict<boolean>>((sum, nestedItemChoiceSet) => {
        const numSelected = nestedItemChoiceSet.choices.reduce<number>((sum, c) => {
          return sum + (c.selected ? 1 : 0);
        }, 0);

        const quantity =
          numSelected &&
          (numSelected === 1
            ? nestedItemChoiceSet.min
              ? Math.min(
                  nestedItemChoiceSet.individualMax || nestedItemChoiceSet.min,
                  nestedItemChoiceSet.min,
                )
              : 1
            : Math.min(numSelected, nestedItemChoiceSet.max ?? numSelected));

        const valid = !nestedItemChoiceSet.min || quantity >= nestedItemChoiceSet.min;
        const isRadioButton = nestedItemChoiceSet.min === 1 && nestedItemChoiceSet.max === 1;

        return { ...sum, [nestedItemChoiceSet.id]: valid && !isRadioButton };
      }, {}),
    );
  }, [collapseEnabled, collapseOptionalByDefault, selectedItems]);

  const toggleCollapsed = (id: string) => {
    setCollapsedSets({
      ...collapsedSets,
      [id]: !collapsedSets || !(id in (collapsedSets || {})) ? true : !collapsedSets![id],
    });
  };

  let preSelect = {};

  //populate stock balance data for pre selection ingredients choices which not in nested purchased
  const populateIngreChoiceStockBalanceData = (
    selection: SDict<ChoiceWithQuantity[]>,
    stockBalanceDataMap: SDict<StockBalanceData> | undefined,
  ): SDict<StockBalanceData> | undefined => {
    if (!selection || !stockBalanceDataMap) return stockBalanceDataMap;
    let stockBalanceDataMapCopy = { ...stockBalanceDataMap };
    const selectedChoices = lodash.flatten(lodash.values(selection as SDict<ChoiceWithQuantity[]>));

    stockBalanceDataMapCopy = lodash.mapValues(stockBalanceDataMap, (value, key) => {
      const qty = (selectedChoices as ChoiceWithQuantity[]).filter(c => c.plucode === key).length;
      return qty > 0
        ? {
            ...value,
            cartAdjustedBalance:
              lodash.has(value, 'cartAdjustedBalance') && (value['cartAdjustedBalance'] || 0) > 0
                ? value['cartAdjustedBalance']! - qty
                : value['cartAdjustedBalance'],
          }
        : value;
    });

    return stockBalanceDataMapCopy;
  };

  const choiceAvailabilityCheck = (
    balanceDataMap: SDict<StockBalanceData> | undefined,
    plucode: string | undefined,
    choiceQty: number,
  ): boolean => {
    if (!plucode) return false;
    return (
      !lodash.has(balanceDataMap, plucode) ||
      (balanceDataMap !== undefined &&
        lodash.has(balanceDataMap, plucode) &&
        (balanceDataMap[plucode]?.cartAdjustedBalance || 0) >= choiceQty)
    );
  };

  return (
    <>
      <div style={baseContainerStyle}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            gap: 20,
            padding: 20,
            paddingBottom: 0,
          }}
        >
          {/* show the meal choices topsection */}
          {!showChoices && (
            <>
              <Text themeKey="purchaseEditorName" style={titleTextSize}>
                {rootItem?.name}
              </Text>
              {choiceSet.name && (
                <Text themeKey="purchaseEditorName" style={baseTextSize}>
                  {choiceSet.name}
                </Text>
              )}
            </>
          )}
          {/* show the nested item topsection */}
          {showChoices && (atleastOneTagExists || item) && (
            <>
              {/* TODO: fix this dodgy casting */}
              <ItemTopHeader item={item as unknown as Item} tags={tags} />
            </>
          )}
        </div>
      </div>
      {/* meal choices */}
      {!showChoices && (
        <div
          style={{
            padding: isMobileDevice ? 10 : 20,
            paddingTop: isMobileDevice ? 10 : 0,
            display: 'flex',
            flexDirection: 'column',
            gap: isMobileDevice ? 5 : 10,
          }}
        >
          {choices.map((choice, index) => {
            const isPurchased = isPurchasedChoiceSet(purchased, choice?.id, stages);
            const setPurchase = isPurchasedChoiceSet(purchased, choiceSet?.key);
            const stockBalanceData = (stockBalanceDataMap || {})[choice.plucode];
            const selected = isPurchased
              ? true
              : !previouslyStaged &&
                choice.selected &&
                !setPurchase &&
                (stockBalanceData?.cartAdjustedBalance || 0) > 0;

            const checkIfDisabled = stockBalanceData?.soldOut;

            return (
              <TouchableOpacity
                style={combineStyles(
                  styles.nestedItem,
                  p(selected ? 'selected' : 'defaultBorder', ['border']),
                  {
                    padding: isMobileDevice ? 10 : 15,
                    gap: isMobileDevice ? 20 : 30,
                    position: 'relative',
                  },
                  checkIfDisabled && { opacity: 0.7 },
                )}
                onClick={() => handleSelect(choice, selected)}
                key={index}
                disabled={checkIfDisabled || false}
              >
                <RedcatImage
                  alt={choice.name}
                  imagePath={choice?.images?.default}
                  size={60}
                  containerStyle={combineStyles(
                    styles.imageContainer,
                    isMobileDevice ? { width: 108, height: 90 } : { width: 120, height: 100 },
                  )}
                  imageStyle={{ width: '100%', height: '100%', borderRadius: 10 }}
                />
                {choice.name}

                <div style={styles.buttonContainer}>
                  {selected ? <RadioCheck size={40} checked /> : ''}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    width: isMobileDevice ? 108 : 120,
                    textAlign: 'center',
                  }}
                >
                  {stockBalanceData?.stockBalanceThreshold && (
                    <Text
                      themeKey={stockBalanceData.stockBalanceThreshold}
                      style={styles.stockBalanceText}
                    >
                      {t('stockBalanceThreshold.' + stockBalanceData.stockBalanceThreshold)}
                    </Text>
                  )}
                </div>
              </TouchableOpacity>
            );
          })}
        </div>
      )}
      {/* ingredient choice */}
      {showChoices && (
        <div style={choiceSetContainerStyle}>
          {selectedItems?.map((e, index) => {
            // no pre-selected quantity in MiM?
            const preSelectChoices = e.choices.filter(c => c.selected);
            // if (preSelectChoices.length > 0) {
            const isNestedChoiceSet =
              'nestedIngredients' in choiceSet && choiceSet['nestedIngredients'];

            const newStockBalanceDataMap = isNestedChoiceSet
              ? populateIngreChoiceStockBalanceData(preSelect, stockBalanceDataMap)
              : stockBalanceDataMap;
            const preSelectQty =
              preSelectChoices.length === 1
                ? e.individualMax
                  ? Math.min(e.min || 1, e.individualMax)
                  : e.min || 1
                : 1;

            let availableChoices: ChoiceWithQuantity[] = [];

            const duplicateChoiceAvailability = choiceAvailabilityCheck(
              newStockBalanceDataMap,
              preSelectChoices.length > 0 ? preSelectChoices[0].plucode : undefined,
              preSelectQty,
            );

            if (preSelectQty > 1 && preSelectChoices.length === 1 && duplicateChoiceAvailability)
              availableChoices = Array.from({ length: preSelectQty }, () => preSelectChoices[0]);

            if (preSelectQty === 1)
              availableChoices = e.choices.filter(c => {
                const choicesAvailability = choiceAvailabilityCheck(
                  newStockBalanceDataMap,
                  c.plucode,
                  preSelectQty,
                );
                return c.selected && choicesAvailability;
              });

            if (isNestedChoiceSet) {
              preSelect = { ...preSelect, [e.id]: availableChoices };
            } else {
              preSelect = availableChoices;
            }

            const isRadioButton = e.min === 1 && e.max === 1;

            // TODO: make this templateable? (like printf format strings, I'm sure there's a way because we do it for some strings, just need to do it)
            const quantityWarning =
              (e.min
                ? e.max
                  ? e.min === e.max
                    ? t('choiceSet.quantityHint.constant', { quantity: e.min })
                    : t('choiceSet.quantityHint.range', { min: e.min, max: e.max })
                  : ''
                : t('choiceSet.quantityHint.maximum', { max: e.max })) +
              (e.free && (!e.max || e.max > e.free)
                ? ' ' + t('choiceSet.quantityHint.freeSuffix', { free: e.free })
                : '');

            const collapsed = !!(collapsedSets && collapsedSets![e.id]);

            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 15,
                }}
                key={index}
              >
                <div>
                  <NestedChoiceSetBanner
                    key={`${index}_banner`}
                    choiceSet={e}
                    collapsed={collapsed}
                    toggleCollapsed={toggleCollapsed}
                  />
                  {quantityWarning && !collapsed && !isRadioButton && (
                    <Text
                      themeKey="choiceSetQuantityHint"
                      style={{ paddingLeft: 20, fontSize: isMobileDevice ? 12 : 14 }}
                      value={quantityWarning}
                    />
                  )}
                </div>
                <NestedChoiceSetTags
                  adjustChoice={adjustChoice}
                  onModify={onModify}
                  choiceSet={e}
                  collapsed={collapsed}
                  key={index}
                  preSelect={preSelect}
                  stockBalanceDataMap={stockBalanceDataMap}
                  currentItem={item}
                  tagChoiceSets={selectedItems}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
const styles: Styles = {
  buttonContainer: {
    display: 'flex',
    padding: 15,
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: 60,
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  nestedItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    padding: 15,
  },
  imageContainer: {
    width: 120,
    height: 100,
    borderRadius: 10,
  },
  description: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 20,
  },
};
export default NestedChoiceSet;
