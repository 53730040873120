import React from 'react';
import { useAppSelector } from '../app/hooks';
import { OrderingConstants, OrderingSelectors } from 'polygon-ordering';
import Text from '../components/Text';
import moment from 'moment';
import lodash from 'lodash';
import { OrderingHooks } from 'polygon-ordering';
import TransactionDetails from '../components/TransactionDetails';
import { useTranslation } from 'react-i18next';
import getConfig from '../selectors/getConfig';
import { Row, Col } from 'reactstrap';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES } from '../utils/theme';

const { useFormattedCurrency } = OrderingHooks;
const { getTransactionHistory, getTaxName } = OrderingSelectors;
const { descriptionMap, REDEMPTION_TXN_TYPES, SHOW_TOTAL_MONEY_FOR_TXN_TYPES } = OrderingConstants;

export const TRANSACTIONS_SCREEN = '/loyalty-history';

const TransactionsScreen: React.FC = () => {
  const orders = useAppSelector(getTransactionHistory);
  return (
    <div style={styles.container}>
      {orders?.map((order, index) => (
        <Transaction key={index} order={order} />
      ))}
    </div>
  );
};

const Transaction = ({ order }: { order: TransactionHistory }) => {
  const { t } = useTranslation();
  const config = useAppSelector(getConfig);
  const p = useAppSelector(getThemeLookup);

  const loyaltyPointsName = config?.loyaltyPointsName && lodash.startCase(config.loyaltyPointsName);

  const gst = lodash.sumBy(order.Items, 'GST') || null;
  const gstDollar = useFormattedCurrency({ cents: Number(gst) });

  const orderTotal =
    order?.Items?.reduce((sum, item) => {
      const price = item?.Total || item?.Price * item?.Qty || 0;
      return sum + price;
    }, 0) || 0;

  const formattedOrderTotal = useFormattedCurrency({ dollars: orderTotal / 100 });
  const taxName = useAppSelector(getTaxName);

  const pointsEarnedOrRedeemedLabel = Boolean(order.Points)
    ? REDEMPTION_TXN_TYPES.includes(order.TxnType)
      ? t('pointsRedeemed')
      : t('pointsEarned')
    : null;

  return (
    <div
      className="p-4"
      style={{
        ...p('cardBorder', ['border']),
        ...styles.transactionCard,
        ...p('transactionHistoryCard', CONTAINER_PROPERTIES),
      }}
    >
      <div>
        {order.Store && (
          <>
            <Text themeKey="loyaltyStoreLocation" value={order.Store.toUpperCase()} />{' '}
          </>
        )}
        <Text
          themeKey="loyaltyOrderDate"
          value={moment(order.TxnDate).format('DD/MM/YYYY hh:mma')}
        />
      </div>

      <div>
        {order.TxnType && (
          <Text
            themeKey="loyaltyOrderTotal"
            value={t(`transactionsDescriptionMap.${order.TxnType}`)}
            block
          />
        )}

        {order.SalesID && (
          <Text themeKey="loyaltyOrderItems" value={'ID: ' + order.SalesID.toString()} block />
        )}
        {order.Items?.map((listItem, index) => (
          <TransactionDetails order={order} transaction={listItem} key={index} />
        ))}
      </div>

      <div>
        {gst && (
          <div>
            <Row>
              <Col>
                <Text themeKey="loyaltyOrderTotal" value={taxName} />
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Text themeKey="loyaltyOrderTotal" value={gstDollar} />
              </Col>
            </Row>
          </div>
        )}

        {SHOW_TOTAL_MONEY_FOR_TXN_TYPES.includes(order.TxnType) && (
          <div>
            <Row>
              <Col>
                <Text themeKey="loyaltyOrderTotal" value={'Total'} />
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Text themeKey="loyaltyOrderTotal" value={formattedOrderTotal} />
              </Col>
            </Row>
          </div>
        )}

        {pointsEarnedOrRedeemedLabel && loyaltyPointsName && (
          <div>
            <Row>
              <Col>
                <Text
                  themeKey="loyaltyOrderTotal"
                  value={`${loyaltyPointsName} ${pointsEarnedOrRedeemedLabel}`}
                />
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Text themeKey="loyaltyOrderTotal" value={`${order.Points}`} />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  transactionCard: {
    gap: 15,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
  },
};

export default TransactionsScreen;
