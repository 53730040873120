// Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import processAdvertisement from '../utils/processors/processAdvertisement';

const initialState = null as null | Advertisement[];

const slice = createSlice({
  name: 'advertisements',
  initialState,
  reducers: {
    storeAdvertisements(
      state,
      action: PayloadAction<RawAdvertisement[] | null>,
    ) {
      return action.payload !== null
        ? (action.payload.map(rawAdvertisement =>
            processAdvertisement(rawAdvertisement),
          ) as unknown as Advertisement[])
        : initialState;
    },
  },
});

export const { storeAdvertisements } = slice.actions;

export default slice.reducer;
