import translateChoiceSelectionsBetweenItems from './translateChoiceSelectionsBetweenItems';
import translateSubstitutionSelectionsBetweenItems from './translateSubstitutionSelectionsBetweenItems';

export default function changePurchaseItem(
  purchase: _Purchase,
  previousItem: Item,
  nextItem: Item,
): _Purchase {
  return {
    ...purchase,
    sizeId: nextItem.id,
    choiceSelections: translateChoiceSelectionsBetweenItems(
      purchase.choiceSelections as ChoiceSelections | NestedChoiceSelections,
      previousItem,
      nextItem,
    ),
    substitutionSelections: translateSubstitutionSelectionsBetweenItems(
      purchase.substitutionSelections,
      previousItem,
      nextItem,
    ),
  };
}
