import moment from 'moment';
import { stripBarcodeTerminators } from '../misc';

// NOTE: if just after midnight, only use the date component
/* const formatOfferDate = (date: string) => {
  const formatted = formatDate(date, 'DD/MM/YYYY h:mmA');

  return formatted ? formatted.replace(/\s+12:0.AM$/, '') : undefined;
}; */

export default function processMemberOffers(raw: RawOffer) {
  return {
    id: raw.ID,
    barcode: raw.Barcode,
    hash: stripBarcodeTerminators(raw.Hash),
    name: raw.ProgramName,
    description: raw.Description,
    longDescription: raw.LongDescription,
    image: raw.ImageURL,
    redeemed: !!raw.Redeemed,
    couponProgram: raw.ProgramID,
    startDate: moment(raw.StartDate).toISOString(),
    expiryDate: moment(raw.ExpiryDate).toISOString(),
    formattedStartDate: new Date(raw.StartDate).toLocaleDateString(),
    formattedExpiryDate: new Date(raw.ExpiryDate).toLocaleDateString(),
    expired:
      !!raw.ExpiryDate &&
      new Date(raw.ExpiryDate).getTime() < new Date().getTime(),
    unstarted:
      !!raw.StartDate &&
      new Date(raw.StartDate).getTime() > new Date().getTime(),
  };
}
