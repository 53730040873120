import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import SectionTitle from './SectionTitle';
import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import LocationSummary from './LocationSummary';
import OrderTypeSummary from './OrderTypeSummary';
import TimeSummary from './TimeSummary';
import PaymentMethods from './PaymentMethods';
import getCurrentPaymentMethod from '../selectors/getCurrentPaymentMethod';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import MustSignIn from './MustSignIn';
import PurchaserDetails from './PurchaserDetails';
import { Message } from './OrderMessages';
import { validateFutureMenuItems } from '../thunks/validateFutureMenuItems';

const {
  getSaleType,
  getGuestOrderingAvailable,
  getMember,
  getShowMaxValue,
  getMaxOrderValue,
  getBypassDeliveryDetails,
} = OrderingSelectors;
const { useFormattedCurrency } = OrderingHooks;
interface Section {
  title: string;
  component: React.ComponentType;
  removeExtraPadding?: boolean;
}

const OrderSummary: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const saleType = useAppSelector(getSaleType);
  const isPickupOrTakeAway =
    saleType === SALE_TYPE.CATERING ||
    saleType === SALE_TYPE.PICKUP ||
    saleType === SALE_TYPE.TAKE_AWAY ||
    saleType === SALE_TYPE.WEB_ORDERING;
  const isDineIn = saleType === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER;
  const isDelivery = saleType === SALE_TYPE.DELIVERY;
  const guestOrderingAvailable = useAppSelector(getGuestOrderingAvailable);
  const member = useAppSelector(getMember);

  const mustSignIn = !guestOrderingAvailable && !member;
  const currentPaymentMethod = useAppSelector(getCurrentPaymentMethod);
  const maxOrderValueReached = useAppSelector(getShowMaxValue);
  const maxOrderValueRule = useAppSelector(getMaxOrderValue);

  const maxOrderValuePrice = useFormattedCurrency({
    cents: maxOrderValueRule?.MaximumOrder,
    hideZeroCents: true,
  });
  const [sections, setSections] = useState<Section[] | null>(null);

  const bypassDeliveryDetails = useAppSelector(getBypassDeliveryDetails);

  useEffect(() => {
    if (isDelivery) {
      dispatch(validateFutureMenuItems());
    }
    getSections();
  }, [mustSignIn, saleType]);

  const getSections = () => {
    const parts: Section[] = [];

    // location
    if (isPickupOrTakeAway || isDineIn || bypassDeliveryDetails) {
      parts.push({
        title: `${t('title.location')}`,
        component: LocationSummary,
      });
    }

    //order type
    parts.push({
      title: `${t('title.orderType')}`,
      component: OrderTypeSummary,
    });

    // delivery - pick up time

    if (isDelivery || isPickupOrTakeAway) {
      parts.push({
        title: isPickupOrTakeAway
          ? `${t('title.pickupTime')}`
          : `${t('title.deliveryTime.heading')}`,
        component: TimeSummary,
      });
    }

    // if (!mustSignIn) {
    parts.push({
      title: `${t('title.customerDetails')}`,
      component: PurchaserDetails,
    });
    // }

    if (
      currentPaymentMethod !== PAYMENT_METHOD.FREE.toString() &&
      !mustSignIn &&
      !maxOrderValueReached
    ) {
      parts.push({
        title: t('title.paymentDetails'),
        component: PaymentMethods,
        removeExtraPadding: true,
      });
    }

    setSections(parts);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5em',
      }}
    >
      {sections?.map(
        (section, index) =>
          section.component && (
            <div key={index}>
              <SectionTitle value={section.title} containerStyle={{ padding: '0 2.25em' }} />
              <section.component />
            </div>
          ),
      )}

      <MustSignIn />
      {maxOrderValueReached && (
        <Message
          showAlertIcon
          message={
            maxOrderValueRule!.Message
              ? maxOrderValueRule!.Message
              : t('maxValueWarning', {
                  amount: `${maxOrderValuePrice}`,
                })
          }
        />
      )}
    </div>
  );
};

export default OrderSummary;
