import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';
import { IS_PRE_RELEASE_VERSION } from '../constants';
import EVENTS from '../constants/events';
import { logEvent, logUser } from '../utils/analytics';
import debug, { debugEnabled } from '../utils/debug';
import locationDebugger from '../utils/locationDebugger';
import { cleanAddressCache } from '../slices/addressCache';
import { RootState } from 'store';
import { applyStringConfiguration } from './applyStringConfiguration';
import { configureApiHandler } from './configureApiHandler';
import { checkDate } from './checkDate';
import { syncMember } from './syncMember';
import { sync } from './sync';

const { clearBuffer, validateOrder, fetchAdvancedCustomHours } = OrderingOperations;
const { getMember, getIsAdvancedCustomHoursEnabled } = OrderingSelectors;

export const boot = createAsyncThunk(
  'boot',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    debug('application boot started');
    console.log('expected hosting path:', process.env.PUBLIC_URL);
    console.log(
      'version:',
      `${process.env.REACT_APP_VERSION_ALIAS || process.env.REACT_APP_VERSION} (${
        IS_PRE_RELEASE_VERSION ? 'PRE-RELEASE' : 'RELEASED'
      })`,
    );

    try {
      const member = getMember(getState() as RootState);
      const stringOverrides = (getState() as RootState).config.stringOverrides;

      // setup location helper for Redcat staff
      window.locations = locationDebugger;
      window.stores = locationDebugger;
      window.validate = () => {
        const memberPresent = Boolean(member);

        dispatch(
          validateOrder({
            authenticationMethod: memberPresent ? 'member' : 'none',
          }),
        );
      };

      if (!member) {
        logUser(null);
      }

      await dispatch(configureApiHandler());
      dispatch(applyStringConfiguration({ overrides: stringOverrides }));
      dispatch(checkDate());
      dispatch(syncMember());
      dispatch(clearBuffer({ preserveReservationId: false }));
      dispatch(cleanAddressCache());
      dispatch(sync());

      // Fetching Custom Hours From New APIs only if the config is enabled
      if (getIsAdvancedCustomHoursEnabled(getState() as RootState)) {
        dispatch(fetchAdvancedCustomHours({}));
      }

      debug('application boot complete');
      logEvent(EVENTS.BOOT);
    } catch (e) {
      console.error('Boot thunk failed', e);
      return rejectWithValue(e);
    }
  },
);
