import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchStockBalances } from '../operations';

const useStockPolling = (
  enableStockPolling: boolean,
  stockPollingInterval: number,
) => {
  if (!enableStockPolling || !(stockPollingInterval > 0)) return;
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchStockBalances());
    const timerId = setInterval(() => {
      dispatch(fetchStockBalances());
    }, stockPollingInterval * 1000);
    return () => clearInterval(timerId);
  }, []);
};

export default useStockPolling;
