// Imports
import React, { useEffect, useState } from 'react';
import Modal from '../components/Modal';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { Spinner } from '@vidstack/react';
import getMenuOverlayAdvertisements from '../selectors/getMenuOverlayAdvertisements';
import { OrderingConstants } from 'polygon-ordering';
import { setMenuOverlayAdsDisplayed } from '../slices/menuOverlayAdsDisplayed';
import { setCurrentModalId } from '../slices/currentModalId';

const { AD_MEDIA_TYPE } = OrderingConstants;
export const MENU_ADVERTISEMENT_MODAL = 'MENU_ADVERTISEMENT_MODAL';

const DEFAULT_IMAGE_AD_DISPLAY_TIME = 3000;

/*
  Sample Vertical Video
  Vertical : 'https://videos.pexels.com/video-files/4678261/4678261-sd_360_640_25fps.mp4'
*/

const MenuAdvertisementModal = () => {
  const dispatch = useAppDispatch();
  const menuOverlayAdvertisements = useAppSelector(getMenuOverlayAdvertisements) || [];

  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [currentAd, setCurrentAd] = useState(menuOverlayAdvertisements[0]);

  // Function that triggers after one ad has ended
  // If we are in the last ad then we start from first again so that we can create a loop of ads
  const handleEnded = () => {
    if (menuOverlayAdvertisements.length > 1) {
      setCurrentAdIndex(prevIndex =>
        prevIndex < menuOverlayAdvertisements.length - 1 ? prevIndex + 1 : 0,
      );
    }
  };

  useEffect(() => {
    setCurrentAd(menuOverlayAdvertisements[currentAdIndex]);

    // If the current ad is an image, set a timer to move to the next ad after provided seconds
    if (currentAd && currentAd?.mediaType === AD_MEDIA_TYPE.image) {
      const adDisplayTime = currentAd?.duration * 1000 || DEFAULT_IMAGE_AD_DISPLAY_TIME;

      const timer = setTimeout(handleEnded, adDisplayTime);
      return () => clearTimeout(timer); // Clear the timer if the component unmounts or ad changes
    }
  }, [currentAdIndex, currentAd]);

  const handleModalClose = () => {
    dispatch(setCurrentModalId(null));
    // Storing in session storage that we have displayed the ads for the current session user
    dispatch(setMenuOverlayAdsDisplayed(true));
  };

  return (
    <Modal
      containerStyle={{ borderRadius: '16px' }}
      desktopContainerStyle={{ padding: 0, gap: 0 }}
      backdropStyle={{ justifyContent: 'center' }}
      desktopMinWidth={666}
      desktopMaxWidth={666}
      enableCloseButton={true}
      closeButtonLeft
      handleClose={handleModalClose}
    >
      {currentAd && currentAd.mediaType === AD_MEDIA_TYPE.video ? (
        <>
          {/* @ts-ignore */}
          <MediaPlayer
            title={currentAd?.title}
            src={currentAd?.imageUrl}
            autoPlay
            muted
            playsInline
            aspectRatio="1"
            style={{ backgroundColor: 'black' }}
            loop={menuOverlayAdvertisements.length === 1}
          >
            <MediaProvider onEnded={handleEnded} />
            <div className="vds-buffering-indicator">
              <Spinner.Root className="vds-buffering-spinner">
                <Spinner.Track className="vds-buffering-track" />
                <Spinner.TrackFill className="vds-buffering-track-fill" />
              </Spinner.Root>
            </div>
          </MediaPlayer>
        </>
      ) : (
        <img
          src={currentAd?.imageUrl}
          alt={currentAd?.title}
          style={{ width: '100%', height: 'auto' }}
        />
      )}
    </Modal>
  );
};

export default MenuAdvertisementModal;
