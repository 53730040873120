import lodash from 'lodash';
import tallyAndValidateChoiceSets from './tallyAndValidateChoiceSets';
import choiceSetsWithQuantities from './choiceSetsWithQuantities';
import tallyAndValidateNestedChoicesets from './tallyAndValidateNestedChoicesets';
import nestedChoiceSetsWithQuantities from './nestedChoiceSetsWithQuantities';
import adjustChoiceSetsForQuantityDiscount from './adjustChoiceSetsForQuantityDiscount';
import adjustChoiceSetsForQuantityDiscountV2 from './adjustChoiceSetsForQuantityDiscountV2';
import adjustNestedChoiceSetsForQuantityDiscountV2 from './adjustNestedChoiceSetsForQuantityDiscountV2';
import enhanceItem from './enhanceItem';

export default function enhancePurchase(
  purchase: _Purchase,
  menu: Menu,
  choiceOrderingMethod: ChoiceOrderingMethod,
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig,
  previouslyStaged?: boolean | undefined,
): Purchase | undefined {
  if (purchase.unavailable) return;

  const rawItem = menu.items[purchase.itemId];
  if (!rawItem) return;
  const rootItem = enhanceItem(rawItem, menu);

  if (!rootItem) return;
  const isComposite = !!rootItem.sizes.length;

  const item =
    (isComposite && rootItem.sizes.find(s => s.id === purchase.sizeId)) ||
    rootItem;

  // BOH required fields for items (needed for coupons)
  const _plucode = item.plucode ?? rootItem.plucode;
  const _class = item.class ?? rootItem.class;
  const _category = item.category ?? rootItem.category;
  // if (!_plucode || !_class || !_category) return;

  const isNested = !!item.choiceSets.find(
    set =>
      'nestedIngredients' in set &&
      set.nestedIngredients &&
      // checkbox is used to implement the "make it a meal" buttons found on olo2,
      set.displayType !== 'checkbox',
  );

  let choiceSets: (ValidatedChoiceSet | ValidatedNestedChoiceSet)[] = [];

  if (isNested) {
    choiceSets = tallyAndValidateNestedChoicesets(
      nestedChoiceSetsWithQuantities(
        item.choiceSets as NestedChoiceSet[],
        purchase.choiceSelections as NestedChoiceSelections,
        choiceOrderingMethod,
        choiceSetDiscountConfig,
        previouslyStaged,
      ),
    );
  } else {
    choiceSets = tallyAndValidateChoiceSets(
      choiceSetsWithQuantities(
        item.choiceSets,
        purchase.choiceSelections as ChoiceSelections,
      ),
      choiceSetDiscountConfig,
    );
  }
  // filter out conditional choicesets where condition has not been met
  let filterList: string[] = [];

  lodash.keys(item.conditionalChoiceSets).forEach(conditionChoiceId => {
    const found = Boolean(
      choiceSets.find(choiceSet => {
        return Boolean(
          choiceSet.choices.find(
            choice => choice.id === conditionChoiceId && choice.quantity,
          ),
        );
      }),
    );

    if (!found) {
      filterList = lodash.uniq([
        ...filterList,
        ...item.conditionalChoiceSets[conditionChoiceId],
      ]);
    }
  });

  choiceSets = choiceSets.filter(
    choiceSet => !filterList.includes(choiceSet.key),
  );

  if (choiceSetDiscountConfig) {
    const {
      useChoiceSetDiscountMap = true,
      choiceSetKey,
      // choiceSetDiscountMap = {},
      discountPlu,
    } = choiceSetDiscountConfig;

    if (!useChoiceSetDiscountMap && choiceSetKey && discountPlu) {
      choiceSets = adjustChoiceSetsForQuantityDiscount(
        choiceSets,
        choiceSetDiscountConfig,
      );
    } else if (useChoiceSetDiscountMap) {
      if (isNested) {
        choiceSets = adjustNestedChoiceSetsForQuantityDiscountV2(
          choiceSets,
          choiceSetDiscountConfig,
          purchase.choiceSelections,
        );
      } else {
        choiceSets = adjustChoiceSetsForQuantityDiscountV2(
          choiceSets,
          choiceSetDiscountConfig,
        );
      }
    }
  }

  const choicesWithQuantity = choiceSets
    .reduce(
      (acc: ChoiceWithQuantity[], set): ChoiceWithQuantity[] => [
        ...acc,
        ...set.choices,
      ],
      [],
    )
    .filter(choice => choice.quantity);

  const valid = Object.values(choiceSets).reduce(
    (acc, choiceSet) => acc && Boolean(choiceSet.valid),
    true,
  );

  const popularChoices: PopularChoice[] = [];

  item.popularChoices.forEach(choiceId => {
    choiceSets.forEach(choiceSet => {
      choiceSet.choices.forEach(choice => {
        if (choice.id === choiceId) {
          popularChoices.push({ choice, choiceSetKey: choiceSet.key });
        }
      });
    });
  });

  return {
    mimCategoryId: purchase.mimCategoryId,
    id: purchase.id,
    item: rootItem,
    choiceSets,
    substitutionSets: item.substitutionSets,
    valid,
    quantity: purchase.quantity,
    choicesWithQuantity,
    baseMoneyPrice: item.baseMoneyPrice,
    basePointsPrice: item.basePointsPrice,
    plucode: _plucode || '',
    class: _class || '',
    category: _category || '',
    popularChoices,
    brandId: purchase.brandId,
    sizeId: purchase.sizeId,
    choiceSelections: purchase.choiceSelections,
    substitutionSelections: purchase.substitutionSelections,
  };
}
