import { createSelector } from 'reselect';
import getUserUnderage from './getUserUnderage';
import getRootCategory from './getRootCategory';
import getMenuFilterTags from './getMenuFilterTags';
import getMenuFilterPrice from './getMenuFilterPrice';
import getCurrentMenuBrandId from './getCurrentMenuBrandId';
import getMenuSearchText from './getMenuSearchText';
// What is the correct way to import this selector?

const getAllTags = (state: EntireFrontendState) =>
  state.ordering.currentOrder.tagsAllergenDietary;

export default createSelector(
  [
    getRootCategory,
    getMenuSearchText,
    getMenuFilterTags,
    getMenuFilterPrice,
    getAllTags,
    getCurrentMenuBrandId,
    getUserUnderage,
  ],
  (
    rootCategory,
    menuSearchText,
    filters,
    filterPrice,
    tags,
    menuBrandId,
    userUnderAge,
  ) => {
    if (
      menuSearchText === null &&
      !filters &&
      !filterPrice &&
      menuBrandId === null &&
      !userUnderAge
    ) {
      return rootCategory;
    }

    const lowerCaseSearchText = (menuSearchText || '').toLowerCase();
    const filteredTags = filters
      ?.map(f => {
        const filtered = tags.find(t => t.recid === f);
        return filtered;
      })
      .filter(e => e);
    const allergen = filteredTags
      ?.filter(t => t?.tag_type === 'ALLERGEN')
      .map(t => t?.recid);
    const diets = filteredTags
      ?.filter(t => t?.tag_type === 'DIETARY')
      .map(t => t?.recid);
    const custom = filteredTags
      ?.filter(t => t?.tag_type === 'CUSTOM')
      .map(t => t?.recid);

    const getSubCategoriesWithItemsFiltered = (subCategories?: Category[]) => {
      if (!subCategories || !subCategories.length) return [];
      return subCategories
        .map((subCategory: Category) => {
          if (subCategory.subCategories.length) return subCategory;
          return {
            ...subCategory,
            items: subCategory.items.filter(item => {
              let filtered = false,
                allergenFilter = !allergen?.length || !item.tags,
                dietFilter = !diets?.length,
                customFilter = !custom?.length;
              const alcoholFilter = !item.alcoholic || !userUnderAge;

              // get the default size item
              const sizeItem =
                (!!item.sizes.length &&
                  item.sizes.find(s => s.id === item.default_size)) ||
                item;

              const tags = sizeItem.tags;

              const tagSet = new Set(tags);
              if (!diets?.some(dietTag => !tagSet.has(dietTag as number))) {
                dietFilter = true;
              }

              for (const tag of tags || []) {
                if (!allergen?.includes(tag)) {
                  allergenFilter = true;
                } else {
                  allergenFilter = false;
                  break;
                }
                if (custom?.includes(tag)) {
                  customFilter = true;
                  break;
                }
              }
              filtered =
                allergenFilter && dietFilter && customFilter && alcoholFilter;

              // if search text is null then the user is not searching
              // if the search text is an empty string then the user is searching but hasn't typed anything
              // as per wireframes the above case should display no items
              const searchFiltered =
                menuSearchText === null ||
                (menuSearchText !== '' &&
                  (item.name.toLowerCase().includes(lowerCaseSearchText) ||
                    item.description
                      ?.toLowerCase()
                      .includes(lowerCaseSearchText)));

              return (
                searchFiltered &&
                filtered &&
                (filterPrice
                  ? item.baseMoneyPrice <= filterPrice ||
                    item.minSizeBaseMoneyPrice <= filterPrice
                  : true)
              );
            }),
          };
        })
        .filter((subCategory: Category) => {
          if (subCategory.subCategories.length) return true;
          const hasItems = Boolean(subCategory.items.length);
          const matchesBrandID =
            menuBrandId == null || subCategory.brandId === menuBrandId;

          return hasItems && matchesBrandID;
        });
    };

    return {
      ...rootCategory,
      subCategories: getSubCategoriesWithItemsFiltered(
        rootCategory?.subCategories,
      ).map((subCategory: Category) => {
        return {
          ...subCategory,
          subCategories: getSubCategoriesWithItemsFiltered(
            subCategory?.subCategories,
          ),
        };
      }),
    };
  },
);
