import { OFFER_TYPE } from '../../constants/offerType';

export default function calculateEffectiveCouponMinSpend(
  selectedOffer: Offer,
  discountInclusive: boolean = false,
) {
  if (!selectedOffer || !selectedOffer.MinOrderValue) {
    return 0;
  }

  const minValue = selectedOffer.MinOrderValue * 100;
  let effectiveMinSpend = 0;

  if (selectedOffer.type === OFFER_TYPE.PERCENTAGE_OFF_ORDER) {
    // Calculate the amount of cart total (before surcharges - cart total) that is required for
    // coupon minimum spend constraint to be satisfied after its application onto the cart total
    // e.g. coupon min spend is 20% off orders over $50.

    if (discountInclusive) {
      // case 1. Config "couponDiscountInclusive" is true:
      //    actual minimum spend = (100 * minimum spend) / (100 - percent discount)
      //    actual minimum spend = (100 * 50) / (100 - 25) = $62.50
      //    for difference calculation, this would be: difference = $62.50 - cart total

      if (selectedOffer.amount >= 100) {
        // Catch divide by 0

        effectiveMinSpend = minValue;
      } else {
        effectiveMinSpend = (100 * minValue) / (100 - selectedOffer.amount);
      }
    } else {
      // case 2. Config "couponDiscountInclusive" is false:
      //    actual minimum spend = minimum spend = $50
      //    for difference calculation, this would be: difference = $50 - cart total

      effectiveMinSpend = minValue;
    }
  } else if (selectedOffer.type === OFFER_TYPE.AMOUNT_OFF_ORDER) {
    if (discountInclusive) {
      effectiveMinSpend = minValue + selectedOffer.amount * 100;
    } else {
      effectiveMinSpend = minValue;
    }
  }
  return Math.max(
    0,
    effectiveMinSpend >= 0
      ? Math.ceil(effectiveMinSpend)
      : Math.floor(effectiveMinSpend),
  );
}
