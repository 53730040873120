import { createAsyncThunk } from '@reduxjs/toolkit';
import Api, { ApiResponse, FetchParams } from '../utils/Api';
import {
  getOrderingProvider,
  getShowExpiredCoupons,
  getShowRedeemedCoupons,
} from '../selectors/config';
import { getMember } from '../selectors';
import { getLocations } from '../selectors';
import { ORDERING_PROVIDER } from '../constants/orderingProvider';
import processOffer from '../utils/processors/processOffer';
import { addOffers, setOffers } from '../reducers/currentOrder/offers';

export const fetchLocationOffers = createAsyncThunk(
  'fetchLocationOffers',
  async (data: undefined, { dispatch, getState, rejectWithValue }) => {
    try {
      const orderingProvider = getOrderingProvider(
        getState() as EntireFrontendState,
      );
      const member = getMember(getState() as EntireFrontendState);
      const locations = getLocations(getState() as EntireFrontendState);

      const authenticationMethod =
        orderingProvider === ORDERING_PROVIDER.KIOSK ? 'trusted' : 'member';

      const showRedeemedCoupons = getShowRedeemedCoupons(
        getState() as EntireFrontendState,
      );
      const showExpiredCoupons = getShowExpiredCoupons(
        getState() as EntireFrontendState,
      );

      const path =
        orderingProvider === ORDERING_PROVIDER.KIOSK
          ? `/api/v1/stores/${
              locations![Object.keys(locations!)[0]].id
            }/trusted-pospoints/coupons/${member?.memberNumber}`
          : `/api/v1/profile/coupons?hideexpired=${
              showExpiredCoupons ? '0' : '1'
            }`;

      const params: FetchParams = {
        path,
        method: 'GET',
      };

      const response: ApiResponse = await Api.fetch(
        params,
        authenticationMethod,
      );

      dispatch(addOffers((response.data as RawOffer[]).map(processOffer)));
    } catch (e) {
      console.warn('Fetch member offers failed');
      return rejectWithValue(e);
    }
  },
);
