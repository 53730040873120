import { createSelector } from 'reselect';
import lodash from 'lodash';

import getStagedPurchases from './getStagedPurchases';
import getStockBalances from './getStockBalances';

const checkIfItemSoldOut = (id: any, stockBalances: StockBalance[] | null) => {
  const balanceObject = lodash.find(stockBalances, ['PLUCode', parseInt(id)]);
  const balance = balanceObject?.Balance;
  if (balance === 0) {
    return true;
  }
  return false;
};

export default createSelector(
  [getStagedPurchases, getStockBalances],
  (stagedPurchases, stockBalances): boolean => {
    let cartContainsSoldOutItems = false;

    // TODO: Get this to handle arbitrary levels of choices e.g. meal deal -> burger -> ingredient
    stagedPurchases.map(stagedPurchase => {
      if (checkIfItemSoldOut(stagedPurchase.item.plucode, stockBalances)) {
        cartContainsSoldOutItems = true;
      }
      if (stagedPurchase.choicesWithQuantity.length) {
        stagedPurchase.choicesWithQuantity.map(choiceWithQuantity => {
          if (checkIfItemSoldOut(choiceWithQuantity.plucode, stockBalances)) {
            cartContainsSoldOutItems = true;
          }
        });
      }
    });

    return cartContainsSoldOutItems;
  },
);
