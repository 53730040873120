// Imports
import moment, { Moment } from 'moment-timezone';

export default function generateMomentsInRange(
  earliestMoment: Moment,
  latestMoment: Moment,
  minuteStep: number = 1,
) {
  const approxEnd = latestMoment.clone().add(1, 'hour').startOf('hour');

  const results = [];

  let i = earliestMoment.clone().startOf('hour');

  while (i <= approxEnd) {
    results.push(i.clone());
    i = i.add(minuteStep, 'minute');
  }

  return results.filter(time => time >= earliestMoment && time <= latestMoment);
}

export function generateMomentsInRangeFDO(
  earliestMoment: Moment,
  latestMoment: Moment,
  minuteStep: number = 1,
  storeTimeSlots?: Array<{
    openingTime: string;
    closingTime: string;
  }>,
) {
  if (storeTimeSlots && storeTimeSlots.length) {
    let results: Moment[] = [];

    /*
    Store has multiple slots for example first one 00:00 -> 03:00 and then 11:00 -> 23:59
    so for display times in website UI making the times
    */

    // Iterating through each store time slot
    storeTimeSlots.forEach(slot => {
      const { openingTime, closingTime } = slot;
      const startMoment = moment.max(moment(openingTime).parseZone(), earliestMoment);
      const endMoment = moment.min(moment(closingTime).parseZone(), latestMoment);

      // Generate moments within the slot range
      const slotMoments = generateMomentsInRange(startMoment, endMoment, minuteStep);
      results.push(...slotMoments);
    });

    return results;
  }

  // Calling the above default generateMomentsInRange Function
  return generateMomentsInRange(earliestMoment, latestMoment, minuteStep);
}
