import { createSelector } from 'reselect';
import { RootState } from 'store';
import {
  filterAdsByCurrentTime,
  filterAdsByOfferTimes,
  getAdMediaType,
} from '../utils/ordering/advertisements';
import getSaleType from './getSaleType';
import getLocation from './getLocation';
import getMemberOffers from './getMemberOffers';
import { AD_MEDIA_TYPE } from '../constants';

const selectAllAdvertisements = (state: RootState) =>
  state.ordering.advertisements;
const selectProfile = (state: RootState) => state.profile;

export default createSelector(
  [
    selectAllAdvertisements,
    getSaleType,
    getLocation,
    selectProfile,
    getMemberOffers,
  ],
  (allAdvertisements, saleType, location, profile, offers) => {
    if (!allAdvertisements) {
      return undefined;
    }

    // Init valid ads
    let validAds = allAdvertisements;

    // Enhance advertisements with mediaType and priority
    validAds = validAds.map((ad: Advertisement) => ({
      ...ad,
      mediaType: getAdMediaType(ad.imageUrl),
      priority: ad.priority ?? -Number.MAX_SAFE_INTEGER,
    }));

    // Filtering valid ads based on some conditions
    validAds = validAds.filter((ad: Advertisement) => {
      const { groups, couponPrograms, saleTypes, stores, mediaType } = ad;

      const isGroupValid =
        !groups.length || groups.includes(String(profile?.groupId));

      const isCouponProgramValid =
        !couponPrograms.length ||
        offers?.some(
          ({ couponProgram, expired }: MemberOffer) =>
            couponPrograms.includes(couponProgram) && !expired,
        );
      const isValidSaleType =
        !saleTypes.length ||
        saleTypes.some(type => String(type) === String(saleType));

      const isValidLocation =
        !stores.length ||
        (location &&
          stores.some(store => String(store) === String(location.id)));
      const isAdMediaUnknown = mediaType === AD_MEDIA_TYPE.unknown;

      return (
        isGroupValid &&
        isCouponProgramValid &&
        isValidSaleType &&
        isValidLocation &&
        !isAdMediaUnknown
      );
    });

    // Filtering valid ads by current time requirements
    validAds = filterAdsByCurrentTime(validAds);
    // Filtering valid ads by offer time requirements
    validAds = filterAdsByOfferTimes(validAds, offers);

    // Sorting advertisements by priority
    return validAds.sort(
      (a: Advertisement, b: Advertisement) => b.priority - a.priority,
    );
  },
);
