import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { Modal } from 'reactstrap';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { setCurrentModal } from '../thunks/setCurrentModal';
import ModalHeader from '../components/ModalHeader';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import StandardButton from '../components/StandardButton';
import DeliveryTime from '../components/DeliveryTime';
import DeliveryAddress from '../components/DeliveryAddress';
import { Formik, FormikProps } from 'formik';
import { OrderingSelectors, OrderingConstants, OrderingOperations } from 'polygon-ordering';
import moment from 'moment';
import DeliveryMap from '../components/DeliveryMap';
import DeliveryTimeWarning from '../components/DeliveryTimeWarning';
import { addToRecentLocations } from '../slices/recentLocations';
import SaveAddressModal from './SaveAddressModal';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
import DeliveryNotes from '../components/DeliveryNotes';
import DeliveryLocations from '../components/DeliveryLocations';
import { RiArrowRightFill } from 'react-icons/ri';
import Text from '../components/Text';
import getDisplayableSaleTypes from '../selectors/getDisplayableSaleTypes';
import { adjustSaleType } from '../thunks/adjustSaleType';
import { applyBufferDeliveryEstimate } from '../thunks/applyBufferDeliveryEstimate';
import { confirmLocationEstimate } from '../thunks/confirmLocationEstimate';
import { performDeliveryEstimate } from '../thunks/performDeliveryEstimate';
import { adjustOrder } from '../thunks/adjustOrder';
import { getEnableMultipleDeliveryEstimate } from '../libs/polygon-ordering/src/selectors/config';
import { setDeliveryEstimateFailed } from '../slices/deliveryEstimateFailed';

import { updateLoadingDependencies } from '../slices/loadingDependencies';

export const DELIVERY_DETAILS_MODAL_ID = 'DELIVERY_DETAILS_MODAL_ID';
const { ASAP_TIME } = OrderingConstants;
const {
  getBufferDeliveryTime,
  getDeliveryReadyToApply,
  getDeliveryRequiresLocationSelection,
  getKeyOrderPropertyUpdateInProgress,
  getMember,
  getLocationDeliveryEstimates,
  getLocationsForDisplay,
  getBypassDeliveryDetails,
} = OrderingSelectors;
const { setDeliveryNotes } = OrderingOperations;

const DeliveryDetails = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const keyOrderPropertyUpdateInProgress = useAppSelector(getKeyOrderPropertyUpdateInProgress);
  const bufferDeliveryTime = useAppSelector(getBufferDeliveryTime);
  const desiredDeliveryTime = useAppSelector(state => state.desiredDeliveryTime);
  const effectiveDeliveryAddress = useAppSelector(state => state.desiredDeliveryAddress);
  const effectiveDeliveryTime = desiredDeliveryTime || bufferDeliveryTime;
  const recentLocations = useAppSelector(state => state.recentLocations);
  const deliveryReadyToApply = useAppSelector(getDeliveryReadyToApply);
  const deliveryRequiresLocationSelection = useAppSelector(getDeliveryRequiresLocationSelection);
  const [selectedLocationId, setSelectedLocationId] = useState<null | string>(null);
  const deliveryEstimateInProgress = useAppSelector(state => state.deliveryEstimateInProgress);
  const displayableSaleTypes = useAppSelector(getDisplayableSaleTypes);

  const member = useAppSelector(getMember);
  const locations = useAppSelector(getLocationsForDisplay);
  const locationDeliveryEstimates = useAppSelector(getLocationDeliveryEstimates);
  const enableMultipleDeliveryEstimate = useAppSelector(getEnableMultipleDeliveryEstimate);

  const inProgress = keyOrderPropertyUpdateInProgress || deliveryEstimateInProgress;
  const deliveryEstimateFailed = useAppSelector(state => state.deliveryEstimateFailed);

  useEffect(() => {
    dispatch(setDeliveryEstimateFailed(false));

    // Make the following actions only applied when it's on the initial delivery details screen and not on the add delivery address screen or when there is already an effective delivery address.
    if (!effectiveDeliveryAddress || formValues?.page !== 1) return;

    dispatch(
      performDeliveryEstimate({
        deliveryAddress: effectiveDeliveryAddress?.formatted_address!,
        desiredDeliveryTime: effectiveDeliveryTime,
      }),
    );
  }, [effectiveDeliveryAddress, enableMultipleDeliveryEstimate]);

  useEffect(() => {
    // If multi delivery estimate is disabled then auto select the first location
    const singleDeliverySingleLocation =
      !enableMultipleDeliveryEstimate && locationDeliveryEstimates.length;
    // If multi delivery estimate is enabled but there is only one location to select then auto select it
    const multiDeliverySingleLocation =
      enableMultipleDeliveryEstimate && locationDeliveryEstimates.length === 1;
    // If multi delivery estimate is enabled and there is multiple locations to select from then don't auto select anything. The user has to manually select one.

    if (singleDeliverySingleLocation || multiDeliverySingleLocation) {
      const locationId = locations[locationDeliveryEstimates[0]?.locationId]?.id;
      if (locationId) setSelectedLocationId(locationId);
    }
  }, [locationDeliveryEstimates.length, enableMultipleDeliveryEstimate]);

  const bypassDeliveryDetails = useAppSelector(getBypassDeliveryDetails);

  const formRef = useRef<FormikProps<DeliveryDetailsModalForm>>(null);
  const formValues = formRef.current?.values;

  const modalParams = useAppSelector(state => state.modalParams);

  const hasDeliveryAddress = Boolean(effectiveDeliveryAddress?.formatted_address!);

  useEffect(() => {
    if (formValues?.page == 1 && hasDeliveryAddress) {
      dispatch(
        performDeliveryEstimate({
          deliveryAddress: effectiveDeliveryAddress?.formatted_address!,
          desiredDeliveryTime: effectiveDeliveryTime,
        }),
      );
    }
  }, [effectiveDeliveryAddress, effectiveDeliveryTime]);

  useEffect(() => {
    if (
      modalParams?.showOnlyTime ||
      (hasDeliveryAddress && !Boolean(formValues?.page) && !deliveryEstimateFailed)
    ) {
      dispatch(
        performDeliveryEstimate({
          deliveryAddress: effectiveDeliveryAddress?.formatted_address!,
          desiredDeliveryTime:
            effectiveDeliveryTime == 'Invalid date' ? ASAP_TIME : effectiveDeliveryTime,
          fromAddDelivery: formValues?.page != 1 && !modalParams?.showOnlyTime,
        }),
      );
    }
  }, []);

  return (
    <Modal
      isOpen={true}
      scrollable
      fullscreen={deviceTypeMobile ? true : false}
      toggle={() => {
        dispatch(setCurrentModal(null));
        dispatch(updateLoadingDependencies({ handleURLParamsInProgress: false }));
      }}
      fade={false}
    >
      <Formik
        innerRef={formRef}
        initialValues={
          {
            deliveryNotes: '',
            buttonActive: false,
            showSaveAddressModal: false,
            saveAddressAlias: '',
            formatted_address: '',
            street_name: '',
            locality: '',
            lat: '',
            lng: '',
            resetMapLocation: false,
            page: !modalParams?.showOnlyTime && bypassDeliveryDetails ? 2 : 1,
            showPicker: effectiveDeliveryTime !== ASAP_TIME ? true : false,
            saveAddress: false,
            time:
              effectiveDeliveryTime !== ASAP_TIME
                ? moment(effectiveDeliveryTime).format('h:mm a')
                : '',
          } as DeliveryDetailsModalForm
        }
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => {
          return (
            <>
              <SaveAddressModal isOpen={values.showSaveAddressModal} />

              <div style={{ width: '100%' }}>
                <ModalHeader
                  backClick={() => {
                    dispatch(updateLoadingDependencies({ handleURLParamsInProgress: false }));

                    if (values.page === 1 || bypassDeliveryDetails) {
                      dispatch(setCurrentModal(null));
                    } else {
                      setFieldValue('page', values.page - 1);
                    }
                  }}
                  title={
                    values.page === 1
                      ? t(`saleType.${SALE_TYPE.DELIVERY}.name`)
                      : 'Add Delivery Address'
                  }
                />
              </div>

              <div style={{ overflow: 'auto', height: '100vh', paddingBottom: 100 }}>
                {values.page === 1 && (
                  <div>
                    <DeliveryTime />
                    <DeliveryTimeWarning />

                    {!modalParams?.showOnlyTime && (
                      <>
                        <DeliveryAddress />
                        <DeliveryNotes />
                        {deliveryRequiresLocationSelection && (
                          <DeliveryLocations
                            selectedLocationId={selectedLocationId}
                            setSelectedLocationId={id => setSelectedLocationId(id)}
                          />
                        )}

                        {(deliveryEstimateFailed || deliveryRequiresLocationSelection) &&
                          displayableSaleTypes.includes(SALE_TYPE.PICKUP) && (
                            <div style={styles.fallbackToPickupContainer}>
                              <Text
                                themeKey="fallbackToPickupMessage"
                                value={t('fallbackToPickupMessage')}
                              />

                              <StandardButton
                                themeKey="fallbackToPickupButton"
                                label={t('button.fallbackToPickup')}
                                RightIconComponent={RiArrowRightFill}
                                disabled={inProgress}
                                onClick={() =>
                                  dispatch(
                                    adjustSaleType({
                                      saleType: SALE_TYPE.PICKUP,
                                      locationId: selectedLocationId,
                                    }),
                                  )
                                }
                              />
                            </div>
                          )}
                      </>
                    )}
                  </div>
                )}
                {values.page === 2 && <DeliveryMap />}
              </div>
              <div className="position-absolute" style={{ bottom: 0, width: '100%', padding: 25 }}>
                <StandardButton
                  label="Continue"
                  themeKey="continueButton"
                  onClick={async () => {
                    dispatch(updateLoadingDependencies({ handleURLParamsInProgress: false }));

                    if (values.page === 1) {
                      if (deliveryReadyToApply || deliveryRequiresLocationSelection) {
                        await dispatch(
                          adjustOrder({
                            saleType: SALE_TYPE.DELIVERY,
                            locationId: selectedLocationId!,
                            confirmLocationDeliveryEstimate: true,
                          }),
                        );
                      }

                      if (deliveryReadyToApply) {
                        logEvent(EVENTS.CONFIRM_DELIVERY_DETAILS);
                        dispatch(applyBufferDeliveryEstimate());
                      } else {
                        if (deliveryRequiresLocationSelection) {
                          dispatch(confirmLocationEstimate({ locationId: selectedLocationId! }));
                        } else {
                          logEvent(EVENTS.CHECK_DELIVERY_DETAILS);
                        }
                      }

                      if (values.deliveryNotes !== '') {
                        dispatch(setDeliveryNotes(values.deliveryNotes));
                        logEvent(EVENTS.CHANGE_DRIVER_NOTES);
                      }

                      if (modalParams?.showOnlyTime) {
                        await dispatch(setCurrentModal(null));
                      }
                    }

                    if (values.page === 2) {
                      if (values.saveAddress && member) {
                        setFieldValue('showSaveAddressModal', true);
                      } else {
                        if (!bypassDeliveryDetails) {
                          dispatch(
                            performDeliveryEstimate({
                              deliveryAddress: effectiveDeliveryAddress?.formatted_address!,
                              desiredDeliveryTime: effectiveDeliveryTime,
                            }),
                          );
                          setFieldValue('page', values.page - 1);
                        } else {
                          dispatch(
                            performDeliveryEstimate({
                              deliveryAddress: effectiveDeliveryAddress?.formatted_address!,
                              desiredDeliveryTime: ASAP_TIME,
                              fromAddDelivery: true,
                            }),
                          )
                            .unwrap()
                            .then(() => {
                              dispatch(setCurrentModal(null));
                              dispatch(applyBufferDeliveryEstimate());
                              dispatch(setCurrentModal(null));
                            });
                        }

                        const effectiveAddressAlreadyInRecents = Boolean(
                          recentLocations?.filter(
                            location => location.street_name === values.street_name,
                          ).length > 0,
                        );

                        if (!effectiveAddressAlreadyInRecents) {
                          dispatch(
                            addToRecentLocations({
                              formatted_address: values.formatted_address,
                              street_name: values.street_name,
                              locality: values.locality,
                              lat: Number(values.lat),
                              lng: Number(values.lng),
                            }),
                          );
                        }
                      }
                    }
                  }}
                  disabled={
                    (effectiveDeliveryAddress
                      ? values.page === 1
                        ? false
                        : !values.buttonActive
                      : !values.buttonActive) ||
                    ((deliveryReadyToApply || deliveryRequiresLocationSelection) &&
                      !selectedLocationId &&
                      !bypassDeliveryDetails) ||
                    deliveryEstimateFailed ||
                    inProgress
                  }
                  showSpinner={keyOrderPropertyUpdateInProgress}
                  containerStyle={{ marginLeft: '10%', marginRight: '10%' }}
                />
              </div>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default DeliveryDetails;

const styles: Styles = {
  fallbackToPickupContainer: {
    // backgroundColor: 'pink',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',

    // minHeight: 100,
    padding: 25,
  },
};
