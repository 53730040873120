import 'bootstrap/dist/css/bootstrap.min.css';
//@ts-ignore
import postscribe from 'postscribe';
import React, { useEffect, useRef, useState } from 'react';
import { RiRefreshLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { IN_DEVELOPMENT } from '../constants';
import Drawers from '../drawers/Drawers';
import Modals from '../modals/Modals';
import Routes from '../screens/Routes';
import getThemeLookup from '../selectors/getThemeLookup';
import { setEnableThemeKeyHints } from '../slices/config/enableThemeKeyHints';
import { setPaymentFormFilled } from '../slices/paymentFormFilled';
import { boot } from '../thunks/boot';
import { checkDate } from '../thunks/checkDate';
import { updateCurrentUserLocationAction } from '../thunks/updateCurrentUserLocation';
import combineStyles from '../utils/combineStyles';
import AuthHookSubscriber from './AuthHookSubscriber';
import BrandedFavicon from './BrandedFavicon';
import CanaryCurrentScreen from './CanaryCurrentScreen';
import CanaryScreenSize from './CanaryScreenSize';
import DevTools from './DevTools';
import { MENU_SCREEN_ROUTE } from '../hooks/useRoutes';

export function measureHeight() {
  const MYSTERIOUS_BUG_FACTOR = 15;

  const clientHeight = document?.documentElement?.clientHeight;

  // https://github.com/mvasin/react-div-100vh/issues/51
  const buggedMeasurement =
    clientHeight && clientHeight + MYSTERIOUS_BUG_FACTOR === window.innerHeight;

  return buggedMeasurement ? window.innerHeight : clientHeight;
}

// NOTE: in general please do not use hooks in this project. This is a special case that will be removed
// ? why not Max ?
export function use100vh() {
  const [height, setHeight] = useState(measureHeight);

  useEffect(function () {
    function setMeasuredHeight() {
      setHeight(measureHeight());
    }

    window.addEventListener('resize', setMeasuredHeight);

    return function () {
      return window.removeEventListener('resize', setMeasuredHeight);
    };
  });

  return height;
}

const MaxHeight: React.FC<React.ReactNode> = ({ children }) => {
  const height = use100vh();

  return <div style={{ height }}>{children}</div>;
};

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const firstSyncComplete = useAppSelector(state => state.firstSyncComplete);
  const p = useAppSelector(getThemeLookup);
  const { pathname } = useLocation();
  const paymentFormFilled = useAppSelector(state => state.paymentFormFilled);
  const scriptInjection = useAppSelector(state => state.config.scriptInjection);
  const [userLocationUpdated, setUserLocationUpdated] = useState(false);
  const enableThemeKeyHints = useAppSelector(state => state.config.enableThemeKeyHints);
  window._showThemeHints = () => dispatch(setEnableThemeKeyHints(!enableThemeKeyHints));
  const loadingDependencies = useAppSelector(state => state.loadingDependencies);
  const { redirectRequiresLocationData, fullLocationDataFetching, ...extraDependencies } =
    loadingDependencies;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Loading checks in useEffect here for performance.
  // Multiple dependent conditions may cause uneccessary re-renders.
  // This bundles them all up into one dependant state that will only change when necessary.
  useEffect(() => {
    const loading =
      !firstSyncComplete ||
      (redirectRequiresLocationData &&
        fullLocationDataFetching &&
        location.pathname !== MENU_SCREEN_ROUTE) ||
      Object.values(extraDependencies).includes(true);
    if (loading !== isLoading) setIsLoading(loading);
  }, [
    firstSyncComplete,
    loadingDependencies,
    redirectRequiresLocationData,
    fullLocationDataFetching,
    location.pathName,
    extraDependencies,
  ]);

  useEffect(() => {
    dispatch(boot());
    const dayCheckInterval = setInterval(() => dispatch(checkDate()), 1000 * 60 * 30);
    return () => {
      clearInterval(dayCheckInterval);
    };
  }, []);

  useEffect(() => {
    if (paymentFormFilled) {
      dispatch(setPaymentFormFilled(false));
    }
  }, [pathname]);

  useEffect(() => runScriptInjection(), []);

  useEffect(() => {
    if (!userLocationUpdated) {
      dispatch(updateCurrentUserLocationAction()).then(() => setUserLocationUpdated(true));
    }
  }, []);

  const myRef = useRef<null | HTMLDivElement>(null);

  const runScriptInjection = () => {
    window.requestAnimationFrame(() => {
      const node = myRef.current;
      // node can be null not just undefined
      if (node && scriptInjection) {
        postscribe('#main-container', scriptInjection);
      }
    });
  };

  return (
    <div id="main-container" ref={myRef}>
      <AuthHookSubscriber />
      <CanaryScreenSize />
      <CanaryCurrentScreen />
      <BrandedFavicon />
      <MaxHeight>
        <div style={combineStyles(styles.app, p('app', ['backgroundColor']))}>
          {firstSyncComplete && (
            <>
              <Routes />
              <Modals />
              <Drawers />
            </>
          )}

          {isLoading && (
            <div
              style={combineStyles(
                styles.loadingOverlay,
                p('appLoadingOverlay', ['backgroundColor']),
              )}
            >
              <RiRefreshLine
                className="spinner"
                style={p('appLoadingSpinner', ['fontSize', 'color'])}
              />
            </div>
          )}
        </div>
      </MaxHeight>

      <div id="injected-scripts"></div>

      {IN_DEVELOPMENT && <DevTools />}
    </div>
  );
};

const styles: Styles = {
  app: {
    height: '100%',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
  },
  loadingOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 900,
  },
};

export default App;
