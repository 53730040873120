import PastOrdersScreen, { PAST_ORDERS_SCREEN_ROUTE } from '../screens/PastOrdersScreen';
import FavouritesScreen, { FAVOURITES_SCREEN_ROUTE } from '../screens/FavouritesScreen';
import InitialScreen from '../screens/InitialScreen';
import CompletedOrderScreen from '../screens/CompletedOrderScreen';
import MenuScreen from '../screens/MenuScreen';
import ReviewOrderScreen from '../screens/ReviewOrderScreen';
import RewardsScreen, { REWARDS_SCREEN_ROUTE } from '../screens/RewardsScreen';
import ReplaceCardScreen, { REPLACE_CARD_ROUTE } from '../screens/ReplaceCardScreen';
import ClaimMissingPointsScreen, {
  CLAIM_MISSING_POINTS_ROUTE,
} from '../screens/ClaimMissingPointsScreen';
import OffersScreen, { OFFERS_ROUTE } from '../screens/OffersScreen';
import ProfileScreen, { PROFILE_ROUTE } from '../screens/ProfileScreen';
import PurchaseGiftCardScreen, {
  PURCHASE_GIFT_CARD_ROUTE,
} from '../screens/PurchaseGiftCardScreen';
import GiftCardCheckBalanceScreen, {
  CHECK_BALANCE_ROUTE,
} from '../screens/GiftCardCheckBalanceScreen';
import GiftCardTopUp, { GIFT_CARD_TOP_UP_ROUTE } from '../screens/GiftCardTopUp';
import AddLoyaltyWalletScreen, {
  ADD_LOYALTY_WALLET_ROUTE,
} from '../screens/AddLoyaltyWalletScreen';
import SignUpScreen, { SIGN_UP_ROUTE, REGISTER_NEW_USER_ROUTE } from '../screens/SignUpScreen';
import MemberMoneyScreen, { MEMBER_MONEY_ROUTE } from '../screens/MemberMoneyScreen';
import TransactionsScreen, { TRANSACTIONS_SCREEN } from '../screens/TransactionsScreen';

import { useEffect, useState } from 'react';
import { useAppSelector } from '../app/hooks';

import PickBrand from '../screens/PickBrand';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import ChangePasswordScreen, { CHANGE_PASSWORD_ROUTE } from '../screens/ChangePasswordScreen';
import DeleteAccountScreen, { DELETE_ACCOUNT_ROUTE } from '../screens/DeleteAccountScreen';
import RegisterScreen, { REGISTER_ROUTE } from '../screens/RegisterScreen';
import VerifyAccountScreen, { VERIFY_ACCOUNT_ROUTE } from '../screens/VerifyAccountScreen';
import checkAuthTokenPresent from '../utils/checkAuthTokenPresent';
import { OrderingSelectors } from 'polygon-ordering';
import LoyaltyMigrationScreen, { LOYALTY_MIGRATION_ROUTE } from '../screens/LoyaltyMigrationScreen';
import ClaimPreviousPointsScreen, {
  CLAIM_PREVIOUS_POINTS_ROUTE,
} from '../screens/ClaimPreviousPointsScreen';
import NewPasswordScreen, { NEW_PASSWORD_ROUTE } from '../screens/NewPasswordScreen';
import ChangeLanguageScreen, { CHANGE_LANGUAGE_ROUTE } from '../screens/ChangeLanguageScreen';
import FeedbackScreen, { FEEDBACK_SCREEN } from '../screens/FeedbackScreen';
import FeedbackStoreScreen, { FEEDBACK_STORE_SCREEN } from '../screens/FeedbackStoreScreen';
import FeedbackMenuItemsScreen, {
  FEEDBACK_MENU_ITEMS_SCREEN,
} from '../screens/FeedbackMenuItemsScreen';
import FeedbackDeliveryScreen, {
  FEEDBACK_DELIVERY_SCREEN,
} from '../screens/FeedbackDeliveryScreen';
import FeedbackCompletedScreen, {
  FEEDBACK_COMPLETED_SCREEN,
} from '../screens/FeedbackCompletedScreen';
import RegistrationVerifyScreen, { REGISTRATION_VERIFY } from '../screens/RegistrationVerify';

const { getLocationBrandsWithMenuRepresentation, getMember } = OrderingSelectors;

export const INITIAL_SCREEN_ROUTE = '/';
export const COMPLETED_ORDER_SCREEN_ROUTE = '/completed-order';
export const MENU_SCREEN_ROUTE = '/menu';
export const REVIEW_ORDER_SCREEN_ROUTE = '/review-order';
export const PICK_BRAND_SCREEN_ROUTE = '/pick-brand';

export const giftcardRoutes = [
  PURCHASE_GIFT_CARD_ROUTE,
  CHECK_BALANCE_ROUTE,
  GIFT_CARD_TOP_UP_ROUTE,
  ADD_LOYALTY_WALLET_ROUTE,
];

export const profileRoutesPath = [PROFILE_ROUTE, CHANGE_PASSWORD_ROUTE, DELETE_ACCOUNT_ROUTE];

const profileRoutes: Routes[] = [
  {
    path: PROFILE_ROUTE,
    exact: false,
    component: ProfileScreen,
    private: true,
    useWrapper: true,
  },
  {
    path: CHANGE_PASSWORD_ROUTE,
    exact: false,
    component: ChangePasswordScreen,
    private: true,
    useWrapper: true,
  },
  {
    path: DELETE_ACCOUNT_ROUTE,
    exact: false,
    component: DeleteAccountScreen,
    private: true,
    useWrapper: true,
  },
];

const orderingRoutes: Routes[] = [
  {
    path: INITIAL_SCREEN_ROUTE,
    exact: true,
    component: InitialScreen,
    private: false,
  },
  {
    path: COMPLETED_ORDER_SCREEN_ROUTE,
    exact: false,
    component: CompletedOrderScreen,
    private: false,
  },

  {
    path: MENU_SCREEN_ROUTE,
    exact: false,
    component: MenuScreen,
    private: false,
  },

  {
    path: REVIEW_ORDER_SCREEN_ROUTE,
    exact: false,
    component: ReviewOrderScreen,
    private: false,
  },

  {
    path: VERIFY_ACCOUNT_ROUTE,
    exact: false,
    component: VerifyAccountScreen,
    private: false,
  },
  {
    path: REGISTRATION_VERIFY,
    exact: false,
    component: RegistrationVerifyScreen,
    private: true,
  },

  { component: PickBrand, path: PICK_BRAND_SCREEN_ROUTE, exact: false, private: false },

  {
    path: NEW_PASSWORD_ROUTE,
    exact: false,
    component: NewPasswordScreen,
    private: false,
  },
];

const loyaltyNoAuthRoutes: Routes[] = [
  {
    path: FEEDBACK_SCREEN,
    exact: false,
    component: FeedbackScreen,
    private: false,
    hideHero: true,
    showBackOnMobile: true,
  },
];

const loyaltyRoutes: Routes[] = [
  {
    path: FAVOURITES_SCREEN_ROUTE,
    exact: false,
    component: FavouritesScreen,
    private: true,
  },
  {
    path: PAST_ORDERS_SCREEN_ROUTE,
    exact: false,
    component: PastOrdersScreen,
    private: true,
  },

  {
    path: MEMBER_MONEY_ROUTE,
    exact: false,
    component: MemberMoneyScreen,
    private: true,
  },
  {
    path: OFFERS_ROUTE,
    exact: false,
    component: OffersScreen,
    private: true,
  },
  {
    path: PROFILE_ROUTE,
    exact: false,
    component: ProfileScreen,
    private: true,
  },
  {
    path: CHANGE_PASSWORD_ROUTE,
    exact: false,
    component: ChangePasswordScreen,
    private: true,
  },
  {
    path: DELETE_ACCOUNT_ROUTE,
    exact: false,
    component: DeleteAccountScreen,
    private: true,
  },
  {
    path: REPLACE_CARD_ROUTE,
    exact: false,
    component: ReplaceCardScreen,
    private: true,
  },
  {
    path: CLAIM_MISSING_POINTS_ROUTE,
    exact: false,
    component: ClaimMissingPointsScreen,
    private: true,
  },
  {
    path: REWARDS_SCREEN_ROUTE,
    exact: false,
    component: RewardsScreen,
    private: true,
  },
  {
    path: TRANSACTIONS_SCREEN,
    exact: false,
    component: TransactionsScreen,
    private: true,
  },
  {
    path: CLAIM_PREVIOUS_POINTS_ROUTE,
    exact: false,
    component: ClaimPreviousPointsScreen,
    private: true,
  },
  {
    path: CHANGE_LANGUAGE_ROUTE,
    exact: false,
    component: ChangeLanguageScreen,
    private: true,
  },
];

const feedbackRoutes: Routes[] = [
  {
    path: FEEDBACK_STORE_SCREEN,
    exact: false,
    component: FeedbackStoreScreen,
    private: true,
  },
  {
    path: FEEDBACK_MENU_ITEMS_SCREEN,
    exact: false,
    component: FeedbackMenuItemsScreen,
    private: true,
  },
  {
    path: FEEDBACK_DELIVERY_SCREEN,
    exact: false,
    component: FeedbackDeliveryScreen,
    private: true,
  },
  {
    path: FEEDBACK_COMPLETED_SCREEN,
    exact: false,
    component: FeedbackCompletedScreen,
    private: false,
  },
];

const unauthorisedRoutesWithWrapper: Routes[] = [
  {
    path: GIFT_CARD_TOP_UP_ROUTE,
    exact: false,
    component: GiftCardTopUp,
    private: false,
  },
  {
    path: ADD_LOYALTY_WALLET_ROUTE,
    exact: false,
    component: AddLoyaltyWalletScreen,
    private: false,
  },
  {
    path: PURCHASE_GIFT_CARD_ROUTE,
    exact: false,
    component: PurchaseGiftCardScreen,
    private: false,
  },
  {
    path: CHECK_BALANCE_ROUTE,
    exact: false,
    component: GiftCardCheckBalanceScreen,
    private: false,
  },
  {
    path: REGISTER_NEW_USER_ROUTE,
    exact: false,
    component: SignUpScreen,
    private: false,
  },
  {
    path: SIGN_UP_ROUTE,
    exact: false,
    component: SignUpScreen,
    private: false,
  },
  {
    path: CHANGE_LANGUAGE_ROUTE,
    exact: false,
    component: ChangeLanguageScreen,
    private: true,
  },
];

const loyaltyOnlyRoutes: Routes[] = [
  {
    path: REGISTER_ROUTE,
    exact: true,
    component: RegisterScreen,
    private: false,
  },
  {
    path: VERIFY_ACCOUNT_ROUTE,
    exact: false,
    component: VerifyAccountScreen,
    private: false,
  },
  {
    path: NEW_PASSWORD_ROUTE,
    exact: false,
    component: NewPasswordScreen,
    private: false,
  },
];

const loyaltyMigrationRoutes: Routes[] = [
  {
    path: LOYALTY_MIGRATION_ROUTE,
    exact: false,
    component: LoyaltyMigrationScreen,
    private: false,
  },
];

const useRoutes = () => {
  const [routes, setRoutes] = useState(orderingRoutes);
  const [loyalty, setLoyalty] = useState(loyaltyRoutes);
  const [unauthorised, setUnauthorised] = useState(unauthorisedRoutesWithWrapper);
  const [profile, setProfile] = useState(profileRoutes);
  const enableBrandSelection = useAppSelector(state => state.config.enableBrandSelection);
  const loyaltyDisabled = useAppSelector(state => state.config.disableLoyalty);
  const disabledLoyaltyRoutes = useAppSelector(state => state.config.disabledLoyaltyRoutes);
  const isGiftcardPurchaseEnabled = useAppSelector(state => state.config.enableGiftcardPurchase);
  const isWalletEnabled = useAppSelector(state => state.config.enableLoyaltyWallet);
  const isOnlyLoyaltyEnabled = useAppSelector(state => state.config).enableOnlyLoyalty;
  const enableLoyaltyMigration = useAppSelector(state => state.config.enableLoyaltyMigration);
  const token = checkAuthTokenPresent();
  const member = useAppSelector(getMember);

  const disableGiftcardRoutes = isGiftcardPurchaseEnabled
    ? isWalletEnabled
      ? []
      : [ADD_LOYALTY_WALLET_ROUTE]
    : isWalletEnabled
    ? giftcardRoutes.filter(item => item !== ADD_LOYALTY_WALLET_ROUTE)
    : giftcardRoutes;

  const representedBrands = useAppSelector(getLocationBrandsWithMenuRepresentation);
  const multipleBrands = representedBrands.length > 1;
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);

  useEffect(() => {
    if (!Boolean(enableBrandSelection && multipleBrands && deviceTypeMobile)) {
      setRoutes(routes.filter(route => route.path !== PICK_BRAND_SCREEN_ROUTE));
    }

    if (isOnlyLoyaltyEnabled) {
      setRoutes(loyaltyOnlyRoutes);
    }

    setLoyalty(
      loyaltyDisabled
        ? []
        : loyalty
            .filter(route => !disableGiftcardRoutes?.includes(route.path))
            .filter(route => !disabledLoyaltyRoutes?.includes(route.path)),
    );
    setProfile(!loyaltyDisabled ? [] : profileRoutes);
    setUnauthorised(unauthorised.filter(route => !disabledLoyaltyRoutes?.includes(route.path)));
  }, [enableBrandSelection, multipleBrands, loyaltyDisabled, deviceTypeMobile]);

  const _loyaltyMigrationRoutes = enableLoyaltyMigration ? loyaltyMigrationRoutes : [];
  return {
    defaultRoutes: routes,
    loyaltyRoutes: loyalty,
    loyaltyNoAuthRoutes,
    unauthorisedRoutesWithWrapper: unauthorised.filter(route => {
      if ([SIGN_UP_ROUTE, REGISTER_NEW_USER_ROUTE].includes(route.path) && member) return false;
      return true;
    }),
    profileRoutes: profile,
    // TODO: rename this to be more general, currently only used by gong cha loyalty migration screens
    loyaltyMigrationRoutes: _loyaltyMigrationRoutes,
    feedbackRoutes,
  };
};

export default useRoutes;
