import history from '../history';
import {
  COMPLETED_ORDER_SCREEN_ROUTE,
  INITIAL_SCREEN_ROUTE,
  MENU_SCREEN_ROUTE,
} from '../hooks/useRoutes';
import { OrderingOperations } from 'polygon-ordering';
import { logEvent } from '../utils/analytics';
import EVENTS from '../constants/events';
import { setOrderDate } from '../slices/orderDate';
import { setReorderableItems } from '../slices/reorderableItems';

const { startNewOrder } = OrderingOperations;

/**
 * Pass current route pathname and app dispatch reference,
 * returns to menu or saletype selection screen.
 */
const navigateToHomeScreen = async (pathname: string, dispatch: any, replace: boolean = true) => {
  if ([INITIAL_SCREEN_ROUTE, MENU_SCREEN_ROUTE].includes(pathname)) return;
  if ([COMPLETED_ORDER_SCREEN_ROUTE].includes(pathname)) {
    // Back to saletype selection screen
    dispatch(
      startNewOrder({
        preserveMember: true,
      }),
    );
    dispatch(setReorderableItems(null));
    dispatch(setOrderDate(null));
    logEvent(EVENTS.START_NEW_ORDER);
  } else {
    replace ? history.replace(INITIAL_SCREEN_ROUTE) : history.push(INITIAL_SCREEN_ROUTE);
  }
};

export default navigateToHomeScreen;
