import React from 'react';
import { useTranslation } from 'react-i18next';
import { setCurrentModal } from '../thunks/setCurrentModal';
import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text'; // TODO: rework this to use Text component
import { useAppDispatch, useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { setMenuMessageModalDisplayed } from '../slices/menuMessageModalDisplayed';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { MOBILE_MODAL_TYPES } from '../constants';
export const MESSAGE_MODAL_ID = 'MESSAGE_MODAL_ID';

const MessageModal = ({
  title,
  message,
  titleStyle,
  desktopMaxWidth = 500,
  desktopWidth = 500,
  handleClose,
}: {
  desktopMaxWidth?: number;
  desktopWidth?: number;
  title?: string;
  message?: string;
  titleStyle?: React.CSSProperties;
  handleClose?: () => void;
  closeButtonStyle?: React.CSSProperties;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const modalParams = useAppSelector(state => state.modalParams) as ModalDataParams;
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);

  return (
    <Modal
      title={title ? title : modalParams?.title}
      desktopMaxWidth={desktopMaxWidth}
      desktopWidth={desktopWidth}
      desktopContainerStyle={styles.modalDesktopContainer}
      // hideFloatingHeader
      titleStyle={titleStyle}
      handleClose={
        modalParams?.onClose ||
        (() => {
          handleClose && handleClose();
          dispatch(setMenuMessageModalDisplayed(true));
          dispatch(setCurrentModal(null));
        })
      }
      wrapperStyle={deviceTypeMobile ? styles.wrapperStyleMobile : styles.wrapperStyleDesktop}
      mobileContainerStyle={styles.mobileContainerStyle}
      mobileModalType={MOBILE_MODAL_TYPES.ALERT}
    >
      {(modalParams?.message || message) && (
        <Text block value={modalParams?.message ?? message} themeKey="messageModalMessage" />
      )}

      <div style={styles.buttonStack}>
        {modalParams.alertButtons && modalParams.alertButtons.length > 0 ? (
          modalParams.alertButtons.map(button => (
            <StandardButton
              label={button.text ?? t('button.ok')}
              onClick={button.action ?? (() => dispatch(setCurrentModal(null)))}
              themeKey={button.isPreferred ? 'messageModalButton' : 'messageModalBorderButton'}
              disabled={button.disabled}
            />
          ))
        ) : (
          <StandardButton
            label={modalParams.buttonText ?? t('button.ok')}
            onClick={modalParams.action ?? (() => dispatch(setCurrentModal(null)))}
            themeKey="messageModalButton"
          />
        )}
      </div>
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    gap: 50,
    paddingBottom: 40,
  },
  wrapperStyleDesktop: {
    justifyContent: 'center',
  },
  wrapperStyleMobile: {
    height: '80%',
  },
  mobileContainerStyle: {
    paddingTop: 100,
    height: '100%',
  },
  buttonStack: {
    gap: 10,
    display: 'flex',
    flexDirection: 'column',
  },
};

export default MessageModal;
