import { createSelector } from 'reselect';
import getCart from './getCart';

/**
 * If an item has a money price (not free) but the points is set to
 * 0 then this item cannot be purchased with points. The same thing
 * applies to any choices or substitutions.
 */
export default createSelector([getCart], cart => {
  for (const item of cart) {
    if (item.Price > 0 && item.Points === 0) return false;

    for (const ingredient of item.Ingredients) {
      if (ingredient.Price > 0 && ingredient.Points === 0) return false;
    }

    for (const substitution of item.Substitutions) {
      if (substitution.Price > 0 && substitution.Points === 0) return false;
    }
  }

  return true;
});
