import { PAST_ORDERS_SCREEN_ROUTE } from '../screens/PastOrdersScreen';
import { FAVOURITES_SCREEN_ROUTE } from '../screens/FavouritesScreen';
import { REWARDS_SCREEN_ROUTE } from '../screens/RewardsScreen';
import { REPLACE_CARD_ROUTE } from '../screens/ReplaceCardScreen';
import { CLAIM_MISSING_POINTS_ROUTE } from '../screens/ClaimMissingPointsScreen';
import { OFFERS_ROUTE } from '../screens/OffersScreen';
import { PROFILE_ROUTE } from '../screens/ProfileScreen';
import { PURCHASE_GIFT_CARD_ROUTE } from '../screens/PurchaseGiftCardScreen';
import { CHECK_BALANCE_ROUTE } from '../screens/GiftCardCheckBalanceScreen';
import { GIFT_CARD_TOP_UP_ROUTE } from '../screens/GiftCardTopUp';
import { ADD_LOYALTY_WALLET_ROUTE } from '../screens/AddLoyaltyWalletScreen';
import { MEMBER_MONEY_ROUTE } from '../screens/MemberMoneyScreen';
import { TRANSACTIONS_SCREEN } from '../screens/TransactionsScreen';

import { store } from '../store';
import { DELETE_ACCOUNT_ROUTE } from '../screens/DeleteAccountScreen';
import { CHANGE_PASSWORD_ROUTE } from '../screens/ChangePasswordScreen';
import { CLAIM_PREVIOUS_POINTS_ROUTE } from '../screens/ClaimPreviousPointsScreen';
import { CHANGE_LANGUAGE_ROUTE } from '../screens/ChangeLanguageScreen';

const getDisabledRoutes = (
  isMemberVerified: boolean,
  isGiftcardPurchaseEnabled: boolean,
  isChangeLanguageDisabled: boolean,
) => {
  const routes = [];

  if (isChangeLanguageDisabled) {
    routes.push(CHANGE_LANGUAGE_ROUTE);
  }

  if (!isMemberVerified) {
    routes.push(MEMBER_MONEY_ROUTE);
  }

  if (!isGiftcardPurchaseEnabled) {
    routes.push(PURCHASE_GIFT_CARD_ROUTE);
  }

  return routes;
};

export const giftcardRoutes = [
  PURCHASE_GIFT_CARD_ROUTE,
  CHECK_BALANCE_ROUTE,
  GIFT_CARD_TOP_UP_ROUTE,
  ADD_LOYALTY_WALLET_ROUTE,
];

export const menu: DropdownModalMenu[] = [
  {
    label: 'button.giftCard',
    path: PURCHASE_GIFT_CARD_ROUTE,
    title: 'giftCardHeader',
    subTitle: 'purchaseGiftCard',
    private: false,
    subRoutes: [
      {
        label: 'purchaseGiftCard',
        path: PURCHASE_GIFT_CARD_ROUTE,
        title: 'giftCardHeader',
        subTitle: 'purchaseGiftCard',
      },
      {
        label: 'checkBalance',
        path: CHECK_BALANCE_ROUTE,
        title: 'giftCardHeader',
        subTitle: 'checkBalance',
      },
      {
        label: 'giftCardTopUp',
        path: GIFT_CARD_TOP_UP_ROUTE,
        title: 'giftCardHeader',
        subTitle: 'giftCardTopUp',
      },
      {
        label: 'addLoyaltyWallet',
        path: ADD_LOYALTY_WALLET_ROUTE,
        title: 'giftCardHeader',
        subTitle: 'addLoyaltyWallet',
      },
    ],
  },
  {
    label: 'button.changeLanguage',
    path: CHANGE_LANGUAGE_ROUTE,
    title: 'changeLanguageHeader',
    private: false,
  },
];

export const loyaltyMenu: DropdownModalMenu[] = [
  {
    label: 'button.rewards',
    path: REWARDS_SCREEN_ROUTE,
    title: 'rewardsHeader',
    private: true,
  },
  {
    label: 'button.offers',
    path: OFFERS_ROUTE,
    title: 'offersHeader',
    private: true,
  },
  {
    label: 'button.profile',
    path: PROFILE_ROUTE,
    title: 'profileHeader',
    private: true,
    subRoutes: [
      {
        label: 'profileDetails',
        path: PROFILE_ROUTE,
        title: 'profileHeader',
      },
      {
        label: 'changePassword',
        path: CHANGE_PASSWORD_ROUTE,
        title: 'changePassword',
      },
      {
        label: 'deleteAccount',
        path: DELETE_ACCOUNT_ROUTE,
        title: 'deleteAccount',
      },
    ],
  },
  {
    label: 'button.claimPreviousPoints',
    path: CLAIM_PREVIOUS_POINTS_ROUTE,
    title: 'button.claimPreviousPoints',
    private: true,
  },
  {
    label: 'button.giftCard',
    path: PURCHASE_GIFT_CARD_ROUTE,
    title: 'giftCardHeader',
    subTitle: 'purchaseGiftCard',
    private: false,
    subRoutes: [
      {
        label: 'purchaseGiftCard',
        path: PURCHASE_GIFT_CARD_ROUTE,
        title: 'giftCardHeader',
        subTitle: 'purchaseGiftCard',
      },
      {
        label: 'checkBalance',
        path: CHECK_BALANCE_ROUTE,
        title: 'giftCardHeader',
        subTitle: 'checkBalance',
      },
      {
        label: 'giftCardTopUp',
        path: GIFT_CARD_TOP_UP_ROUTE,
        title: 'giftCardHeader',
        subTitle: 'giftCardTopUp',
      },
      {
        label: 'addLoyaltyWallet',
        path: ADD_LOYALTY_WALLET_ROUTE,
        title: 'giftCardHeader',
        subTitle: 'addLoyaltyWallet',
      },
    ],
  },
  {
    label: 'memberMoneyHeader',
    path: MEMBER_MONEY_ROUTE,
    title: 'memberMoneyHeader',
    subTitle: 'button.topupButton',
    private: true,
  },
  {
    label: 'button.favouriteOrders',
    path: FAVOURITES_SCREEN_ROUTE,
    title: 'favouriteOrdersHeader',
    private: true,
  },
  {
    label: 'button.pastOrdersMemberButton',
    path: PAST_ORDERS_SCREEN_ROUTE,
    title: 'pastOrdersHeader',
    private: true,
  },
  {
    label: 'button.transactions',
    path: TRANSACTIONS_SCREEN,
    private: true,
    title: 'transactionsHeader',
  },
  {
    label: 'button.claimMissingPoints',
    path: CLAIM_MISSING_POINTS_ROUTE,
    title: 'claimMissingPointsHeader',
    private: true,
  },
  {
    label: 'button.replaceCard',
    path: REPLACE_CARD_ROUTE,
    title: 'replaceCardHeader',
    private: true,
  },
  {
    label: 'button.changeLanguage',
    path: CHANGE_LANGUAGE_ROUTE,
    title: 'changeLanguageHeader',
    private: false,
  },
];

export const getDefaultDropdownMenu = () => {
  const isGiftcardPurchaseEnabled = store.getState().config.enableGiftcardPurchase;
  const isChangeLanguageDisabled = store.getState().config.disableLanguages;
  const disabledRoutes = [
    !isGiftcardPurchaseEnabled && PURCHASE_GIFT_CARD_ROUTE,
    isChangeLanguageDisabled && CHANGE_LANGUAGE_ROUTE,
  ];
  const isWalletEnabled = store.getState().config.enableLoyaltyWallet;

  return isGiftcardPurchaseEnabled
    ? isWalletEnabled
      ? menu.filter(item => !disabledRoutes.includes(item.path))
      : menu
          .filter(item => !disabledRoutes.includes(item.path))
          .map(item => {
            if (item.subRoutes) {
              item.subRoutes = item.subRoutes.filter(
                route => route.path !== ADD_LOYALTY_WALLET_ROUTE,
              );
            }
            return item;
          })
    : menu.filter(item => !disabledRoutes.includes(item.path));
};

export const getLoyaltyDropdownMenu = () => {
  const disabledLoyaltyRoutes = store.getState().config.disabledLoyaltyRoutes;
  const isGiftcardPurchaseEnabled = store.getState().config.enableGiftcardPurchase;
  const isChangeLanguageDisabled = store.getState().config.disableLanguages;
  const isMemberVerified =
    !!store.getState().profile?.verificationDate ||
    !store.getState().config.loyaltyRequiresVerification;
  const disabledRoutes = getDisabledRoutes(
    isMemberVerified,
    isGiftcardPurchaseEnabled,
    isChangeLanguageDisabled,
  );

  const isWalletEnabled = store.getState().config.enableLoyaltyWallet;
  const isSelfDeleteEnabled = store.getState().config.enableSelfDelete;

  return loyaltyMenu
    .filter(menu => !disabledLoyaltyRoutes?.includes(menu.path))
    .filter(menu => !disabledRoutes?.includes(menu.path))
    .map(menu => {
      if (!isWalletEnabled && menu.subRoutes) {
        menu.subRoutes = menu.subRoutes?.filter(route => route.path !== ADD_LOYALTY_WALLET_ROUTE);
      }
      if (!isMemberVerified && menu.subRoutes) {
        menu.subRoutes = menu.subRoutes?.filter(route => route.path !== GIFT_CARD_TOP_UP_ROUTE);
      }
      if (!isSelfDeleteEnabled && menu.subRoutes) {
        menu.subRoutes = menu.subRoutes?.filter(route => route.path !== DELETE_ACCOUNT_ROUTE);
      }

      return menu;
    });
};
