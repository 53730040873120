import { createSelector } from 'reselect';

import getSelectedOffer, { $getSelectedOffer } from './getSelectedOffer';
import getOrderTotals, { $getOrderTotals } from './getOrderTotals';
import getCouponMinimumSpendingInclusive from './getCouponMinimumSpendingInclusive';
import getSelectedOfferValid from './getSelectedOfferValid';
import calculateEffectiveCouponMinSpend from '../utils/ordering/calculateEffectiveCouponMinSpend';

const get = (
  selectedOffer: Offer | undefined,
  orderTotals: OrderTotals | undefined,
  isOfferValid: boolean | undefined,
  couponDiscountInclusive: boolean,
): number => {
  if (
    orderTotals === undefined ||
    !isOfferValid ||
    !selectedOffer ||
    !selectedOffer.MinOrderValue
  ) {
    return 0;
  }

  const minValue = calculateEffectiveCouponMinSpend(
    selectedOffer,
    couponDiscountInclusive,
  );

  return Math.max(0, minValue - orderTotals.purchasesMoneyPrice);
};

export default createSelector(
  [
    getSelectedOffer,
    getOrderTotals,
    getSelectedOfferValid,
    getCouponMinimumSpendingInclusive,
  ],
  get,
);

export const $getSelectedOfferMiniValueValid = createSelector(
  [
    $getSelectedOffer,
    $getOrderTotals,
    getSelectedOfferValid,
    getCouponMinimumSpendingInclusive,
  ],
  get,
);
