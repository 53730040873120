import { createSelector } from 'reselect';

import getOrdering from './getOrdering';

export default createSelector([getOrdering], (ordering): MemberOffer[] => {
  if (!ordering.memberOffers) {
    return [];
  }

  return ordering.memberOffers;
});
