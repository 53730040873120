import React from 'react';
import { RiAddFill, RiSubtractFill } from 'react-icons/ri';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { OrderingOperations } from 'polygon-ordering';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
import { TEXT_PROPERTIES } from '../utils/theme';

const { adjustOpenPurchaseQuantity } = OrderingOperations;

interface IProps {
  quantity: number;
  containerStyle?: React.CSSProperties;
  maxQuantity?: number;
  minQuantity?: number;
  increaseQty?: () => void;
  decreaseQty?: () => void;
  iconContainerStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  buttonSize?: number;
  increaseEnabled?: boolean;
  decreaseEnabled?: boolean;
  isMobileDevice?: boolean;
  containerStopPropogation?: boolean;
}

const QuantityControl: React.FC<IProps> = ({
  quantity,
  containerStyle,
  maxQuantity = 99,
  minQuantity = 1,
  decreaseQty,
  increaseQty,
  iconContainerStyle,
  textStyle,
  buttonSize = 30,
  increaseEnabled = true,
  decreaseEnabled = true,
  isMobileDevice = false,
  containerStopPropogation = false,
}) => {
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();

  const iconStyle = combineStyles(
    styles.icon,
    p('quantityPickerIcon', ['color']),
    {
      fontSize: buttonSize - 12,
      // this is just to override the maxbrenner theme:
      // TODO: fix this theming issue?
      color: 'white',
    },
    isMobileDevice && p('quantityPickerIconMobileView', ['color']),
  );

  const buttonStyle = combineStyles(
    styles.buttonContainer,
    iconContainerStyle,
    p('quantityPickerIcon', ['backgroundColor']),
    isMobileDevice && p('quantityPickerIconMobileView', ['backgroundColor']),
    { width: buttonSize },
  );

  const disabledStyle = combineStyles(
    buttonStyle,
    styles.disabled,
    p('quantityPickerDisabled', ['backgroundColor', 'color']),
  );

  const quantityThemekey = isMobileDevice ? 'quantityPickerMobileView' : 'quantityPicker';

  const subtractEnabled = decreaseEnabled && quantity > minQuantity;
  const addEnabled = increaseEnabled && quantity < maxQuantity;

  return (
    <TouchableOpacity
      style={combineStyles(styles.main, containerStyle, { height: '100%' })}
      {...(containerStopPropogation
        ? {
            onClick: e => {
              e?.stopPropagation();
            },
            activeStyle: { opacity: 0.9 },
          }
        : {})}
    >
      <TouchableOpacity
        style={subtractEnabled ? buttonStyle : disabledStyle}
        onClick={
          decreaseQty
            ? decreaseQty
            : () => {
                dispatch(adjustOpenPurchaseQuantity(-1));
                logEvent(EVENTS.CHANGE_OPEN_PURCHASE_QUANTITY);
              }
        }
        disabled={!subtractEnabled}
        disabledStyle={disabledStyle}
        ariaLabel="decrease quantity"
      >
        <RiSubtractFill style={iconStyle} />
      </TouchableOpacity>

      <Text
        themeKey={quantityThemekey}
        style={combineStyles(styles.quantity, textStyle ? textStyle : '')}
      >
        {quantity}
      </Text>

      <TouchableOpacity
        style={addEnabled ? buttonStyle : disabledStyle}
        onClick={
          increaseQty
            ? increaseQty
            : () => {
                dispatch(adjustOpenPurchaseQuantity(1));
                logEvent(EVENTS.CHANGE_OPEN_PURCHASE_QUANTITY);
              }
        }
        disabled={!addEnabled}
        disabledStyle={disabledStyle}
        ariaLabel="increase quantity"
      >
        <RiAddFill style={iconStyle} />
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

const styles: Styles = {
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    gap: 40,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: '1/1',
    borderRadius: '100%',
  },
  disabled: {},
  quantity: {
    position: 'absolute',
    textAlign: 'center',
  },
  icon: {
    position: 'absolute',
  },
};

export default QuantityControl;
