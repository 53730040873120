import React, { FC } from 'react';
import CheckWrapper from './CheckWrapper';
import { createTheme, ThemeProvider } from '@mui/system';
import RedcatImage from './RedcatImage';
import { useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import { OrderingHooks } from 'polygon-ordering';
import Text from './Text';

const { useFormattedCurrency } = OrderingHooks;

interface ChoiceWithQuantity extends Choice {
  quantity?: number;
}
type Props = {
  // This is mainly used as a callback to change what is selected.
  onClick?: () => void;
  label?: JSX.Element;

  // This is only used not as input but as an indicator
  checked: boolean;

  // These props here are what makes function like a radio button
  value?: string;
  selected?: string;

  size?: number;
  displayType?: string;
  choice?: ChoiceWithQuantity;
  disabled?: boolean;
  price?: number;
};

const RadioCheck: FC<Props> = ({
  onClick,
  checked,
  label,
  value,
  selected,
  size,
  displayType,
  choice,
  disabled = false,
  price = 0,
}) => {
  const priceShowing = useFormattedCurrency({ cents: price });
  const p = useAppSelector(getThemeLookup);
  const radioCheckStyling = createTheme({
    palette: {
      borderRadius: '100%',
      size: size,
    },
  });
  const iconVisible =
    displayType === 'list' || displayType === 'checkbox' || displayType === undefined;
  const displayGrid = displayType === 'grid';

  return (
    <ThemeProvider theme={radioCheckStyling}>
      <CheckWrapper onClick={onClick} disabled={disabled}>
        <div
          style={combineStyles(
            {
              display: 'flex',
              gap: 15,
              flexDirection: displayGrid ? 'column' : 'row',
              alignItems: 'center',
            },
            !displayGrid && { flex: 1 },
          )}
        >
          {iconVisible && <div className={`check-wrapper ${checked && 'checked'}`}></div>}
          {displayGrid && (
            <div style={{ width: 150, height: 100 }}>
              <RedcatImage
                alt={choice?.name || ''}
                imagePath={choice?.images?.default}
                containerStyle={styles.imageContainer}
                imageStyle={combineStyles(
                  styles.imageStyle,
                  { borderWidth: checked ? '2px' : '0' },
                  checked && p('purchaseEditorSelectedImage', ['border']),
                )}
              />
            </div>
          )}
          {label ? (
            <div
              style={
                displayGrid
                  ? combineStyles(
                      {
                        padding: 10,
                        borderRadius: 10,
                        width: 150,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        flex: 1,
                      },
                      checked && p('itemChoiceActive', CONTAINER_PROPERTIES),
                    )
                  : { flex: 1 }
              }
            >
              {label}
              {displayGrid && (
                <div style={{ flex: 1 }}>
                  {price > 0 && (
                    <Text
                      style={combineStyles([
                        { textAlign: 'right', zIndex: -1 },
                        checked && p('itemChoiceActive', TEXT_PROPERTIES),
                      ])}
                    >{`+ ${priceShowing}`}</Text>
                  )}
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </CheckWrapper>
    </ThemeProvider>
  );
};

export default RadioCheck;

const styles: Styles = {
  imageContainer: {
    height: 100,
    minWidth: 150,
    maxWidth: 150,
  },
  imageStyle: {
    width: '100%',
    height: '100%',
  },
};
