import { RedcatApiHandler } from 'polygon-utils';

import { storeMemberOffers } from '../reducers/memberOffers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMember } from '../selectors';

export const fetchMemberOffers = createAsyncThunk(
  '$fetchMemberOffers',
  async (
    data: {
      showRedeemedCoupons?: boolean;
      showExpiredCoupons?: boolean;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    try {
      /**
       * The reason why we don't get showRedeemedCoupons, showExpiredCoupons
       * straight from polygon ordering is because maybe one day we could
       * implement some sort of filtering in our front end for users.
       */
      const { showRedeemedCoupons, showExpiredCoupons } = data;

      const path = `/api/v1/profile/coupons?hideredeemed=${
        showRedeemedCoupons ? '0' : '1'
      }&hideexpired=${showExpiredCoupons ? '0' : '1'}`;

      const member = getMember(getState() as EntireFrontendState);

      const fetch = member
        ? RedcatApiHandler.authorisedFetch
        : RedcatApiHandler.fetch;

      return fetch({ method: 'GET', path }).then(response => {
        dispatch(storeMemberOffers(response.data));
      });
    } catch (e) {
      console.warn('Fetch offers thunk failed', { e });
      return rejectWithValue(e);
    }
  },
);
