import React from 'react';

// Utils
import { useTranslation } from 'react-i18next';
import combineStyles from '../utils/combineStyles';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';

// Redux
import { setLoyaltySelectedOffer } from '../slices/loyaltySelectedOffer';

// Components
import { LOYALTY_OFFER_MODAL_ID } from '../modals/LoyaltyOfferModal';
import TouchableOpacity from '../components/TouchableOpacity';
import RedcatImage from '../components/RedcatImage';
import Text from '../components/Text';
import { CONTAINER_PROPERTIES } from '../utils/theme';

/**
 * # Offers Card
 * This is a card for individual offers. It's used in the offers screen.
 * Clicking on this card opens up modal: LOYALTY_OFFER_MODAL_ID (<LoyaltyOfferModal>)
 */
const OffersCard = ({ offer, key }: { offer: Offer | MemberOffer; key: string }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);

  return (
    <TouchableOpacity
      key={key}
      onClick={() => {
        dispatch(setLoyaltySelectedOffer(offer));
        dispatch(setCurrentModal(LOYALTY_OFFER_MODAL_ID));
      }}
      style={{ ...styles.offerCard, ...p('offer', CONTAINER_PROPERTIES) }}
      hoverStyle={{ ...styles.offerCardHover, ...p('offerHover', CONTAINER_PROPERTIES) }}
      enableHoverEffect
    >
      {!!offer.image && (
        <RedcatImage
          imagePath={offer.image}
          imgFluid
          imageStyle={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
        />
      )}

      <div style={combineStyles(styles.offerCardDetails)}>
        <Text value={offer.name} variant={'h3'} themeKey="offerName" />
        <Text value={t('moreInformation')} variant={'body'} themeKey="offersMoreText" />
      </div>
    </TouchableOpacity>
  );
};

const styles: Styles = {
  offerCard: {
    backgroundColor: '#fff',
    marginBottom: '20px',
    borderRadius: 20,
    borderColor: 'rgba(196, 196, 196, 1)',
    borderStyle: 'solid',
    borderWidth: 2,
    overflow: 'clip',
    transition: '0.35s cubic-bezier(0, 0.28, 0, 0.98)',
    boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
  },
  offerCardHover: {
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.25)',
    scale: '1.005',
    opacity: 1,
  },
  offerCardDetails: {
    padding: '20px 30px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 15,
  },
};

export default OffersCard;
