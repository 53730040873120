import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import SectionTitle from './SectionTitle';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { FormikValues, useFormikContext } from 'formik';
import SubSection from './SubSection';
import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';
import SavedAddresses from './SavedAddresses';
import RadioCheck from './RadioCheck';
import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';

const { clearBuffer } = OrderingOperations;
const { getMember } = OrderingSelectors;

const DeliveryAddress = () => {
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();

  const { setFieldValue, values } = useFormikContext<FormikValues>();
  const effectiveDeliveryAddress = useAppSelector(state => state.desiredDeliveryAddress);
  const member = useAppSelector(getMember);

  const effectiveDeliveryAddressIsASavedAddress =
    (member?.savedAddresses || []).filter(
      address =>
        address.street_name === effectiveDeliveryAddress?.street_name &&
        address.locality === effectiveDeliveryAddress.locality,
    ).length > 0;

  return (
    <div className="p-2 m-3">
      <SectionTitle value="DELIVERY ADDRESS" />
      <div className="m-3">
        <TouchableOpacity
          onClick={() => {
            setFieldValue('page', values.page + 1);
            dispatch(clearBuffer({}));
          }}
        >
          <Text
            style={combineStyles(
              [{ fontSize: 12, color: 'red' }],
              p('enterDeliveryAddressTitle', TEXT_PROPERTIES),
            )}
          >
            Enter new delivery address
          </Text>
        </TouchableOpacity>
        {effectiveDeliveryAddress && !effectiveDeliveryAddressIsASavedAddress && (
          <SubSection
            subtitle={
              effectiveDeliveryAddress.street_name + ' ' + effectiveDeliveryAddress.locality
            }
            action={() => {
              setFieldValue('page', values.page + 1);
              dispatch(clearBuffer({}));
            }}
            ActiveIcon={() => <RadioCheck checked={effectiveDeliveryAddress != null} size={24} />}
          />
        )}

        <SavedAddresses />
      </div>
    </div>
  );
};

export default DeliveryAddress;
