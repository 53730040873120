import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeErrorSerialisable } from '../utils/sagas';
import { FetchParams } from '../utils/Api';
import processLocation from '../utils/processors/processLocation';
import { setLocations } from '../reducers/locations';
import { normaliseArray } from '../utils/misc';
import { fetchReducedLocations } from './fetchReducedLocations';
import { getAdvancedCustomHours } from '../selectors';
import { getConfig, getOrderingProvider } from '../selectors/config';

export const fetchLocations = createAsyncThunk(
  '$fetchLocations',
  async (
    data: {
      locationId?: string;
    },
    { dispatch, getState },
  ) => {
    const state = getState() as any;

    const params: FetchParams = {
      path: data.locationId
        ? `/api/v1/stores/${data.locationId}?hide_invisible=0`
        : '/api/v1/stores?hide_invisible=0',
      method: 'GET',
    };

    dispatch(fetchReducedLocations());

    return RedcatApiHandler.authorisedFetch(params)
      .then(response => {
        const locationsToProcess: RawLocation[] = data.locationId
          ? [response.data]
          : response.data;
        // Getting the advanced custom hours
        const advancedCustomHours = getAdvancedCustomHours(state);

        // Getting current order provider
        const orderingProvider = getOrderingProvider(state);

        const { deliveryTimeOpen, deliveryTimeClose } = state.config;

        const processedLocations = locationsToProcess.map(location =>
          processLocation(
            location,
            advancedCustomHours,
            orderingProvider,
            deliveryTimeOpen,
            deliveryTimeClose,
          ),
        );

        dispatch(
          setLocations({
            locations: normaliseArray(processedLocations, 'id'),
            merge: Boolean(data.locationId),
          }),
        );
      })
      .catch(err => makeErrorSerialisable(err));
  },
);
