import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors } from 'polygon-ordering';
import { RedcatApiHandler } from 'polygon-utils';
import { useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import history from '../history';
import { Text, StandardButton, SendVerificationButton, ScreenHero } from '../components';
import { OFFERS_ROUTE, PROFILE_ROUTE, REGISTER_ROUTE } from './';

const { getVerificationAttemptsConfig, getMember } = OrderingSelectors;

const useResendCountDown = (
  verificationAttemptsConfig: {
    verificationMaxAttempts: number;
    verificationDelay: number;
    verificationLockout: number;
  },
  apiHandlerReady: boolean,
) => {
  const timerId = useRef<NodeJS.Timer | null>(null);
  const [verificationAttempts, setVerificationAttempts] = useState<
    { attempts: number; last_attempt: string; next_attempt?: string | number } | undefined
  >();
  const [resendDisabled, setResendDisabled] = useState(false);
  const { verificationDelay = 30, verificationMaxAttempts } = verificationAttemptsConfig;
  const [delay, setDelay] = useState<number>(verificationDelay);
  const [showDisableLabel, setShowDisableLabel] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (apiHandlerReady) {
      RedcatApiHandler.authorisedFetch({
        method: 'GET',
        path: 'api/v1/profile/verification_attempts',
      })
        .then(re => {
          setVerificationAttempts(re.data);
          const { attempts, last_attempt } = re.data;
          const pastTime = Math.round(
            (new Date().getTime() - new Date(last_attempt).getTime()) / 1000,
          );
          if (attempts < 1 && pastTime > verificationDelay) setShowDisableLabel(true);
        })
        .finally(() => setLoading(false));
    }
  }, [apiHandlerReady]);

  useEffect(() => {
    if (verificationDelay > 0 && resendDisabled) {
      timerId.current = setInterval(() => {
        setDelay(pre => {
          if (pre > 0) {
            return (pre as number) - 1;
          } else return 0;
        });
      }, 1000);
    }
    return () => {
      if (timerId.current) clearInterval(timerId.current);
    };
  }, [resendDisabled]);

  useEffect(() => {
    if (verificationAttempts) {
      const { attempts, last_attempt } = verificationAttempts;
      const pastTime = Math.round((new Date().getTime() - new Date(last_attempt).getTime()) / 1000);

      if (pastTime > verificationDelay && attempts > 0) setResendDisabled(false);
      else {
        if (pastTime < verificationDelay && !(attempts < 0)) {
          setResendDisabled(true);
          setDelay(verificationDelay > pastTime ? verificationDelay - pastTime : verificationDelay);
        }
      }
    }
  }, [verificationAttempts]);

  useEffect(() => {
    if (delay < 1 && (verificationAttempts?.attempts || 0) > 0) setResendDisabled(false);
    if (delay < 1 && (verificationAttempts?.attempts || 0) < 1) setShowDisableLabel(true);
  }, [delay]);

  return {
    verificationAttempts,
    setVerificationAttempts,
    resendDisabled,
    setResendDisabled,
    verificationDelay,
    verificationMaxAttempts,
    delay,
    setDelay,
    showDisableLabel,
    setShowDisableLabel,
    isLoading,
  };
};

const RegistrationVerify: React.FC<{
  showContinueButton?: boolean;
  internalRedirect?: boolean;
}> = ({ showContinueButton, internalRedirect }) => {
  const location = useLocation();
  const member = useAppSelector(getMember);
  const { state } = location;
  const loyaltyRequiresVerification = useAppSelector(
    state => state.config.loyaltyRequiresVerification,
  );
  const isVerified = !!member?.verified && loyaltyRequiresVerification;
  if (isVerified || (!state?.isRegistration && !member)) history.push('/');

  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const brandName = useAppSelector(state => state.config.headerBrandName);
  const apiHandlerReady = useAppSelector(state => state.apiHandlerReady);
  const verificationAttemptsConfig = useAppSelector(getVerificationAttemptsConfig);
  let title = '';
  let description = '';
  const { pathname } = location;
  switch (pathname) {
    case OFFERS_ROUTE:
      title = '';
      description = t('RegistrationVerify.offersVerify');
      break;
    case PROFILE_ROUTE:
      title = '';
      description = t('RegistrationVerify.accountVerify');
      break;
    case REGISTRATION_VERIFY:
    case REGISTER_ROUTE:
      title = t('RegistrationVerify.title', { name: brandName });
      description = t('RegistrationVerify.welcome', { name: brandName });
      break;
    default:
      title = '';
      description = t('RegistrationVerify.verifyFirst');
  }

  const {
    verificationAttempts,
    setVerificationAttempts,
    resendDisabled,
    setResendDisabled,
    verificationDelay,
    delay,
    setDelay,
    showDisableLabel,
    isLoading,
  } = useResendCountDown(verificationAttemptsConfig, apiHandlerReady);

  const resendEmailLabel = !resendDisabled
    ? t('RegistrationVerify.resendEmail')
    : delay < verificationDelay && !showDisableLabel
    ? t('RegistrationVerify.resendEmailCountDown', {
        time: ` ${Math.trunc(delay / 60)}:${(delay % 60).toLocaleString(undefined, {
          minimumIntegerDigits: 2,
        })}`,
      })
    : '';

  const handleResend = () => {
    setResendDisabled(true);
    if ((verificationAttempts?.attempts || 0) > 0) {
      setDelay(verificationDelay);
      setVerificationAttempts({
        //@ts-ignore
        attempts: verificationAttempts?.attempts - 1,
        last_attempt: new Date().toISOString(),
      });
    }
  };
  const handleContinue = () => {
    history.push('/');
  };
  return isVerified ? null : (
    <div
      style={{
        ...styles.wrapper,
        paddingTop: internalRedirect ? 0 : 50,
        paddingBottom: internalRedirect ? 0 : 50,
      }}
    >
      <div
        style={{
          ...styles.container,
          paddingLeft: internalRedirect ? 0 : 100,
          paddingRight: internalRedirect ? 0 : 100,
          paddingBottom: internalRedirect ? 0 : 50,
        }}
      >
        <div style={styles.top}>
          <Text
            value={title}
            style={{
              fontWeight: 500,
              fontSize: 20,
              marginBottom: 30,
            }}
          />
          <Text value={description} style={{ textAlign: 'center', marginBottom: 30 }} />
          {isLoading ? null : showDisableLabel ? (
            <Text
              style={{ padding: '0 0 20px', textAlign: 'center', opacity: '0.6' }}
              value={t('RegistrationVerify.unableResendEmail')}
            />
          ) : (
            <SendVerificationButton
              onClick={handleResend}
              disabled={resendDisabled}
              label={resendEmailLabel}
              invisibleContainer
              labelStyle={{
                fontSize: 14,
                fontWeight: 700,
                color: p('defaultButton', ['backgroundColor'])['backgroundColor'],
              }}
            />
          )}
        </div>
        {showContinueButton && (
          <div style={styles.bottom}>
            <StandardButton
              onClick={handleContinue}
              label={t('continue')}
              containerStyle={{ width: '70%' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const RegistrationVerifyScreen: React.FC<{
  showContinueButton?: boolean;
  internalRedirect?: boolean;
}> = ({ showContinueButton, internalRedirect }) => {
  return internalRedirect ? (
    <RegistrationVerify
      showContinueButton={showContinueButton}
      internalRedirect={internalRedirect}
    />
  ) : (
    <ScreenHero disablePSB={true}>
      <RegistrationVerify
        showContinueButton={showContinueButton}
        internalRedirect={internalRedirect}
      />
    </ScreenHero>
  );
};

const styles: Styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'grey',
    flex: 1,
    height: '100vh',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 660,
    backgroundColor: 'white',
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
};
export const REGISTRATION_VERIFY = '/registration_verify';
export default RegistrationVerifyScreen;
