import { combineReducers, AnyAction, Reducer } from 'redux';
import lodash from 'lodash';
import { OrderingReducer, localforageReducer } from 'polygon-ordering';
import firstSyncComplete from './firstSyncComplete';
import config from './config';
import currentModalId from './currentModalId';
import previousModalId from './previousModalId';
import modalParams from './modalParams';
import calloutOffsets from './calloutOffsets';
import deviceType from './deviceType';
import deviceDimensions from './deviceDimensions';
import currentUserLocation from './currentUserLocation';
import returnModalDetails from './returnModalDetails';
import currentPaymentMethod from './currentPaymentMethod';
import currentSavedCardToken from './currentSavedCardToken';
import desiredDeliveryAddress from './desiredDeliveryAddress';
import desiredDeliveryTime from './desiredDeliveryTime';
import desiredDeliveryAddressNotFound from './desiredDeliveryAddressNotFound';
import orderDate from './orderDate';
import addressCache from './addressCache';
import locationWhitelist from './locationWhitelist';
import apiHandlerReady from './apiHandlerReady';
import checkoutInProgress from './checkoutInProgress';
import validationInProgress from './validationInProgress';
import creditCardComplete from './creditCardComplete';
import currentCategoryId from './currentCategoryId';
import deliveryEstimateFailed from './deliveryEstimateFailed';
import deliveryEstimateInProgress from './deliveryEstimateInProgress';
import handleAdjustOrderInProgress from './handleAdjustOrderInProgress';
import menuCategoriesBarHeight from './menuCategoriesBarHeight';
import loadingDependencies from './loadingDependencies';
import loyaltyHeaders from './loyaltyHeaders';
import loyaltyTiers from './loyaltyTiers';
import loyaltyFilters from './loyaltyFilters';
import loyaltyDateFilters from './loyaltyDateFilters';
import memberOffers from './memberOffers';
import profile from './profile';
import paymentFormInUse from './paymentFormInUse';
import paymentMethod from './paymentMethod';
import paymentFormFilled from './paymentFormFilled';
import savedCardToken from './savedCardToken';
import savedCards from './savedCards';
import loyaltySelectedOffer from './loyaltySelectedOffer';
import cvcFilled from './cvcFilled';
import reorderableItems from './reorderableItems';
import mapCenter from './mapCenter';
import nearbyLocations from './nearbyLocations';
import recentLocations from './recentLocations';
import enableMultiPayments from './enableMultiPayments';
import currentDrawer from './currentDrawer';
import googleReCaptcha from './googleReCaptcha';
import menuMessageModalDisplayed from './menuMessageModalDisplayed';
import menuOverlayAdsDisplayed from './menuOverlayAdsDisplayed';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import localforage from 'localforage';

const orderingPersistConfig = {
  key: 'ordering',
  storage: storage,
};
const orderingMenuPersistConfig = {
  key: 'localForage',
  storage: localforage,
};

const PERSIST = {
  addressCache,
  calloutOffsets,
  config,
  currentModalId,
  currentPaymentMethod,
  currentSavedCardToken,
  currentUserLocation,
  desiredDeliveryAddress,
  desiredDeliveryAddressNotFound,
  desiredDeliveryTime,
  deviceDimensions,
  deviceType,
  firstSyncComplete,
  locationWhitelist,
  modalParams,
  memberOffers,
  orderDate,
  previousModalId,
  returnModalDetails,
  profile,
  recentLocations,
  loyaltyTiers,
};

const rootReducer = combineReducers({
  ...PERSIST,
  ordering: persistReducer(orderingPersistConfig, OrderingReducer),
  localForage: persistReducer(orderingMenuPersistConfig, localforageReducer),
  nearbyLocations,
  mapCenter,
  cvcFilled,
  reorderableItems,
  apiHandlerReady,
  checkoutInProgress,
  creditCardComplete,
  currentCategoryId,
  deliveryEstimateFailed,
  deliveryEstimateInProgress,
  handleAdjustOrderInProgress,
  menuCategoriesBarHeight,
  validationInProgress,
  loadingDependencies,
  loyaltyHeaders,
  loyaltyTiers,
  loyaltyFilters,
  loyaltyDateFilters,
  paymentFormInUse,
  paymentMethod,
  paymentFormFilled,
  savedCardToken,
  savedCards,
  loyaltySelectedOffer,
  enableMultiPayments,
  currentDrawer,
  googleReCaptcha,
  menuMessageModalDisplayed,
  menuOverlayAdsDisplayed,
});

export const PERSIST_KEYS = lodash.keys(PERSIST);
export default rootReducer;
