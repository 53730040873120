import lodash from 'lodash';
import moment from 'moment';

import { safeDate } from '../misc';
import combineAddressParts from '../ordering/combineAddressParts';
import processTradingHours from './processTradingHours';
import { SALE_TYPE } from '../../constants/saleType';
import { processAdvancedCustomHours } from './processAdvancedCustomHours';

export default function processLocation(
  rawLocation: RawLocation,
  advancedCustomHours: Array<AdvancedCustomHours> | null,
  orderingProvider: ORDERING_PROVIDER | null,
  deliveryTimeOpen: string | null,
  deliveryTimeClose: string | null,
): POSLocation {
  const supportedSaleTypes = lodash
    .toPairs(rawLocation.SaleTypes || {})
    .map(([key, value]) => (value ? parseInt(key) : undefined))
    .filter(type => type) as SALE_TYPE[];

  // Finding custom hours for this location
  // Checking store id === null because store id null means it applies to all stores
  const advancedCustomHoursForThisStore = advancedCustomHours
    ? advancedCustomHours.filter(
        custom =>
          custom.StoreID === Number(rawLocation.StoreID) ||
          custom.StoreID === null,
      )
    : null;

  // Getting the custom data modified
  const availableSaleTypesToday = processAdvancedCustomHours(
    rawLocation.OpeningTimeToday,
    rawLocation.ClosingTimeToday,
    deliveryTimeOpen,
    deliveryTimeClose,
    Object.keys(rawLocation?.SaleTypes),
    advancedCustomHoursForThisStore,
    rawLocation.UTCOffset,
  );

  // Handling service sessions
  const serviceSessions: Record<string, ServiceSession[]> = {};

  supportedSaleTypes.forEach(st => {
    const saleTypeServiceSessionInfo = (rawLocation.ServiceSessions || {})[st];

    if (saleTypeServiceSessionInfo != null) {
      serviceSessions[st] = processSaleTypeServiceSessionInfo(
        saleTypeServiceSessionInfo,
      );
    } else {
      serviceSessions[st] = [
        {
          start: availableSaleTypesToday[st].openingTime,
          end: availableSaleTypesToday[st].closingTime,
        },
      ];
    }
  });

  const brandIds = (rawLocation.VirtualBrands || []).map(String);

  let physicalBrand;

  if (rawLocation.BrandID != null) {
    physicalBrand = rawLocation.BrandID;
    brandIds.unshift(rawLocation.BrandID);
  }

  const guestOrderingSaleTypes = (rawLocation.GuestOrderingSaleTypes || []).map(
    value => {
      return parseInt(value);
    },
  );

  return {
    id: String(rawLocation.StoreID),
    name: (rawLocation.LocationName || '').trim(),
    latitude: rawLocation.Latitude,
    longitude: rawLocation.Longitude,
    address: {
      address1: rawLocation.Address1,
      address2: rawLocation.Address2,
      postCode: rawLocation.PostCode,
      state: rawLocation.State,
      suburb: rawLocation.Suburb,
      country: rawLocation.Country,
      combinedShort: combineAddressParts([
        rawLocation.Address1,
        rawLocation.Address2,
        rawLocation.Suburb,
      ]),
      combinedLong: combineAddressParts([
        rawLocation.Address1,
        rawLocation.Address2,
        rawLocation.Suburb,
        rawLocation.State,
        rawLocation.PostCode,
      ]),
    },
    averageOrderWaitTime: rawLocation.AvgOrderTime || 0,
    abn: rawLocation.ABN || undefined,
    openNow: rawLocation.OpenNow,
    status: rawLocation.StoreStatus,
    online: rawLocation.StoreStatus === 'Online',

    phone: rawLocation.Phone,
    posType: rawLocation.PosType,
    paymentGatewayPublicKey: rawLocation.PaymentGatewayPublicKey,

    outOfSessionOrderingEnabled: Boolean(rawLocation.OrderAfterHours),
    availableSaleTypesToday,
    openingTimeTomorrow: rawLocation.OpeningTimeTomorrow,
    supportedSaleTypes,
    serviceSessions: serviceSessions as Record<SALE_TYPE, ServiceSession[]>,
    hidden: Boolean(rawLocation.HiddenStore),

    brandIds,
    physicalBrand,

    tradingHours: processTradingHours(rawLocation.OpeningHours),
    orderLeadTimes: rawLocation.Thresholds?.map(processThreshold) || [],

    guestOrderingEnabled: rawLocation.GuestOrderingEnabled,
    guestOrderingSaleTypes,
    cutoffTresholds: rawLocation.CutoffThresholds || [],
    closingTimeYesterday: rawLocation.ClosingTimeYesterday,
    timeZone: rawLocation.Timezone,
    customHours: rawLocation?.CustomHours,
    advancedCustomHours: advancedCustomHoursForThisStore,
    orderingProvider,
    holidayHours: rawLocation.AllHolidays,
    utcOffset: rawLocation.UTCOffset,
    _isFullData: true,
  };
}

function processThreshold(
  threshold: RawLocationOrderLeadTimeThreshold,
): OrderLeadTimeThreshold {
  return {
    moneyLower: threshold.MoneyLower,
    saleType: threshold.SaleType,
    time: threshold.Time,
  };
}

function processSaleTypeServiceSessionInfo(
  info: RawSaleTypeServiceSessionInfo,
): ServiceSession[] {
  let today = moment().format('dddd').toLowerCase();

  if (info.IsPublicHoliday) {
    today = 'publicholiday';
  }

  return info.Sessions.filter(
    session =>
      session.Day.toString().toLowerCase() === today &&
      session.Start !== 'Closed',
  ).map(session => ({
    start: moment(session.Start, 'HH:mm:ss').format(),
    end: moment(session.End, 'HH:mm:ss').format(),
  }));
}
