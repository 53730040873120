import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { OrderingSelectors, OrderingOperations, OrderingConstants } from 'polygon-ordering';
import { setCurrentModal } from './setCurrentModal';
import i18next from 'i18next';
import { CONFIRM_CHANGES_MODAL_ID } from '../modals/ConfirmChangesModal';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { setDesiredDeliveryTime } from '../slices/desiredDeliveryTime';

const {
  getStagedPurchases,
  getPickupTime,
  getChangedTime,
  getSaleType,
  // getCategoryAvailabilityById
  getRootCategory,
} = OrderingSelectors;

const { removeStagedPurchase, setPickupTime } = OrderingOperations;

export const validateFutureMenuItems = createAsyncThunk(
  'validateFutureMenuItems',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;

      const saleType = getSaleType(state);
      const changedTime = getChangedTime(state);
      const pickupTime = getPickupTime(state);
      const desiredDeliveryTime = state.desiredDeliveryTime;
      const deliveryTime = state.ordering.currentOrder.deliveryTime;

      const stagedPurchases = getStagedPurchases(state);
      const selectedScheduledTime =
        saleType === SALE_TYPE.DELIVERY ? desiredDeliveryTime : changedTime;
      const currentDay =
        desiredDeliveryTime === 'ASAP'
          ? null
          : new Date(selectedScheduledTime as string)
              .toLocaleString('en-US', { weekday: 'long' })
              .toUpperCase();
      const currentTime =
        desiredDeliveryTime === 'ASAP'
          ? null
          : new Date(selectedScheduledTime as string).toTimeString().slice(0, 8); // Get time in HH:MM:SS format

      const invalidItems = stagedPurchases
        .filter(purchase => {
          if (currentDay && currentTime) {
            const isWithinDay =
              purchase?.item.days.length && purchase?.item.availability
                ? purchase?.item.days.map(day => String(day)).includes(currentDay)
                : true;
            const isWithinTime =
              purchase?.item.start && purchase?.item.end && purchase?.item.availability
                ? currentTime >= purchase?.item.start && currentTime <= purchase?.item.end
                : true; // No time constraint if start or end is null

            let isWithinDayCategory = true;
            let isWithinTimeCategory = true;
            if (isWithinDay || isWithinTime) {
              const rootCategory = getRootCategory(state);
              const categoryOfPurchase = rootCategory?.subCategories.filter(
                subCategory => subCategory.id === purchase?.mimCategoryId,
              );
              isWithinDayCategory =
                categoryOfPurchase!.length &&
                categoryOfPurchase![0].days.length &&
                categoryOfPurchase![0].availability
                  ? categoryOfPurchase![0].days.map(day => String(day)).includes(currentDay)
                  : true;
              isWithinTimeCategory =
                categoryOfPurchase!.length &&
                categoryOfPurchase![0].start &&
                categoryOfPurchase![0].end &&
                categoryOfPurchase![0].availability
                  ? currentTime >= categoryOfPurchase![0].start &&
                    currentTime <= categoryOfPurchase![0].end
                  : true; // No time constraint if start or end is null
            }
            return !isWithinDay || !isWithinTime || !isWithinDayCategory || !isWithinTimeCategory; // Filter out items not available now
          }
        })
        .map(({ id, item }) => ({ id, name: item.name }));

      const updateTime = () => {
        dispatch(setPickupTime(changedTime!));
      };

      if (invalidItems.length) {
        dispatch(
          setCurrentModal({
            modalId: CONFIRM_CHANGES_MODAL_ID,
            params: {
              title: i18next.t('confirmChanges.title'),
              message: i18next.t('confirmChanges.subtitle'),
              previousChangesTitle: i18next.t('confirmChanges.previousChangesTitle'),
              previousChangesDescription: i18next.t('confirmChanges.previousChangesDescription'),
              currentChangesTitle: i18next.t('confirmChanges.currentChangesTitle'),
              currentChangesDescription: i18next.t('confirmChanges.currentChangesTitle'),
              confirmChangesTitle: i18next.t('confirmChanges.subsectionTitle'),
              confirmChangesDescription: i18next.t(
                'confirmChanges.confirmChangesSubsectionDescription',
              ),
              changesBreakdown: invalidItems,
              buttonText: i18next.t('button.confirm'),
              action: () => {
                invalidItems &&
                  Array.isArray(invalidItems) &&
                  invalidItems.map(item => dispatch(removeStagedPurchase(item.id)));
                updateTime();
                dispatch(setCurrentModal(null));
              },
            },
          }),
        );
      } else {
        updateTime();
      }
    } catch (e) {
      console.error('validateFutureMenuItems thunk failed', e);
      return rejectWithValue(e);
    }
  },
);
