import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import MapAddressSearch from './MapAddressSearch';
import Map from './Map';
import NearbyLocations from './NearbyLocations';
import RecentLocations from './RecentLocations';
import { FormikValues, useFormikContext } from 'formik';

const DeliveryMap = () => {
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const { values } = useFormikContext<FormikValues>();

  return (
    <div className="position-relative" style={styles.container}>
      <Map
        mapContainerStyle={{ height: '35vh' }}
        mapDivStyle={{ height: '35vh' }}
        showNearbyLocations
        markerPosition={
          values.lat && values.lng
            ? {
                lat: values.lat,
                lng: values.lng,
              }
            : null
        }
      />
      <div
        className="my-3"
        style={deviceTypeMobile ? styles.badgeOptionsMobile : styles.badgeOptions}
      >
        <MapAddressSearch />
        <NearbyLocations />
        <RecentLocations />
      </div>
    </div>
  );
};

export default DeliveryMap;

const styles: Styles = {
  container: { display: 'flex', height: '100%', flexDirection: 'column' },
  badgeOptions: { marginLeft: '5%', marginRight: '5%', paddingBottom: 86 },
  badgeOptionsMobile: { marginLeft: 10, marginRight: 10, flexGrow: 1, marginBottom: 86 },
};
