import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchParams } from '../utils/Api';
import { setReducedLocations } from '../reducers/locations';
import { normaliseArray } from '../utils/misc';
import processLocationShort from '../utils/processors/processLocationShort';
import { getAdvancedCustomHours } from '../selectors';
import { getOrderingProvider } from '../selectors/config';

export const fetchReducedLocations = createAsyncThunk(
  '$fetchReducedLocations',
  async (data: undefined, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as any;

      const params: FetchParams = {
        path: `/api/v1/stores/reduced`,
        method: 'GET',
      };

      const response = await RedcatApiHandler.fetch(params);

      // Getting the advanced custom hours
      const advancedCustomHours = getAdvancedCustomHours(state);

      const { deliveryTimeOpen, deliveryTimeClose } = state.config;

      const processedLocations = response.data.map(
        (location: RawLocationShort) =>
          processLocationShort(
            location,
            advancedCustomHours,
            deliveryTimeOpen,
            deliveryTimeClose,
          ),
      );

      dispatch(
        setReducedLocations({
          locationsShort: normaliseArray(processedLocations, 'id'),
        }),
      );
    } catch (e) {
      console.warn(e);
      return rejectWithValue(e);
    }
  },
);
