import { ORDER_TYPE_MODAL_ID } from '../modals/OrderTypeModal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightSLine } from 'react-icons/ri';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import SubSection from './SubSection';
import { OrderingSelectors } from 'polygon-ordering';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import getThemeLookup from '../selectors/getThemeLookup';
import { TEXT_PROPERTIES } from '../utils/theme';
import { DELIVERY_DETAILS_MODAL_ID } from '../modals/DeliveryDetailsModal';
import { LOCATION_MODAL_ID } from '../modals/LocationModal';
import { FormHelperText } from '@material-ui/core';

const { getSaleType, getLocationId, getTable, getBypassDeliveryDetails, getDeliveryAddressString } =
  OrderingSelectors;

const OrderTypeSummary: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const saleType = useAppSelector(getSaleType);
  const deliveryAddress = useAppSelector(getDeliveryAddressString);
  const table = useAppSelector(getTable);
  const locationId = useAppSelector(getLocationId);

  const isDelivery = saleType === SALE_TYPE.DELIVERY;
  const isPickupOrTakeAway =
    saleType === SALE_TYPE.CATERING ||
    saleType === SALE_TYPE.PICKUP ||
    saleType === SALE_TYPE.TAKE_AWAY ||
    saleType === SALE_TYPE.WEB_ORDERING;
  const isDineIn = saleType === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER;
  const missingTableNumber = isDineIn && !table;
  const displayTableText = `Ordering to table ${missingTableNumber ? '' : table}`;

  const bypassDeliveryDetails = useAppSelector(getBypassDeliveryDetails);
  const desiredDeliveryAddressNotFound = useAppSelector(
    state => state.desiredDeliveryAddressNotFound,
  );

  const bypassedDetailsMissingAddress =
    bypassDeliveryDetails && (desiredDeliveryAddressNotFound || !deliveryAddress);
  return (
    <div style={{ padding: '0 3.5em' }}>
      <SubSection
        title={
          isPickupOrTakeAway || isDelivery
            ? t(`saleType.${saleType}.name`)
            : isDineIn
            ? displayTableText
            : ''
        }
        subtitle={
          isDelivery
            ? bypassedDetailsMissingAddress
              ? 'Enter address'
              : deliveryAddress
            : missingTableNumber
            ? '* Please select a table'
            : ''
        }
        subtitleStyle={
          bypassedDetailsMissingAddress || missingTableNumber
            ? p('menuLocationHeaderTitleAction', TEXT_PROPERTIES)
            : { lineHeight: '185%', ...p('orderTypeDeliverySubtitleAddress', TEXT_PROPERTIES) }
        }
        action={() => {
          if (missingTableNumber) {
            dispatch(
              setCurrentModal({
                modalId: LOCATION_MODAL_ID,
                params: {
                  saleType,
                  locationId,
                  allowRedirect: false,
                  action: () => {
                    dispatch(setCurrentModal(null));
                  },
                },
              }),
            );
          } else if (bypassDeliveryDetails) {
            dispatch(
              setCurrentModal({
                modalId: DELIVERY_DETAILS_MODAL_ID,
                params: { saleType, allowRedirect: false },
              }),
            );
          } else {
            dispatch(
              setCurrentModal({
                modalId: ORDER_TYPE_MODAL_ID,
                params: { allowRedirect: false },
              }),
            );
          }
        }}
        ActiveIcon={() => <RiArrowRightSLine color={p('modalSubSection', ['color']).color} />}
      />
    </div>
  );
};

export default OrderTypeSummary;
