import { createAsyncThunk } from '@reduxjs/toolkit';
import getItems from '../selectors/getItems';
import getUnenhancedOpenPurchase from '../selectors/getUnenhancedOpenPurchase';
import substitutionSelectionsFromBase from '../utils/ordering/substitutionSelectionsFromBase';

export const resetOpenPurchaseSubstitutionSets = createAsyncThunk(
  '$resetOpenPurchaseSubstitutionSets',
  async (
    data: {
      targetSubstitutionSetIds: string[];
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const { targetSubstitutionSetIds } = data;

      const openPurchase = getUnenhancedOpenPurchase(
        getState() as EntireFrontendState,
      );

      let adjustedPurchase: _Purchase;

      const items = getItems(getState() as EntireFrontendState);
      const item = items![openPurchase?.sizeId ?? openPurchase!.itemId] as Item;

      const defaultSubstitutionSelections = substitutionSelectionsFromBase(
        item.substitutionSets,
      );

      let newSubstitutionSelections: SubstitutionSelections = {
        ...(openPurchase!.substitutionSelections as SubstitutionSelections),
      };

      targetSubstitutionSetIds.forEach(substitutionSetId => {
        newSubstitutionSelections[substitutionSetId] =
          defaultSubstitutionSelections[substitutionSetId];
      });

      adjustedPurchase = {
        ...openPurchase!,
        substitutionSelections: newSubstitutionSelections,
      };

      return {
        purchase: adjustedPurchase,
      };
    } catch (e) {
      console.error('Reset open purchase substitution sets failed', { e });
      return rejectWithValue(e);
    }
  },
);
