import Analytics from 'analytics';
//@ts-ignore
import googleAnalytics from '@analytics/google-analytics'; // https://github.com/DavidWells/analytics/issues/107
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from './TiktokPixel';
import { IN_DEVELOPMENT } from '../constants';
import EVENTS from '../constants/events';
import * as braze from '@braze/web-sdk';
import debug from './debug';

import { centsToDollars } from '../libs/polygon-ordering/src/utils/misc';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent as firebaseEvent } from 'firebase/analytics';
import { v4 as uuidv4 } from 'uuid';
import sha256 from 'js-sha256';
import TagManager from 'react-gtm-module';

function getCurrency() {
  const currency = 'AUD';

  return currency;
}

const saleTypeLabels = {
  107: 'Catering',
  103: 'Delivery',
  100: 'Dine In',
  104: 'Drive Thru',
  102: 'Pick Up',
  105: 'Table Order',
  101: 'Take Away',
  106: 'Web Ordering',
};

const formatCurrency = (cents?: number | null) => {
  const amount = centsToDollars(cents);
  if (!amount) return cents;

  const result = `${amount < 0 ? '-' : ''}${Math.abs(amount).toFixed(2)}`;

  return result;
};

let enabledBrazeEvents: string[] | null;

function getInitialised() {
  const google = Boolean(window._GAnalytics);
  const facebook = Boolean(window._FBAnalytics);
  const tiktok = Boolean(window.ttq);
  const firebase = Boolean(window.firebase);
  const brazeAnalytics = Boolean(window.brazeAnalytics);
  const googleTagManager = Boolean(window.googleTagManager);

  const noAnalytics = !google && !facebook && !firebase && !tiktok && !googleTagManager;

  return {
    google,
    facebook,
    noAnalytics,
    firebase,
    tiktok,
    brazeAnalytics,
    googleTagManager,
  };
}

function getContentIDs(items: any) {
  const content_ids = [];

  for (let i = 0; i < items.length; i++) {
    content_ids.push(`${items[i].id}`);
  }

  return content_ids?.length > 1 ? `[${content_ids.join(',')}]` : content_ids[0];
}

function getContent(items: any, provider: string) {
  const content = [];

  if (provider === 'facebook') {
    for (let i = 0; i < items.length; i++) {
      content.push(`{id:${items[i].id}, quantity:${items[i].quantity}}`);
    }

    return `[${content.join(',')}]`;
  }
  if (provider === 'google') {
    for (let i = 0; i < items.length; i++) {
      content.push({
        item_id: items[i].id,
        item_name: items[i].name,
        price: centsToDollars(items[i].totalPrice),
        currency: getCurrency(),
      });
    }
    return content;
  }

  if (provider === 'tiktok') {
    for (let i = 0; i < items.length; i++) {
      content.push({
        content_id: items[i].id,
        content_type: 'product',
        quantity: items[i].quantity,
        content_name: items[i].name,
        price: centsToDollars(items[i].totalPrice),
      });
    }
    return content;
  }
}

export function initAnalytics({
  googleAnalyticsKey,
  facebookAnalyticsKey,
  member,
  firebaseConfig,
  tiktokAnalyticsKey,
  brazeAnalyticsKey,
  brazeSdkEndpoint,
  brazeEventsEnabled,
}: {
  googleAnalyticsKey: string;
  facebookAnalyticsKey: string;
  member?: Member;
  firebaseConfig: FirebaseConfig;
  tiktokAnalyticsKey: string;
  brazeAnalyticsKey: string;
  brazeSdkEndpoint: string;
  brazeEventsEnabled: string[] | null;
}) {
  try {
    if (brazeEventsEnabled) enabledBrazeEvents = brazeEventsEnabled;
    const { google, facebook, tiktok, firebase, brazeAnalytics } = getInitialised();

    let tiktokAdvancedMatching: AdvancedMatching = {};
    let advancedMatching: AdvancedMatching = {};

    if (member) {
      advancedMatching.em = member.email;
      advancedMatching.fn = member.givenName;
      advancedMatching.ln = member.surname;
      tiktokAdvancedMatching.email = member.email;
      if (member.mobile) {
        tiktokAdvancedMatching.phone = member.mobile;
      }
    }

    if (firebase) {
      const app = initializeApp(firebaseConfig);
      getAnalytics(app);
    }

    if (tiktokAnalyticsKey && !tiktok) {
      TiktokPixel.init(tiktokAnalyticsKey, tiktokAdvancedMatching, {
        debug: IN_DEVELOPMENT ? true : false,
      });
      debug('initialised tiktok analytics', { tiktokAnalyticsKey });
    }
    if (googleAnalyticsKey && !google) {
      window._GAnalytics = Analytics({
        app: 'polygon-web-ordering',
        version: process.env.REACT_APP_VERSION,
        plugins: [
          googleAnalytics({
            measurementIds: [googleAnalyticsKey],
          }),
        ],
      });

      debug('initialised google analytics', { googleAnalyticsKey });
    }

    if (facebookAnalyticsKey && !facebook) {
      //@ts-ignore
      ReactPixel.init(facebookAnalyticsKey!, advancedMatching, {
        autoConfig: false, // https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: IN_DEVELOPMENT ? true : false, // logs
      });

      window._FBAnalytics = true;

      debug('initialised facebook analytics', { facebookAnalyticsKey });
    }

    if (brazeAnalyticsKey && !brazeAnalytics) {
      braze.initialize(brazeAnalyticsKey, {
        baseUrl: brazeSdkEndpoint,
        enableLogging: IN_DEVELOPMENT ? true : false,
      });

      braze.getDeviceId(function (deviceId) {
        console.log('The device id is' + deviceId);
      });

      braze.automaticallyShowInAppMessages();
      braze.openSession();
      braze.requestContentCardsRefresh();
      window.brazeAnalytics = true;
      window.braze = braze;
      debug('initialised braze analytics', { brazeAnalyticsKey });
    }
  } catch (e) {
    console.error('initAnalytics failed', { e });
  }
}

export function initiateContentCards() {
  try {
    const { brazeAnalytics } = getInitialised();
    if (brazeAnalytics) {
      const { cards } = braze.getCachedContentCards();
      if (cards.length !== 0) {
        braze.toggleContentCards();
      }
    }
  } catch (err) {
    console.error('initiate content cards failed.', { err });
  }
}

export function logScreen(screen: string) {
  try {
    const { google, facebook, firebase, tiktok, noAnalytics, brazeAnalytics } = getInitialised();

    if (IN_DEVELOPMENT || noAnalytics) {
      debug('SCREEN:', screen);
      return;
    }

    if (brazeAnalytics && enabledBrazeEvents?.includes('Screen Name')) {
      braze.logCustomEvent('Screen Name', {
        screenName: screen,
      });
    }

    if (tiktok) {
      TiktokPixel.pageView();
    }

    if (firebase) {
      const analytics = getAnalytics();
      //@ts-ignore
      firebaseEvent(analytics, 'screen_view', {
        firebase_screen: screen!,
      });
    }

    if (google) {
      window._GAnalytics.page();
    }

    if (facebook) {
      ReactPixel.pageView();
    }
  } catch (e) {
    console.error('logScreen failed', { e });
  }
}
export function logModal(modal: string | null) {
  try {
    const { noAnalytics } = getInitialised();

    if (IN_DEVELOPMENT || noAnalytics) {
      debug('MODAL:', modal);
      return;
    }

    logEvent(modal ? EVENTS.OPEN_MODAL : EVENTS.CLOSE_MODAL, { label: modal });
  } catch (e) {
    console.error('logModal failed', { e });
  }
}

export async function removeUserSession() {
  const { noAnalytics, brazeAnalytics } = getInitialised();

  if (IN_DEVELOPMENT || noAnalytics) {
    debug('Wiping braze session data');
    return;
  }

  if (brazeAnalytics) {
    debug('Wiping braze session data');
    braze.wipeData(); //remove user session and data
  }
}

export function logUser(userId?: string | null, data?: Member) {
  const userIdOrGuest = userId || 'GUEST';
  try {
    const { google, facebook, firebase, noAnalytics, brazeAnalytics } = getInitialised();

    if (IN_DEVELOPMENT || noAnalytics) {
      const logParts: (string | Member)[] = [userIdOrGuest];

      if (data) {
        logParts.push(data);
      }

      debug('USER:', ...logParts);

      return;
    }

    if (brazeAnalytics && userId) {
      //@ts-ignore
      braze.changeUser(sha256(data!.email.toLowerCase()));

      if (data?.email) {
        braze.getUser()?.setEmail(data!.email);
      }
      if (data?.givenName) {
        braze.getUser()?.setFirstName(data!.givenName);
      }

      if (data?.surname) {
        braze.getUser()?.setLastName(data!.surname);
      }

      if (data?.dateOfBirth) {
        const date = new Date(data.dateOfBirth);
        braze.getUser()?.setDateOfBirth(date.getFullYear(), date.getDate(), date.getDay());
      }

      if (data?.mobile) {
        braze.getUser()?.setPhoneNumber(data.mobile);
      } else if (data?.phone) {
        braze.getUser()?.setPhoneNumber(data.phone);
      }

      navigator.geolocation.getCurrentPosition(position => {
        const { coords } = position;
        braze
          .getUser()
          ?.setLastKnownLocation(
            coords.latitude,
            coords.longitude,
            coords.accuracy,
            coords.altitude ? coords.altitude : undefined,
            coords.altitudeAccuracy ? coords.altitudeAccuracy : undefined,
          );
      });

      const fields = Object.keys(data!).filter(
        item =>
          item === data?.email ||
          item === data?.givenName ||
          item === data?.surname ||
          item === data?.dateOfBirth ||
          item === data?.mobile ||
          item === data?.phone,
      );

      fields.map(field =>
        //@ts-ignore
        braze.getUser()?.setCustomUserAttribute(field, data![field as keyof Member]),
      ); // custom fields )
      initiateContentCards();
    }

    if (firebase) {
      const analytics = getAnalytics();
      //@ts-ignore
      analytics.setUserId(userIdOrGuest);
    }

    if (google) {
      window._GAnalytics.identify(userIdOrGuest, data);
    }

    if (facebook) {
      // is there a corresponding function?
    }
  } catch (e) {
    console.error('logUser failed', { e });
  }
}

type LogEvent = {
  action: string;
  category: string;
};
type LogEventParams = { category?: string; label?: string; value?: string | number };
type LogEventData = { label?: string | null; value?: number | string };

export function logEvent(event: LogEvent, data?: LogEventData) {
  try {
    const { google, facebook, firebase, tiktok, noAnalytics, brazeAnalytics } = getInitialised();

    if (IN_DEVELOPMENT || noAnalytics) {
      const logParts: (string | LogEventData)[] = [event.action];

      if (data) {
        logParts.push(data);
      }

      debug('EVENT:', ...logParts);

      return;
    }

    const params: LogEventParams = {};

    params.category = event.category;

    if (data?.label != null) {
      params.label = data.label;
    }

    if (data?.value != null) {
      params.value = data.value;
    }

    if (brazeAnalytics && enabledBrazeEvents?.includes(event.action)) {
      braze.logCustomEvent(event.action, params);
    }

    if (tiktok) {
      // No custom tracking
    }

    if (firebase) {
      const analytics = getAnalytics();
      firebaseEvent(analytics, event.action, params);
    }

    if (google) {
      window._GAnalytics.track(event.action, params);
    }

    if (facebook) {
      // More info about custom events:
      // https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
      ReactPixel.trackCustom(event.action, params);

      // More info about standard events:
      // https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
      // https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
      // ReactPixel.track(event, data);
    }
  } catch (e) {
    console.error('logEvent failed', { e });
  }
}

export function paymentInfo(items: any, total: number, payment_method: PAYMENT_METHOD) {
  try {
    const { google, facebook, firebase, tiktok, noAnalytics, brazeAnalytics } = getInitialised();

    if (IN_DEVELOPMENT || noAnalytics) {
      debug('PaymentInfo:', { items, total, payment_method });
      return;
    }

    if (brazeAnalytics && enabledBrazeEvents?.includes('Payment Info')) {
      braze.logCustomEvent('Payment Info', {
        content_category: payment_method,
        contents: getContent(items, 'tiktok'),
        currency: getCurrency(),
        value: centsToDollars(total),
      });
    }

    if (tiktok) {
      TiktokPixel.track('AddPaymentInfo', {
        content_category: payment_method,
        contents: getContent(items, 'tiktok'),
        currency: getCurrency(),
        value: centsToDollars(total),
      });
    }

    if (firebase) {
      const analytics = getAnalytics();
      //@ts-ignore
      firebaseEvent(analytics, 'add_payment_info', {
        currency: getCurrency(),
        value: centsToDollars(total),
        items: getContent(items, 'google'),
        payment_type: payment_method,
      });
    }

    if (facebook) {
      ReactPixel.track('AddPaymentInfo', {
        content_category: payment_method,
        content_ids: getContentIDs(items),
        currency: getCurrency(),
        value: centsToDollars(total),
        content_type: 'product',
      });
    }
  } catch (err) {
    console.log('PaymentInfo logging failed.');
  }
}

export function viewProductDetailPage(
  product: Item,
  quantity: number | null,
  choicesWithQuantity: ChoiceWithQuantity[],
  brand: string | null,
  category: string | null,
) {
  try {
    const { noAnalytics, googleTagManager } = getInitialised();

    if (IN_DEVELOPMENT || noAnalytics) {
      debug('ViewItem:', { product });
      return;
    }

    //TODO add other analytics providers

    if (googleTagManager) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'dh_view_item',
          ecommerce: {
            transaction_id: undefined,
            value: formatCurrency(product.baseMoneyPrice),
            currency: getCurrency(),
            items: [
              {
                item_id: product.id || undefined,
                item_name: product.name || undefined,
                item_brand: brand || undefined,
                item_category: category || undefined,
                item_variant: choicesWithQuantity.length
                  ? choicesWithQuantity.map(option => ({
                      id: option.id,
                      name: option.name,
                      moneyPrice: formatCurrency(option.moneyPrice),
                      quantity: option.quantity,
                    }))
                  : undefined,
                price: formatCurrency(product.baseMoneyPrice) || undefined,
                quantity: quantity || undefined,
              },
            ],
          },
          UserID: undefined,
          FirstName: undefined,
          LastName: undefined,
          Email: undefined,
          PhoneNumber: undefined,
          State: undefined,
          City: undefined,
          ZipCode: undefined,
          DateOfBirth: undefined,
          saleType: undefined,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  } catch (err) {
    console.log('ViewItem logging failed.');
  }
}

export function addToCart(
  product: Item,
  moneyPrice: number,
  quantity: number,
  choicesWithQuantity: ChoiceWithQuantity[],
  brand: string | null,
  category: string | null,
) {
  try {
    const { google, facebook, firebase, tiktok, noAnalytics, brazeAnalytics, googleTagManager } =
      getInitialised();

    if (IN_DEVELOPMENT || noAnalytics) {
      debug('AddtoCart:', { product, moneyPrice, quantity });
      return;
    }

    if (brazeAnalytics && enabledBrazeEvents?.includes('Add To Cart')) {
      braze.logCustomEvent('Add To Cart', {
        product,
        moneyPrice,
        quantity,
      });
    }

    if (tiktok) {
      TiktokPixel.track('AddToCart', {
        content_type: 'product',
        quantity,
        content_name: product.name,
        content_id: `${product.id}`,
        currency: getCurrency(),
        value: centsToDollars(moneyPrice),
      });
    }

    if (firebase) {
      const analytics = getAnalytics();
      //@ts-ignore
      firebaseEvent(analytics, 'add_to_cart', {
        currency: getCurrency(),
        value: centsToDollars(moneyPrice),
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            quantity,
            price: centsToDollars(moneyPrice),
            currency: getCurrency(),
          },
        ],
      });
    }

    if (facebook) {
      ReactPixel.track('AddToCart', {
        content_name: product.name, // name of the page
        content_type: 'product', // always product
        content_ids: [`${product.id}`], // array of numbers or strings
        currency: getCurrency(),
        value: centsToDollars(moneyPrice),
      });
    }

    if (googleTagManager) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'dh_add_to_cart',
          ecommerce: {
            transaction_id: undefined,
            value: formatCurrency(moneyPrice),
            currency: getCurrency(),
            items: [
              {
                item_id: product.id || undefined,
                item_name: product.name || undefined,
                item_brand: brand || undefined,
                item_category: category || undefined,
                item_variant: choicesWithQuantity.length
                  ? choicesWithQuantity.map(option => ({
                      id: option.id,
                      name: option.name,
                      moneyPrice: formatCurrency(option.moneyPrice),
                      quantity: option.quantity,
                    }))
                  : undefined,
                price: formatCurrency(product.baseMoneyPrice) || undefined,
                quantity: quantity || undefined,
              },
            ],
          },
          UserID: undefined,
          FirstName: undefined,
          LastName: undefined,
          Email: undefined,
          PhoneNumber: undefined,
          State: undefined,
          City: undefined,
          ZipCode: undefined,
          DateOfBirth: undefined,
          saleType: undefined,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  } catch (err) {
    console.log('AddtoCart logging failed.');
  }
}

export function orderNow() {
  try {
    const { noAnalytics, googleTagManager } = getInitialised();

    if (IN_DEVELOPMENT || noAnalytics) {
      debug('ViewItem:', {});
      return;
    }

    //TODO add other analytics providers

    if (googleTagManager) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'dh_order_now',
          UserID: undefined,
          FirstName: undefined,
          LastName: undefined,
          Email: undefined,
          PhoneNumber: undefined,
          State: undefined,
          City: undefined,
          ZipCode: undefined,
          DateOfBirth: undefined,
          ecommerce: undefined,
          saleType: undefined,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  } catch (err) {
    console.log('OrderNow logging failed.');
  }
}

export function deliveryModeSelected(saleType: SALE_TYPE) {
  try {
    const { noAnalytics, googleTagManager } = getInitialised();

    if (IN_DEVELOPMENT || noAnalytics) {
      debug('ViewItem:', { saleType: saleTypeLabels[saleType] });
      return;
    }

    //TODO add other analytics providers

    if (googleTagManager) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'dh_order_type',
          saleType: saleTypeLabels[saleType],
          UserID: undefined,
          FirstName: undefined,
          LastName: undefined,
          Email: undefined,
          PhoneNumber: undefined,
          State: undefined,
          City: undefined,
          ZipCode: undefined,
          DateOfBirth: undefined,
          ecommerce: undefined,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  } catch (err) {
    console.log('OrderType logging failed.');
  }
}

export function purchase(items: any[], total: number, transactionId?: string, brand?: string) {
  try {
    const { google, facebook, firebase, tiktok, noAnalytics, googleTagManager } = getInitialised();

    if (IN_DEVELOPMENT || noAnalytics) {
      debug('Purchase:', { items, total });
      return;
    }

    if (tiktok) {
      TiktokPixel.track('CompletePayment', {
        contents: getContent(items, 'tiktok'),
        value: centsToDollars(total),
        currency: getCurrency(),
      });
    }

    if (firebase) {
      const analytics = getAnalytics();
      //@ts-ignore
      firebaseEvent(analytics, 'purchase', {
        currency: getCurrency(),
        value: centsToDollars(total),
        items: getContent(items, 'google'),
        transaction_id: uuidv4(), //  helps you avoid getting duplicate events for a purchase
      });
    }

    if (facebook) {
      ReactPixel.track('Purchase', {
        content_type: 'product', // always product
        content_ids: getContentIDs(items), // array of numbers or strings
        currency: getCurrency(),
        value: centsToDollars(total),
      });
    }

    if (googleTagManager) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'dh_purchase',
          ecommerce: {
            transaction_id: transactionId,
            value: formatCurrency(total),
            currency: getCurrency(),
            items: items.length
              ? items.map((item: Item & { quantity: number; choicesWithQuantity: any[] }) => ({
                  item_id: item.id || undefined,
                  item_name: item.name || undefined,
                  item_brand: brand || undefined,
                  item_category: item.category || undefined,
                  item_variant: item.choicesWithQuantity
                    ? item.choicesWithQuantity.map(option => ({
                        id: option.id,
                        name: option.name,
                        moneyPrice: formatCurrency(option.moneyPrice),
                        quantity: option.quantity,
                      }))
                    : undefined,
                  price: formatCurrency(item.baseMoneyPrice) || undefined,
                  quantity: item.quantity || undefined,
                }))
              : undefined,
          },
          UserID: undefined,
          FirstName: undefined,
          LastName: undefined,
          Email: undefined,
          PhoneNumber: undefined,
          State: undefined,
          City: undefined,
          ZipCode: undefined,
          DateOfBirth: undefined,
          saleType: undefined,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  } catch (err) {
    console.log('Purchase logging failed.');
  }
}

export function purchaseGiftCard(amount: number) {
  try {
    const { noAnalytics, googleTagManager } = getInitialised();

    if (IN_DEVELOPMENT || noAnalytics) {
      debug('ViewItem:', {});
      return;
    }

    //TODO add other analytics providers

    if (googleTagManager) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'dh_purchase',
          ecommerce: {
            transaction_id: undefined,
            value: amount,
            currency: getCurrency(),
            items: [
              {
                item_id: undefined,
                item_name: 'Gift card',
                item_brand: undefined,
                item_category: undefined,
                item_variant: undefined,
                price: amount,
                quantity: 1,
              },
            ],
          },
          UserID: undefined,
          FirstName: undefined,
          LastName: undefined,
          Email: undefined,
          PhoneNumber: undefined,
          State: undefined,
          City: undefined,
          ZipCode: undefined,
          DateOfBirth: undefined,
          saleType: undefined,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  } catch (err) {
    console.log('PurchaseGiftCard logging failed.');
  }
}

export function registerUser(
  content_name: string,
  status: boolean,
  value?: OrderTotals,
  items?: any,
  userData?: any,
) {
  try {
    const { google, facebook, firebase, tiktok, noAnalytics, googleTagManager } = getInitialised();

    if (IN_DEVELOPMENT || noAnalytics) {
      debug('REGISTER USER:', content_name, status, value, items);
      return;
    }

    if (tiktok) {
      TiktokPixel.track('CompleteRegistration', {
        contents: getContent(items, 'tiktok'),
        content_name,
        value: centsToDollars(value?.discountedMoneyPrice) || 0,
        currency: getCurrency(),
      });
    }

    if (firebase) {
      const analytics = getAnalytics();
      firebaseEvent(analytics, 'sign_up', { method: content_name });
    }

    if (facebook) {
      ReactPixel.track('CompleteRegistration', {
        content_name,
        status,
        value: centsToDollars(value?.discountedMoneyPrice) || 0,
        currency: getCurrency(),
      });
    }

    if (googleTagManager && userData) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'dh_user_register',
          UserID: userData?.ID,
          FirstName: userData?.GivenNames,
          LastName: userData?.Surname,
          Email: userData?.Email,
          PhoneNumber: userData?.Mobile,
          State: userData?.State,
          City: userData?.Suburb,
          ZipCode: userData?.Postcode,
          DateOfBirth: userData?.DateOfBirth,
          ecommerce: undefined,
          saleType: undefined,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  } catch (err) {
    console.log('Registering user failed', err);
  }
}

export function initiateCheckout(items: any, total: number) {
  try {
    const { google, facebook, firebase, tiktok, noAnalytics, googleTagManager } = getInitialised();

    if (IN_DEVELOPMENT || noAnalytics) {
      debug('INITIATE CHECKOUT:', items, total);
      return;
    }

    if (tiktok) {
      TiktokPixel.track('InitiateCheckout', {
        contents: getContent(items, 'tiktok'),
        value: centsToDollars(total),
        currency: getCurrency(),
      });
    }

    if (firebase) {
      const analytics = getAnalytics();
      //@ts-ignore
      firebaseEvent(analytics, 'begin_checkout', {
        currency: getCurrency(),
        value: centsToDollars(total),
        items: getContent(items, 'google'),
      });
    }

    if (facebook) {
      ReactPixel.track('InitiateCheckout', {
        content_ids: getContentIDs(items),
        currency: getCurrency(),
        num_items: parseInt(items.length),
        value: centsToDollars(total),
        content_type: 'product',
      });
    }

    if (googleTagManager) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'dh_begin_checkout',
          ecommerce: {
            transaction_id: undefined,
            value: formatCurrency(total),
            currency: getCurrency(),
            items: items.length
              ? items?.map(
                  (
                    item: Item & {
                      quantity: number;
                      choicesWithQuantity: any[];
                      brand: string | null;
                    },
                  ) => ({
                    item_id: item.id || undefined,
                    item_name: item.name || undefined,
                    item_brand: item.brand || undefined,
                    item_category: item.category || undefined,
                    item_variant: item.choicesWithQuantity.length
                      ? item.choicesWithQuantity.map(option => ({
                          id: option.id,
                          name: option.name,
                          moneyPrice: formatCurrency(option.moneyPrice),
                          quantity: option.quantity,
                        }))
                      : undefined,
                    price: formatCurrency(item.baseMoneyPrice) || undefined,
                    quantity: item.quantity || undefined,
                  }),
                )
              : undefined,
          },
          UserID: undefined,
          FirstName: undefined,
          LastName: undefined,
          Email: undefined,
          PhoneNumber: undefined,
          State: undefined,
          City: undefined,
          ZipCode: undefined,
          DateOfBirth: undefined,
          saleType: undefined,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  } catch (err) {
    console.log('InitiateCheckout Failed', err);
  }
}
