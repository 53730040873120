// Imports
import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeAdvertisements } from '../reducers/advertisements';

import { getMemberOffers } from '../selectors';
import { fetchMemberOffers } from '../operations';
import { hasKeyWithNonEmptyArray } from '../utils/misc';
import {
  getShowExpiredCoupons,
  getShowRedeemedCoupons,
} from '../selectors/config';

export const fetchAdvertisements = createAsyncThunk(
  '$fetchAdvertisements',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    try {
      const path = '/api/v1/public/adverts';

      return RedcatApiHandler.fetch({ method: 'GET', path }).then(response => {
        dispatch(storeAdvertisements(response.data));

        /**
         * Check if any of the advertisements contains a coupons in any ad's coupon program array.
         * If it does fetch for offers. This offer reducer is used by getValidAdvertisments to
         * show specific advertisements if the user has a specific coupon.
         */
        const memberOffers = getMemberOffers(getState() as EntireFrontendState);
        const showRedeemedCoupons = getShowRedeemedCoupons(
          getState() as EntireFrontendState,
        );
        const showExpiredCoupons = getShowExpiredCoupons(
          getState() as EntireFrontendState,
        );

        if (
          hasKeyWithNonEmptyArray(response.data, 'Coupons') &&
          memberOffers.length === 0
        ) {
          dispatch(
            fetchMemberOffers({ showRedeemedCoupons, showExpiredCoupons }),
          );
        }
      });
    } catch (e) {
      console.error('Fetch adverts failed', { e });
      return rejectWithValue(e);
    }
  },
);
