import lodash from 'lodash';
import moment from 'moment';
import { SALE_TYPES } from '../../constants';
import { SALE_TYPE } from '../../constants/saleType';

import calculateOrderingWindow from './calculateOrderingWindow';
import calculateFutureDatedOrderingWindow from './calculateFutureDatedOrderingWindow';

interface EnhancedServiceSession extends ServiceSession {
  inSession: boolean;
  beforeSession: boolean;
  afterSession: boolean;
  isLastSession: boolean;
}

// NOTE: this function assumes it is being mapped onto a sorted list of sessions
function enhanceServiceSession(
  session: ServiceSession,
  index: number,
  allSessions: ServiceSession[],
  averageOrderWaitTime: number,
) {
  const earliest = moment().add(averageOrderWaitTime, 'minutes');

  const start = moment(session.start);
  const end = moment(session.end);

  const beforeSession = earliest < start;
  const afterSession = earliest > end;

  return {
    ...session,
    beforeSession,
    afterSession,
    inSession: !beforeSession && !afterSession,
    isLastSession: index + 1 === allSessions.length,
  };
}

function getMostRelevantSession(
  sessions: EnhancedServiceSession[],
): EnhancedServiceSession | undefined {
  if (!sessions || !sessions.length) {
    return;
  }

  // only one session, return it
  if (sessions.length === 1) {
    return sessions[0];
  }

  const sortedSessions = lodash.orderBy(sessions, 'start', ['asc']);

  // return current session (if any)
  const currentSession = sortedSessions.find(session => session.inSession);
  if (currentSession) {
    return currentSession;
  }

  // return the first session we are before (if any)
  const firstSessionBefore = sortedSessions.find(
    session => session.beforeSession,
  );
  if (firstSessionBefore) {
    return firstSessionBefore;
  }

  // must be after all sessions, return the last one
  return sortedSessions[sortedSessions.length - 1];
}

interface SessionInfo {
  takingOrders: boolean;
  session: EnhancedServiceSession | undefined;
}

function getSessionInfo(
  location: POSLocation | undefined,
): Record<string, SessionInfo> {
  const sessionInfo: Record<string, SessionInfo> = {};

  location?.supportedSaleTypes.forEach(saleType => {
    const enhancedSessions = lodash
      .chain(location.serviceSessions[saleType])
      .map((session, index, allSessions) =>
        enhanceServiceSession(
          session,
          index,
          //@ts-ignore
          allSessions,
          location.averageOrderWaitTime,
        ),
      )
      .orderBy('start', 'asc')
      .value();

    const mostRelevantSession = getMostRelevantSession(enhancedSessions);

    const preSessionOrderingPossible =
      !!mostRelevantSession?.beforeSession &&
      location.outOfSessionOrderingEnabled;

    const inSession = Boolean(mostRelevantSession?.inSession);

    let takingOrders = true;

    // If catering sale type then no need to do anything
    if (saleType !== SALE_TYPE.CATERING) {
      if (
        location?.availableSaleTypesToday[saleType].isAvailable &&
        moment().isBetween(
          moment(location.availableSaleTypesToday[saleType].openingTime),
          moment(location.availableSaleTypesToday[saleType].closingTime),
        )
      ) {
        takingOrders = inSession || preSessionOrderingPossible;
      } else {
        takingOrders = false;
      }
    }

    sessionInfo[saleType] = { takingOrders, session: mostRelevantSession };
  });

  return sessionInfo;
}

export default function determineLocationSessions(
  location: POSLocation | POSLocationShort | undefined,
  padding: OrderingWindowPadding | null,
  currentSaleType?: SALE_TYPE,
  futureDatedOrderingLimit = 0,
  minuteStep?: number,
  pickupTime?: string,
): {
  orderingWindowStart: string;
  orderingWindowEnd: string;
  takingOrders: SALE_TYPE[];
  storeTimeSlots?: Array<{
    openingTime: string;
    closingTime: string;
  }>;
} {
  if (!!location && !location?._isFullData) {
    // Getting location  opening and closing time based on current selected sale type
    const { openingTime: LocationOT, closingTime: LocationCT } =
      location.availableSaleTypesToday[String(currentSaleType)];

    // we only have a reduced version of the store currently, do an estimation
    const t_now = moment();
    const t_open = (LocationOT && moment(LocationOT)) || t_now;
    const timeBeforeOpening = t_open.diff(t_now);
    return {
      orderingWindowStart: LocationOT ?? '',
      orderingWindowEnd: LocationCT ?? '',
      takingOrders:
        location.openNow &&
        location.outOfSessionOrderingEnabled &&
        timeBeforeOpening < 0
          ? location.supportedSaleTypes
          : [],
    };
  }

  const sessionInfo = getSessionInfo(location);

  const mostRelevantCurrentSaleTypeSession = currentSaleType
    ? sessionInfo[currentSaleType]?.session
    : undefined;

  if (futureDatedOrderingLimit > 0) {
    const { earliestTime, latestTime, storeTimeSlots } =
      calculateFutureDatedOrderingWindow(
        pickupTime || moment().toISOString(),
        location,
        location?.averageOrderWaitTime,
        padding?.opening,
        padding?.closing,
        currentSaleType,
      );

    const takingOrders = lodash
      .chain(sessionInfo)
      .mapValues(value => value.takingOrders)
      .toPairs()
      .filter(([_, value]) => value)
      .map(([key, _]) => parseInt(key))
      .value();

    return {
      orderingWindowStart: earliestTime,
      orderingWindowEnd: latestTime,
      takingOrders,
      storeTimeSlots,
    };
  } else {
    const { orderingWindowStart, orderingWindowEnd } = calculateOrderingWindow(
      mostRelevantCurrentSaleTypeSession
        ? mostRelevantCurrentSaleTypeSession.start
        : location?.availableSaleTypesToday[String(currentSaleType)]
            .openingTime,
      mostRelevantCurrentSaleTypeSession
        ? mostRelevantCurrentSaleTypeSession.end
        : location?.availableSaleTypesToday[String(currentSaleType)]
            .closingTime,
      location?.averageOrderWaitTime,
      padding?.opening,
      padding?.closing,
    );

    const takingOrders = lodash
      .chain(sessionInfo)
      .mapValues(value => value.takingOrders)
      .toPairs()
      .filter(([_, value]) => value)
      .map(([key, _]) => parseInt(key))
      .value();

    return {
      orderingWindowStart,
      orderingWindowEnd,
      takingOrders,
    };
  }
}
