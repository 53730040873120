import Modal from '../components/Modal';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { setCurrentModal } from '../thunks/setCurrentModal';
import CloseIcon from '../components/icons/CloseIcon';
import MenuSearchBar from '../components/MenuSearchBar';
import getFilteredMenuItemCount from '../selectors/getFilteredMenuItemCount';
import lodash from 'lodash';
import MenuCategory from '../components/MenuCategory';
import Text from '../components/Text';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';

export const MENU_SEARCH_MODAL = 'MENU_SEARCH_MODAL';

const { getFilteredRootCategory } = OrderingSelectors;
const { setMenuSearchText } = OrderingOperations;

const MenuSearchModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const itemCount = useAppSelector(getFilteredMenuItemCount);
  const rootCategory = useAppSelector(getFilteredRootCategory);

  useEffect(() => {
    if (!deviceTypeMobile) {
      dispatch(setCurrentModal(null));
    }
  }, [deviceTypeMobile]);

  const categories = rootCategory?.subCategories || [];

  // on unmount (when the modal closes), clear the menu search
  useEffect(
    () => () => {
      dispatch(setMenuSearchText(null));
    },
    [],
  );

  return (
    <Modal
      tallestPossible
      mobileContainerStyle={{ marginTop: 55 }}
      hideFloatingHeader
      containerStyle={{ padding: 0 }}
    >
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            backgroundColor: 'white',
            paddingBottom: 10,
            borderRadius: 10,
            zIndex: 999,
          }}
        >
          <div style={{ justifyContent: 'flex-end', display: 'flex', marginRight: 20 }}>
            <CloseIcon onClick={() => dispatch(setCurrentModal(null))} boxSize={'35px'} />
          </div>
          <div>
            <MenuSearchBar hideCloseButton />
          </div>
        </div>
      </div>

      <div style={{ padding: 20, marginTop: 70 }}>
        {itemCount ? (
          lodash.map(categories, category => <MenuCategory key={category.id} category={category} />)
        ) : (
          <div>
            <Text
              themeKey="noResultsInMenuSearchMessage"
              value={t('noResultsInMenuSearchMessage')}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default MenuSearchModal;
