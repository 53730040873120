import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMenuSource, getOrderingProvider } from '../selectors/config';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
// import { defaultMenuProvider } from './defaultMenuProvider';
import { setBufferMenu } from '../reducers/buffer/menu';
import { $getLocationId } from '../selectors/getLocationId';
import { $getSaleType } from '../selectors/getSaleType';

import { defaultMenuProviderV2 as defaultMenuProvider } from './defaultMenuProviderV2';
import { fetchAllergenDietaryTags } from './fetchAllergenDietaryTags';
import { setUpsellTitle } from '../reducers/config/upsellTitle';
import { setMenu } from '../reducers/menu';
import getPlatform, { PLATFORM } from '../utils/getPlatform';
import { fetchMaxOrderValueRules } from './fetchMaxOrderValueRules';

export const fetchMenu = createAsyncThunk(
  '$fetchMenu',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    try {
      const locationId = $getLocationId(getState() as EntireFrontendState);
      const saleType = $getSaleType(getState() as EntireFrontendState);
      const menuSource = getMenuSource(getState() as EntireFrontendState);
      const orderingProvider = getOrderingProvider(
        getState() as EntireFrontendState,
      );

      // const menuProvider = menuSource
      //   ? menuSource === 'boost' || menuSource === 'boost-test'
      //     ? boostMenuProvider
      //     : defaultMenuProvider
      //   : defaultMenuProvider;

      const menuProvider = defaultMenuProvider;
      await dispatch(fetchMaxOrderValueRules());
      const response = await dispatch(
        //@ts-ignore
        menuProvider({
          locationId: locationId!,
          saleType,
          orderingProvider: orderingProvider!,
          menuSource: menuSource!,
        }),
      ).unwrap();

      const [
        rootMenuNode,
        items,
        choiceSets,
        upsells,
        upsellTitle,
        allCategoryInfo,
        substitutionSets,
        boostIngredients = null,
      ] = response as [
        MenuNode | undefined,
        _Items,
        ChoiceSets,
        RedCat.UpsellChoiceSet[],
        string,
        CategoryInfo[],
        SubstitutionSets,
      ];

      const menu: Menu = {
        id: uuidv4(),
        locationId: locationId!,
        fetchedTime: moment().toISOString(),
        rootMenuNode: rootMenuNode!,
        items,
        choiceSets,
        upsells,
        allCategoryInfo,
        substitutionSets,
        boostIngredients,
      };

      const platform = getPlatform();

      if (platform == PLATFORM.WEB) {
        setTimeout(() => {
          dispatch(setMenu(menu));
        }, 0);
      } else {
        dispatch(setBufferMenu(menu));
      }

      dispatch(fetchAllergenDietaryTags());
      dispatch(setUpsellTitle(upsellTitle));
    } catch (e) {
      console.error('Fetch menu failed', e);
      return rejectWithValue(e);
    }
  },
);
