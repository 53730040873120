import React from 'react';
import Text from '../components/Text';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import ReorderButton from './ReorderButton';
import ResendInvoice from './ViewInvoice';
import moment from 'moment';
import { AiTwotoneHeart } from 'react-icons/ai';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import TouchableOpacity from '../components/TouchableOpacity';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { FAVOURITE_NAME_MODAL } from '../modals/FavouriteNameModal';
import PastOrderItem from './PastOrderItem';
import { OrderingHooks } from 'polygon-ordering';

const { useFormattedCurrency } = OrderingHooks;
import { centsToDollars, dollarsToCents, formattedDollars } from '../utils/misc';
import FloatingFooter from './FloatingFooter';
import StandardButton from './StandardButton';

import { useHistory } from 'react-router-dom';

const SinglePastOrder: React.FC<{
  selectedOrder: EnhancedHistoricalOrder;
}> = ({ selectedOrder: order }) => {
  const { t } = useTranslation();
  const deviceType = useAppSelector(state => state.deviceType);
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();
  const orderTotal = useFormattedCurrency({ cents: order.moneyPrice });
  const history = useHistory();

  console.log({ order });
  const tableHeaders = [
    {
      title: 'title.loyaltyDescriptionHeader',
      showMobile: true,
    },
    {
      title: 'title.loyaltyQuantity',
      showMobile: false,
    },
    ...(order.pointsAward
      ? [
          {
            title: 'title.loyaltyPoints',
            showMobile: false,
          },
        ]
      : []),
    {
      title: 'title.loyaltyPrice',
      showMobile: true,
    },
  ];

  const orderDetails = [
    {
      title: 'title.pastOrdersSaleID',
      themeKey: 'loyaltySubHeaders',
      value: order.id,
    },
    {
      title: 'title.loyaltyDateString',
      themeKey: 'loyaltySubHeaders',
      value: order.orderDate,
    },
    {
      title: 'title.loyaltyTimeString',
      themeKey: 'loyaltySubHeaders',
      value: order.orderTime,
    },
  ];

  // This function stores and returns all choicesets associated with this item in one array and the total price of
  const processChoiceSets = (choiceSets: SDict<SaleItem[]> | undefined) => {
    let total = 0;
    let points = 0;
    let allChoices: SaleItem[] = [];

    function recursiveHelper(sets: SDict<SaleItem[]> | undefined) {
      for (let key in sets) {
        if (sets.hasOwnProperty(key)) {
          sets[key].forEach(item => {
            total += item.moneyPrice;
            points += item.redeemPoints || 0;
            allChoices.push(item);

            // Recursively process nested choiceSets
            recursiveHelper(item.choiceSets);
          });
        }
      }
    }

    recursiveHelper(choiceSets);
    return { total, points, allChoices };
  };

  console.log({ order });

  return (
    <div className="p-3">
      <div className="mt-4">
        <Text
          themeKey="pastOrdersTitleSaleType"
          variant="h4"
          value={t(`saleType.${order.saleType}.name`).toUpperCase()}
          block
        />
        {order.locationName && (
          <Text themeKey="historicalOrderDetailTitle" variant="h2" value={order.locationName} />
        )}
      </div>

      {/**
       * Sale ID, Date, Time...
       */}
      <div style={styles.orderDetailsRow}>
        <div style={styles.orderDetails}>
          {orderDetails.map((order, index) => (
            <div key={index}>
              <Text value={t(order.title)} themeKey="loyaltySubHeaders" variant="body" />
              {': '}
              <Text value={String(order.value)} themeKey="loyaltyDefaultText" variant="body" />
            </div>
          ))}
        </div>
        <div style={styles.rateOrderWrapper}>
          {order.feedbackGiven == false && (
            <StandardButton
              style={{ height: 50, padding: '0 1.5em' }}
              themeKey={'outlineRoundButton'}
              onClick={() => {
                history.push(`/feedback?order=${order.id}`);
              }}
              label={t('button.feedback.create')}
            />
          )}
        </div>
      </div>

      {/**
       * TODO: Refactor this table to use css grids instead
       */}
      <div className="table-responsive">
        <table className="table table-borderless">
          <thead>
            <tr>
              {tableHeaders.map((header, index) =>
                deviceType === 1 ? (
                  <th
                    key={index}
                    scope="col"
                    style={{ textAlign: header.title === 'title.loyaltyPrice' ? 'right' : 'left' }}
                    className={`border-0 p-0 ${
                      header.title === 'title.loyaltyQuantity' && 'text-center'
                    }`}
                  >
                    <Text value={t(header.title)} themeKey="loyaltySubHeaders" variant="body" />
                  </th>
                ) : (
                  header.showMobile && (
                    <th
                      key={index}
                      scope="col"
                      style={{
                        textAlign: header.title === 'title.loyaltyPrice' ? 'right' : 'left',
                      }}
                      className={`border-0 p-0 ${
                        header.title === 'title.loyaltyQuantity' && 'text-center'
                      }`}
                    >
                      <Text value={t(header.title)} themeKey="loyaltySubHeaders" variant="body" />
                    </th>
                  )
                ),
              )}
            </tr>
          </thead>

          <tbody>
            {order.items.map((item, index) => {
              // Initializing choices, points and item price
              let choices: SaleItem[] = [];
              let points = item.redeemPoints || 0;
              let itemTotalMoneyPrice = item.moneyPrice;

              // Getting all choicesets in one array and calculating the total price of this item
              if (item.choiceSets) {
                const result = processChoiceSets(item.choiceSets);
                choices = result.allChoices;
                itemTotalMoneyPrice += result.total;
                points += result.points;
              }

              console.log({ choices, itemTotalMoneyPrice });

              return (
                <PastOrderItem
                  key={index}
                  item={{ ...item, moneyPrice: itemTotalMoneyPrice, redeemPoints: points }}
                  order={order}
                  choices={choices}
                />
              );
            })}
          </tbody>
        </table>

        <table className="table table-borderless">
          <thead>
            <tr>
              <th scope="col" className={`border-0 p-0`} style={{ width: '80%' }}>
                <Text value={t('title.loyaltyTotal')} themeKey="loyaltySubHeaders" />
              </th>
              <th
                scope="col"
                className={`border-0 p-0`}
                style={{ width: '20%', textAlign: 'right' }}
              >
                <Text value={orderTotal} themeKey="loyaltySubHeaders" />
              </th>
            </tr>
            <tr>
              {!!order.pointsAward && (
                <>
                  <th scope="col" className={`border-0 p-0`} style={{ width: '80%' }}>
                    <Text value={t('totals.pointsAward')} themeKey="loyaltySubHeaders" />
                  </th>
                  <th
                    scope="col"
                    className={`border-0 p-0`}
                    style={{ width: '20%', textAlign: 'right' }}
                  >
                    <Text value={String(order.pointsAward)} themeKey="historicalOrderTotals" />
                  </th>
                </>
              )}
              {!!order.rewardsAward && (
                <>
                  <th scope="col" className={`border-0 p-0`} style={{ width: '80%' }}>
                    <Text value={t('totals.rewardsAward')} themeKey="loyaltySubHeaders" />
                  </th>
                  <th
                    scope="col"
                    className={`border-0 p-0`}
                    style={{ width: '20%', textAlign: 'right' }}
                  >
                    <Text
                      value={formattedDollars(centsToDollars(order.rewardsAward), null)}
                      themeKey="historicalOrderRewards"
                    />
                  </th>
                </>
              )}
            </tr>
          </thead>
        </table>
      </div>

      {/* TODO: Add missing favourite button on tab bar on mobile, and make this one disappear */}
      <div className="position-absolute" style={{ top: 28, right: 10 }}>
        <TouchableOpacity
          onClick={() =>
            dispatch(
              setCurrentModal({
                modalId: FAVOURITE_NAME_MODAL,
                params: {
                  historicalOrderItems: order.items,
                },
              }),
            )
          }
        >
          <AiTwotoneHeart
            style={{
              fontSize: 32,
              ...p('activeHeartIcon', ['color']),
            }}
          />
        </TouchableOpacity>
      </div>

      {/**
       * TODO: mobile:
       * style={deviceType != 1
       *
       */}
      <FloatingFooter containerStyle={{ gap: '1em' }}>
        <ReorderButton order={order} fromDetailedScreen />
        <ResendInvoice invoiceID={order.id} />
      </FloatingFooter>
    </div>
  );
};

export default SinglePastOrder;

const styles: Styles = {
  orderDetailsRow: {
    display: 'flex',
    margin: '1em 0 2.5em',

    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
