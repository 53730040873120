import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { OrderingOperations, OrderingConstants, OrderingSelectors } from 'polygon-ordering';

import debug from '../utils/debug';

import { setDeliveryEstimateInProgress } from '../slices/deliveryEstimateInProgress';
import { setDesiredDeliveryAddressNotFound } from '../slices/desiredDeliveryAddressNotFound';
import { setDeliveryEstimateFailed } from '../slices/deliveryEstimateFailed';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { enqueueErrorSnackbar } from '../utils/snackbar';
import { setCurrentModal } from './setCurrentModal';
import { MESSAGE_MODAL_ID } from '../modals/MessageModal';
import i18next from 'i18next';
import { DELIVERY_DETAILS_MODAL_ID } from '../modals/DeliveryDetailsModal';
import { RootState } from '../store';
import { applyBufferDeliveryEstimate } from './applyBufferDeliveryEstimate';
import { adjustOrder } from './adjustOrder';
import { updateLoadingDependencies } from '../slices/loadingDependencies';

const { getLocation, getBypassDeliveryDetails } = OrderingSelectors;
const { updateKeyOrderProperty, clearBuffer } = OrderingOperations;
const { FAILURE_REASON, ASAP_TIME, API_HANDLER_NOT_READY_MESSAGE } = OrderingConstants;

export const performDeliveryEstimate = createAsyncThunk(
  'performDeliveryEstimate',
  async (
    data: {
      deliveryAddress: string;
      desiredDeliveryTime: string;
      forceUseClosestStore?: boolean;
      fromAddDelivery?: boolean;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    let { deliveryAddress, desiredDeliveryTime, forceUseClosestStore, fromAddDelivery } = data;

    const location = getLocation(getState() as RootState);
    const bypassDeliveryDetails = getBypassDeliveryDetails(getState() as RootState);
    const locationId = bypassDeliveryDetails && !forceUseClosestStore ? location?.id : undefined;

    debug('performing delivery estimate', { deliveryAddress, desiredDeliveryTime, locationId });

    const showDeliveryDetailsModal = () => {
      dispatch(
        setCurrentModal({
          modalId: DELIVERY_DETAILS_MODAL_ID,
          params: {
            allowRedirect: false,
          },
        }),
      );
    };

    const showDeliveryAddressModal = () => {
      dispatch(
        setCurrentModal({
          modalId: DELIVERY_DETAILS_MODAL_ID,
          params: { saleType: SALE_TYPE.DELIVERY, allowRedirect: false },
        }),
      );
    };

    try {
      if (
        desiredDeliveryTime &&
        desiredDeliveryTime !== ASAP_TIME &&
        moment() > moment(desiredDeliveryTime)
      ) {
        desiredDeliveryTime = ASAP_TIME;
      }
      dispatch(setDeliveryEstimateFailed(false));
      dispatch(setDeliveryEstimateInProgress(true));

      await dispatch(
        updateKeyOrderProperty({
          autoApply: false,
          saleType: SALE_TYPE.DELIVERY,
          deliveryAddress,
          desiredDeliveryTime,
          locationId,
          bypassDeliveryDetails,
        }),
      ).unwrap();

      dispatch(setDeliveryEstimateInProgress(false));
      dispatch(setDesiredDeliveryAddressNotFound(false));
    } catch (e) {
      console.error('Perform delivery estimate thunk failed', e);

      const { reason, userReason, systemReason } = e as any;

      dispatch(setDeliveryEstimateFailed(true));

      let alertButtons: AlertButton[] = [
        {
          text: i18next.t('button.continue'),
          isPreferred: true,
        },
      ];

      if (
        reason === FAILURE_REASON.ADDRESS_NOT_FOUND ||
        String(systemReason).includes('Delivery is currently unavailable for your location')
      ) {
        dispatch(setDesiredDeliveryAddressNotFound(true));
      }

      const asapAndBypassingDeliveryDetails =
        desiredDeliveryTime == ASAP_TIME && bypassDeliveryDetails;

      if (asapAndBypassingDeliveryDetails) {
        alertButtons = [
          {
            text: i18next.t('button.changeAddress'),
            action: showDeliveryAddressModal,
            isPreferred: true,
          },
          {
            text: forceUseClosestStore
              ? i18next.t('button.retryClosestStore')
              : i18next.t('button.useClosestStore'),
            action: async () => {
              try {
                await dispatch(setCurrentModal(null));
                dispatch(updateLoadingDependencies({ performingDeliveryEstimates: true }));

                await dispatch(
                  performDeliveryEstimate({
                    deliveryAddress,
                    desiredDeliveryTime,
                    forceUseClosestStore: true,
                  }),
                ).unwrap();

                await dispatch(
                  adjustOrder({
                    saleType: SALE_TYPE.DELIVERY,
                    bypassDeliveryDetailsInit: true,
                  }),
                );
              } catch (error) {
                console.warn(error);
              } finally {
                dispatch(updateLoadingDependencies({ performingDeliveryEstimates: false }));
              }
            },
          },
        ];

        dispatch(
          setCurrentModal({
            modalId: MESSAGE_MODAL_ID,
            params: {
              message:
                fromAddDelivery &&
                String(systemReason).includes('Delivery is currently unavailable for your location')
                  ? i18next.t('bypassDeliveryAddressAddAddressFailed')
                  : systemReason,
              alertButtons,
              onClose: showDeliveryDetailsModal,
            },
            clearReturnModalDetails: true,
          }),
        );
      } else {
        if (e.e !== API_HANDLER_NOT_READY_MESSAGE) {
          enqueueErrorSnackbar(
            (reason === FAILURE_REASON.UNKNOWN ? '' : reason + '\n') +
              (userReason || e.e) +
              '\n' +
              (systemReason || ''),
          );
        }
      }

      dispatch(setDeliveryEstimateInProgress(false));

      return rejectWithValue(e);
    }
  },
);
