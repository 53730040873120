import lodash from 'lodash';

import purchaseFromItem from './purchaseFromItem';
import findItemOrSubstitute from './findItemOrSubstitute';
import { objectForEach, objectMap } from '../misc';
import choiceSetDiscountPlus from './choiceSetDiscountPlus';

export default function (
  shadowPurchase: ShadowItem,
  allItems: Items,
  allChoiceSets: ChoiceSets,
  purchases: _Purchase[],
  purchaseFailures: ShadowItem[],
  ingredientFailures: ShadowItem[],
  allSubstitutionSets: SubstitutionSets,
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig,
) {
  let item: Item | undefined = findItemOrSubstitute(allItems, {
    id: shadowPurchase.compositeId ?? shadowPurchase.id,
    plucode: shadowPurchase.plucode,
    name: shadowPurchase.name,
  });

  const sizeItem =
    (shadowPurchase.compositeId &&
      item &&
      item.sizes.length &&
      item.sizes.find(
        s => s.id === shadowPurchase.id || s.plucode === shadowPurchase.plucode,
      )) ||
    item;

  const anyNestedChoiceSets =
    sizeItem?.choiceSets.some(
      cs => 'nestedIngredients' in cs && cs.nestedIngredients,
    ) ?? false;

  if (!item || !sizeItem || !sizeItem.available) {
    purchaseFailures.push(shadowPurchase);
    return;
  }

  const isChoiceADiscountPlu = (choice: Choice) => {
    if (choiceSetDiscountConfig) {
      const { choiceSetDiscountMap } = choiceSetDiscountConfig;
      const { plus: discountPlus } = choiceSetDiscountPlus(
        choiceSetDiscountMap || [],
      );

      return discountPlus.includes(choice.plucode);
    }

    return false;
  };

  try {
    // the types are totally incorrect here but this is what we have used
    // choiceselections stores choices as arrays of choice ids,
    // nestedchoiceselections stores choices as choicewithquantity objects (which is the incorrect type)
    // ... it stores lists of these in the same way as the choice ids, the quantity in the choicewithquantity object is ignored
    // TODO: REFACTOR
    const choiceSelections: ChoiceSelections | NestedChoiceSelections = {};
    const substitutionSelections: SubstitutionSelections = {};

    if (shadowPurchase.choiceSets) {
      // new mim-style choice structure
      // makes heaps of sense, simple tree structure, but we are going to desecrate it and put it into our new choice selections structure
      Object.entries(shadowPurchase.choiceSets).forEach(
        ([choiceSetId, shadowChoices]) => {
          // because we "unroll" nested choice sets into multiple I have to get all choice sets matching this id
          const choiceSetDefinitions = sizeItem?.choiceSets
            .filter(c => choiceSetId === c.id)
            .map(c => allChoiceSets[c.key]);
          if (!choiceSetDefinitions || !choiceSetDefinitions.length) return;

          // get the first one for normal choice sets
          const choiceSet0 = choiceSetDefinitions[0];
          // have to do this hack because the types are all messed up
          const isNested =
            'nestedIngredients' in (choiceSet0 as ChoiceSet) &&
            !!(choiceSet0 as ChoiceSet & { nestedIngredients: boolean })
              .nestedIngredients;
          // it gets worse, checkbox display type is not just a display type, it also changes the nesting behaviour
          const isCheckbox = choiceSet0.displayType === 'checkbox';

          if (!isNested) {
            const choiceSetSelections: ChoiceWithQuantity[] = [];

            // normal top level choice set
            shadowChoices.forEach(shadowChoice => {
              const choiceObj = choiceSet0.choices.find(
                c =>
                  c.id === shadowChoice.id ||
                  c.plucode === shadowChoice.plucode,
              );

              if (!choiceObj || isChoiceADiscountPlu(choiceObj)) return;
              // see here we are duplicating the choice object and placing it into an array which is the aforementioned desecration
              (choiceSetSelections as ChoiceWithQuantity[]).push(
                ...Array(shadowChoice.quantity).fill(
                  anyNestedChoiceSets ? choiceObj : choiceObj.id,
                ),
              );
            });

            choiceSelections[choiceSet0.key] = choiceSetSelections;
          } else {
            // disgusting type structure
            // figure 1: const choiceSetSelections: SDict<SDict<ChoiceWithQuantity[]>> = {};
            // choice set with nestings

            // this is another hack to make the unrolled nested choice sets work
            // basically at each iteration we may only fill 1 of multiple duplicates of the same choice set, so we keep track of which ones we did fill
            let choiceSetDefinitionsUsed = 0;
            shadowChoices.forEach(shadowChoice => {
              const choiceDefinition = (
                choiceSet0 as NestedChoiceSet
              ).choices.find(
                c =>
                  c.id === shadowChoice.id ||
                  c.plucode === shadowChoice.plucode,
              );
              if (!choiceDefinition) return;
              // shadowchoice is a nested item
              const nestedItemSelections: SDict<ChoiceWithQuantity[]> = {};
              objectForEach(
                shadowChoice.choiceSets ?? {},
                (nestedShadowItemChoiceSetSelections, nestedChoiceSetId) => {
                  const nestedItemChoiceSetSelections: ChoiceWithQuantity[] =
                    [];
                  // get the (nested) choiceset from the (nested) item definition
                  const choiceSetDefinition = Object.values(
                    (choiceDefinition as unknown as Item).choiceSets ?? [],
                  ).find(c => c.id === nestedChoiceSetId);
                  if (!choiceSetDefinition) return;

                  // go through all the choice selections of the choice set (within a nested item)
                  nestedShadowItemChoiceSetSelections.forEach(
                    nestedItemShadowChoice => {
                      const itemDefinition = choiceSetDefinition.choices.find(
                        c =>
                          c.id === nestedItemShadowChoice.id ||
                          c.plucode === nestedItemShadowChoice.plucode,
                      );
                      if (!itemDefinition) return;

                      nestedItemChoiceSetSelections.push(
                        ...Array(nestedItemShadowChoice.quantity).fill(
                          itemDefinition,
                        ),
                      );
                    },
                  );

                  // hack addition of checkbox conditional choice sets
                  // basically we ignore that there was nesting involved and plonk the additions straight into the top-level item selections
                  if (isCheckbox) {
                    choiceSelections[choiceSetDefinition.key] =
                      nestedItemChoiceSetSelections;
                  }

                  nestedItemSelections[nestedChoiceSetId] =
                    nestedItemChoiceSetSelections;
                },
              );

              // skip final step below for aforementioned checkbox hack
              if (isCheckbox) {
                // add in a marker to show that the make it a meal is checked
                // I hate this btw
                choiceSelections[choiceSet0.key] = [choiceDefinition.id];
                return;
              }

              // this is the reason why figure 1 is not used
              for (
                let j = 0;
                choiceSetDefinitionsUsed < choiceSetDefinitions.length &&
                j < shadowChoice.quantity;
                choiceSetDefinitionsUsed++, j++
              ) {
                // the selections for a nested choice set are a dictionary with 1 key (the id of the selected nested item), the value being a dictionary of selection arrays
                // again ideally this should be refactored
                const choiceSetDefinition =
                  choiceSetDefinitions[choiceSetDefinitionsUsed];
                choiceSelections[choiceSetDefinition.key] = {
                  [choiceDefinition.id!]: nestedItemSelections,
                };
              }
            });
          }
        },
      );
    } else if (shadowPurchase.ingredients) {
      // old style ingredients, try to convert into new object structure
      const withQuantitiesCollapsed: SDict<ShadowItem> = {};
      // collapse ingredients (squash by increasing quantity)
      (shadowPurchase.ingredients || []).forEach(ingredient => {
        const { plucode: id } = ingredient;
        const stringId = String(id);

        if (withQuantitiesCollapsed[stringId]) {
          withQuantitiesCollapsed[stringId] = {
            ...withQuantitiesCollapsed[stringId],
            quantity:
              (ingredient.quantity || 0) +
              (withQuantitiesCollapsed[stringId].quantity || 0),
          };
        } else {
          withQuantitiesCollapsed[stringId] = ingredient;
        }
      });

      // pay attention to the max of each choiceSet to avoid overfilling them
      lodash.values(item.choiceSets).forEach(choiceSet => {
        choiceSet.choices.forEach(choice => {
          const ingredient = withQuantitiesCollapsed[choice.id];

          while (
            ingredient &&
            ingredient.quantity &&
            (!choiceSet.max ||
              ((choiceSelections as ChoiceSelections)[choiceSet.id] || [])
                .length < choiceSet.max)
          ) {
            (choiceSelections as ChoiceSelections)[choiceSet.id] = [
              ...((choiceSelections as ChoiceSelections)[choiceSet.id] || []),
              choice.id,
            ];

            ingredient.quantity -= 1;
          }
        });
      });
    }

    sizeItem?.substitutionSets.forEach(
      s => (substitutionSelections[s.key] = s.targetId),
    );

    if (shadowPurchase.substitutionSets) {
      Object.entries(shadowPurchase.substitutionSets).forEach(
        ([substitutionSetId, shadowSubstitutions]) => {
          const substitutionSetDefinitions = sizeItem?.substitutionSets
            .filter(s => substitutionSetId === s.id)
            .map(s => allSubstitutionSets[s.key]);
          if (
            !substitutionSetDefinitions ||
            !substitutionSetDefinitions.length
          ) {
            return;
          }
          const length = shadowSubstitutions.length;
          const key = substitutionSetDefinitions[0].key;
          if (!length) {
            substitutionSelections[key] =
              substitutionSetDefinitions[0].targetId;
          } else if (length === 1) {
            substitutionSelections[key] = 'none';
          } else if (length === 2 && shadowSubstitutions[1].id) {
            substitutionSelections[key] = shadowSubstitutions[1].id;
          }
        },
      );
    }

    const purchase = purchaseFromItem(
      item,
      undefined,
      choiceSelections,
      shadowPurchase.quantity,
      undefined,
      shadowPurchase.compositeId && sizeItem.id,
      undefined,
      substitutionSelections,
    );

    purchases.push(purchase);
  } catch (e) {
    console.log(e);
    purchaseFailures.push(shadowPurchase);
  }
}
