import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import SectionTitle from './SectionTitle';
import { OrderingSelectors, OrderingConstants, OrderingOperations } from 'polygon-ordering';
import { setDesiredDeliveryTime } from '../slices/desiredDeliveryTime';
import { FormikValues, useFormikContext } from 'formik';
import SubSection from './SubSection';
import TimeSelector from './TimeSelector';
import { logEvent } from '../utils/analytics';
import EVENTS from '../constants/events';
import RadioCheck from './RadioCheck';
import { validateFutureMenuItems } from '../thunks/validateFutureMenuItems';

const { getBufferDeliveryTime } = OrderingSelectors;
const { ASAP_TIME } = OrderingConstants;
const { clearBuffer, setChangedTime } = OrderingOperations;

const ORDER_TIME_INTERVAL = 5 * 60 * 1000; // 5 mins (ms)
const DEFAULT_DELIVERY_DELAY = 10 * 60 * 1000; // 10 mins (ms)

const DeliveryTime = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const bufferDeliveryTime = useAppSelector(getBufferDeliveryTime);
  const desiredDeliveryTime = useAppSelector(state => state.desiredDeliveryTime);
  const effectiveDeliveryTime = desiredDeliveryTime || bufferDeliveryTime;
  const { setFieldValue, values } = useFormikContext<FormikValues>();
  const deliveryTimeOpen = useAppSelector(state => state.config.deliveryTimeOpen);
  const deliveryTimeClose = useAppSelector(state => state.config.deliveryTimeClose);

  const options = [
    {
      title: 'ASAP',
      subtitle: t('title.deliveryAsapSubTitle'),
      action: () => {
        dispatch(setDesiredDeliveryTime(ASAP_TIME));
        setFieldValue('showPicker', false);
        setFieldValue('time', '');
        dispatch(clearBuffer({}));
      },
      Icon: () => <RadioCheck checked={effectiveDeliveryTime === ASAP_TIME} size={24} />,
    },
    {
      title: 'Schedule for later',
      action: () => {
        // calculate the time
        const defaultDeliveryTimeMillis = new Date().getTime() + DEFAULT_DELIVERY_DELAY;
        // round the time to the nearest 5min interval
        const defaultDeliveryTime = new Date(
          Math.ceil(defaultDeliveryTimeMillis / ORDER_TIME_INTERVAL) * ORDER_TIME_INTERVAL,
        );
        setFieldValue('showPicker', true);
        dispatch(setDesiredDeliveryTime(defaultDeliveryTime.toISOString()));
        console.log(defaultDeliveryTime);
        dispatch(clearBuffer({}));
      },
      Icon: () => <RadioCheck checked={effectiveDeliveryTime !== ASAP_TIME} size={24} />,
    },
  ];

  return (
    <div className="p-2 m-3">
      <SectionTitle value="When" />
      <div style={{ marginLeft: 15, marginRight: 15, marginTop: 15 }}>
        {options.map((option, index) => (
          <SubSection
            key={index}
            title={option.title}
            subtitle={option.subtitle}
            action={option.action}
            ActiveIcon={option.Icon}
          />
        ))}

        {values.showPicker && (
          <TimeSelector
            themeKey="deliveryTimeSelect"
            earliestTime={deliveryTimeOpen!}
            latestTime={deliveryTimeClose!}
            timePadding={10}
            value={effectiveDeliveryTime}
            setValue={(value: string | null) => {
              const time = value || ASAP_TIME;
              logEvent(EVENTS.CHANGE_DELIVERY_TIME, { label: time });
              // Given this component used at the start of the order flow as well as mid order flow, it's less work to set changedTime to old time for reverting changes
              // cause the other complexity would lie in checking where the store can deliver based on a trasient changeTime
              // Change time is not even needed for delivery, since we have a UI state desiredDeliveryTime and the actual deliveryTime is in the order state
              dispatch(setChangedTime(desiredDeliveryTime));
              dispatch(setDesiredDeliveryTime(time));
              dispatch(validateFutureMenuItems());
              dispatch(clearBuffer({}));
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DeliveryTime;

const styles: Styles = {
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
    marginBottom: 5,
  },
};
