import { useAppSelector } from '../app/hooks';
import React, { CSSProperties } from 'react';
import getThemeLookup from '../selectors/getThemeLookup';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { TEXT_PROPERTIES } from '../utils/theme';
import combineStyles from '../utils/combineStyles';

const SubSection = ({
  action,
  title,
  subtitle,
  subtitleStyle,
  ActiveIcon,
}: {
  action: () => void;
  title?: string;
  subtitle?: string;
  subtitleStyle?: CSSProperties;
  ActiveIcon: () => JSX.Element;
}) => {
  const p = useAppSelector(getThemeLookup);
  return (
    <TouchableOpacity onClick={action}>
      <div style={styles.container}>
        <div style={{ flex: 1 }}>
          {title && <Text style={p('modalSubSection', TEXT_PROPERTIES)}>{title}</Text>}
          {subtitle && (
            <>
              {title && <br />}

              <Text style={combineStyles(p('modalSubSection', ['color']), { fontSize: 12 }, subtitleStyle)}>
                {subtitle}
              </Text>
            </>
          )}
        </div>
        <div style={{ alignSelf: 'center' }}>{<ActiveIcon />}</div>
      </div>
    </TouchableOpacity>
  );
};

export default SubSection;

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
};
