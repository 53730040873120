import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEVICE_TYPES, DESKTOP_MODAL_TYPES, MOBILE_MODAL_TYPES } from '../constants';
import ModalMobile from './ModalMobile';
import ModalDesktop from './ModalDesktop';
import ModalCallout from './ModalCallout';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setCurrentModal } from '../thunks/setCurrentModal';
import combineStyles from '../utils/combineStyles';

interface IProps {
  title?: string;
  forceFloatingTitle?: boolean;
  closeButtonLabel?: string;
  clickBackgroundToClose?: boolean;
  desktopMaxWidth?: number;
  desktopWidth?: number;
  desktopMinWidth?: number;
  desktopMinHeight?: number;
  desktopMaxHeight?: number;
  desktopModalType?: number;
  desktopContainerStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  mobileModalType?: number;
  mobileContainerStyle?: React.CSSProperties;
  backdropStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  tallestPossible?: boolean;
  locked?: boolean;
  hideFloatingHeader?: boolean;
  expandToMaxWidth?: boolean;
  backgroundClick?: () => void;
  handleClose?: () => void;
  handleBack?: () => void;
  titleStyle?: React.CSSProperties;
  closeButtonStyle?: React.CSSProperties;
  enableBackButton?: boolean;
  enableCloseButton?: boolean;
  headerContainerStyle?: React.CSSProperties;
  closeButtonLeft?: boolean;
}

const Modal: React.FC<IProps> = ({
  title,
  closeButtonLabel: closeButtonLabelProp,
  clickBackgroundToClose = false,
  desktopModalType = DESKTOP_MODAL_TYPES.DEFAULT,
  desktopWidth,
  desktopMaxWidth,
  desktopMinWidth,
  desktopMaxHeight,
  desktopMinHeight,
  desktopContainerStyle,
  containerStyle,
  mobileContainerStyle,
  backdropStyle,
  wrapperStyle,
  tallestPossible,
  expandToMaxWidth,
  children,
  locked,
  hideFloatingHeader,
  backgroundClick,
  titleStyle,
  handleClose,
  handleBack,
  closeButtonStyle,
  enableCloseButton,
  enableBackButton,
  headerContainerStyle,
  closeButtonLeft,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const deviceType = useAppSelector(state => state.deviceType);
  const isMobileDevice = deviceType === DEVICE_TYPES.MOBILE;
  const previousModalId = useAppSelector(state => state.previousModalId);
  const calloutOffsets = useAppSelector(state => state.calloutOffsets);

  let closeButtonLabel = closeButtonLabelProp;

  let Component;

  if (isMobileDevice) {
    Component = ModalMobile;
  } else if (deviceType === DEVICE_TYPES.DESKTOP) {
    if (desktopModalType === DESKTOP_MODAL_TYPES.CALLOUT) {
      Component = ModalCallout;
    } else {
      Component = ModalDesktop;
    }
  }

  if (!Component) {
    dispatch(setCurrentModal(null));
    return null;
  }

  return (
    <Component
      children={children}
      closeButtonLabel={closeButtonLabel}
      previousModalId={previousModalId}
      clickBackgroundToClose={clickBackgroundToClose}
      title={title}
      setCurrentModal={params => dispatch(setCurrentModal(params))}
      desktopMaxWidth={desktopMaxWidth}
      desktopWidth={desktopWidth}
      desktopMinWidth={desktopMinWidth}
      desktopMaxHeight={desktopMaxHeight}
      desktopMinHeight={desktopMinHeight}
      containerStyle={containerStyle}
      desktopContainerStyle={combineStyles(
        defaultStyles.desktopContainer,
        containerStyle,
        desktopContainerStyle,
      )}
      mobileContainerStyle={combineStyles(
        defaultStyles.mobileContainer,
        containerStyle,
        mobileContainerStyle,
      )}
      backdropStyle={backdropStyle}
      wrapperStyle={wrapperStyle}
      tallestPossible={tallestPossible}
      expandToMaxWidth={expandToMaxWidth}
      calloutOffsets={calloutOffsets}
      locked={locked}
      hideFloatingHeader={hideFloatingHeader}
      backgroundClick={backgroundClick}
      titleStyle={combineStyles({ fontSize: isMobileDevice ? 18 : 20 }, titleStyle)}
      handleClose={handleClose}
      handleBack={handleBack}
      closeButtonStyle={closeButtonStyle}
      enableBackButton={enableBackButton}
      enableCloseButton={enableCloseButton}
      headerContainerStyle={headerContainerStyle}
      closeButtonLeft={closeButtonLeft}
    />
  );
};

const defaultStyles: Styles = {
  desktopContainer: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 30,
  },
  mobileContainer: {
    padding: '30px 30px 45px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 25,
  },
};

export default Modal;
