import React from 'react';
import StandardButton from './StandardButton';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { ORDER_TYPE_MODAL_ID } from '../modals/OrderTypeModal';
import history from '../history';
import { OrderingOperations } from 'polygon-ordering';
import getOrderInProgress from '../selectors/getOrderInProgress';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useTranslation } from 'react-i18next';

const { setShadowCart, applyShadowOrder } = OrderingOperations;

const ReorderButton: React.FC<{
  order: EnhancedHistoricalOrder | FavouriteOrder;
  fromDetailedScreen?: boolean;
  themeKey?: string;
}> = ({ order, fromDetailedScreen, themeKey = 'reorderButton' }) => {
  const orderInProgress = useAppSelector(getOrderInProgress);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <StandardButton
      containerStyle={fromDetailedScreen ? styles.buttonFromFooter : styles.button}
      themeKey={themeKey}
      label={t('button.reOrder')}
      onClick={() => {
        //@ts-ignore type mismatch  EnhancedHistoricalOrder | FavouriteOrder
        const newOrderItems = order.items.map(item => ({
          id: item.id,
          plucode: item.plucode,
          name: item.name,
          quantity: item.quantity,
          choiceSets: item.choiceSets,
          compositeId: item.compositeId,
        }));

        dispatch(setShadowCart(newOrderItems));

        if (orderInProgress) {
          dispatch(applyShadowOrder());
          history.push('menu');
        } else {
          dispatch(
            setCurrentModal({
              modalId: ORDER_TYPE_MODAL_ID,
              preserveReturnModalDetails: true,
            }),
          );
        }
      }}
    />
  );
};

const styles: Styles = {
  button: {
    borderRadius: 25,
    padding: '0 1.5em',
    height: 50,
  },

  buttonFromFooter: {
    borderRadius: 5,
    width: '100%',
  },
};

export default ReorderButton;
