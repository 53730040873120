import calculateStockBalanceData from './calculateStockBalanceData';

// this function iterates through the choice set selections and looks for duplicated choices to figure out total quantities
// eg. for a choicset of 3 bugers, users can select the same burger 3 times.
export const checkCurrentChoiceSelection = (
  itemId,
  choiceList,
  stagedPLUMap,
) => {
  let tmpBalance,
    tmpQuantity = 0;
  for (var i = 0; i < choiceList.length; i++) {
    if (itemId === choiceList[i].id) {
      tmpQuantity += choiceList[i].quantity;
    }
  }
  const balance = calculateStockBalanceData(itemId).itemBalance;
  const stagedBal =
    stagedPLUMap[itemId] !== undefined ? stagedPLUMap[itemId] : 0;
  tmpBalance = balance - stagedBal;

  return { id: itemId, balance: tmpBalance, quantity: tmpQuantity };
};

export default function (choices, pluQtyMap) {
  const choiceSelectionBalances = [];
  if (choices.length) {
    choices.map(choice => {
      const balance = calculateStockBalanceData(choice.plucode).itemBalance;
      const stagedBal =
        pluQtyMap[choice.plucode] !== undefined ? pluQtyMap[choice.plucode] : 0;
      // if something is undefined then it just means there are infinite quantities
      if (balance !== undefined) {
        choiceSelectionBalances.push({
          id: choice.plucode,
          balance: balance - stagedBal,
          quantity: choice.quantity,
        });
      }
    });
  }

  const findLowestAllowedBalance = {};
  for (var i = 0; i < choiceSelectionBalances.length; i++) {
    // we only need to check the the choices for repeated itemId once, so we should ignore the calc if we have already done them
    if (
      !findLowestAllowedBalance.hasOwnProperty(choiceSelectionBalances[i].id)
    ) {
      const selectedChoices = checkCurrentChoiceSelection(
        choiceSelectionBalances[i].id,
        choiceSelectionBalances,
        pluQtyMap,
      );

      findLowestAllowedBalance[selectedChoices.id] =
        selectedChoices.balance / selectedChoices.quantity;
    }
  }

  const keys = Object.keys(findLowestAllowedBalance);
  const values = keys.map(key => {
    return findLowestAllowedBalance[key];
  });
  const minBalance =
    values.length > 0 ? Math.min.apply(null, values) : undefined;

  return minBalance;
}
