import { createAsyncThunk } from '@reduxjs/toolkit';
import getMenu from '../selectors/getMenu';
import getItems from '../selectors/getItems';
import purchaseFromItem from '../utils/ordering/purchaseFromItem';
import enhancePurchase from '../utils/ordering/enhancePurchase';
import removeUnusedNestedChoiceSelections from '../utils/ordering/removeUnusedNestedChoiceSelections';
import { v4 as uuidv4 } from 'uuid';
import { addStagedPurchase } from '../reducers/currentOrder/stagedPurchases';
import { getOpenPurchasePreviouslyStaged } from '../selectors';

// why is this thunk needed?
// can't we just have another action and reducer in stagedPurchases.ts?
// Answer: because it needs the menu items and some config and reducer actions wouldn't allow that
export const stagePurchaseFromItem = createAsyncThunk(
  '$stagePurchaseFromItem',
  async (
    data: {
      itemId: string;
      brandId?: string;
    },
    { dispatch, getState, rejectWithValue, fulfillWithValue },
  ) => {
    try {
      const { itemId, brandId } = data;

      const menu = getMenu(getState() as EntireFrontendState);
      const allItems = getItems(getState() as EntireFrontendState);
      const choiceOrderingMethod = (getState() as EntireFrontendState).ordering
        .config.choiceOrderingMethod;
      const previouslyStaged = getOpenPurchasePreviouslyStaged(
        getState() as EntireFrontendState,
      );

      if (!menu || !allItems) {
        throw new Error('menu is undefined');
      }

      const item = allItems[itemId];
      const purchase = removeUnusedNestedChoiceSelections(
        purchaseFromItem(item, uuidv4(), undefined, undefined, brandId),
        allItems,
      );

      const enhanced = enhancePurchase(
        purchase,
        menu,
        choiceOrderingMethod || 'cheapest',
        undefined,
        previouslyStaged,
      );

      if (!enhanced || !enhanced.valid) {
        throw new Error('cannot stage invalid purchase');
      }

      await dispatch(addStagedPurchase(purchase));

      // return the id of the purchase
      return fulfillWithValue(purchase.id);
    } catch (e) {
      console.error('Stage purchase from item failed:', e);
      return rejectWithValue(e);
    }
  },
);
