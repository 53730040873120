export default function calculateSubstitutionSelectionsTotals({
  substitutionSelections,
  substitutionSets,
}: {
  substitutionSelections?: SubstitutionSelections;
  substitutionSets: SubstitutionSet[];
}): { moneyPrice: number; pointsPrice: number; pointsAward: number } {
  const sumsTotal = { moneyPrice: 0, pointsPrice: 0, pointsAward: 0 };
  Object.entries(substitutionSelections ?? {}).forEach(([key, selection]) => {
    const substitutionSet = substitutionSets.find(
      cs => cs.key === key || cs.id === key,
    );
    if (!substitutionSet) return;

    let substitution = substitutionSet.substitutions.find(
      substitution => substitution.id === selection,
    );

    sumsTotal.moneyPrice += substitution?.baseMoneyPrice || 0;
    sumsTotal.pointsPrice += substitution?.basePointsPrice || 0;
    sumsTotal.pointsAward += substitution?.basePointsAward || 0;
  });
  return sumsTotal;
}
