import StandardButton from './StandardButton';
import React, { useContext } from 'react';
import { OrderingHooks, OrderingSelectors } from 'polygon-ordering';
import { useAppSelector } from '../app/hooks';
import { INestedChoiceSetsContext, NestedChoiceSetsContext } from './NestedChoiceSetsContext';
import calcMinBalanceStaged from '../libs/polygon-ordering/src/utils/ordering/calcMinBalanceStaged';
import calcStagedPurchasesInCart from '../libs/polygon-ordering/src/utils/ordering/calcStagedPurchasesInCart';
import calculateStockBalanceData from '../libs/polygon-ordering/src/utils/ordering/calculateStockBalanceData';

const { useFormattedCurrency } = OrderingHooks;
const { getOpenPurchase, getItemAvailability } = OrderingSelectors;

const AddToCartButton = ({
  onClick,
  label,
  value,
  valid,
  showLabelOnly = false,
}: {
  label: string;
  value: number;
  onClick: () => void;
  valid: boolean;
  showLabelOnly?: boolean;
}) => {
  const price = useFormattedCurrency({ cents: value });
  const priceString = `${label} - ${price}${valid ? '' : '*'} `;
  const openPurchase = useAppSelector(getOpenPurchase);
  const {
    choiceSets = [],
    quantity,
    choicesWithQuantity,
    item,
    plucode,
    mimCategoryId,
  } = openPurchase as PurchaseWithTotals;
  const [itemAvailability] = useAppSelector(getItemAvailability(item, mimCategoryId));
  const { nestedItemInValid } = useContext(NestedChoiceSetsContext) as INestedChoiceSetsContext;
  const quantityMap = calcStagedPurchasesInCart(openPurchase!.id);
  const minBalanceStaged = calcMinBalanceStaged(choicesWithQuantity, quantityMap);
  const stockBalanceData = calculateStockBalanceData(plucode);

  const validateCart = () => {
    const nested = !!choiceSets.filter(
      c => 'nestedIngredients' in c && c.displayType !== 'checkbox',
    ).length;
    return (
      (nested ? nestedItemInValid : !valid) ||
      quantity >
        (minBalanceStaged
          ? minBalanceStaged
          : stockBalanceData.cartAdjustedBalance!
          ? stockBalanceData.cartAdjustedBalance!
          : stockBalanceData.itemBalance!) ||
      minBalanceStaged === 0 ||
      !itemAvailability
    );
  };

  return (
    <StandardButton
      themeKey="modalProceedButton"
      label={showLabelOnly ? label : priceString}
      onClick={onClick}
      disabled={validateCart()}
      style={styles.button}
    />
  );
};

export default AddToCartButton;

const styles = {
  button: {
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    flexGrow: 1,
    padding: 10,
  },
};
