// Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import processMemberOffers from '../utils/processors/processMemberOffers';

const initialState = [] as MemberOffer[];

const slice = createSlice({
  name: 'memberOffers',
  initialState,
  reducers: {
    storeMemberOffers(state, action: PayloadAction<RawOffer[] | null>) {
      return action.payload !== null
        ? (action.payload.map(rawOffers =>
            processMemberOffers(rawOffers),
          ) as unknown as MemberOffer[])
        : initialState;
    },

    // TODO: Add an extra reducer that does a cleanup when the user signs out
  },
});

export const { storeMemberOffers } = slice.actions;

export default slice.reducer;
