export default function substitutionSelectionsFromBase(
  substitutionSets: SubstitutionSet[],
  keyProperty: 'key' | 'id' = 'key',
): SubstitutionSelections {
  const result: SubstitutionSelections = {};

  substitutionSets.forEach(substitutionSet => {
    result[substitutionSet[keyProperty]] = substitutionSet.targetId;
  });

  return result;
}
