import { v4 as uuidv4 } from 'uuid';

import choiceSelectionsFromBase from './choiceSelectionsFromBase';
import substitutionSelectionsFromBase from './substitutionSelectionsFromBase';

export default function purchaseFromItem(
  item: Item,
  id: string = uuidv4(),
  choiceSelections?: ChoiceSelections | NestedChoiceSelections,
  quantity?: number | null,
  brandId?: string,
  sizeId: string = item.default_size,
  stockBalanceDataMap?: SDict<StockBalanceData>,
  substitutionSelections?: SubstitutionSelections,
): _Purchase {
  const emptyChoiceSelections: ChoiceSelections = {};
  const emptySubstitutionSelections: SubstitutionSelections = {};

  const rootItem = item;
  // if there is a sizeid (or default) then choose that size, if that size is not available then choose the first one
  const _item =
    (!!rootItem.sizes.length &&
      (rootItem.sizes.find(s => s.id === sizeId) ||
        rootItem.sizes.find(() => true))) ||
    rootItem;

  _item.choiceSets.forEach(choiceSet => {
    emptyChoiceSelections[choiceSet.key] = [];
  });

  _item.substitutionSets.forEach(substitutionSet => {
    emptySubstitutionSelections[substitutionSet.key] = '';
  });

  const overriddenChoiceSelections = {
    ...emptyChoiceSelections,
    ...choiceSelections,
  };

  const overriddenSubstitutionSelections = {
    ...emptySubstitutionSelections,
    ...substitutionSelections,
  };

  return {
    id,
    itemId: rootItem.id,
    quantity: quantity || 1,
    choiceSelections: choiceSelections
      ? overriddenChoiceSelections
      : choiceSelectionsFromBase(
          _item.choiceSets,
          undefined,
          stockBalanceDataMap,
        ),
    substitutionSelections: substitutionSelections
      ? overriddenSubstitutionSelections
      : substitutionSelectionsFromBase(_item.substitutionSets),
    brandId,
    sizeId: rootItem === _item ? undefined : _item.id,
  };
}
