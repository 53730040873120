import { createSelector } from 'reselect';

import getOrdering from './getOrdering';
import getIsAdvancedCustomHoursEnabled from './getIsAdvancedCustomHoursEnabled';

export default createSelector(
  [getOrdering, getIsAdvancedCustomHoursEnabled],
  (ordering, isAdvancedCustomHoursEnabled) => {
    if (isAdvancedCustomHoursEnabled) return ordering.advancedCustomHours;

    return null;
  },
);
