/**
 * This is to get current offers that are available in an ordering flow
 */
import { createSelector } from 'reselect';
import * as lodash from 'lodash';
import enhanceOfferTargetPLUs from '../utils/ordering/enhanceOfferTargetPLUs';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';
import getItems, { $getItems } from './getItems';
import getSaleType, { $getSaleType } from './getSaleType';
import { SALE_TYPE } from '../constants/saleType';
import {
  getShowExpiredCoupons,
  getShowRedeemedCoupons,
  getShowUnstartedCoupons,
} from './config';

const filterOffers = (
  offers: Offer[],
  items: Items,
  saleType: SALE_TYPE,
  showExpiredCoupons: boolean,
  showRedeemedCoupons: boolean,
  showUnstartedCoupons: boolean,
  locationId: string | null,
) => {
  const currentDateTime = new Date().getTime();
  return (
    offers
      .filter(offer => {
        // Filtering based on location
        if (!offer.availableAtLocation && locationId !== null) return false;

        // Filtering based on time
        if (!offer.availableAtTime && locationId !== null) return false;

        // Filtering based on expiry date
        if (
          !showExpiredCoupons &&
          offer.expiryDate &&
          new Date(offer.expiryDate).getTime() < currentDateTime
        )
          return false;

        // Filtering based on redemption
        // offer.redeemed will remain false for multi-use coupons even if it has been redeemed.
        // This is good because we only want to filter out redeemed single-use coupons.
        if (!showRedeemedCoupons && offer.redeemed) return false;

        // Filtering based on unstarted
        if (
          !showUnstartedCoupons &&
          offer.startDate &&
          new Date(offer.startDate).getTime() > currentDateTime
        )
          return false;

        // Filtering based on saleType
        if (offer.saleTypes.length && !offer.saleTypes.includes(saleType))
          return false;

        return true;
      })
      // bruh, precomputing PLUs in this step is not at all clear Max, should be documented at least
      .map(offer => enhanceOfferTargetPLUs(offer, items))
  );
};

export const $getOffers = createSelector(
  [
    getCurrentOrder,
    getBuffer,
    $getItems,
    $getSaleType,
    getShowExpiredCoupons,
    getShowRedeemedCoupons,
    getShowUnstartedCoupons,
  ],
  (
    order,
    buffer,
    items,
    saleType,
    showExpiredCoupons,
    showRedeemedCoupons,
    showUnstartedCoupons,
  ): Offer[] => {
    if (!items) {
      return [];
    }

    return filterOffers(
      buffer.offers || order.offers,
      items,
      saleType,
      showExpiredCoupons,
      showRedeemedCoupons,
      showUnstartedCoupons,
      buffer.locationId || order.locationId,
    );
  },
);

export default createSelector(
  [
    $getOffers,
    getCurrentOrder,
    getItems,
    getSaleType,
    getShowExpiredCoupons,
    getShowRedeemedCoupons,
    getShowUnstartedCoupons,
  ],
  (
    bufferOffers,
    order,
    items,
    saleType,
    showExpiredCoupons,
    showRedeemedCoupons,
    showUnstartedCoupons,
  ): Offer[] =>
    filterOffers(
      lodash.unionBy(order.offers, bufferOffers, 'id'),
      items ?? {},
      saleType,
      showExpiredCoupons,
      showRedeemedCoupons,
      showUnstartedCoupons,
      order.locationId,
    ),
);
