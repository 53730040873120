// Imports
import moment from 'moment';
import {
  AD_MEDIA_TYPE,
  IMAGE_EXTENSIONS,
  VIDEO_EXTENSIONS,
} from '../../constants';

// Helper function to determine the media type of the ads
export const getAdMediaType = (url: string) => {
  if (!url) return AD_MEDIA_TYPE.unknown;
  if (VIDEO_EXTENSIONS.some(ext => url.toLowerCase().endsWith(ext))) {
    return AD_MEDIA_TYPE.video;
  } else if (IMAGE_EXTENSIONS.some(ext => url.toLowerCase().endsWith(ext))) {
    return AD_MEDIA_TYPE.image;
  }
  return AD_MEDIA_TYPE.unknown;
};

export const filterAdsByOfferTimes = (
  advertisements: Advertisement[],
  offers: MemberOffer[] | null,
): Advertisement[] => {
  const currentMoment = moment();

  return advertisements.filter((advertisement: Advertisement) => {
    const { couponPrograms } = advertisement;

    // If there are no coupon programs, include the advertisement
    if (couponPrograms.length === 0) {
      return true;
    }

    // If no offers are provided or no valid offers exist, exclude the advertisement
    if (!offers || offers.length === 0) {
      return false;
    }

    // Check if any offer is currently valid
    const hasValidOffer = offers.some(({ startDate, expiryDate }) => {
      const offerNotStarted =
        startDate && currentMoment.isBefore(moment(startDate));
      const offerExpired =
        expiryDate && currentMoment.isAfter(moment(expiryDate));
      return !offerNotStarted && !offerExpired;
    });

    return hasValidOffer;
  });
};

export const filterAdsByCurrentTime = (
  advertisements: Advertisement[],
): Advertisement[] => {
  const currentMoment = moment();
  return advertisements.filter(advertisement => {
    const { finishTime, finishDate, startTime, startDate, days } =
      advertisement;

    const invalidByTime =
      (finishTime && currentMoment.isAfter(moment(finishTime))) ||
      (startTime && currentMoment.isBefore(moment(startTime)));

    const invalidByDate =
      (finishDate && currentMoment.isAfter(moment(finishDate))) ||
      (startDate && currentMoment.isBefore(moment(startDate)));

    const invalidByDay = days && !days.includes(currentMoment.format('ddd'));

    return !(invalidByTime || invalidByDate || invalidByDay);
  });
};
