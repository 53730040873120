// Imports
import { createSelector } from 'reselect';
import { OrderingConstants, OrderingSelectors } from 'polygon-ordering';

const { WEB_ORDERING_CLIENT, AD_SECTIONS } = OrderingConstants;
const { getValidAdvertisements } = OrderingSelectors;

export default createSelector([getValidAdvertisements], advertisements => {
  if (!advertisements) return undefined;

  return advertisements.filter(({ client, section }) => {
    const isValidClient = client === WEB_ORDERING_CLIENT;
    const isValidSection = section === AD_SECTIONS.MENU_OVERLAY;

    return isValidClient && isValidSection;
  });
});
