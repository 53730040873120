import * as _ from 'lodash';
import adjustChoiceSetMaxForQuantityDiscount from './adjustChoiceSetMaxForQuantityDiscount';
import choiceSetDiscountPlus from './choiceSetDiscountPlus';

export function getAllChoiceSetsUnderChoicesOfThisChoiceSet(
  choiceSet: NestedChoiceSet | ValidatedChoiceSet | ChoiceSet,
) {
  let choiceSetsArray: ValidatedChoiceSet[] = [];

  choiceSet.choices.forEach((ch: any) => {
    if (ch?.choiceSets) {
      choiceSetsArray = [...choiceSetsArray, ...ch.choiceSets];
    }
  });

  return choiceSetsArray;
}

const getAdjustedChoiceSet = (
  choiceSet: ValidatedChoiceSet,
  discountPlus: {
    id: string;
    quantity: number;
  }[],
) => {
  let childChoiceSet;
  let childrenChoiceSets: ValidatedChoiceSet[] = [];

  const choices = choiceSet.choices.map((choice: any) => {
    const discountPlu = _.find(discountPlus, e => e.id === choice.plucode);

    if (choice?.choiceSets) {
      childrenChoiceSets = [];
      childrenChoiceSets = choice.choiceSets.map((cs: any) => {
        childChoiceSet = getAdjustedChoiceSet(cs, discountPlus);
        return childChoiceSet;
      });
    }

    if (!discountPlu) {
      return {
        ...choice,
        choiceSets: choice?.choiceSets ? childrenChoiceSets : null,
      };
    }

    return {
      ...choice,
      quantity: discountPlu.quantity,
      moneyPrice: discountPlu.quantity * choice.baseMoneyPrice,
    };
  });

  return {
    ...choiceSet,
    choices,
  };
};

export default function adjustNestedChoiceSetsForQuantityDiscountV2(
  choiceSets: ValidatedChoiceSet[],
  choiceSetDiscountConfig: ChoiceSetQuantityDiscountConfig,
  choiceSelections: ChoiceSelections | NestedChoiceSelections,
): ValidatedChoiceSet[] {
  const { choiceSetKey, choiceSetDiscountMap } = choiceSetDiscountConfig;
  return choiceSets.map(choiceSet => {
    let currentChoice: any = null;
    let currentDiscountChoiceSet = null;

    if (choiceSelections) {
      const currentChoiceSetKey = choiceSet.key;
      const currentChoiceIds = choiceSelections[currentChoiceSetKey];

      if (Array.isArray(currentChoiceIds) && currentChoiceIds.length) {
        currentChoice = choiceSet.choices.find(
          ch => ch.id === currentChoiceIds[0],
        );
      } else if (typeof currentChoiceIds === 'object') {
        const choiceChoiceSetKeys = Object.keys(currentChoiceIds);
        currentChoice = choiceSet.choices.find((ch: any) => {
          let found = false;
          if (ch?.choiceSets) {
            ch.choiceSets.forEach((cs: any) => {
              if (choiceChoiceSetKeys.includes(cs.key)) {
                found = true;
              }
            });
          }
          return found;
        });
      }
    }

    if (currentChoice && currentChoice?.choiceSets) {
      currentDiscountChoiceSet = currentChoice.choiceSets.find(
        (ch: any) => ch?.name?.toLowerCase() === choiceSetKey?.toLowerCase(),
      );
    } else {
      // Getting all choice sets under choices of this choiceset (nested)
      const allChoiceSetsUnderChoicesOfThisChoiceSet =
        getAllChoiceSetsUnderChoicesOfThisChoiceSet(choiceSet);

      // Matched discount choice sets with discount choice set key in PC
      const discountChoiceSets =
        allChoiceSetsUnderChoicesOfThisChoiceSet.filter(
          (ch: any) => ch?.name?.toLowerCase() === choiceSetKey?.toLowerCase(),
        );

      // From all the discountChoiceSets we need to find the current one for this choice set and we do it by checking quantity
      currentDiscountChoiceSet = discountChoiceSets.find(
        (c: any) => c.quantity,
      );
    }

    if (!currentDiscountChoiceSet) {
      return choiceSet;
    }

    const { quantity: choiceSetQuantity } = currentDiscountChoiceSet;
    const { ChoiceSetDiscountMapObj, plus } = choiceSetDiscountPlus(
      choiceSetDiscountMap || [],
    );

    const discountPlus = plus.map(e => ({ id: e, quantity: 0 }));
    for (let NumberOfChoices in ChoiceSetDiscountMapObj) {
      if (Number(NumberOfChoices) <= choiceSetQuantity) {
        if (discountPlus.length) {
          discountPlus.forEach(discountPlu => {
            if (discountPlu.id === ChoiceSetDiscountMapObj[NumberOfChoices]) {
              discountPlu.quantity++;
            } else {
              discountPlus.push({
                id: ChoiceSetDiscountMapObj[NumberOfChoices],
                quantity: 1,
              });
            }
          });
        } else {
          discountPlus.push({
            id: ChoiceSetDiscountMapObj[NumberOfChoices],
            quantity: 1,
          });
        }
      }
    }

    // This returns the choiceSet by update the discount choice quantity
    let adjustedChoiceSet = getAdjustedChoiceSet(choiceSet, discountPlus);

    adjustedChoiceSet = {
      ...adjustedChoiceSet,
      choices: adjustedChoiceSet?.choices?.map(ch => {
        return {
          ...ch,
          choiceSets: ch?.choiceSets?.map((cs: any) => {
            if (cs?.name?.toLowerCase() !== choiceSetKey?.toLowerCase())
              return cs;

            return adjustChoiceSetMaxForQuantityDiscount(
              //@ts-ignore
              cs,
              choiceSetKey as string,
              1,
            );
          }),
        };
      }),
    };

    return adjustedChoiceSet;
  });
}
