import { sumObjectProperties } from '../misc';

import calculateChoiceSetTotals from './calculateChoiceSetTotals';
import calculateChoiceSelectionsTotals from './calculateChoiceSelectionsTotals';
import calculateSubstitutionSelectionsTotals from './calculateSubstitutionSelectionsTotals';

export default function calculateTotalsForPurchase(
  purchase: Purchase,
  choiceOrderingMethod: ChoiceOrderingMethod,
  staged: boolean = false,
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig,
): PurchaseTotals {
  // recursively total all choice selections
  const choiceSelectionsTotals = calculateChoiceSelectionsTotals(
    purchase.choiceSelections,
    purchase.choiceSets,
    choiceOrderingMethod,
    choiceSetDiscountConfig,
  );

  // recursively total all substitution selections
  const substitutionSelectionsTotals = calculateSubstitutionSelectionsTotals({
    substitutionSelections: purchase.substitutionSelections,
    substitutionSets: purchase.substitutionSets,
  });
  const { item: rootItem, sizeId } = purchase;

  // if item is composite get the selected composite variety
  const item =
    (!!rootItem.sizes.length && rootItem.sizes.find(s => s.id === sizeId)) ||
    rootItem;

  const individualMoneyPrice =
    item.baseMoneyPrice +
    choiceSelectionsTotals.moneyPrice +
    substitutionSelectionsTotals.moneyPrice;
  const moneyPrice = individualMoneyPrice * purchase.quantity;

  const individualPointsPrice =
    item.basePointsPrice +
    choiceSelectionsTotals.pointsPrice +
    substitutionSelectionsTotals.pointsPrice;
  const pointsPrice = individualPointsPrice * purchase.quantity;

  const individualPointsAward =
    item.basePointsAward +
    choiceSelectionsTotals.pointsAward +
    substitutionSelectionsTotals.pointsAward;
  const pointsAward = individualPointsAward * purchase.quantity;

  return {
    pointsPrice,
    pointsAward,
    moneyPrice,
    moneyDiscount: 0,
    discountedMoneyPrice: moneyPrice,
  };
}
