import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const isMenuOverlayAdsDisplayed = sessionStorage.getItem('menuOverlayAdsDisplayed');

const slice = createSlice({
  name: 'menuOverlayAdsDisplayed',
  initialState: isMenuOverlayAdsDisplayed ? true : false,
  reducers: {
    setMenuOverlayAdsDisplayed(state, action: PayloadAction<boolean>) {
      sessionStorage.setItem('menuOverlayAdsDisplayed', 'true');
      return action.payload;
    },
  },
});

export const { setMenuOverlayAdsDisplayed } = slice.actions;

export default slice.reducer;
