import tallyAndValidateChoiceSets from './tallyAndValidateChoiceSets';
import choiceSetsWithQuantities from './choiceSetsWithQuantities';
import choiceSelectionsFromBase from './choiceSelectionsFromBase';
import { checkTimeAvailability } from './daypartMenuAvailability';

const validateUpsellItems = (
  items: Item[],
  stockBalanceDataMap?: SDict<StockBalanceData>,
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig,
): (Item & { upsellAvailable: boolean })[] | undefined => {
  const result: ({ upsellAvailable: boolean } & Item)[] = [];
  items.forEach(item => {
    const { choiceSets } = item;

    //pre selection validate
    const ChoiceSelections = choiceSelectionsFromBase(
      choiceSets,
      undefined,
      stockBalanceDataMap,
    );
    const validatedSets = tallyAndValidateChoiceSets(
      choiceSetsWithQuantities(choiceSets, ChoiceSelections),
      choiceSetDiscountConfig,
    );
    const isValidItem = validatedSets.reduce(
      (acc: boolean, current) => acc && current.valid,
      true,
    );

    if (isValidItem) {
      const { availability, days, start, end } = item;
      //stock balance validate
      const stockBalanceData =
        stockBalanceDataMap &&
        item.plucode &&
        stockBalanceDataMap[item.plucode];
      const upsellAvailable =
        !!(
          !stockBalanceData ||
          (stockBalanceData && (stockBalanceData.cartAdjustedBalance || 0) > 0)
        ) && !!checkTimeAvailability(availability, days, start, end); //daypart menu validate

      result.push({ ...item, upsellAvailable });
    }
  });

  return result;
};

export default validateUpsellItems;
