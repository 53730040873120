import { sumObjectProperties, dollarsToCents } from '../misc';

import { OFFER_FOR_ENTIRE_ORDER } from '../../constants';
import { OFFER_TYPE } from '../../constants/offerType';
import { calculateBOGODiscount } from './bogoCoupon';
import calculateEffectiveCouponMinSpend from './calculateEffectiveCouponMinSpend';
import {
  calculateChoiceSelectionsTotals,
  calculateSubstitutionSelectionsTotals,
} from 'polygon-ordering';

export default function calculateTotalsForOrder(
  purchaseTotals: StagedPurchasesTotals,
  selectedOffer: Offer | undefined,
  offerMinSpendInclusive: boolean,
  surcharges: Surcharge[] | undefined,
  rewardsAccrualRate: number | undefined,
  purchasedItems: any[],
  couponOrderingMethod: any,
  choiceOrderingMethod: any,
  purchase: Purchase,
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig,
): OrderTotals {
  const {
    pointsPrice,
    pointsAward,
    moneyPrice: purchasesMoneyPrice,
    moneyDiscount: purchasesMoneyDiscount,
    discountedMoneyPrice: purchasesDiscountedMoneyPrice,
  } = purchaseTotals;

  let { amount: surchargesMoneyPrice } = sumObjectProperties(surcharges || [], [
    'amount',
  ]);

  let choiceSelectionsTotals = {
    moneyPrice: 0,
    pointsPrice: 0,
    pointsAward: 0,
  };
  let substitutionSelectionsTotals = {
    moneyPrice: 0,
    pointsPrice: 0,
    pointsAward: 0,
  };
  if (purchase) {
    // recursively total all choice selections
    choiceSelectionsTotals = calculateChoiceSelectionsTotals(
      purchase.choiceSelections,
      purchase.choiceSets,
      choiceOrderingMethod,
      choiceSetDiscountConfig,
    );
    // recursively total all substitution selections
    substitutionSelectionsTotals = calculateSubstitutionSelectionsTotals({
      substitutionSelections: purchase.substitutionSelections,
      substitutionSets: purchase.substitutionSets,
    });
  }

  let moneyPrice =
    purchasesMoneyPrice +
    surchargesMoneyPrice +
    choiceSelectionsTotals.moneyPrice;

  let discountedMoneyPrice =
    purchasesDiscountedMoneyPrice + surchargesMoneyPrice;

  let orderMoneyDiscount = 0;

  if (selectedOffer && OFFER_FOR_ENTIRE_ORDER.includes(selectedOffer.type)) {
    if (selectedOffer.type === OFFER_TYPE.AMOUNT_OFF_ORDER) {
      // Check if coupon minimum spend constraint is satisfied, otherwise don't apply the discount

      if (
        calculateEffectiveCouponMinSpend(
          selectedOffer,
          offerMinSpendInclusive,
        ) <= purchasesDiscountedMoneyPrice
      ) {
        // Apply discount

        orderMoneyDiscount = dollarsToCents(selectedOffer.amount);
      } else {
        // Hide surcharges until coupon min spend is met
        moneyPrice = purchasesMoneyPrice;
        surchargesMoneyPrice = 0;
      }
    } else if (selectedOffer.type === OFFER_TYPE.PERCENTAGE_OFF_ORDER) {
      // Check if coupon minimum spend constraint is satisfied, otherwise don't apply the discount

      if (
        calculateEffectiveCouponMinSpend(
          selectedOffer,
          offerMinSpendInclusive,
        ) <= purchasesDiscountedMoneyPrice
      ) {
        // Apply discount

        // NOTE: OFFER_FOR_ENTIRE_ORDER coupons don't include surcharges
        // this looks right but due to floating point limits can be wrong
        // orderMoneyDiscount = (selectedOffer.amount / 100) * purchasesMoneyPrice;

        // this seems to work
        const a = 100 - selectedOffer.amount;
        const b = purchasesMoneyPrice * a;
        const negativeOrderMoneyDiscount = b / 100.0 - purchasesMoneyPrice;

        // This is equivalent to the above 4 lines:
        // couponAmount = purchasesMoneyPrice * (selectedOffer.amount / 100);

        orderMoneyDiscount = negativeOrderMoneyDiscount * -1;

        if (selectedOffer.limit !== 0) {
          orderMoneyDiscount = Math.min(
            orderMoneyDiscount,
            selectedOffer.limit,
          );
        }

        orderMoneyDiscount = Math.round(orderMoneyDiscount);
      } else {
        // Hide surcharges until coupon min spend is met
        moneyPrice = purchasesMoneyPrice;
        surchargesMoneyPrice = 0;
      }
    }

    orderMoneyDiscount = Math.min(orderMoneyDiscount, purchasesMoneyPrice);
    discountedMoneyPrice = Math.max(0, moneyPrice - orderMoneyDiscount);
  }
  if (
    selectedOffer?.type === OFFER_TYPE.AMOUNT_OFF_BOGO ||
    selectedOffer?.type === OFFER_TYPE.PERCENTAGE_OFF_BOGO
  ) {
    return calculateBOGODiscount(
      selectedOffer,
      purchasedItems,
      purchaseTotals,
      rewardsAccrualRate as number,
      surcharges as Surcharge[],
      couponOrderingMethod,
      choiceOrderingMethod,
    );
  }

  const rewardsAward = rewardsAccrualRate
    ? Math.round(discountedMoneyPrice / rewardsAccrualRate)
    : 0;

  return {
    pointsPrice:
      Math.round((pointsPrice + choiceSelectionsTotals.pointsPrice) * 100) /
      100,
    pointsAward:
      Math.round((pointsAward + choiceSelectionsTotals.pointsPrice) * 100) /
      100,
    rewardsAward,
    moneyPrice,
    purchasesMoneyDiscount,
    orderMoneyDiscount,
    moneyDiscount: purchasesMoneyDiscount + orderMoneyDiscount,
    discountedMoneyPrice,
    purchasesMoneyPrice,
    surchargesMoneyPrice,
  };
}
