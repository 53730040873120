import lodash from 'lodash';
import moment from 'moment';

// TODO: move to polygon-utils lib
export function centsToDollars(cents: undefined | number | null) {
  return cents !== null && cents !== undefined ? cents / 100 : undefined;
}

export function dollarsToCents(dollars: number | string) {
  let dollarsNum: number;

  if (typeof dollars == 'string') {
    dollarsNum = parseFloat(dollars || '0');
  } else {
    dollarsNum = dollars;
  }

  return Math.round(dollarsNum * 100);
}

export function centsToDollarString(cents: undefined | number) {
  const dollars = centsToDollars(cents);

  if (dollars === undefined) {
    return '';
  }

  return String(dollars.toFixed(2));
}

// converts a potentially messy string into a clean id string for page anchors etc.
export function safeString(s: string | null | undefined): string {
  return (s || '')
    .trim()
    .replace(/[\W_]+/g, '-')
    .toLowerCase();
}

// TODO: re-write this to preserve type information (keys)
export function sumObjectProperties(
  arr: SDict<any>[],
  properties?: string[],
  baseValues: SDict<number | undefined> = {},
  defaultBaseValue: number = 0,
) {
  const result: SDict<number> = {};

  if (!arr.length && properties === undefined) {
    return result;
  }

  (properties === undefined ? Object.keys(arr[0]) : properties).forEach(
    property => {
      const base =
        baseValues[property] === undefined
          ? defaultBaseValue
          : baseValues[property] || 0;

      result[property] = lodash.sumBy(arr, property) + base;
    },
  );

  return result;
}

export function normaliseArray<T>(array: Array<T>, indexKey: keyof T) {
  const normalizedObject: any = {};
  for (let i = 0; i < array.length; i++) {
    const key = array[i][indexKey];
    normalizedObject[key] = array[i];
  }
  return normalizedObject as { [key: string]: T };
}

export function replaceOrAppend<T>(
  array: Array<T>,
  appendable: T | undefined,
  finder: (el: T) => boolean,
  replacer: (prev: T[]) => T | undefined,
): T[] {
  const firstMatchIndex = array.findIndex(finder);
  const matches = array.filter(finder).length;

  let before = [];
  let after = [];

  let result: Array<T | undefined> = [];

  if (matches === 0) {
    result = [...array, appendable];
  } else {
    before = array.slice(0, Math.max(firstMatchIndex, 0));

    after = array
      .slice(firstMatchIndex + 1, array.length)
      .filter(element => !finder(element));

    result = [...before, replacer(array.filter(finder)), ...after];
  }

  return result.filter(element => element !== undefined) as T[];
}

export function generateTwoWayMap(
  list: Record<string | number, string | number>[],
): Record<string | number, string | number> {
  return list.reduce(
    (acc, n) => ({ ...acc, [n.value]: n.id, [n.id]: n.value }),
    {},
  );
}

export function removeUndefinedKeys(raw: SDict<any>): SDict<any> {
  return lodash.pickBy(raw, v => v !== undefined);
}

export function safeDate(input?: string, utc?: string) {
  return input
    ? moment(input)
        .utcOffset(utc || moment().format('Z'))
        .toISOString(true)
    : undefined;
}

export function findPropertyMinAndMax(things: any[], property: string) {
  return {
    min: (lodash.minBy(things, t => t[property]) ?? { [property]: undefined })[
      property
    ] as number,
    max: (lodash.maxBy(things, t => t[property]) ?? { [property]: undefined })[
      property
    ] as number,
  };
}

export function undefinedIfNull(value: any) {
  return value === null ? undefined : value;
}

export function getItemPaymentTotal(
  selectedPaymentMethods: any[],
  methodLabel: string,
) {
  let amount = 0;
  selectedPaymentMethods.some(selectedPaymentMethod => {
    if (selectedPaymentMethod.method === methodLabel) {
      amount = selectedPaymentMethod.amount;
    }
  });
  return amount;
}

// like Array.find but short circuits the value returned by the successful iteration, instead of returning the value found
export function findMap<IN, OUT>(
  array: IN[],
  predicate: (value: IN, index: number, array: IN[]) => OUT | undefined,
): OUT | undefined {
  let val;
  for (let i = 0; i < array.length; i++) {
    val = predicate(array[i], i, array);
    if (val !== undefined) {
      return val;
    }
  }

  return undefined;
}

// like Array.map but for objects
export function objectMap<IN, OUT, K extends string | number | symbol = string>(
  obj: Record<K, IN>,
  predicate: (value: IN, key: string, obj: Record<K, IN>) => OUT
): Record<string, OUT> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    // Object.fromEntries doesn't exist for older Android version 8.1
    // @ts-ignore
    acc[key] = predicate(value, key, obj);
    return acc;
  }, {} as Record<string, OUT>);
}

// like Array.forEach but for objects
export function objectForEach<IN, K extends string | number | symbol = string>(
  obj: Record<K, IN>,
  predicate: (value: IN, key: string, obj: Record<K, IN>) => void,
): void {
  Object.entries<IN>(obj).forEach(([k, v]: [string, IN]) =>
    predicate(v, k, obj),
  );
}

// like Array.filter but for objects
export function objectFilter<IN, K extends string | number | symbol = string>(
  obj: Record<K, IN>,
  predicate: (value: IN, key: string, obj: Record<K, IN>) => boolean,
): Record<string, IN> {
  return Object.fromEntries(
    Object.entries<IN>(obj).filter(([k, v]: [string, IN]) =>
      predicate(v, k, obj),
    ),
  );
}

// like Array.reduce but for objects
export function objectReduce<
  OUT,
  IN,
  K extends string | number | symbol = string,
>(
  obj: Record<K, IN>,
  predicate: (sum: OUT, value: IN, key: string, obj: Record<K, IN>) => OUT,
  initial: OUT,
): OUT {
  return Object.entries<IN>(obj).reduce<OUT>(
    (sum: OUT, [k, v]: [string, IN]) => predicate(sum, v, k, obj),
    initial,
  );
}

// like a for i in range(n) loop in python but useable in the same contexts as Array.map in jsx
export function mapNumbers<OUT>(
  number: number,
  predicate: (index: number) => OUT,
): OUT[] {
  const output = [] as OUT[];
  for (let i = 0; i < number; i++) {
    output.push(predicate(i));
  }
  return output;
}

// filter and map, first map then filter out undefined responses
export function filterMap<IN, OUT>(
  arr: IN[],
  predicate: (value: IN, index: number, arr: IN[]) => OUT | undefined,
): OUT[] {
  return arr
    .map(predicate)
    .filter<OUT>(
      (value => value !== undefined) as (
        value: OUT | undefined,
      ) => value is OUT,
    );
}

export default function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function stripBarcodeTerminators(string: string) {
  if (string) {
    return string.replace(/[#;?]/g, '');
  }

  return string;
}

export function hasKeyWithNonEmptyArray(objects: any[], key: string): boolean {
  return objects.some(obj => Array.isArray(obj[key]) && obj[key].length > 0);
}
