import { createAsyncThunk } from '@reduxjs/toolkit';
import { SALE_TYPE } from '../constants/saleType';
import { resetOrder } from '../operations';
import { setLocationId } from '../reducers/currentOrder/locationId';
import { setSaleType } from '../reducers/currentOrder/saleType';
import { getLocation } from '../selectors';
import moment from 'moment-timezone';

export const startNewOrder = createAsyncThunk(
  '$startNewOrder',
  async (
    data: {
      preserveMember?: boolean;
      saleType?: SALE_TYPE;
      locationId?: string;
      uuid?: string;
      lifecycleEventParams?: any;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const {
        preserveMember = false,
        saleType,
        locationId,
        uuid,
        lifecycleEventParams,
      } = data;

      dispatch(resetOrder({ preserveMember, uuid }));

      if (saleType != null) {
        const location = getLocation(getState() as EntireFrontendState);
        if (
          location &&
          (!location.availableSaleTypesToday[saleType].isAvailable ||
            !moment().isBetween(
              moment(location.availableSaleTypesToday[saleType].openingTime),
              moment(location.availableSaleTypesToday[saleType].closingTime),
            ))
        ) {
          throw new Error(
            `Saletype ${saleType} is not available now for location ${location.name}`,
          );
        }

        console.log('Setting saletype : ', saleType);
        dispatch(setSaleType(saleType));
      }

      if (locationId !== null) {
        dispatch(setLocationId(locationId!));
      }

      return {
        lifecycleEventParams,
      };
    } catch (e) {
      console.error('Start new order failed', { e });
      return rejectWithValue(e);
    }
  },
);
