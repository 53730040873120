import React, { useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import LocationSearch from './LocationSearch';
import MapWrapper from './MapWrapper';
import { getNearbyStreetName, getNearbyLocality } from '../utils/maps';
import { useFormikContext } from 'formik';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import { setDesiredDeliveryAddress } from '../slices/desiredDeliveryAddress';

const { getMember } = OrderingSelectors;

const AddressSearchBox = () => {
  const [map, setMap] = useState<google.maps.places.Autocomplete>();
  const onLoad = React.useCallback(function callback(map: google.maps.places.Autocomplete) {
    setMap(map);
  }, []);
  const member = useAppSelector(getMember);

  const { setFieldValue } = useFormikContext();

  const geocoderCountryRestriction = useAppSelector(
    state => state.config.geocoderCountryRestriction,
  );

  const dispatch = useAppDispatch();

  const onPlacesChanged = () => {
    const places = map?.getPlace();
    if (places) {
      const streetName = getNearbyStreetName(places);
      const locality = getNearbyLocality(places);

      setFieldValue('formatted_address', places.formatted_address);
      setFieldValue('street_name', streetName);
      setFieldValue('locality', locality);
      setFieldValue('lat', places.geometry?.location?.lat());
      setFieldValue('lng', places.geometry?.location?.lng());
      setFieldValue('buttonActive', true);

      dispatch(
        setDesiredDeliveryAddress({
          formatted_address: places.formatted_address || '',
          street_name: streetName,
          locality: locality,
          lat: Number(places.geometry?.location?.lat()),
          lng: Number(places.geometry?.location?.lng()),
        }),
      );
    }
  };

  return (
    <MapWrapper>
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlacesChanged}
        restrictions={{
          country: geocoderCountryRestriction,
        }}
      >
        <LocationSearch
          placeholder="Enter your address"
          showSaveSwitch={Boolean(member)}
          containerStyle={{ marginTop: 12, marginBottom: 20 }}
        />
      </Autocomplete>
    </MapWrapper>
  );
};

export default React.memo(AddressSearchBox);
