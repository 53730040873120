import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { setPaymentFormInUse } from '../slices/paymentFormInUse';
import { useFormikContext } from 'formik';
import { processPayment } from '../thunks/processPayment';
import { dollarsToCents } from '../libs/polygon-ordering/src/utils/misc';
import { OrderingSelectors } from 'polygon-ordering';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { loadStripe, PaymentRequest, Stripe } from '@stripe/stripe-js';
import getPaymentGatewayPublicKey from '../selectors/getPaymentGatewayPublicKey';

const { getCalculatedGiftcardSurcharge, getPaymentGatewayConfig } = OrderingSelectors;

const { getStripeCurrency } = OrderingSelectors;
const AppleGooglePayButtons = ({ route }: { route: string }) => {
  const [paymentRequest, setPaymentRequest] = useState<null | PaymentRequest>(null);
  const stripeCurrency = useAppSelector(getStripeCurrency);
  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHOD>();
  const dispatch = useAppDispatch();
  const formik = useFormikContext<FormikFields>();
  const merchantAccount = useAppSelector(state => state.config.stripeMerchantAccount);
  // const paymentGatewayConfig = useAppSelector(getPaymentGatewayConfig);
  const paymentGatewayPublicKey = useAppSelector(getPaymentGatewayPublicKey);

  const { values } = formik;

  const surcharge = useAppSelector(state =>
    getCalculatedGiftcardSurcharge(state, Number(values.Amount)),
  );

  const stripe = useStripe();
  // make our own stripe object to override the global one because we need to use the store's merchant account for google/apple pay
  // const [stripe, setStripe] = useState<Stripe | null>();
  // useEffect(() => {
  //   if (!merchantAccount) return;
  //   loadStripe(paymentGatewayPublicKey!, {
  //     // TODO: find the hq merchant account
  //     stripeAccount: merchantAccount,
  //   }).then(setStripe);
  // }, [merchantAccount]);

  useEffect(() => {
    if (!stripe) return;
    const pr = stripe.paymentRequest({
      country: 'AU', // TODO: fix to avoid stripe overseas fees
      currency: stripeCurrency,
      total: {
        label: 'Payment Total',
        amount: dollarsToCents(values.Amount!) + Number(surcharge.value),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then(async result => {
      if (result?.applePay || result?.googlePay) {
        setPaymentRequest(pr);
      }
    });
  }, [stripe]);

  useEffect(() => {
    if (!paymentRequest) return;
    setPaymentRequest(paymentRequest => {
      if (paymentRequest && values.Amount !== undefined) {
        paymentRequest.update({
          total: {
            label: 'Payment Total',
            amount: dollarsToCents(values.Amount!) + Number(surcharge.value),
          },
        });
      }
      return paymentRequest;
    });
  }, [paymentRequest, values.Amount]);

  useEffect(() => {
    if (!paymentRequest) return;

    paymentRequest.on('cancel', () => {
      dispatch(setPaymentFormInUse(false));
    });

    paymentRequest.on('paymentmethod', ev => {
      console.log(ev);
      dispatch(setPaymentFormInUse(true));

      const data = {
        values: formik.values,
        token: ev.paymentMethod,
        route,
        giftcardSurcharge: surcharge.value,
      };

      dispatch(processPayment(data)).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          formik.resetForm();
          ev.complete('success');
        }

        if (response.meta.requestStatus === 'rejected') {
          ev.complete('fail');
        }
      });
    });

    () => {
      paymentRequest.off('cancel');
      paymentRequest.off('paymentmethod');
    };
  }, [paymentRequest]);

  return <>{paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}</>;
};

export default AppleGooglePayButtons;
