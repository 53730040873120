import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { setShadowCart, setShadowNotes, setNotes } from '../operations';
import {
  getAvailableChoiceSets,
  getNotes,
  getAvailableSubstitutionSets,
} from '../selectors';
import getItems from '../selectors/getItems';
import getShadowOrder from '../selectors/getShadowOrder';
import constructPurchaseFromShadow from '../utils/ordering/constructPurchaseFromShadow';
import { addStagedPurchases } from '../reducers/currentOrder/stagedPurchases';
import { objectMap } from '../utils/misc';
import { getChoiceSetDiscountConfig } from '../selectors/config';

export const applyShadowOrder = createAsyncThunk(
  '$applyShadowOrder',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    try {
      const shadowOrder = getShadowOrder(getState() as EntireFrontendState);
      const { notes, cart } = shadowOrder;

      const purchaseFailures: ShadowItem[] = [];
      const ingredientFailures: ShadowItem[] = [];
      const purchases: _Purchase[] = [];

      // NOTE: this is a label, they are rare and only used here to enable breaking from an IF early
      // https://stackoverflow.com/questions/4851657/call-break-in-nested-if-statements
      cartSection: if (cart && cart.length) {
        dispatch(setShadowCart(null));

        const orderItems = getItems(getState() as EntireFrontendState);
        const orderChoiceSets = getAvailableChoiceSets(
          getState() as EntireFrontendState,
        );
        const orderSubstitutionSets = getAvailableSubstitutionSets(
          getState() as EntireFrontendState,
        );

        if (!orderItems) {
          // skip section if items are missing (no menu data)
          break cartSection;
        }

        // Getting choice set discount config
        const choiceSetDiscountConfig = getChoiceSetDiscountConfig(
          getState() as EntireFrontendState,
        );

        cart.forEach(
          shadowPurchase =>
            parseInt(shadowPurchase.plucode) >= 20 &&
            constructPurchaseFromShadow(
              //@ts-ignore
              shadowPurchase,
              orderItems || {},
              orderChoiceSets || {},
              purchases,
              purchaseFailures,
              ingredientFailures,
              orderSubstitutionSets || {},
              choiceSetDiscountConfig,
            ),
        );

        if (purchases.length) {
          dispatch(addStagedPurchases(purchases));
        }

        // items unavailable in current context so we add them as unavailable purchase objects
        if (purchaseFailures.length) {
          dispatch(
            addStagedPurchases(
              purchaseFailures.map(shadowItem => {
                return {
                  id: uuidv4(),
                  itemId: (shadowItem.compositeId ?? shadowItem.id)!,
                  sizeId: shadowItem.compositeId && shadowItem.id,
                  quantity: shadowItem.quantity,
                  // super dodgy hack to get around needing menu data, undefined behaviour may ensue
                  choiceSelections: objectMap(
                    shadowItem.choiceSets || {},
                    (shadowChoices, choiceSetId) => {
                      const isNested = shadowChoices.some(
                        shadowChoice =>
                          !!Object.values(shadowChoice.choiceSets || {}).length,
                      );
                      return isNested
                        ? {}
                        : shadowChoices.reduce(
                            (flattened, shadowItem) => [
                              ...flattened,
                              ...Array(
                                (shadowItem.id && shadowItem.quantity) || 0,
                              ).fill(shadowItem.id),
                            ],
                            [] as string[],
                          );
                    },
                  ) as ChoiceSelections | NestedChoiceSelections,
                  substitutionSelections: {} as SubstitutionSelections,
                  unavailable: true,
                  item: {
                    name: shadowItem.name,
                  } as Item,
                };
              }),
            ),
          );
        }
      }

      if (notes) {
        const currentNotes = getNotes(getState() as EntireFrontendState);
        if (!currentNotes) {
          dispatch(setNotes(notes));
        }
        dispatch(setShadowNotes(''));
      }

      return {
        purchases,
        ingredientFailures,
        purchaseFailures,
      };
    } catch (e) {
      console.error('Apply shadow order failed', { e });
      return rejectWithValue(e);
    }
  },
);
