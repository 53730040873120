import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderingConstants, OrderingOperations, OrderingSelectors } from 'polygon-ordering';

import debug from '../utils/debug';
import history from '../history';
import { setCurrentModal } from './setCurrentModal';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { RootState } from 'store';
import getConfig from '../selectors/getConfig';
import { MENU_SCREEN_ROUTE, PICK_BRAND_SCREEN_ROUTE } from '../hooks/useRoutes';
import i18next from 'i18next';
import { MESSAGE_MODAL_ID } from '../modals/MessageModal';

const { applyBuffer } = OrderingOperations;
const { SALE_TYPES } = OrderingConstants;
const { getLocationBrandsWithMenuRepresentation, getBypassDeliveryDetails } = OrderingSelectors;

export const applyBufferDeliveryEstimate = createAsyncThunk(
  'applyBufferDeliveryEstimate',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    debug('applying buffered delivery estimate');

    try {
      await dispatch(applyBuffer()).unwrap();
      const representedBrands = getLocationBrandsWithMenuRepresentation(
        getState() as EntireFrontendState,
      );
      const bypassDeliveryDetails = getBypassDeliveryDetails(getState() as EntireFrontendState);

      const modalParams = (getState() as RootState).modalParams;

      const multipleBrands = representedBrands.length > 1;

      const popUpTitle = i18next.t(`preSaleMessage.title.${SALE_TYPES.DELIVERY}`);
      const popUpMessage = i18next.t(`preSaleMessage.message.${SALE_TYPES.DELIVERY}`);

      const deviceTypeMobile = getDeviceTypeMobile(getState() as RootState);
      const enableBrandSelection = getConfig(getState() as RootState).enableBrandSelection;
      const showBrandPicker = deviceTypeMobile && enableBrandSelection && multipleBrands;

      if (!bypassDeliveryDetails && modalParams?.allowRedirect !== false) {
        history.replace(showBrandPicker ? PICK_BRAND_SCREEN_ROUTE : MENU_SCREEN_ROUTE);
      }

      if (popUpTitle !== '' && popUpMessage !== '') {
        dispatch(setCurrentModal(null));

        dispatch(
          setCurrentModal({
            modalId: MESSAGE_MODAL_ID,
            params: {
              title: popUpTitle,
              message: popUpMessage,
            },
            clearReturnModalDetails: true,
          }),
        );
      }
    } catch (e) {
      console.error('Apply buffer delivery estimate failed:', e);
      return rejectWithValue(e);
    }
  },
);
