import lodash from 'lodash';

import { OrderingSelectors } from 'polygon-ordering';

import { store } from '../../../../../store';

const { getStagedPurchases } = OrderingSelectors;

// This function tracks the plu quantities of everything that is in the cart except for the existing selection that is being edited or changed
export default function (purchaseID) {
  const state = store.getState();
  const stagedPurchases = getStagedPurchases(state);

  const quantityMap = {};
  stagedPurchases.some(stagedPurchase => {
    if (stagedPurchase.id === purchaseID) {
      // do nothing
    } else {
      lodash.update(quantityMap, stagedPurchase.plucode, n => {
        return n ? n + stagedPurchase.quantity : stagedPurchase.quantity;
      });

      if (stagedPurchase.choicesWithQuantity.length) {
        stagedPurchase.choicesWithQuantity.map(choice => {
          lodash.update(quantityMap, choice.plucode, n => {
            return n
              ? n + choice.quantity * stagedPurchase.quantity
              : choice.quantity * stagedPurchase.quantity;
          });
        });
      }
    }
  });

  return quantityMap;
}
