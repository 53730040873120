import { createSelector } from 'reselect';
import getMenu from './getMenu';
import { getChoiceSetDiscountConfig } from './config';
import adjustChoiceSetMaxForQuantityDiscount from '../utils/ordering/adjustChoiceSetMaxForQuantityDiscount';

export default createSelector(
  [getMenu, getChoiceSetDiscountConfig],
  (menu, choiceSetDiscountConfig) => {
    if (
      !menu?.choiceSets ||
      !choiceSetDiscountConfig ||
      !choiceSetDiscountConfig.choiceSetKey
    )
      return menu?.choiceSets || {};

    const { choiceSetKey } = choiceSetDiscountConfig;

    // Iterate over choiceSets (assuming it's an object)
    return Object.entries(menu.choiceSets).reduce((acc: any, [key, cs]) => {
      acc[key] = adjustChoiceSetMaxForQuantityDiscount(
        cs as ValidatedChoiceSet,
        choiceSetKey,
        1,
      );
      return acc;
    }, {});
  },
);
