export default function adjustedSelections(
  previousSubstitutionSelections: SubstitutionSelections,
  targetSubstitutionSetId: string,
  targetSubstitutionId: string | null,
): SubstitutionSelections {
  return {
    ...previousSubstitutionSelections,
    [targetSubstitutionSetId]: targetSubstitutionId,
  };
}
