import { createSelector } from 'reselect';

import createCartItemFromPurchase from '../utils/ordering/createCartItemFromPurchase';

import getStagedPurchases from './getStagedPurchases';
import getRootCategory from './getRootCategory';
import { getChoiceOrderingMethod, getBrands } from './config';

export default createSelector(
  [getStagedPurchases, getChoiceOrderingMethod, getBrands, getRootCategory],
  (enhancedStagedPurchases, choiceOrderingMethod, brands, rootCategory) => {
    if (choiceOrderingMethod === null) {
      return [];
    }

    return (enhancedStagedPurchases || []).map(p => ({
      mimCategoryId: p.mimCategoryId,
      ...createCartItemFromPurchase(
        p,
        choiceOrderingMethod,
        brands,
        rootCategory,
      ),
    }));
  },
);
