import { createAsyncThunk } from '@reduxjs/toolkit';
import getUnenhancedOpenPurchase from '../selectors/getUnenhancedOpenPurchase';
import adjustedSubstitutionSelections from '../utils/ordering/adjustedSubstitutionSelections';

export const adjustOpenPurchaseSubstitution = createAsyncThunk(
  '$adjustOpenPurchaseSubstitution',
  async (
    data: {
      targetSubstitutionSetId: string;
      targetSubstitutionId: string | null;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const { targetSubstitutionSetId, targetSubstitutionId } = data;
      const openPurchase = getUnenhancedOpenPurchase(
        getState() as EntireFrontendState,
      )!;

      let adjustedPurchase: _Purchase;

      adjustedPurchase = {
        ...openPurchase,
        substitutionSelections: adjustedSubstitutionSelections(
          openPurchase!.substitutionSelections as SubstitutionSelections,
          targetSubstitutionSetId,
          targetSubstitutionId,
        ),
      };

      return { purchase: adjustedPurchase };
    } catch (e) {
      console.error('Adjust open purchase substitution failed', { e });
      return rejectWithValue(e);
    }
  },
);
