import { createSelector } from 'reselect';

import getStagedPurchases, { $getStagedPurchases } from './getStagedPurchases';

const get = (purchases: PurchaseWithTotals[]) => purchases.some(p => p.basePointsPrice === 0)

const getStagedPurchasesPointsPriceValidility = createSelector([getStagedPurchases], get);

export default getStagedPurchasesPointsPriceValidility;

export const $getStagedPurchasesPointsPriceValidility = createSelector(
  [$getStagedPurchases],
  get,
);
