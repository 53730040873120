export default function purchaseInvalidForPointsSale(
  purchase: PurchaseWithTotals,
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig,
) {
  let discount: string;
  if (choiceSetDiscountConfig) {
    discount = choiceSetDiscountConfig.discountPlu;
  }

  function removeDiscountPLU(choice: any) {
    return choice.plucode != discount;
  }

  return purchase.choiceSets.some(({ choices }) => {
    const choicesWithoutDiscountPLU = choices.filter(removeDiscountPLU);

    const zeroPointsPriceChoiceExists = choicesWithoutDiscountPLU.some(
      // this checks the choiceSets in the purchase
      choice =>
        !!choice.quantity &&
        choice.basePointsPrice === 0 &&
        choice.baseMoneyPrice !== 0,
    );

    console.log({ zeroPointsPriceChoiceExists });
    return zeroPointsPriceChoiceExists;
  });
}
