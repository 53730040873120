import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { getMember } from '../libs/polygon-ordering/src/selectors';
import LoyaltyWrapper from '../components/LoyaltyWrapper';
import RegistrationVerifyScreen from './RegistrationVerify';

const PrivateRoute: React.FC<{
  exact: boolean;
  path: string;
  component: React.ElementType;
  hideHero?: boolean;
}> = ({ component: Component, path, exact, hideHero }) => {
  const member = useAppSelector(getMember);

  return (
    <Route
      path={path}
      exact={exact}
      render={props =>
        member ? (
          member.verified ? (
            <LoyaltyWrapper hideHero={hideHero}>
              <Component {...props} />
            </LoyaltyWrapper>
          ) : (
            <LoyaltyWrapper>
              <RegistrationVerifyScreen showContinueButton={false} internalRedirect />
            </LoyaltyWrapper>
          )
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
