import React from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { FaLocationArrow } from 'react-icons/fa';
import { FormikValues, useFormikContext } from 'formik';
import TouchableOpacity from './TouchableOpacity';
import { setMapCenter } from '../slices/mapCenter';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import getUserLocation from '../selectors/getUserLocation';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa';
import Text from './Text';

import { styled } from '@mui/system';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import combineStyles from '../utils/combineStyles';
import Switch from '../components/Switch';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';

const SearchWrapper = styled('div')({
  borderRadius: 30,
  backgroundColor: 'whitesmoke',
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  //marginRight: 5,
  height: '2.8em',
  alignItems: 'center',
  padding: '0 1em',
  transition: '0.25s all ease-out',
  outline: '#DFDFDF solid',
  outlineWidth: 0,
  outlineOffset: -1,

  '&:hover': {
    background: '#EBEBEB',
  },

  '&:focus-within': {
    outlineWidth: 1,
    background: '#fff',
  },
});

const LocationSearch = ({
  placeholder,
  showSaveSwitch = false,
  containerStyle,
}: {
  placeholder?: string;
  showSaveSwitch?: boolean;
  containerStyle?: React.CSSProperties;
}) => {
  const p = useAppSelector(getThemeLookup);
  const { values, handleChange, setFieldValue } = useFormikContext<FormikValues>();
  const userCoords = useAppSelector(getUserLocation);
  const dispatch = useAppDispatch();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);

  return (
    <div
      style={{
        ...styles.searchContainer,
        ...(deviceTypeMobile ? styles.searchContainerMobile : styles.searchContainerDesktop),
        ...containerStyle,
      }}
    >
      <SearchWrapper
        style={combineStyles([
          p('searchBar', CONTAINER_PROPERTIES),
          { backgroundColor: p('searchBar', CONTAINER_PROPERTIES).backgroundColor || 'whitesmoke' },
        ])}
      >
        <TouchableOpacity>
          <RiSearchLine size={20} color={p('searchBarIcon', ['color']).color || 'silver'} />
        </TouchableOpacity>

        <input
          className="location-search px-3"
          name="formatted_address"
          placeholder={placeholder}
          value={values.formatted_address}
          onChange={e => {
            setFieldValue('buttonActive', false);
            handleChange(e);
          }}
          style={{ border: 'none', width: '100%', backgroundColor: 'transparent' }}
        />
        <TouchableOpacity onClick={() => dispatch(setMapCenter(userCoords))}>
          <FaLocationArrow size={15} color={p('searchBarIcon', ['color']).color || 'silver'} />
        </TouchableOpacity>
      </SearchWrapper>

      {showSaveSwitch && (
        <div
          style={{
            ...styles.switchWrapper,
            ...(deviceTypeMobile ? styles.switchWrapperMobile : styles.switchWrapperDesktop),
          }}
        >
          <Switch
            checked={values.saveAddress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('saveAddress', e.target.checked)
            }
          />
          <TouchableOpacity
            onClick={() => setFieldValue('saveAddress', !values.saveAddress)}
            style={{
              flexGrow: 1,
            }}
          >
            <Text style={{ fontSize: deviceTypeMobile ? 14 : 12 }}>Save address</Text>
          </TouchableOpacity>
        </div>
      )}
    </div>
  );
};

const styles: Styles = {
  searchContainer: {
    //display: 'flex',
  },
  searchContainerMobile: {
    display: 'block',
  },
  searchContainerDesktop: {
    display: 'flex',
    gap: '0.75em',
  },
  switchWrapper: {
    display: 'flex',
  },
  switchWrapperMobile: {
    marginTop: 10,
    flexDirection: 'row-reverse',
    alignItems: 'center',
    gap: '1em',
    justifyContent: 'space-between',
  },
  switchWrapperDesktop: {
    gap: 0,
    flexDirection: 'column',
  },
};

export default LocationSearch;
