import React, { useState } from 'react';
import { useAppSelector } from '../app/hooks';
import TagAllergenDietary from './TagAllergenDietary';
import { OrderingSelectors } from 'polygon-ordering';
import TouchableOpacity from './TouchableOpacity';
import Text from '../components/Text';
import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';

const { getAllergenDietaryTags } = OrderingSelectors;

// Made this component thinking it could be used in multiple areas
// however the purchase editor modal wants each list seperated so this
// is a little bit redundant
const AllergenTagList = ({
  item,
  includeTempTags = true,
  hideIcons = false,
}: {
  item: Item;
  includeTempTags?: boolean;
  hideIcons?: boolean;
}) => {
  const { allergenTags, dietaryTags, customTags } = useAppSelector(state =>
    getAllergenDietaryTags(state, item.tags, includeTempTags),
  );

  // const hiddenDietaryTagCount = dietaryTags.length > 3 ? dietaryTags.length - 3 : 0;

  const p = useAppSelector(getThemeLookup);

  return (
    <>
      {(dietaryTags.length > 0 || allergenTags.length > 0 || customTags.length > 0) && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 5,
              flexWrap: 'wrap',
            }}
          >
            {!hideIcons && <AllergenIconList item={item} includeTempTags={includeTempTags} />}

            {customTags.length > 0 &&
              customTags.map((custom, index) => (
                <div style={{ marginTop: 10 }}>
                  <TagAllergenDietary option={custom} key={index} themeKey="customTagWarning" />
                </div>
              ))}
          </div>
          <div>
            {allergenTags.length > 0 && (
              <Text themeKey="menuItemContains" style={{ fontSize: 12 }}>
                Contains: {allergenTags.map(allergen => allergen.display_name).join(', ')}
              </Text>
            )}
          </div>
        </>
      )}
    </>
  );
};

export const AllergenIconList = ({
  item,
  includeTempTags = true,
  deviceTypeMobile = false,
  onSetDietaryCount,
}: {
  item: Item;
  includeTempTags?: boolean;
  deviceTypeMobile?: boolean;
  onSetDietaryCount?: (val: number) => any;
}) => {
  const { dietaryTags } = useAppSelector(state =>
    getAllergenDietaryTags(state, item.tags, includeTempTags),
  );
  const updateDietaryCount = (val: number) => {
    if (onSetDietaryCount) onSetDietaryCount(val);
    setDietaryCountVal(val);
  };
  const [dietaryCount, setDietaryCountVal] = useState(3);
  const hiddenDietaryTagCount = dietaryTags.length > 3 ? dietaryTags.length - 3 : 0;

  const WrapperComponent = (props: any) =>
    hiddenDietaryTagCount !== 0 && dietaryCount <= 3 ? (
      <TouchableOpacity {...props} />
    ) : (
      <div {...props} />
    );

  return dietaryTags.length > 0 ? (
    <TouchableOpacity
      enablePropagation={!(hiddenDietaryTagCount != 0 && dietaryCount == 3)}
      onClick={e => {
        console.log('hiddenDietaryTagCount', hiddenDietaryTagCount, 'dietaryCount', dietaryCount);
        if (hiddenDietaryTagCount != 0 && dietaryCount == 3) {
          e?.stopPropagation();
        }
        console.log('e?.isPropagationStopped()', e?.isPropagationStopped());
        updateDietaryCount(dietaryTags.length);
      }}
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: deviceTypeMobile ? 10 : 15,
        flexWrap: 'wrap',
        margin: '10px 0 0 0',
      }}
    >
      {dietaryTags.length > 0 &&
        dietaryTags
          .slice(0, dietaryCount)
          .map((diet, index) => (
            <TagAllergenDietary option={diet} size={deviceTypeMobile ? 24 : 30} key={index} />
          ))}

      {dietaryCount !== dietaryTags.length && hiddenDietaryTagCount > 0 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text>+{hiddenDietaryTagCount}</Text>
        </div>
      )}
    </TouchableOpacity>
  ) : (
    <></>
  );
};

export default AllergenTagList;
