// Imports
import { createAsyncThunk } from '@reduxjs/toolkit';
import Api, { ApiResponse, FetchParams } from '../utils/Api';
import { getOrderingProvider } from '../selectors/config';
import { setAdvancedCustomHours } from '../reducers/advancedCustomHours';

// SAMPLE JSON RESPONSE
const sampleResponse = {
  CustomHours: [
    {
      AdjustedDate: '2024-07-15',
      CloseTime: '16:30:00',
      Closed: true,
      CustomerMessage: null,
      Day: null,
      Dynamic: false,
      ID: 23,
      InternalDescription: null,
      OpenTime: '07:00:00',
      OrderingProviderID: null,
      SaleType: 100,
      StoreID: 1,
    },
    {
      AdjustedDate: '2024-07-18',
      CloseTime: '14:00:00',
      Closed: false,
      CustomerMessage: null,
      Day: null,
      Dynamic: false,
      ID: 24,
      InternalDescription: null,
      OpenTime: '10:00:00',
      OrderingProviderID: 1,
      SaleType: 102,
      StoreID: 1,
    },
    {
      AdjustedDate: '2024-07-19',
      CloseTime: null,
      Closed: true,
      CustomerMessage: null,
      Day: null,
      Dynamic: false,
      ID: 27,
      InternalDescription: null,
      OpenTime: null,
      OrderingProviderID: 1,
      SaleType: 102,
      StoreID: 1,
    },
  ],
};

export const fetchAdvancedCustomHours = createAsyncThunk(
  '$fetchAdvancedCustomHours',
  async (
    { storeId }: { storeId?: string } = {},
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      // Ordering provider
      const orderingProvider = getOrderingProvider(
        getState() as EntireFrontendState,
      );

      // Throwing error if ordering provider is not there
      if (!orderingProvider) {
        throw new Error('Ordering provider is required');
      }

      // API params
      const params: FetchParams = {
        path: `/api/v1/storehoursmaps/customhours?provider=${orderingProvider}`,
        method: 'GET',
      };

      // Adding store ID to API call if store ID is passed (FOR KIOSK)
      if (storeId) {
        params.path = params.path + `&storeId=${storeId}`;
      }

      // Hitting API
      const response: ApiResponse = await Api.fetch(params);

      // Setting advanced custom hours if response is successfull
      if (response.success) {
        console.log('Custom Hours From API : ', response.data.CustomHours);
        dispatch(
          setAdvancedCustomHours(
            response.data.CustomHours as Array<AdvancedCustomHours>,
          ),
        );
      }
    } catch (e) {
      console.warn('Fetching advanced custom hours failed : ', e);
      return rejectWithValue(e);
    }
  },
);
