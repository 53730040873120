import { OrderingSelectors } from 'polygon-ordering';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import CreditLoyaltyBalance from '../components/CreditLoyaltyBalance';
import MemberBalance from '../components/MemberBalance';
import Text from '../components/Text';
import TouchableOpacity from '../components/TouchableOpacity';
import history from '../history';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { mapNumbers } from '../libs/polygon-ordering/src/utils/misc';
import { SCAN_CODE_MODAL_ID } from '../modals/ScanCodeModal';
import getConfig from '../selectors/getConfig';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getProfile from '../selectors/getProfile';
import getStampcardConfig from '../selectors/getStampcardConfig';
import getThemeLookup from '../selectors/getThemeLookup';
import { setCurrentModal } from '../thunks/setCurrentModal';
import combineStyles from '../utils/combineStyles';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import { MEMBER_MONEY_ROUTE } from './MemberMoneyScreen';

const { getMember, getEnabledPaymentMethods } = OrderingSelectors;

export const REWARDS_SCREEN_ROUTE = '/rewards';

const RewardsScreen = () => {
  const profile = useAppSelector(getProfile);
  const loyaltyTiers = useAppSelector(state => state.loyaltyTiers);
  const tier = loyaltyTiers?.filter(tier => tier.tierName === profile?.loyaltyTierName)[0];
  const nextTier = loyaltyTiers?.filter(loyaltyTier => loyaltyTier.id === tier?.nextTier)[0];
  const enableTieredLoyalty = useAppSelector(state => state.config.enableTieredLoyalty);

  const dispatch = useAppDispatch();
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);
  // const profile = useAppSelector(getProfile);
  // mobile used the profile selector but member is maybe better?
  // TODO: unify this across platforms, profile has some data, member has some, they all draw from api/v1/profile so no excuses
  const member = useAppSelector(getMember);
  const enabledPaymentMethods = useAppSelector(getEnabledPaymentMethods);
  const memberMoneyEnabled = enabledPaymentMethods.includes(PAYMENT_METHOD.MEMBER_MONEY)
    ? true
    : false;

  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);

  const config = useAppSelector(getConfig);
  const {
    loyaltySendVerificationEmail,
    loyaltyEnableSmsVerification,
    loyaltyPointsName,
    loyaltyMoneyName,
    creditLoyaltyEnabled,
    paymentGatewayPublicKey,
  } = config;
  const showLoyaltyPointsBalance = true; // apparently no config for this on web

  // STAMPCARDS-TODO: get stampcards enabled, number, icon, etc
  const {
    enabled: stampcardEnabled,
    stampsPerCard,
    stampDefaultImage,
    stampRewardImageMap,
  } = useAppSelector(getStampcardConfig);
  const { stampcardText, stampsEarned } = member ?? {};

  const cardSectionStyle = combineStyles(
    styles.cardSection,
    p('defaultBorder', ['borderTop']),
    p('accountCardSection', CONTAINER_PROPERTIES),
  );

  const cardButtonStyle = combineStyles(
    styles.cardButtonSubSection,
    p('defaultBorder', ['borderRight']),
    p('accountCardButton', CONTAINER_PROPERTIES),
  );

  // STAMPCARDS-TODO: what configs define when a user can top up their wallet
  const showTopUpButton = !!member?.verified && !!memberMoneyEnabled && !!paymentGatewayPublicKey;

  return (
    <div
      style={combineStyles(
        styles.screen,
        p('screen', CONTAINER_PROPERTIES),
        p('screenCard', CONTAINER_PROPERTIES),
        isMobileDevice && { padding: 10 },
      )}
    >
      {!!member && (
        <div
          style={combineStyles(
            styles.cardContainer,
            p('defaultBorder', ['border']),
            p('accountCardContainer', CONTAINER_PROPERTIES),
          )}
        >
          {/* top section: name, member number, balance, progress, etc */}
          <div
            style={combineStyles(
              cardSectionStyle,
              styles.memberDetailsSection,
              enableTieredLoyalty && {
                backgroundColor: tier?.tierColour,
              },
            )}
          >
            {/* name */}
            <Text
              style={combineStyles(
                { marginTop: 10 },
                p('accountCardName', TEXT_PROPERTIES),
                enableTieredLoyalty && {
                  color: tier?.tierTextColour,
                },
              )}
            >
              {member.fullName}
            </Text>
            {/* member id/code? */}
            <Text
              style={combineStyles(
                { marginTop: 5 },
                p('accountCardMemberNumber', TEXT_PROPERTIES),
                enableTieredLoyalty && {
                  color: tier?.tierTextColour,
                },
              )}
            >
              {member.memberNumber}
            </Text>
            {/* balances */}
            {creditLoyaltyEnabled ? (
              <>
                <div style={{ marginTop: 20 }} />
                <CreditLoyaltyBalance />
              </>
            ) : (
              <>
                {(showLoyaltyPointsBalance || memberMoneyEnabled) && (
                  <div style={styles.memberBalances}>
                    {/* non-credit loyalty, so no progress bar */}
                    {showLoyaltyPointsBalance && (
                      <MemberBalance
                        amount={member?.pointsBalance ?? 0}
                        label={loyaltyPointsName ?? ''}
                      />
                    )}
                    {memberMoneyEnabled && (
                      <MemberBalance
                        amount={member?.moneyBalance ?? 0}
                        label={loyaltyMoneyName ?? ''}
                        isCurrency
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          {/* stampcard section */}
          {stampcardEnabled && !!stampsPerCard && (
            <div style={combineStyles(cardSectionStyle, styles.stampcardSection)}>
              {!!stampDefaultImage ? (
                <>
                  <div style={combineStyles(styles.stampImageGrid, { flexDirection: 'column' })}>
                    {(() => {
                      let columns = 5;
                      // set columns = 5 if divisible by 5, 4 if divisible by 4, 3 if divisible by 3, else 5
                      for (let i = 5; i >= 3; i--) {
                        if (stampsPerCard % i === 0) {
                          columns = i;
                          break;
                        }
                      }
                      const rows = Math.ceil(stampsPerCard / columns);

                      return mapNumbers(rows, rowIndex => {
                        return (
                          <div
                            key={rowIndex}
                            style={combineStyles(styles.stampImageGrid, { flexDirection: 'row' })}
                          >
                            {mapNumbers(columns, columnIndex => {
                              const stampIndex = rowIndex * columns + columnIndex;
                              const stampFulfilled = stampIndex < (stampsEarned ?? 0);

                              const showingImage =
                                stampRewardImageMap[stampIndex + 1] ||
                                (stampFulfilled && stampDefaultImage) ||
                                undefined;

                              return (
                                <div
                                  key={stampIndex}
                                  style={combineStyles(
                                    styles.imageStampContainer,
                                    p('stampImageContainer', [
                                      ...CONTAINER_PROPERTIES,
                                      'width',
                                      'height',
                                    ]),
                                    ...(stampFulfilled
                                      ? [
                                          p('stampImageContainerFulfilled', CONTAINER_PROPERTIES),
                                          enableTieredLoyalty && {
                                            backgroundColor: tier?.tierColour,
                                          },
                                        ]
                                      : []),
                                    stampIndex >= stampsPerCard && {
                                      opacity: 0,
                                    },
                                  )}
                                >
                                  {!!showingImage && (
                                    <img
                                      style={combineStyles(
                                        styles.stampImage,
                                        !stampFulfilled &&
                                          p('stampImageUnfulfilled', CONTAINER_PROPERTIES),
                                      )}
                                      src={showingImage}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        );
                      });
                    })()}
                  </div>
                </>
              ) : (
                <div style={combineStyles(styles.defaultStampsContainer)}>
                  {/* display this or an image grid depending on images */}
                  {mapNumbers(stampsPerCard, index => (
                    // stamp icon for when there's no image
                    <div
                      key={index}
                      style={combineStyles(
                        styles.defaultStamp,
                        p('defaultStamp', CONTAINER_PROPERTIES),
                        ...(index < (stampsEarned ?? 0)
                          ? [
                              combineStyles(
                                p('defaultStampFulfilled', CONTAINER_PROPERTIES),
                                enableTieredLoyalty &&
                                  tier?.tierColour && {
                                    backgroundColor: tier?.tierColour,
                                  },
                              ),
                            ]
                          : []),
                      )}
                    >
                      {/* put this dot in here in case any clients decide they want it */}
                      {/* off by default with display hidden */}
                      {!!stampRewardImageMap[index + 1] && (
                        <div
                          style={combineStyles(
                            styles.defaultStampRewardIndicator,
                            p('defaultStampRewardIndicator', [...CONTAINER_PROPERTIES, 'display']),
                          )}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div
                style={combineStyles(
                  styles.stampsProgressContainer,
                  p('stampsProgressContainer', CONTAINER_PROPERTIES),
                )}
              >
                <Text
                  style={combineStyles(
                    { marginRight: 10 },
                    p('stampcardProgressCounter', TEXT_PROPERTIES),
                    enableTieredLoyalty && {
                      color: tier?.tierTextColour,
                    },
                  )}
                >
                  {t('accountPage.stampcard.progressCounter', {
                    earned: stampsEarned ?? 0,
                    max: stampsPerCard,
                  })}
                </Text>
                <Text
                  style={combineStyles(
                    { textAlign: 'left' },
                    p('stampcardProgressText', TEXT_PROPERTIES),
                    enableTieredLoyalty && {
                      color: tier?.tierTextColour,
                    },
                  )}
                >
                  {stampcardText}
                </Text>
              </div>
            </div>
          )}
          {(showTopUpButton || isMobileDevice) && ( // don't show the border if there are no buttons (i.e a client with no top up on desktop)
            <div style={combineStyles(cardSectionStyle, styles.cardButtonsSectionContainer)}>
              {/* bottom section with row buttons */}
              {showTopUpButton && (
                <div
                  style={combineStyles(
                    cardButtonStyle,
                    // removes the right border when the second button isn't showing
                    !isMobileDevice && { borderRightWidth: 0 },
                  )}
                >
                  {/* top up button */}
                  <TouchableOpacity onClick={() => history.push(MEMBER_MONEY_ROUTE)}>
                    <Text
                      style={combineStyles(
                        enableTieredLoyalty && {
                          color: tier?.tierTextColour,
                        },
                        p('creditLoyaltyTopUpButtonText', TEXT_PROPERTIES),
                      )}
                    >
                      {t('accountPage.buttons.topUp')}
                    </Text>
                  </TouchableOpacity>
                </div>
              )}
              {isMobileDevice && (
                <div style={combineStyles(cardButtonStyle, { borderRightWidth: 0 })}>
                  {/* scan card button */}
                  <TouchableOpacity
                    onClick={() =>
                      dispatch(
                        setCurrentModal({
                          modalId: SCAN_CODE_MODAL_ID,
                        }),
                      )
                    }
                  >
                    <Text
                      style={combineStyles(
                        enableTieredLoyalty && {
                          color: tier?.tierTextColour,
                        },
                        p('creditLoyaltyTopUpButtonText', TEXT_PROPERTIES),
                      )}
                    >
                      {t('accountPage.buttons.scanCard')}
                    </Text>
                  </TouchableOpacity>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {/* <SendVerificationButton /> */}
    </div>
  );
};

export default RewardsScreen;

const styles: Styles = {
  screen: {
    flex: 1,
  },

  orderNowButton: {
    marginTop: 20,
    width: '60%',
  },
  message: {
    textAlign: 'center',
  },

  cardContainer: {
    // border, etc
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    overflow: 'hidden',
  },
  cardSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  memberDetailsSection: {
    borderTopWidth: 0,

    padding: 20,
  },
  stampcardSection: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  cardButtonsSectionContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardButtonSubSection: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 17,
  },

  defaultStampsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: 10,
  },
  defaultStamp: {
    width: 25,
    height: 25,
  },
  defaultStampRewardIndicator: {
    display: 'none',
    position: 'absolute',
    top: -1,
    right: -1,
    width: 8,
    height: 8,
    borderRadius: 4,
  },

  stampImageGrid: {
    display: 'flex',
    // flexDirection: ''
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
  },
  imageStampContainer: {
    // not sure how to go about this because responsiveness is tricky
    // width: 75,
    // height: 75,
  },
  stampImage: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },

  stampsProgressContainer: {
    marginTop: 10,
    // display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },

  memberBalances: {
    display: 'flex',
    padding: 20,
    paddingBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },

  creditLoyaltyTopLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  creditLoyaltyBalanceContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
};
