import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { Modal } from 'reactstrap';
import ModalHeader from '../components/ModalHeader';
import FormTextField from '../components/FormTextField';
import { FormikValues, useFormikContext } from 'formik';
import StandardButton from '../components/StandardButton';
import { OrderingConstants, OrderingOperations, OrderingSelectors } from 'polygon-ordering';
import { enqueueErrorSnackbar } from '../utils/snackbar';
import { performDeliveryEstimate } from '../thunks/performDeliveryEstimate';
import { applyBufferDeliveryEstimate } from '../thunks/applyBufferDeliveryEstimate';

const { getBufferDeliveryTime, getBypassDeliveryDetails } = OrderingSelectors;
const { saveAddress } = OrderingOperations;
const { ASAP_TIME } = OrderingConstants;

const SaveAddressModal = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { handleChange, values, setFieldValue } = useFormikContext<FormikValues>();
  const [requestInProgress, setRequestInProgress] = useState(false);

  const bypassDeliveryDetails = useAppSelector(getBypassDeliveryDetails);

  const bufferDeliveryTime = useAppSelector(getBufferDeliveryTime);
  const desiredDeliveryTime = useAppSelector(state => state.desiredDeliveryTime);
  const effectiveDeliveryAddress = useAppSelector(state => state.desiredDeliveryAddress);
  const effectiveDeliveryTime = desiredDeliveryTime || bufferDeliveryTime;

  return (
    <Modal isOpen={isOpen} style={{ marginTop: '25%', padding: 25 }}>
      <ModalHeader
        backClick={() => setFieldValue('showSaveAddressModal', false)}
        title="Address Name"
        showCloseButton
      />

      <div className="m-4 px-4">
        <FormTextField
          required
          name="saveAddressAlias"
          type="text"
          label="Name"
          onChange={handleChange}
        />

        <StandardButton
          label="Save Address"
          themeKey="continueButton"
          disabled={values.saveAddressAlias === '' || requestInProgress}
          onClick={() => {
            setRequestInProgress(true);
            dispatch(
              saveAddress({
                SDAddress1: values.street_name,
                SDAddress2: values.locality,
                SDAlias: values.saveAddressAlias,
                SDLatitude: values.lat,
                SDLongitude: values.lng,
              }),
            )
              .unwrap()
              .then(() => {
                setFieldValue('showSaveAddressModal', false);

                if (!bypassDeliveryDetails) {
                  setFieldValue('page', values.page - 1);
                } else {
                  dispatch(
                    performDeliveryEstimate({
                      deliveryAddress: effectiveDeliveryAddress?.formatted_address!,
                      desiredDeliveryTime: ASAP_TIME,
                    }),
                  )
                    .unwrap()
                    .then(() => {
                      if (bypassDeliveryDetails) {
                        dispatch(applyBufferDeliveryEstimate());
                      }
                    });
                }

                setRequestInProgress(false);
              })
              .catch(e => {
                enqueueErrorSnackbar(e || e.message || e.reason);
                setRequestInProgress(false);
              });
          }}
        />
      </div>
    </Modal>
  );
};

export default SaveAddressModal;
