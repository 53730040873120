import React, { useState, useEffect, useRef, createContext } from 'react';
import lodash from 'lodash';
import { useTranslation } from 'react-i18next';

import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';

import { MENU_TOP_ANCHOR } from '../constants';

import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';

import getFilteredMenuItemCount from '../selectors/getFilteredMenuItemCount';
import getCurrentCategoryId from '../selectors/getCurrentCategoryId';
import getOrderInProgress from '../selectors/getOrderInProgress';

import combineStyles from '../utils/combineStyles';
import debug from '../utils/debug';
import { getHeight } from '../utils/screen';

import ScreenHero from '../components/ScreenHero';
import MenuLocationDetails from '../components/MenuLocationDetails';
import MenuCategoriesBar from '../components/MenuCategoriesBar';
import MenuCategory from '../components/MenuCategory';
import RedirectAndPreserveSearch from '../components/RedirectAndPreserveSearch';
import ScrollAnchor from '../components/ScrollAnchor';
import Text from '../components/Text';

import {
  INITIAL_SCREEN_ROUTE,
  COMPLETED_ORDER_SCREEN_ROUTE,
  REVIEW_ORDER_SCREEN_ROUTE,
} from '../hooks/useRoutes';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import ViewCartButton from '../components/ViewCartButton';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { PROOF_OF_AGE_MODAL } from '../modals/AgeProofModal';
import { MESSAGE_MODAL_ID } from '../modals/MessageModal';
import checkIfAlcoholExistsInCategories from '../utils/checkIfAlcoholExistsInCategories';
import delay from '../utils/delay';
import EnergyText from '../components/EnergyText';
import { addStagedPurchases } from '../libs/polygon-ordering/src/reducers/currentOrder/stagedPurchases';
import { setReorderableItems } from '../slices/reorderableItems';
import { setMenuMessageModalDisplayed } from '../slices/menuMessageModalDisplayed';
import { useLocation } from 'react-router-dom';
import { ORDER_SUMMARY_MODAL_ID } from '../modals/OrderSummaryModal';
import { UPSELL_MODAL } from '../modals/Upsells';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { setCurrentModalId } from '../slices/currentModalId';
import { MENU_ADVERTISEMENT_MODAL } from '../modals/MenuAdvertisementModal';
import getMenuOverlayAdvertisements from '../selectors/getMenuOverlayAdvertisements';

const {
  getOrderSubmitted,
  getMenuFilterTags,
  getAgeProofSubmitted,
  getLocationBrandsWithMenuRepresentation,
  getFilteredRootCategory,
  getStagedPurchaseCount,
  getEnabledUpsellByCheckout,
  getResetEnableUpsellByCheckout,
  getUpsellsByCheckout,
  getUpsellTitle,
  getSaleType,
  getDeliveryTime,
} = OrderingSelectors;
const { fetchStockBalances } = OrderingOperations;

export const CurrentCategoryContext = createContext<string | null>(null);

const MenuScreen = () => {
  const [scrollTop, setScrollTop] = useState<any>(null);
  const [categoryRefs, setCategoryRefs] = useState<{ [key: string]: any }>({});
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const rootCategory = useAppSelector(getFilteredRootCategory);
  const orderSubmitted = useAppSelector(getOrderSubmitted);
  const itemCount = useAppSelector(getFilteredMenuItemCount);
  const orderInProgress = useAppSelector(getOrderInProgress);
  const [currentCategoryId, setCurrentCategoryId] = useState('MENU_TOP_ANCHOR');
  const modalMessageShown = useAppSelector(state => state.menuMessageModalDisplayed);
  const modalAdsDisplayed = useAppSelector(state => state.menuOverlayAdsDisplayed);

  //const currentCategoryId = useAppSelector(getCurrentCategoryId);
  const menuCategoriesBarHeight = useAppSelector(state => state.menuCategoriesBarHeight);
  const dispatch = useAppDispatch();
  const enableStockPolling = useAppSelector(state => state.config.enableStockPolling);
  const stockPollingInterval = useAppSelector(state => state.config.stockPollingInterval);
  const [checkPLUQuantityOnLoad, setCheckPLUQuantityOnLoad] = useState(true);
  const interval = useRef<NodeJS.Timeout | null>(null);
  const filterTags = useAppSelector(getMenuFilterTags);
  const ageProofSubmitted = useAppSelector(getAgeProofSubmitted);
  const representedBrands = useAppSelector(getLocationBrandsWithMenuRepresentation);
  const multipleBrands = representedBrands.length > 1;

  const reorderableItems = useAppSelector(state => state.reorderableItems);
  const stagedPurchaseCount = useAppSelector(getStagedPurchaseCount) as number;

  const enableUpsellByCheckout = useAppSelector(getEnabledUpsellByCheckout);
  const resetEnableUpsellByCheckout = useAppSelector(getResetEnableUpsellByCheckout);

  const upsells: (Item & { upsellAvailable: boolean })[] = useAppSelector(getUpsellsByCheckout);
  const upsellTitle = useAppSelector(getUpsellTitle);

  const menuOverlayAdvertisements = useAppSelector(getMenuOverlayAdvertisements);

  const browserLocation = useLocation();

  const saleType = useAppSelector(getSaleType);
  const desiredDeliveryTime = useAppSelector(state => state.desiredDeliveryTime);
  const deliveryTime = useAppSelector(getDeliveryTime);
  const selectedScheduledTime = saleType === SALE_TYPE.DELIVERY ? deliveryTime : null;
  const currentDay = selectedScheduledTime
    ? new Date(selectedScheduledTime as string)
        .toLocaleString('en-US', { weekday: 'long' })
        .toUpperCase()
    : null;
  const currentTime = selectedScheduledTime
    ? new Date(selectedScheduledTime as string).toTimeString().slice(0, 8)
    : null; // Get time in HH:MM:SS format

  const handleViewCart = () => {
    dispatch(
      setCurrentModal({
        modalId: ORDER_SUMMARY_MODAL_ID,
      }),
    );
    if (enableUpsellByCheckout && resetEnableUpsellByCheckout && upsells.length > 0)
      dispatch(
        setCurrentModal({
          modalId: UPSELL_MODAL,
          preserveReturnModalDetails: true,
          params: {
            items: upsells,
            title: upsellTitle ? upsellTitle : t('title.modal.upsellTitle'),
            checkoutUpsell: true,
          },
        }),
      );
  };

  useEffect(() => {
    if (stagedPurchaseCount !== 0 && browserLocation?.state?.from === REVIEW_ORDER_SCREEN_ROUTE) {
      handleViewCart();
    }
  }, [browserLocation, stagedPurchaseCount]);

  useEffect(() => {
    const menuHasAds = menuOverlayAdvertisements && menuOverlayAdvertisements.length;
    if (modalAdsDisplayed === false && menuHasAds) {
      dispatch(setCurrentModal(null));
      dispatch(setCurrentModalId(MENU_ADVERTISEMENT_MODAL));
    } else if (modalMessageShown === false && (modalAdsDisplayed || !menuHasAds)) {
      showMessageModals();
    }
  }, [rootCategory, menuOverlayAdvertisements, modalAdsDisplayed, modalMessageShown]);

  const showMessageModals = async () => {
    const menuHasAlcohol = checkIfAlcoholExistsInCategories(rootCategory?.subCategories!);

    if (multipleBrands) {
      await setTimeout(() =>
        dispatch(
          setCurrentModal({
            modalId: MESSAGE_MODAL_ID,
            params: {
              title: t('title.modal.brandsEducation'),
              message: t('brandsEducationMessage'),
              action: async () => {
                if (menuHasAlcohol) {
                  dispatch(setCurrentModal(PROOF_OF_AGE_MODAL));
                } else {
                  dispatch(setCurrentModal(null));
                  dispatch(setMenuMessageModalDisplayed(true));
                }
              },
            },
            clearReturnModalDetails: true,
          }),
        ),
      );
    } else {
      if (menuHasAlcohol) {
        await dispatch(setCurrentModal(PROOF_OF_AGE_MODAL));
      }
    }
  };

  useEffect(() => {
    if (checkPLUQuantityOnLoad) {
      dispatch(fetchStockBalances());
      setCheckPLUQuantityOnLoad(false);
    }

    if (enableStockPolling && stockPollingInterval) {
      interval.current = setInterval(
        () => dispatch(fetchStockBalances()),
        stockPollingInterval * 1000,
      );
      return () => {
        clearInterval(interval.current as NodeJS.Timeout);
        dispatch(setMenuMessageModalDisplayed(true));
      };
    }
  }, []);

  const reportRef = (categoryId: string, ref: any) => {
    setCategoryRefs(prevState => {
      return { ...prevState, [categoryId]: ref };
    });
  };

  const scrollToCategory = (categoryId: string, offset?: number) => {
    const ADDITIONAL_ADJUSTMENT = deviceTypeMobile ? 90 : 30; // magic number changes if you use psb

    const categoryRef = categoryRefs[categoryId];

    setScrollTop(
      (categoryRef?.current?.offsetTop || 0) -
        Number(menuCategoriesBarHeight) -
        ADDITIONAL_ADJUSTMENT +
        (offset || 0),
    );
  };

  const setCurrentCategoryIdAndScroll = (newCurrentCategoryId: string) => {
    if (currentCategoryId !== newCurrentCategoryId) {
      setCurrentCategoryId(newCurrentCategoryId);
    }

    scrollToCategory(newCurrentCategoryId);
  };

  const onScroll = () => {
    try {
      if (scrollTop != null) {
        setScrollTop(null);
      }

      // distance from top of screen (or is it the scroll container?)
      // magic number (based on feel + avg/min height of menu items)
      const THRESHOLD = 250;

      const categoryBottomPositions = lodash
        .chain(categoryRefs)
        .toPairs()
        .map(([categoryId, ref]) =>
          ref?.current
            ? {
                categoryId,
                bottom: ref.current.getBoundingClientRect().bottom,
              }
            : undefined,
        )
        .filter()
        .orderBy('bottom', 'asc')
        .value();

      let newCurrentCategoryId;

      const firstBelowThreshold = categoryBottomPositions.find(
        (bar: any) => bar.bottom > THRESHOLD,
      );
      const last = categoryBottomPositions[categoryBottomPositions.length - 1];

      if (firstBelowThreshold) {
        newCurrentCategoryId = firstBelowThreshold.categoryId;
      } else if (last) {
        newCurrentCategoryId = last.categoryId;
      }

      if (newCurrentCategoryId == null) {
        return;
      }

      if (newCurrentCategoryId !== currentCategoryId) {
        setCurrentCategoryId(newCurrentCategoryId);
      }
    } catch (e) {
      debug(false, 'menu onScroll encountered an problem', e);
    }
  };

  useEffect(() => {
    onScroll();
  }, []);

  const setCartWithReorderableItems = (items: _Purchase[]) => {
    dispatch(addStagedPurchases(items));
    dispatch(setReorderableItems(null));
  };

  //If the user pressed "Reorder" then need to populate the cart with the previously ordered items.
  useEffect(() => {
    if (reorderableItems !== null) {
      //Don't know why but this timeout is required. Otherwise the cart doesn't get populated.
      //@ts-ignore
      setTimeout(() => setCartWithReorderableItems(reorderableItems), 1000);
    }
  }, []);

  if (orderSubmitted) {
    return <RedirectAndPreserveSearch to={COMPLETED_ORDER_SCREEN_ROUTE} />;
  }

  if (!orderInProgress) {
    return <RedirectAndPreserveSearch to={INITIAL_SCREEN_ROUTE} />;
  }

  const categories = rootCategory?.subCategories || [];

  const categoryWithAvailability = categories.filter(cat => {
    if (selectedScheduledTime && currentDay && currentTime) {
      const isWithinDayCategory = cat.days.length
        ? cat.days.map(day => String(day)).includes(currentDay)
        : true;
      const isWithinTimeCategory =
        cat?.start && cat?.end ? currentTime >= cat?.start && currentTime <= cat?.end : true; // No time constraint if start or end is null
      return isWithinDayCategory && isWithinTimeCategory; // Filter out items not available now
    } else {
      return true;
    }
  });

  return (
    <ScreenHero
      onScroll={lodash.throttle(onScroll, 300)}
      scrollTop={scrollTop}
      heroChildren={!deviceTypeMobile && <MenuLocationDetails />}
      heroBottom={<ViewCartButton handleViewCart={handleViewCart} />}
      themeKey="menuScreenParent"
    >
      <CurrentCategoryContext.Provider value={currentCategoryId}>
        <MenuCategoriesBar
          setCurrentCategoryId={setCurrentCategoryIdAndScroll}
          jumpToTop={() => setTimeout(() => scrollToCategory(MENU_TOP_ANCHOR, 0), 100)}
        />

        <div
          style={combineStyles(
            deviceTypeMobile ? styles.mainContainerMobile : styles.mainContainerDesktop,
            p('screen', ['backgroundColor']),
            p('menuScreen', ['backgroundColor']),
          )}
        >
          <ScrollAnchor reportRef={reportRef} identifier={MENU_TOP_ANCHOR} />

          {itemCount ? (
            categories.map((category, index) => (
              <MenuCategory
                key={category.id}
                category={category}
                reportRef={reportRef}
                shrinkMargin={index === categories.length - 1}
              />
            ))
          ) : (
            <div style={combineStyles(styles.noResults, { minHeight: getHeight() })}>
              <Text
                themeKey="noResultsInMenuSearchMessage"
                value={t('noResultsInMenuSearchMessage')}
              />
              {filterTags && filterTags.length > 0 && (
                <Text
                  style={{ marginTop: 10 }}
                  themeKey="removeFiltersWarnings"
                  value={t('removeFiltersWarnings')}
                />
              )}
            </div>
          )}

          {!!itemCount && (
            <div style={{ textAlign: 'center' }}>
              <EnergyText style={{ fontSize: 12 }} themeKey="energyIntakeText" />
            </div>
          )}
        </div>
      </CurrentCategoryContext.Provider>
    </ScreenHero>
  );
};

const styles = {
  mainContainerDesktop: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 20,
    maxWidth: 1600,
    margin: '2.5em auto 28px auto',
  },
  mainContainerMobile: {
    flex: 1,
    padding: '35px 25px 28px 25px',
    marginBottom: '20px',
  },

  noResults: {
    paddingTop: 30,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export default MenuScreen;
