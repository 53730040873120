import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'enableRecaptchaResendVerification',
  initialState: false,
  reducers: {
    setEnableRecaptchaResendVerification: (_, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setEnableRecaptchaResendVerification } = slice.actions;
export default slice.reducer;
