import React from 'react';

import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import Text from './Text';
import { HEADER_BRANDING_TYPES } from '../constants';
import combineStyles from '../utils/combineStyles';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import navigateToHomeScreen from '../utils/navigateToHomeScreen';
import { useLocation } from 'react-router-dom';

const HeaderBranding = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const headerBrandImagePath = useAppSelector(state => state.config.headerBrandImagePath);
  const headerBrandName = useAppSelector(state => state.config.headerBrandName);
  const headerBrandingType = useAppSelector(state => state.config.headerBrandingType);

  const headerBrandingClicked = async () => {
    navigateToHomeScreen(pathname, dispatch);
  };

  return (
    <div style={styles.mainContainer}>
      <div
        style={{
          height: '100%',
          display: 'flex',
          cursor: 'pointer',
          userSelect: 'none',
        }}
        onClick={() => headerBrandingClicked()}
      >
        {headerBrandingType === HEADER_BRANDING_TYPES.LONG ? (
          Boolean(headerBrandImagePath) &&
          headerBrandImagePath && (
            <img
              className="imageLong"
              style={combineStyles(styles.imageLong, p('headerBrandingImage', ['maxHeight']))}
              src={headerBrandImagePath}
              alt="logo"
            />
          )
        ) : (
          <>
            {Boolean(headerBrandImagePath) && headerBrandImagePath && (
              <img
                className="ImageSquare"
                style={combineStyles(styles.imageSquare, p('headerBrandingImage', ['maxHeight']))}
                src={headerBrandImagePath}
                alt="logo"
              />
            )}
            <Text
              themeKey="headerBrandingName"
              style={p(
                deviceTypeMobile ? 'headerBrandingNameMobile' : 'headerBrandingNameDesktop',
                ['fontSize'],
              )}
            >
              {headerBrandName}
            </Text>
          </>
        )}
      </div>
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  imageLong: {
    width: '100%',
    objectFit: 'contain',
    objectPosition: '0 0',
  },
  imageSquare: {
    maxHeight: '150%',
    marginRight: 10,
    objectFit: 'contain',
  },
};

export default HeaderBranding;
