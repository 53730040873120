import { Form, Formik } from 'formik';
import { OrderingOperations } from 'polygon-ordering';
import { RedcatApiHandler } from 'polygon-utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text';
import getMemberFields from '../selectors/getMemberFields';
import getMemberSchema from '../selectors/getMemberSchema';
import getProfile from '../selectors/getProfile';
import getProfileInitialValues from '../selectors/getProfileInitialValues';
import { enqueueErrorSnackbar, enqueueWarningSnackbar } from '../utils/snackbar';

const { removeMember } = OrderingOperations;

export const DELETE_ACCOUNT_ROUTE = '/delete-account';

const DeleteAccountScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const initialVal = useAppSelector(getProfileInitialValues);
  const memberFields = useAppSelector(getMemberFields);
  const memberSchema = useAppSelector(getMemberSchema);
  const profile = useAppSelector(getProfile);

  // should be the same endpoint to update the password
  const submit = (params = {}) => {
    const path = '/api/v1/profile/remove';
    RedcatApiHandler.authorisedFetch({
      path,
      method: 'POST',
      body: params,
    })
      .then(response => {
        //@ts-ignore
        // if (response.token) {
        //   dispatch(syncMember());
        // }

        if (response.success) {
          dispatch(removeMember());
          enqueueWarningSnackbar(t('snackbarMessages.accountDeleted'));

          //   setSubmitting(false);
          //   resetForm({ values });
        }
      })
      .catch(err => {
        enqueueErrorSnackbar(err);
      });
  };

  return (
    <Formik
      validationSchema={memberSchema.visible}
      initialValues={initialVal.visible}
      onSubmit={submit}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, isValid, dirty }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className="position-relative" style={{ marginTop: 20 }}>
              <Text value={t('deleteAccountDetails')} style={{ textAlign: 'left', fontSize: 16 }} />
              <div style={{ paddingTop: 50, margin: '0 50px' }}>
                <StandardButton
                  containerStyle={styles.button}
                  themeKey="loyaltyButton"
                  label={t('button.deleteAccount')}
                  onClick={handleSubmit}
                  disabled={false}
                  showSpinner={isSubmitting}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const styles: Styles = {
  button: {
    borderRadius: 5,
    height: 40,
  },
};

export default DeleteAccountScreen;
