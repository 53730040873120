import React from 'react';
import getDisplayableSaleTypes from '../selectors/getDisplayableSaleTypes';
import Modal from '../components/Modal';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import SaleTypeButton from '../components/SaleTypeButton';
import getOrderInProgress from '../selectors/getOrderInProgress';

export const ORDER_TYPE_MODAL_ID = 'ORDER_TYPE_MODAL_ID';

const { getLocation } = OrderingSelectors;

const OrderTypeModal = () => {
  const displayableSaleTypes = useAppSelector(getDisplayableSaleTypes);
  const location = useAppSelector(getLocation);
  const locationId = location ? location.id : null;
  const orderInProgress = useAppSelector(getOrderInProgress);

  // Sale types that are currently available for this location
  const availableSaleTypesTodayNowForCurrentLocation = displayableSaleTypes.filter(
    option => location?.availableSaleTypesToday[option].isAvailable,
  );

  const saleTypesToDisplay = orderInProgress
    ? availableSaleTypesTodayNowForCurrentLocation || []
    : displayableSaleTypes;

  return (
    <Modal
      desktopMaxWidth={400}
      desktopMinWidth={350}
      closeButtonLabel=""
      containerStyle={{ gap: 0 }}
    >
      {saleTypesToDisplay.map(saleType => (
        <SaleTypeButton key={saleType} saleType={saleType} locationId={locationId} />
      ))}
    </Modal>
  );
};

const styles: Styles = {};

export default OrderTypeModal;
