import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors } from 'polygon-ordering';
import StagedPurchase from './StagedPurchase';
import Text from './Text';
import { useAppSelector } from '../app/hooks';

const {
  getStagedPurchases,
  getStockBalances,
  getCartContainsInvalidQuantities,
  getUnavailablePurchases,
} = OrderingSelectors;

interface IProps {
  readOnly?: boolean;
}

const Cart: React.FC<IProps> = ({ readOnly }) => {
  const { t } = useTranslation();
  const stagedPurchases = useAppSelector(getStagedPurchases);
  const stockBalances = useAppSelector(getStockBalances);
  const cartHasInvalidItems = useAppSelector(getCartContainsInvalidQuantities);

  const unavailablePurchases = useAppSelector(getUnavailablePurchases);

  const [updatedPLUQuantities, setUpdatedPLUQuantities] = useState({});
  useEffect(() => {
    //@ts-ignore
    const trackPLUUpdates = cartHasInvalidItems.trackQuantityUpdates;
    setUpdatedPLUQuantities(trackPLUUpdates);
  }, [stockBalances, stagedPurchases]);

  return (
    <div style={styles.mainContainer}>
      {/* {stagedPurchases?.length || unavailablePurchases.length ? ( */}
      {stagedPurchases?.length ? (
        <>
          {stagedPurchases?.map(purchase => (
            <StagedPurchase
              key={purchase.id}
              purchase={purchase}
              readOnly={readOnly}
              qtyUpdates={updatedPLUQuantities}
            />
          ))}
          {/* Before concept8 were going live the unavilable items weren't 
              working properly especially for nested items so that's why it was commented
           */}
          {/* {unavailablePurchases.map(uPurchase => (
            <StagedPurchase
              key={uPurchase.id}
              unavailablePurchase={uPurchase}
              readOnly={readOnly}
              qtyUpdates={updatedPLUQuantities}
            />
          ))} */}
        </>
      ) : (
        <div style={styles.emptyCartMessageContainer}>
          <Text themeKey="emptyCartMessage" value={t('emptyCartMessage')} style={styles.message} />
        </div>
      )}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    padding: '1.5em 2.25em',
    gap: '2.25em',
    display: 'flex',
    flexDirection: 'column',
  },
  emptyCartMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  },
  message: {
    textAlign: 'center',
  },
};

export default Cart;
