import React, { CSSProperties } from 'react';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';
import getThemeLookup from '../selectors/getThemeLookup';
import { useAppSelector } from '../app/hooks';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';

type TypographyTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'body' | 'body2';

interface IProps {
  themeKey?: string;
  style?: React.CSSProperties | null;
  value?: string;
  children?: React.ReactNode;
  ariaHidden?: boolean;
  onClick?: () => void;
  block?: boolean;
  href?: string;
  openInNewTab?: boolean;
  lines?: number;
  variant?: TypographyTypes;
}

const mobileStyles: { [T in TypographyTypes]: CSSProperties } = {
  h1: { fontSize: 20, fontWeight: 500 },
  h2: { fontSize: 18, fontWeight: 500 },
  h3: { fontSize: 16, fontWeight: 500 },
  h4: { fontStyle: 'bold', fontSize: 16, fontWeight: 700, textTransform: 'uppercase' },
  body: { fontSize: 14, fontWeight: 400 },
  body2: { fontSize: 12, fontWeight: 400 },
};

const desktopStyles: { [T in TypographyTypes]: CSSProperties } = {
  h1: { fontSize: 22, fontWeight: 500 },
  h2: { fontSize: 20, fontWeight: 500 },
  h3: { fontSize: 18, fontWeight: 700 },
  h4: { fontStyle: 'bold', fontSize: 18, fontWeight: 700 },
  body: { fontSize: 16, fontWeight: 400 },
  body2: { fontSize: 16, fontWeight: 400 },
};

const Text: React.FC<IProps> = ({
  themeKey,
  style,
  value,
  children,
  ariaHidden,
  onClick,
  block,
  href,
  openInNewTab,
  lines,
  variant = 'body',
}) => {
  const p = useAppSelector(getThemeLookup);
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);

  const props = {
    style: combineStyles(
      { marginBottom: 0 }, // _reboot.scss for some reason adds 16px of margin-bottom of <p>, so this is here to overwrite that.
      p('defaultText', TEXT_PROPERTIES),
      isMobileDevice ? mobileStyles[variant] : desktopStyles[variant],
      lines &&
        ({
          textOverflow: 'ellipsis',
          // whiteSpace: 'pre-wrap',
          // whiteSpace: lines === 1 ? 'nowrap' : 'pre-line',
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitLineClamp: lines,
          lineClamp: lines,
          WebkitBoxOrient: 'vertical',
          boxOrient: 'vertical',
          // maxWidth: '10px',
          // blockOverflow: 'ellipsis',
          // lineClamp: 3,
          // backgroundColor: '#22000033',
        } as CSSProperties),
      themeKey && p(themeKey, TEXT_PROPERTIES),
      block && !lines && { display: 'block' },
      style,
    ),
    'aria-hidden': ariaHidden, // hide from screen readers
    onClick: onClick,
    ...(href && openInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {}),
    href,
  };

  if (href) {
    return <a {...props}>{children != null ? children : value}</a>;
  } else if (lines) {
    return <p {...props}>{children != null ? children : value}</p>;
  } else {
    return <span {...props}>{children != null ? children : value}</span>;
  }
};

export default Text;
