import lodash from 'lodash';
// Imports
import moment from 'moment';
import { KIOSK_CLIENT } from '../../constants';

function cleanDateTime(raw: any) {
  return raw ? moment(raw).toISOString() : undefined;
}

export default function processAdvertisement(raw: RawAdvertisement) {
  let background = raw.BackgroundColour;
  if (raw.Type !== 2) {
    background = 'transparent';
  }

  return {
    id: raw.Id,
    active: Boolean(raw.Active),
    createdDate: raw.Created,
    title: raw.Title,
    description: raw.Description,
    duration: raw.Duration,
    backgroundColour: background,
    colour: raw.Colour,
    imageUrl: raw.ImageUrl,
    minutes: raw.Minutes,
    linkUrl: raw.LinkUrl,
    style: raw.Style,
    priority: raw.Priority,
    client: raw.Client,
    section: raw.Section,
    subsection: raw.Subsection,
    type: raw.Type,
    platform: raw.Platform,
    startVersion:
      raw.Client === KIOSK_CLIENT
        ? lodash.get(raw, 'StartVersion.Kiosk', undefined)
        : raw.StartVersion,
    finishVersion:
      raw.Client === KIOSK_CLIENT
        ? lodash.get(raw, 'FinishVersion.Kiosk', undefined)
        : raw.FinishVersion,
    startDate: cleanDateTime(raw.StartDate),
    finishDate: cleanDateTime(raw.FinishDate),
    startTime: raw.StartTime,
    finishTime: raw.FinishTime,
    days: raw.Days || [],
    stores: raw.Stores || [],
    couponPrograms: raw.Coupons || [],
    groups: raw.Groups || [],
    saleTypes: raw[`Sale Types`] || [],
  };
}
