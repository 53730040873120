import React from 'react';
import { useTranslation } from 'react-i18next';
import { setCurrentModal } from '../thunks/setCurrentModal';
import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text'; // TODO: rework this to use Text component
import { useAppDispatch, useAppSelector } from '../app/hooks';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { OrderingOperations } from 'polygon-ordering';
import { setMenuMessageModalDisplayed } from '../slices/menuMessageModalDisplayed';

const { setAgeProofSubmitted, setUserUnderage } = OrderingOperations;
export const PROOF_OF_AGE_MODAL = 'PROOF_OF_AGE_MODAL';

const AgeProofModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);

  const closeModal = () => dispatch(setCurrentModal(null));
  const onConfirm = () => {
    dispatch(setAgeProofSubmitted(true));
    dispatch(setUserUnderage(false));
    closeModal();
    dispatch(setMenuMessageModalDisplayed(true));
  };

  return (
    <Modal
      title={t('proofOfAgeTitle')}
      desktopMaxWidth={500}
      desktopMinWidth={500}
      enableCloseButton={false}
    >
      <Text
        block
        style={{
          textAlign: 'center',
          padding: isMobileDevice ? 0 : '0px 25px',
          fontSize: isMobileDevice ? 14 : 16,
        }}
        value={t('proofOfAgeMessage')}
        themeKey="messageModalMessage"
      />
      <div style={{ padding: isMobileDevice ? '15px 0px' : '10px 0px' }}>
        <StandardButton
          label={t('button.over18')}
          onClick={onConfirm}
          themeKey="messageModalButton"
        />
        <StandardButton
          label={t('button.underage')}
          onClick={() => {
            dispatch(setAgeProofSubmitted(true));
            dispatch(setUserUnderage(true));
            closeModal();
            dispatch(setMenuMessageModalDisplayed(true));
          }}
          containerStyle={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
            padding: isMobileDevice ? 15 : 20,
          }}
          themeKey="transparentButton"
        />
      </div>
    </Modal>
  );
};

export default AgeProofModal;
