import i18next from 'i18next';
import { OrderingConstants } from 'polygon-ordering';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './snackbar';

const { FAILURE_REASON } = OrderingConstants;

export default function (reason: OrderingConstants.FAILURE_REASON, errorText: string) {
  let title;
  let subtitle;

  const getFailureReasonString = (reason: OrderingConstants.FAILURE_REASON) =>
    i18next.t(`failureReason.${reason}`);

  switch (reason) {
    case FAILURE_REASON.FETCH_FAILED: {
      title = getFailureReasonString(FAILURE_REASON.FETCH_FAILED);
      break;
    }
    case FAILURE_REASON.LOCATION_OFFLINE: {
      title = getFailureReasonString(FAILURE_REASON.LOCATION_OFFLINE);
      break;
    }
    case FAILURE_REASON.LOCATION_CLOSED: {
      title = getFailureReasonString(FAILURE_REASON.LOCATION_CLOSED);
      break;
    }
    case FAILURE_REASON.UNKNOWN:
    default:
      title = getFailureReasonString(FAILURE_REASON.UNKNOWN);
      subtitle = errorText;
  }

  if (subtitle?.toLowerCase().includes('coupon program has finished')) {
    subtitle = 'This coupon is not available at the new time selected';
  }

  enqueueErrorSnackbar(title + (subtitle ? '\n' + subtitle : ''));
}

export const customerConfirm = () => {
  const value = window.confirm(i18next.t('deliveryTimeUpdateMessage'));

  if (value === true) {
    enqueueSuccessSnackbar(i18next.t('snackbarMessages.deliveryTimeUpdate'));

    return true;
  } else {
    enqueueSuccessSnackbar(i18next.t('snackbarMessages.deliveryTimeUpdate'));

    return false;
  }
};
