import { combineReducers, Reducer, AnyAction } from 'redux';

import apiHandlerReady from './apiHandlerReady';
import attempts from './attempts';
import brands from './brands';
import cardsets from './cardsets';
import choiceSetQuantityDiscount from './choiceSetQuantityDiscount';
import couponOrderingMethod from './couponOrderingMethod';
import eftpos from './eftpos';
import enableDineInNameMode from './enableDineInNameMode';
import enableNonMemberPhoneNumberRequired from './enableNonMemberPhoneNumberRequired';
import enabledPaymentMethods from './enabledPaymentMethods';
import enableGuestOrdering from './enableGuestOrdering';
import enabledSaleTypes from './enabledSaleTypes';
import enableDuplicateSaleProtection from './enableDuplicateSaleProtection';
import enableDynamicPaymentGatewayConfig from './enableDynamicPaymentGatewayConfig';
import enableMultipleDeliveryEstimate from './enableMultipleDeliveryEstimate';
import excludedChoiceSets from './excludedChoiceSets';
import flattenCategories from './flattenCategories';
import choiceOrderingMethod from './choiceOrderingMethod';
import freeChoiceOrderingMethod from './freeChoiceOrderingMethod';
import importedGiftCardRegex from './importedGiftCardRegex';
import internationalMobileAllowed from './internationalMobileAllowed';
import loyaltyRequiresVerification from './loyaltyRequiresVerification';
import loyaltyPointsName from './loyaltyPointsName';
import menuSource from './menuSource';
import merchant from './merchant';
import orderingProvider from './orderingProvider';
import orderingWindowPadding from './orderingWindowPadding';
import paymentEnvironment from './paymentEnvironment';
import printerless from './printerless';
import product from './product';
import rememberCreditCard from './rememberCreditCard';
import requiredGuestPurchaserDetails from './requiredGuestPurchaserDetails';
import requiredPurchaserDetails from './requiredPurchaserDetails';
import saleInputType from './saleInputType';
import showHiddenLocations from './showHiddenLocations';
import thresholdOrderingProviders from './thresholdOrderingProviders';
import enablePayLater from './enablePayLater';
import payLaterRules from './payLaterRules';
import hideNegativeIngredients from './hideNegativeIngredients';
import stockBalanceThresholds from './stockBalanceThresholds';
import stockBalanceIgnoreThreshold from './stockBalanceIgnoreThreshold';
import mobileRegexWhiteList from './mobileRegexWhitelist';
import futureOrderingLimit from './futureOrderingLimit';
import enableFutureOrdering from './enableFutureOrdering';
import futureOrderingLimitCatering from './futureOrderingLimitCatering';
import includeDiscountMinSpend from './includeDiscountMinSpend';
import localiseOrderTimes from './localiseOrderTimes';
import showExpiredCoupons from './showExpiredCoupons';
import showRedeemedCoupons from './showRedeemedCoupons';
import showUnstartedCoupons from './showUnstartedCoupons';
import tagDisplayType from './tagDisplayType';
import energyDisplayTypes from './energyDisplayTypes';
import includeCouponDiscountSurcharge from './includeCouponDiscountSurcharge';
import currencySymbol from './currencySymbol';
import orderingCurrency from './orderingCurrency';
import taxName from './taxName';
import countryCode from './countryCode';
import paymentCountryCode from './paymentCountryCode';
import deviceId from './deviceId';
import disableAllBrandsMenuTab from './disableAllBrandsMenuTab';
import language from './language';
import upsellDisplayLimit from './upsellDisplayLimit';
import enableUpsellByItem from './enableUpsellByItem';
import enableUpsellByCheckout from './enableUpsellByCheckout';
import upsellItemHideGrey from './upsellItemHideGrey';
import upsellTitle from './upsellTitle';
import upsellDefaultTitle from './upsellDefaultTitle';
import resetEnableUpsellByCheckout from './resetEnableUpsellByCheckout';
import hideUnavailableItem from './hideUnavailableItem';
import dayPartMenu from './dayPartMenu';
import oAuthDetails from './oAuthDetails';
import enableStripeCvcRevalidation from './enableStripeCvcRevalidation';
import enableAdvancedCustomHours from './enableAdvancedCustomHours';
import disableTableNumberSaleBody from './disableTableNumberSaleBody';
import verificationAttempts from './verificationAttempts';
import enableReCaptcha from './enableReCaptcha';
import enableReCaptchaForForgetPassword from './enableReCaptchaForForgetPassword';
import enableRecaptchaResendVerification from './enableRecaptchaResendVerification';
import enableRecaptchaUserLogin from './enableRecaptchaUserLogin';

// @ts-ignore
const rootReducer: Reducer<Config, AnyAction> = combineReducers({
  enableReCaptcha,
  enableRecaptchaResendVerification,
  enableReCaptchaForForgetPassword,
  enableRecaptchaUserLogin,
  oAuthDetails,
  dayPartMenu,
  tagDisplayType,
  energyDisplayTypes,
  showExpiredCoupons,
  showRedeemedCoupons,
  showUnstartedCoupons,
  futureOrderingLimitCatering,
  mobileRegexWhiteList,
  apiHandlerReady,
  attempts,
  brands,
  cardsets,
  choiceSetQuantityDiscount,
  couponOrderingMethod,
  eftpos,
  enableDineInNameMode,
  enableNonMemberPhoneNumberRequired,
  enabledPaymentMethods,
  enableGuestOrdering,
  enabledSaleTypes,
  enableDuplicateSaleProtection,
  enableDynamicPaymentGatewayConfig,
  enableMultipleDeliveryEstimate,
  excludedChoiceSets,
  flattenCategories,
  choiceOrderingMethod,
  freeChoiceOrderingMethod,
  importedGiftCardRegex,
  internationalMobileAllowed,
  loyaltyRequiresVerification,
  loyaltyPointsName,
  menuSource,
  merchant,
  orderingProvider,
  orderingWindowPadding,
  paymentEnvironment,
  printerless,
  product,
  rememberCreditCard,
  requiredGuestPurchaserDetails,
  requiredPurchaserDetails,
  saleInputType,
  showHiddenLocations,
  thresholdOrderingProviders,
  enablePayLater,
  payLaterRules,
  hideNegativeIngredients,
  stockBalanceThresholds,
  stockBalanceIgnoreThreshold,
  futureOrderingLimit,
  enableFutureOrdering,
  includeDiscountMinSpend,
  localiseOrderTimes,
  includeCouponDiscountSurcharge,
  currencySymbol,
  orderingCurrency,
  taxName,
  countryCode,
  paymentCountryCode,
  deviceId,
  disableAllBrandsMenuTab,
  language,
  upsellDisplayLimit,
  enableUpsellByItem,
  enableUpsellByCheckout,
  upsellItemHideGrey,
  upsellTitle,
  upsellDefaultTitle,
  resetEnableUpsellByCheckout,
  hideUnavailableItem,
  enableStripeCvcRevalidation,
  enableAdvancedCustomHours,
  disableTableNumberSaleBody,
  verificationAttempts,
});

export default rootReducer;
