import React from 'react';
import { styled, Switch as MaterialSwitch, SwitchProps } from '@mui/material';
import { useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';

type InputProps = {
  primaryColor: string;
};

const MUISwitch = styled(MaterialSwitch)<InputProps>(({ primaryColor }) => ({
  height: 26,
  width: 47,
  padding: 0,
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#DFDFDF',
    opacity: 1,
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 20,
    height: 20,
    margin: 1,
  },
  '& .Mui-checked': {
    color: `${primaryColor} !important`,
  },
  '& .Mui-checked + .MuiSwitch-track': {
    backgroundColor: `${primaryColor} !important`,
  },
}));

const Switch = (props: SwitchProps) => {
  const p = useAppSelector(getThemeLookup);

  return (
    <MUISwitch
      primaryColor={p('switchChecked', ['backgroundColor']).backgroundColor || ''}
      {...props}
    />
  );
};

export default Switch;
