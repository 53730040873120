import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import TouchableOpacity from '../TouchableOpacity';
import combineStyles from '../../utils/combineStyles';
import { IoClose } from 'react-icons/io5';

const CloseIcon = ({
  onClick,
  iconSize = 25,
  boxSize,
  style,
}: {
  onClick: () => void;
  iconSize?: string | number;
  boxSize?: string | number;
  style?: CSSProperties;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <TouchableOpacity
      style={combineStyles(
        {
          // background: '#0f05',
          right: 0,
          textAlign: 'center',
          fontSize: iconSize,
        },
        boxSize
          ? {
              minWidth: boxSize,
              aspectRatio: 1,
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }
          : {},
        style,
      )}
      onClick={onClick}
    >
      <IoClose />
    </TouchableOpacity>
  );
};

export default CloseIcon;
