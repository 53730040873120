import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import Warning from './Warning';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import getMinimumOrderAmountNotMet from '../selectors/getMinimumOrderAmountNotMet';
import getInvalidStagedPurchasePresent from '../selectors/getInvalidStagedPurchasePresent';
import getShowMinExcludesSurchargeMessage from '../selectors/getShowMinExcludesSurchargeMessage';
import getInvalidOfferPresent from '../selectors/getInvalidOfferPresent';

const { getSaleType, getStagedPurchases } = OrderingSelectors;

const Warnings: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const saleType = useAppSelector(getSaleType);
  const enableFraudPreventionMessage = useAppSelector(
    state => state.config.enableFraudPreventionMessage,
  );
  const isDelivery = saleType === SALE_TYPE.DELIVERY;
  const minimumOrderAmountNotMet = useAppSelector(getMinimumOrderAmountNotMet);
  const invalidStagedPurchasePresent = useAppSelector(getInvalidStagedPurchasePresent);
  const showMinExcludesSurchargeMessage = useAppSelector(getShowMinExcludesSurchargeMessage);
  const invalidOfferPresent = useAppSelector(getInvalidOfferPresent);
  const stagedPurchases = useAppSelector(getStagedPurchases);
  const cartIncludesAlcohol = stagedPurchases.filter(i => i.item.alcoholic).length > 0;

  const fraudPreventionMessage = enableFraudPreventionMessage
    ? isDelivery
      ? 'fraudPreventionMessage.delivery'
      : 'fraudPreventionMessage.collection'
    : '';

  return (
    <div>
      <Warning message={fraudPreventionMessage} visible={Boolean(enableFraudPreventionMessage)} />
      {/* <Warning
        message="minimumOrderWarningMessage"
        visible={minimumOrderAmountNotMet}
        showAlertIcon
      /> */}
      <Warning
        message="invalidStagedPurchasePresentMessage"
        visible={invalidStagedPurchasePresent}
        showAlertIcon
      />
      <Warning
        message="minimumExcludesSurchargeMessage"
        visible={showMinExcludesSurchargeMessage}
      />
      <Warning message="incompatibleOfferMessage" visible={invalidOfferPresent} showAlertIcon />
      <Warning message="checkoutItemHasAlcohol" visible={cartIncludesAlcohol} showAlertIcon />
    </div>
  );
};

export default Warnings;
