import { createAsyncThunk } from '@reduxjs/toolkit';
import { getChoiceSetDiscountConfig } from '../selectors/config';
import getItems from '../selectors/getItems';
import getUnenhancedOpenPurchase from '../selectors/getUnenhancedOpenPurchase';
import adjustChoiceSetMaxForQuantityDiscount from '../utils/ordering/adjustChoiceSetMaxForQuantityDiscount';
import adjustedSelections from '../utils/ordering/adjustedSelections';
import { getAllChoiceSetsUnderChoicesOfThisChoiceSet } from '../utils/ordering/adjustNestedChoiceSetsForQuantityDiscountV2';
import { getOpenPurchasePreviouslyStaged } from '../selectors';

export const adjustOpenPurchaseChoice = createAsyncThunk(
  'adjustOpenPurchaseChoice',
  async (
    {
      targetChoiceSetId,
      targetChoiceId,
      clear,
      decrease,
      parentChoiceSetId,
      currentItemId,
      clearParent = false,
      targetChoiceSetIdForModify,
    }: {
      targetChoiceSetId: string;
      targetChoiceId?: string;
      clear?: boolean;
      decrease?: boolean;
      parentChoiceSetId?: string;
      currentItemId?: string;
      clearParent?: boolean;
      targetChoiceSetIdForModify?: string;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const state = getState() as EntireFrontendState;
      const openPurchase = getUnenhancedOpenPurchase(state);
      const choiceSetDiscountConfig = getChoiceSetDiscountConfig(state);
      const previouslyStaged = getOpenPurchasePreviouslyStaged(state);
      if (!openPurchase) throw new Error('Open purchase not found.');

      let adjustedPurchase = null;

      const updateSelections = (
        targetChoiceSetMax?: number,
        targetChoiceSetIndividualMax?: number,
      ) => {
        return {
          ...openPurchase,
          choiceSelections: adjustedSelections(
            targetChoiceSetMax || undefined,
            targetChoiceSetIndividualMax || undefined,
            openPurchase.choiceSelections as ChoiceSelections,
            targetChoiceSetId,
            parentChoiceSetId,
            targetChoiceId!,
            choiceSetDiscountConfig,
            clear, // This one to remove the choice
            decrease, // This one to decrease the quantity of the choice
            clearParent,
            targetChoiceSetIdForModify,
            currentItemId,
            previouslyStaged,
          ),
        };
      };

      if ((clear || clearParent) && openPurchase.choiceSelections) {
        adjustedPurchase = updateSelections();
      } else {
        if (!targetChoiceId) {
          throw new Error("targetChoiceId required when 'clear' is false");
        }

        const items = getItems(state);
        const item = items?.[openPurchase.sizeId ?? openPurchase.itemId];
        if (!item) throw new Error('Item not found.');

        let allChoiceSets = item.choiceSets;

        // Handle nested choice sets
        if (parentChoiceSetId) {
          const parentChoiceSet = item.choiceSets.find(
            cs => cs.key === parentChoiceSetId,
          );
          allChoiceSets = parentChoiceSet
            ? getAllChoiceSetsUnderChoicesOfThisChoiceSet(parentChoiceSet)
            : [];
        }

        const targetChoiceSet = allChoiceSets.find(
          set => set.key === targetChoiceSetId,
        ) as ValidatedChoiceSet;
        if (!targetChoiceSet) throw new Error('Target choice set not found.');

        // Apply quantity discount if available
        if (choiceSetDiscountConfig) {
          const {
            choiceSetKey: discountChoiceSetId = '',
            tierSize: discountTierSize = 1,
          } = choiceSetDiscountConfig;
          adjustedPurchase = updateSelections(
            adjustChoiceSetMaxForQuantityDiscount(
              targetChoiceSet,
              discountChoiceSetId,
              discountTierSize,
            ).max,
            targetChoiceSet.individualMax,
          );
        } else {
          adjustedPurchase = updateSelections(
            targetChoiceSet.max,
            targetChoiceSet.individualMax,
          );
        }
      }

      return { purchase: adjustedPurchase };
    } catch (e) {
      console.error('Adjust open purchase choice failed', e);
      return rejectWithValue(e);
    }
  },
);
