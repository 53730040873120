import React, { useState, useEffect } from 'react';
import PaymentMethodProvider from './PaymentMethodProvider';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import Text from './Text';
import RedcatImage from './RedcatImage';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useFormikContext } from 'formik';
import { dollarsToCents } from '../libs/polygon-ordering/src/utils/misc';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import combineStyles from '../utils/combineStyles';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import getThemeLookup from '../selectors/getThemeLookup';

const { getStripeCurrency } = OrderingSelectors;

const ApplePayProvider = () => {
  const [applePayEnabled, setApplePayEnabled] = useState<boolean | null>(null);
  const stripe = useStripe();
  const elements = useElements();
  const { values } = useFormikContext<FormikFields>();
  const stripeCurrency = useAppSelector(getStripeCurrency);
  const p = useAppSelector(getThemeLookup);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    // From what I can tell, this payment request is only used to check if apple pay is enabled
    // PLEASE DO NOT TRY TO MAKE A PAYMENT USING THIS PAYMENT REQUEST AS YOU WILL PROBABLY DOUBLE CHARGE THE USER
    // In my opinion all of the payments code should be refactored so that this isn't confusing
    const pr = stripe.paymentRequest({
      country: 'AU', // TODO: fix to avoid stripe overseas fees
      currency: stripeCurrency,
      total: {
        label: 'Payment Total:',
        amount: dollarsToCents(values.Amount!) + Number(values.surcharge) || 0,
      },
    });

    pr.canMakePayment().then(result => {
      if (result?.applePay) {
        setApplePayEnabled(true);
      }
    });
  }, [stripe, elements]);

  return (
    <>
      {applePayEnabled && (
        <PaymentMethodProvider
          method={PAYMENT_METHOD.APPLE_PAY}
          right={
            <div style={styles.buttonContainer}>
              <div style={styles.markContainer}>
                <img
                  src="https://assets.redcatcloud.com.au/webordering/Apple_Pay_Mark_RGB_041619.svg"
                  style={combineStyles({ height: 30}, p('applePayLogo', [...TEXT_PROPERTIES, ...CONTAINER_PROPERTIES]))}
                />
              </div>
            </div>
          }
          subtitle={<Text themeKey="paymentMethodUnavailableReason">&nbsp;</Text>}
        />
      )}
    </>
  );
};

const styles: Styles = {
  buttonContainer: {
    position: 'relative',
  },
  markContainer: {
    position: 'relative',
    // top: -26,
    // left: 'auto',
    right: 4,
  },
};

export default ApplePayProvider;
