import React from 'react';
import { useTranslation } from 'react-i18next';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text'; // TODO: rework this to use Text component
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Col, Row } from 'reactstrap';
import TouchableOpacity from '../components/TouchableOpacity';
import combineStyles from '../utils/combineStyles';
import { setDesiredDeliveryTime } from '../slices/desiredDeliveryTime';
export const CONFIRM_CHANGES_MODAL_ID = 'CONFIRM_CHANGES_MODAL_ID';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

const { getPickupTime, getChangedTime, getSaleType } = OrderingSelectors;

const { removeStagedPurchase, setPickupTime } = OrderingOperations;

const ConfirmChangesModal = ({
  title,
  message,
  previousChangesTitle,
  previousChangesDescription,
  currentChangesTitle,
  currentChangesDescription,
  confirmChangesTitle,
  confirmChangesDescription,
  changesBreakdown,
  titleStyle,
  desktopMaxWidth = 600,
  desktopMinWidth = 600,
  handleClose,
}: {
  desktopMaxWidth: number;
  desktopMinWidth: number;
  title?: string;
  message?: string;
  previousChangesTitle?: string;
  previousChangesDescription?: string;
  currentChangesTitle?: string;
  currentChangesDescription?: string;
  confirmChangesTitle?: string;
  confirmChangesDescription?: string;
  changesBreakdown?: {
    id: string;
    name: string;
  }[];
  titleStyle?: React.CSSProperties;
  handleClose?: () => void;
  //   closeButtonStyle?: ReactCSSProperties;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalParams = useAppSelector(state => state.modalParams);
  const pickupTime = useAppSelector(getPickupTime);
  const changedTime = useAppSelector(getChangedTime);
  const saleType = useAppSelector(getSaleType);
  const desiredDeliveryTime = useAppSelector(state => state.desiredDeliveryTime);
  const deliveryTime = useAppSelector(state => state.ordering.currentOrder.deliveryTime);

  // if it has coupons then maybe we do a validate cart inside here
  return (
    <Modal
      title={title ? title : modalParams?.title}
      desktopMaxWidth={desktopMaxWidth}
      desktopMinWidth={desktopMinWidth}
      desktopContainerStyle={styles.modalDesktopContainer}
      // hideFloatingHeader
      titleStyle={titleStyle}
      handleClose={() => {
        saleType === SALE_TYPE.DELIVERY ?? dispatch(setDesiredDeliveryTime(changedTime! as string));
        dispatch(setCurrentModal(null));
      }}
      wrapperStyle={styles.wrapperStyle}
      mobileContainerStyle={styles.mobileContainerStyle}
    >
      {(modalParams?.message || message) && (
        <Text
          style={{ textAlign: 'center' }}
          block
          value={modalParams?.message ?? message}
          themeKey="confirmChangesModalMessage"
        />
      )}

      <Row style={{ padding: '0 30px' }}>
        <Col sm={{ size: 6 }}>
          <div style={window.matchMedia('(max-width: 768px)').matches ? { paddingTop: 30 } : {}}>
            <Text
              block
              value={
                previousChangesTitle?.toUpperCase() ??
                modalParams?.previousChangesTitle?.toUpperCase()
              }
              themeKey="confirmChangesPreviousChangesTitle"
            />
            <Text
              block
              style={window.matchMedia('(max-width: 768px)').matches ? { paddingLeft: 30 } : {}}
              value={
                previousChangesDescription ?? saleType === SALE_TYPE.DELIVERY
                  ? new Date(deliveryTime! as string).toLocaleString('en-AU', {
                      weekday: 'short', // e.g., "Tuesday"
                      day: 'numeric', // "3"
                      month: 'numeric', // "12"
                      hour: 'numeric', // e.g., "9 PM"
                      minute: '2-digit', // e.g., "35"
                      timeZoneName: 'short', // e.g., "AEDT"
                    })
                  : new Date(pickupTime!).toLocaleString('en-AU', {
                      weekday: 'short', // e.g., "Tuesday"
                      day: 'numeric', // "3"
                      month: 'numeric', // "12"
                      hour: 'numeric', // e.g., "9 PM"
                      minute: '2-digit', // e.g., "35"
                      timeZoneName: 'short', // e.g., "AEDT"
                    })
              }
              themeKey="confirmChangesPreviousChangesTime"
            />
          </div>
        </Col>
        <Col sm={{ size: 6 }}>
          <div style={window.matchMedia('(max-width: 768px)').matches ? { paddingTop: 30 } : {}}>
            <Text
              block
              value={
                currentChangesTitle?.toUpperCase() ??
                modalParams?.currentChangesTitle?.toUpperCase()
              }
              themeKey="confirmChangesCurrentChangesTitle"
            />
            <Text
              block
              style={window.matchMedia('(max-width: 768px)').matches ? { paddingLeft: 30 } : {}}
              value={
                currentChangesDescription ?? saleType === SALE_TYPE.DELIVERY
                  ? desiredDeliveryTime === 'ASAP'
                    ? 'ASAP'
                    : new Date(desiredDeliveryTime! as string).toLocaleString('en-AU', {
                        weekday: 'short', // e.g., "Tuesday"
                        day: 'numeric', // "3"
                        month: 'numeric', // "12"
                        hour: 'numeric', // e.g., "9 PM"
                        minute: '2-digit', // e.g., "35"
                        timeZoneName: 'short', // e.g., "AEDT"
                      })
                  : new Date(changedTime! as string).toLocaleString('en-AU', {
                      weekday: 'short', // e.g., "Tuesday"
                      day: 'numeric', // "3"
                      month: 'numeric', // "12"
                      hour: 'numeric', // e.g., "9 PM"
                      minute: '2-digit', // e.g., "35"
                      timeZoneName: 'short', // e.g., "AEDT"
                    })
              }
              themeKey="confirmChangesCurrentChangesTime"
            />
          </div>
        </Col>
      </Row>

      <Row style={{ padding: '0 30px' }}>
        <Text
          block
          value={
            confirmChangesTitle?.toUpperCase() ?? modalParams?.confirmChangesTitle?.toUpperCase()
          }
          themeKey="confirmChangesSubsectionTitle"
        />
        <Text
          block
          value={
            confirmChangesDescription ??
            modalParams?.confirmChangesDescription ??
            t('confirmChanges.confirmChangesSubsectionDescription', { purchase: 'item' })
          }
          themeKey="confirmChangesPreviousChangesTime"
        />
      </Row>

      <Row style={{ padding: '0 30px' }}>
        {modalParams?.changesBreakdown &&
          Array.isArray(modalParams?.changesBreakdown) &&
          modalParams?.changesBreakdown.map(item => (
            <Text
              style={window.matchMedia('(max-width: 768px)').matches ? { paddingLeft: 50 } : {}}
              block
              value={item.name}
              themeKey="changesBreakDown"
            />
          ))}
      </Row>

      <Row style={{ padding: '50px 30px 0 30px' }}>
        <StandardButton
          label={modalParams?.buttonText ?? t('button.confirm')}
          onClick={
            modalParams?.action ??
            (() => {
              modalParams?.changesBreakdown &&
                Array.isArray(modalParams?.changesBreakdown) &&
                modalParams?.changesBreakdown.map(item => dispatch(removeStagedPurchase(item.id)));
              saleType === SALE_TYPE.PICKUP ?? dispatch(setPickupTime(changedTime! as string));
              dispatch(setCurrentModal(null));
            })
          }
          themeKey="messageModalButton"
          style={{ marginTop: 'auto' }}
        />
      </Row>

      <TouchableOpacity
        style={{ padding: '0 30px', textAlign: 'center' }}
        onClick={() => {
          dispatch(setCurrentModal(null));
          saleType === SALE_TYPE.DELIVERY ??
            dispatch(setDesiredDeliveryTime(changedTime! as string));
        }}
        ariaLabel={'Back to previous page'}
      >
        <Text
          themeKey="confirmChangesRevertLink"
          value={t('confirmChanges.revertLink')}
          style={combineStyles(
            styles.label,
            // p('labelThemeKey', TEXT_PROPERTIES),
          )}
        />
      </TouchableOpacity>
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    gap: 25,
    paddingBottom: 40,
  },
  wrapperStyle: {
    height: '80%',
  },
  mobileContainerStyle: {
    paddingTop: 100,
    paddingLeft: 0,
    paddingRight: 0,
    height: '100%',
  },
};

export default ConfirmChangesModal;
