import React, { useState, useEffect } from 'react';
import PaymentMethodProvider from './PaymentMethodProvider';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import Text from './Text';
import RedcatImage from './RedcatImage';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useFormikContext } from 'formik';
import { dollarsToCents } from '../libs/polygon-ordering/src/utils/misc';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import { TEXT_PROPERTIES, CONTAINER_PROPERTIES } from '../utils/theme';

const { getStripeCurrency } = OrderingSelectors;

const GooglePayProvider = () => {
  const [googlePayEnabled, setGooglePayEnabled] = useState<boolean | null>(null);
  const stripe = useStripe();
  const elements = useElements();
  const { values } = useFormikContext<FormikFields>();
  const stripeCurrency = useAppSelector(getStripeCurrency);
  const p = useAppSelector(getThemeLookup);


  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    // From what I can tell, this payment request is only used to check if google pay is enabled
    // PLEASE DO NOT TRY TO MAKE A PAYMENT USING THIS PAYMENT REQUEST AS YOU WILL PROBABLY DOUBLE CHARGE THE USER
    // In my opinion all of the payments code should be refactored so that this isn't confusing
    const pr = stripe.paymentRequest({
      country: 'AU', // TODO: fix to avoid stripe overseas fees
      currency: stripeCurrency,
      total: {
        label: 'Payment Total:',
        amount: dollarsToCents(values.Amount!) + Number(values.surcharge) || 0,
      },
    });

    pr.canMakePayment().then(result => {
      if (result?.googlePay) {
        setGooglePayEnabled(true);
      }
    });
  }, [stripe, elements]);

  return (
    <>
      {googlePayEnabled && (
        <PaymentMethodProvider
          method={PAYMENT_METHOD.GOOGLE_PAY}
          right={
            <div style={styles.buttonContainer}>
              <div style={styles.markContainer}>
                <img
                  src={
                    'https://assets.redcatcloud.com.au/webordering/GPay_Acceptance_Mark_800.png'
                  }
                  style={combineStyles({ height: 30}, p('googlePayLogo', [...TEXT_PROPERTIES, ...CONTAINER_PROPERTIES]))}
                />
              </div>
            </div>
          }
          subtitle={<Text themeKey="paymentMethodUnavailableReason">&nbsp;</Text>}
        />
      )}
    </>
  );
};

const styles: Styles = {
  buttonContainer: {
    position: 'relative',
  },
  markContainer: {
    // position: 'absolute',
    // top: -32,
    // left: 'auto',
    // right: 0,
  },
};

export default GooglePayProvider;
