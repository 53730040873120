import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

export default function (saleType: SALE_TYPE) {
  const isPickupOrTakeAway =
    saleType === SALE_TYPE.PICKUP ||
    saleType === SALE_TYPE.TAKE_AWAY ||
    saleType === SALE_TYPE.CATERING;
  const isDelivery = saleType === SALE_TYPE.DELIVERY;
  const isDineIn = saleType === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER;

  return {
    isPickupOrTakeAway,
    isDelivery,
    isDineIn,
  };
}
