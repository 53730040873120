// Imports
import moment, { Moment } from 'moment-timezone';

// Return type of the following function calculateFutureDatedOrderingWindow
export interface IFutureOrderingData {
  earliestTime: string;
  latestTime: string;
  storeTimeSlots?: Array<{
    openingTime: string;
    closingTime: string;
  }>;
}

export interface IStoreTimeSlot {
  earliestTime: Moment | null;
  latestTime: Moment | null;
  storeTimeSlots?: Array<{
    openingTime: Moment | null;
    closingTime: Moment | null;
  }>;
}

// Public holiday key name in trading hours object
const PUBLIC_HOLIDAY = 'P. Holidays';

// Initial store utc which will be later replaced
let storeUtc = moment().format('Z');

// Unavailable slot
const unavailableSlot = {
  earliestTime: null,
  latestTime: null,
};

// Wrapper function to add/subtract/convert required time from data
export default function calculateFutureDatedOrderingWindow(
  pickupTime: string,
  location: POSLocation | POSLocationShort | undefined,
  averageOrderWaitTime: number = 0,
  openingPadding: number = 0,
  closingPadding: number = 0,
  currentSaleType?: SALE_TYPE,
): IFutureOrderingData {
  const { earliestTime, latestTime, storeTimeSlots } =
    calculateFutureOrderTimes(pickupTime, location, currentSaleType);

  if (!earliestTime || !latestTime) {
    return {
      earliestTime: moment().utcOffset(storeUtc).toISOString(true),
      latestTime: moment().utcOffset(storeUtc).toISOString(true),
    };
  }

  const result: IFutureOrderingData = {
    earliestTime: earliestTime
      .clone()
      .add(openingPadding, 'minute')
      .add(averageOrderWaitTime, 'minute')
      .toISOString(true),

    latestTime: latestTime
      .clone()
      .subtract(closingPadding, 'minute')
      .subtract(averageOrderWaitTime, 'minute')
      .toISOString(true),
  };

  // Checking if we have storeTimeSlots
  if (!storeTimeSlots || !storeTimeSlots.length) {
    return result;
  }

  const slots: Array<{
    openingTime: string;
    closingTime: string;
  }> = [];

  storeTimeSlots.forEach(slot => {
    if (slot.openingTime && slot.closingTime) {
      const adjustedSlotTiming = {
        openingTime: slot.openingTime,
        closingTime: slot.closingTime,
      };

      // Add padding and wait time
      adjustedSlotTiming.openingTime
        .add(openingPadding, 'minute')
        .add(averageOrderWaitTime, 'minute');

      // Subtract padding and wait time
      adjustedSlotTiming.closingTime
        .subtract(closingPadding, 'minute')
        .subtract(averageOrderWaitTime, 'minute');

      // Convert times to ISO string
      slots.push({
        openingTime: adjustedSlotTiming.openingTime.toISOString(true),
        closingTime: adjustedSlotTiming.closingTime.toISOString(true),
      });
    }
  });

  result.storeTimeSlots = slots;

  return result;
}

// Main function to calculate the future ordering times
const calculateFutureOrderTimes = (
  pickupTime: string,
  location: POSLocation | POSLocationShort | undefined,
  currentSaleType?: SALE_TYPE,
): IStoreTimeSlot => {
  // Destructuring
  let {
    tradingHours,
    customHours,
    advancedCustomHours,
    holidayHours,
    utcOffset,
  } = location as POSLocation;

  storeUtc = getTimeFromUTCOffset(utcOffset);
  const userPickupTime = moment(pickupTime).utcOffset(storeUtc);

  // Finding year, month, date and day from requested pickup time
  const pickupDay = userPickupTime.format('dddd');
  const pickupFormattedDate = userPickupTime.format('YYYY-MM-DD');

  // Modifying  the custom hours if we do have advanced custom hours
  if (advancedCustomHours?.length && currentSaleType) {
    // Finding advanced custom hour for pickup day
    const advancedCustomHoursForPickupDay = advancedCustomHours.filter(
      custom =>
        custom.AdjustedDate === pickupFormattedDate ||
        (custom.Dynamic && custom.Day === pickupDay),
    );

    if (advancedCustomHoursForPickupDay.length) {
      // Finding custom hour for all sale types
      // If sale type is null then that means the custom hours is same for all sale types
      const customHoursForAllSaleTypes = advancedCustomHoursForPickupDay.find(
        custom => custom.SaleType === null,
      );

      // Finding custom hour for current sale type
      const customHoursForCurrentSaleType =
        advancedCustomHoursForPickupDay.find(
          custom => custom.SaleType === currentSaleType,
        );

      // Storing the custom hour
      const customHoursForSaleType =
        customHoursForAllSaleTypes || customHoursForCurrentSaleType;

      // Formatting custom hours object with advanced custom hour data if exists
      if (customHoursForSaleType) {
        customHours = {
          [pickupFormattedDate]: {
            closed: customHoursForSaleType.Closed,
            ct: customHoursForSaleType.CloseTime,
            message: customHoursForSaleType.CustomerMessage,
            ot: customHoursForSaleType.OpenTime,
          },
        };
      }
    }
  }

  // Checking whether this pickup time falls under custom hours
  const hasCustomHours = pickupTimeIsOnCustomHours(
    userPickupTime,
    tradingHours,
    customHours,
    holidayHours,
  );

  if (hasCustomHours) return hasCustomHours;

  // Checking whether this pickup time falls under holiday hours
  const hasHoliday = pickupTimeIsOnHoliday(
    userPickupTime,
    tradingHours,
    customHours,
    holidayHours,
  );

  if (hasHoliday) return hasHoliday;

  // Finding pickup day trading hours
  const pickupDayTradingHours = tradingHours.find(th => th.name === pickupDay);

  // Checking whether store is closed on that day
  if (
    !pickupDayTradingHours ||
    pickupDayTradingHours.closed ||
    !pickupDayTradingHours.openingTime ||
    !pickupDayTradingHours.closingTime
  ) {
    return unavailableSlot;
  }

  // Initial result
  const result = {
    earliestTime: moment(
      `${pickupFormattedDate}T${pickupDayTradingHours.openingTime}`,
    ).utcOffset(storeUtc, true),
    latestTime: moment(
      `${pickupFormattedDate}T${pickupDayTradingHours.closingTime}`,
    ).utcOffset(storeUtc, true),
  };

  // Checking if store is open 24/7
  const isOpenAllDay = isStoreOpenAllDay(pickupDayTradingHours, userPickupTime);

  if (isOpenAllDay && isOpenAllDay.open) return isOpenAllDay.storeTimeData;

  // One day before pickup day INFO
  const { dayBeforePickupTime, dayBeforePickupHours } = getDayBeforePickupInfo(
    userPickupTime,
    tradingHours,
    customHours,
    holidayHours,
  );

  // Returning result if no info about day before pickup found
  if (
    !dayBeforePickupTime ||
    dayBeforePickupHours.closed ||
    !dayBeforePickupHours.openingTime ||
    !dayBeforePickupHours.closingTime
  ) {
    return result;
  }

  // Store time data
  const storeTimeData = handleStoreTimeSessions(
    userPickupTime,
    pickupDayTradingHours,
    dayBeforePickupTime,
    dayBeforePickupHours,
  );

  return storeTimeData;
};

// Checking custom hours for the store on given pickup time
const pickupTimeIsOnCustomHours = (
  pickupTime: Moment,
  tradingHours: TradingHours[],
  customHours: Record<string, CustomHours> | undefined,
  holidayHours: HolidayHours[] | undefined,
): IStoreTimeSlot | null => {
  // Checking whether customHours object has data in it or not
  if (customHours && Object.keys(customHours).length) {
    for (const [key, value] of Object.entries(customHours)) {
      // Looking for pickup date in custom hours object to see whether pickup day has custom hours set
      if (pickupTime.isSame(moment(key).utcOffset(storeUtc, true), 'days')) {
        // Returning null if custom opening and closing time is not there
        if (value.closed || !value.ot || !value.ct) {
          return unavailableSlot;
        }

        const earliestTime = moment(`${key}T${value.ot}`).utcOffset(
          storeUtc,
          true,
        );
        let latestTime = moment(`${key}T${value.ct}`).utcOffset(storeUtc, true);

        // Checking if closing time is after opening time
        if (latestTime.isSameOrBefore(earliestTime)) {
          // Modifying latest time to end of day
          latestTime = pickupTime.clone().endOf('day');
        }

        // Formatting for passing to the function isClosingOneDayAfter
        const pickuDayCustomHours: any = {};

        pickuDayCustomHours.openingTime = value.ot;
        pickuDayCustomHours.closingTime = value.ct;

        // One day before pickup day INFO
        const { dayBeforePickupTime, dayBeforePickupHours } =
          getDayBeforePickupInfo(
            pickupTime,
            tradingHours,
            customHours,
            holidayHours,
          );

        // Returning if no info about day before pickup found
        if (
          !dayBeforePickupTime ||
          dayBeforePickupHours.closed ||
          !dayBeforePickupHours.openingTime ||
          !dayBeforePickupHours.closingTime
        ) {
          return { earliestTime, latestTime };
        }

        // Store time data
        const storeTimeData = handleStoreTimeSessions(
          pickupTime,
          pickuDayCustomHours,
          dayBeforePickupTime,
          dayBeforePickupHours,
        );

        return storeTimeData;
      }
    }
  }

  return null;
};

// Checking holiday hours for the store on given pickup time
const pickupTimeIsOnHoliday = (
  pickupTime: Moment,
  tradingHours: TradingHours[],
  customHours: Record<string, CustomHours> | undefined,
  holidayHours: HolidayHours[] | undefined,
): IStoreTimeSlot | null => {
  // Checking whether holidayHours and tradingHours array has data
  if (holidayHours && holidayHours.length && tradingHours.length) {
    for (let i = 0; i < holidayHours.length; i++) {
      const holiday = holidayHours[i].Date;

      // Getting holiday date
      const year = String(pickupTime.year());
      const month = holiday.substring(2);
      const date = holiday.substring(0, 2);

      const holidayDate = year + '-' + month + '-' + date;

      // Checking whether pickup time day is the HOLIDAY
      if (
        pickupTime.isSame(moment(holidayDate).utcOffset(storeUtc, true), 'date')
      ) {
        // Finding Public Holiday Trading time
        const tradingHour = tradingHours.find(th => th.name === PUBLIC_HOLIDAY);

        if (
          tradingHour &&
          !tradingHour.closed &&
          tradingHour.openingTime &&
          tradingHour.closingTime
        ) {
          const earliestTime = moment(
            `${holidayDate}T${tradingHour.openingTime}`,
          ).utcOffset(storeUtc, true);

          let latestTime = moment(
            `${holidayDate}T${tradingHour.closingTime}`,
          ).utcOffset(storeUtc, true);

          // Checking if closing time is after opening time
          if (latestTime.isSameOrBefore(earliestTime)) {
            // Modifying latest time to end of day
            latestTime = pickupTime.clone().endOf('day');
          }

          // One day before pickup day INFO
          const { dayBeforePickupTime, dayBeforePickupHours } =
            getDayBeforePickupInfo(
              pickupTime,
              tradingHours,
              customHours,
              holidayHours,
            );

          // Returning if no info about day before pickup found
          if (
            !dayBeforePickupTime ||
            dayBeforePickupHours.closed ||
            !dayBeforePickupHours.openingTime ||
            !dayBeforePickupHours.closingTime
          ) {
            return { earliestTime, latestTime };
          }

          // Store time data
          const storeTimeData = handleStoreTimeSessions(
            pickupTime,
            tradingHour,
            dayBeforePickupTime,
            dayBeforePickupHours,
          );

          return storeTimeData;
        }

        return unavailableSlot;
      }
    }
  }

  return null;
};

// Checking if store is open 24/7
const isStoreOpenAllDay = (
  pickupDayTradingHours: TradingHours,
  pickupTime: Moment,
): { open: boolean; storeTimeData: IStoreTimeSlot } | null => {
  // Store open 24/7
  let isOpen = false;

  const pickupDate = pickupTime.format('YYYY-MM-DD');

  let openingTime = moment(
    `${pickupDate}T${pickupDayTradingHours.openingTime}`,
  ).utcOffset(storeUtc, true);
  let closingTime = moment(
    `${pickupDate}T${pickupDayTradingHours.closingTime}`,
  ).utcOffset(storeUtc, true);

  // Checking if subtracting one minute from opening time makes opening time equal to closing time if that's the case then the store is open for 24 hours
  const oneMinuteMinusOpeningTime = openingTime.clone().subtract(1, 'minutes');
  if (oneMinuteMinusOpeningTime.isSame(closingTime)) {
    openingTime = pickupTime.clone().startOf('day');
    closingTime = pickupTime.clone().endOf('day');

    // Making it true as store is open 24/7
    isOpen = true;
  }

  return {
    open: isOpen,
    storeTimeData: {
      earliestTime: openingTime,
      latestTime: closingTime,
    },
  };
};

// Handling store time sessions
const handleStoreTimeSessions = (
  pickupTime: Moment,
  pickupDayTradingHours: TradingHours,
  dayBeforePickupTime: Moment,
  dayBeforePickupTradingHours: TradingHours,
): IStoreTimeSlot => {
  /* This is because let's say yesterday the store closed at 2:20am which means actually today 
  so today will have two sessions one starting at 00:00am to 02:20 am another session will be
  today's store opening time to today's store closing time if today's store closing time also 
  moves to tomorrow then today's closing time will be 11:59.
  */

  const storeTimeSlots = [];

  const pickupDate = pickupTime.format('YYYY-MM-DD');

  let openingTime = moment(
    `${pickupDate}T${pickupDayTradingHours.openingTime}`,
  ).utcOffset(storeUtc, true);
  let closingTime = moment(
    `${pickupDate}T${pickupDayTradingHours.closingTime}`,
  ).utcOffset(storeUtc, true);

  // Checking if closing time is before opening time
  if (closingTime.isBefore(openingTime)) {
    // Modified closing time
    closingTime = pickupTime.clone().endOf('day');
  }

  const result: IStoreTimeSlot = {
    earliestTime: openingTime,
    latestTime: closingTime,
  };

  // Day before pickup
  const dayBeforePickupYear = dayBeforePickupTime.year();
  const dayBeforePickupMonth = dayBeforePickupTime.month() + 1;
  const dayBeforePickupDate = dayBeforePickupTime.date();

  let dayBeforePickupOpeningTime = moment(
    `${dayBeforePickupYear}-${dayBeforePickupMonth}-${dayBeforePickupDate} ${dayBeforePickupTradingHours.openingTime}`,
    'YYYY-MM-DD HH:mm',
  ).utcOffset(storeUtc, true);
  let dayBeforePickupClosingTime = moment(
    `${dayBeforePickupYear}-${dayBeforePickupMonth}-${dayBeforePickupDate} ${dayBeforePickupTradingHours.closingTime}`,
    'YYYY-MM-DD HH:mm',
  ).utcOffset(storeUtc, true);

  // Checking if day before pickup closing time is BEFORE day before pickup opening time
  if (dayBeforePickupClosingTime.isBefore(dayBeforePickupOpeningTime)) {
    // Adding 1 day to day before closing time as it passed 11:59
    dayBeforePickupClosingTime.add(1, 'days');

    // Pickup day first time slot be
    const pickupDayFirstSlot = {
      openingTime: pickupTime.clone().startOf('day'),
      closingTime: dayBeforePickupClosingTime,
    };

    // Pickup day second slot
    const pickupDaySecondSlot = {
      openingTime,
      closingTime,
    };

    result.earliestTime = pickupDayFirstSlot.openingTime;
    result.latestTime = pickupDaySecondSlot.closingTime;

    storeTimeSlots.push(pickupDayFirstSlot);
    storeTimeSlots.push(pickupDaySecondSlot);
  }

  if (storeTimeSlots.length) {
    result.storeTimeSlots = storeTimeSlots;
  }

  return result;
};

const getDayBeforePickupInfo = (
  pickupTime: Moment,
  tradingHours: TradingHours[],
  customHours: Record<string, CustomHours> | undefined,
  holidayHours: HolidayHours[] | undefined,
): {
  dayBeforePickupTime: Moment;
  dayBeforePickupHours: TradingHours;
} => {
  const dayBeforePickupHours: any = {};

  // One day before pickup day
  const dayBeforePickupTime = pickupTime.clone().subtract(1, 'days');

  const dayBeforePickupFormatted = dayBeforePickupTime.format('YYYY-MM-DD');

  const dayBeforePickupTradingHours = tradingHours.find(
    th => th.name === dayBeforePickupTime.format('dddd'),
  )!;

  dayBeforePickupHours.openingTime = dayBeforePickupTradingHours.openingTime;
  dayBeforePickupHours.closingTime = dayBeforePickupTradingHours.closingTime;

  // Checking if day before pickup day had custom hours
  if (customHours && customHours.hasOwnProperty(dayBeforePickupFormatted)) {
    dayBeforePickupHours.openingTime = customHours[dayBeforePickupFormatted].ot;
    dayBeforePickupHours.closingTime = customHours[dayBeforePickupFormatted].ct;

    if (customHours[dayBeforePickupFormatted].closed) {
      dayBeforePickupHours.closingTime = dayBeforePickupHours.openingTime;
    }
  }
  // Checking if day before pickup day had holiday
  else if (holidayHours && holidayHours.length) {
    for (let i = 0; i < holidayHours.length; i++) {
      const holiday = holidayHours[i].Date;

      // Getting holiday date
      const year = dayBeforePickupTime.year();
      const month = Number(holiday.substring(2));
      const date = Number(holiday.substring(0, 2));

      const holidayDate = year + '-' + month + '-' + date;

      if (
        dayBeforePickupTime.isSame(
          moment(holidayDate).utcOffset(storeUtc, true),
        )
      ) {
        // Finding Public Holiday Trading time
        const tradingHour = tradingHours.find(th => th.name === PUBLIC_HOLIDAY);

        if (tradingHour && tradingHour.openingTime && tradingHour.closingTime) {
          dayBeforePickupHours.openingTime = moment(
            `${holidayDate}T${tradingHour.openingTime}`,
          ).utcOffset(storeUtc, true);

          dayBeforePickupHours.closingTime = moment(
            `${holidayDate}T${tradingHour.closingTime}`,
          ).utcOffset(storeUtc, true);

          if (tradingHour.closed) {
            dayBeforePickupHours.closingTime = dayBeforePickupHours.openingTime;
          }
        }

        break;
      }
    }
  }

  return {
    dayBeforePickupTime,
    dayBeforePickupHours,
  };
};

/* 
Helper function to find utc off set string from utcoffset seconds 
like if you pass 34200 to this function which is adelaide offset 
this function will return +09:30
*/

export const getTimeFromUTCOffset = (utcOffset: number) => {
  // Calculate the total offset in hours (including minutes)
  const totalOffsetHours = utcOffset / 3600;

  // Calculate the hours and minutes
  const hours = Math.floor(totalOffsetHours);
  const minutes = Math.floor((totalOffsetHours - hours) * 60);

  // Determine the sign of the offset
  const sign = totalOffsetHours >= 0 ? '+' : '-';

  // Format the time string
  const formattedTime = `${sign}${Math.abs(hours)
    .toString()
    .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  return formattedTime;
};
