import React, { useState, useContext } from 'react';
import Text from './Text';
import NestedChoiceSet from './NestedChoiceSet';
import {
  NestedChoiceSetsContext,
  INestedChoiceSetsContext,
  calculateChoiceSetPrice,
  isPurchasedChoiceSet,
} from './NestedChoiceSetsContext';
import combineStyles from '../utils/combineStyles';
import { useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import TouchableOpacity from './TouchableOpacity';
import RadioCheck from './RadioCheck';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import {
  OrderingHooks,
  OrderingSelectors,
  calculateChoiceSelectionsTotals,
} from 'polygon-ordering';

const { useFormattedCurrency } = OrderingHooks;
const { getChoiceSetDiscountConfig } = OrderingSelectors;

export default function NestedChoiceSets({
  choiceSets,
  adjustOpenPurchaseChoice,
  rootItem,
  setImage,
  onModify,
  onClick,
}: {
  choiceSets: ValidatedChoiceSet[];
  adjustOpenPurchaseChoice: (params: AdjustChoice) => void;
  hideNegativeIngredients: boolean;
  rootItem: Item;
  setImage: (imageurl?: string) => void;
  onModify: () => void;
  onClick: () => void;
}): JSX.Element | null {
  const { showNestedItem } = useContext(NestedChoiceSetsContext) as INestedChoiceSetsContext;
  const [selectedChoiceSet, setSelectedChoiceSet] = useState<ValidatedChoiceSet | null>(null);
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);

  if (!choiceSets.filter(set => set.displayType !== 'checkbox').length) return null;
  return (
    <React.Fragment>
      {!showNestedItem && (
        <div
          style={combineStyles(styles.listContainer, {
            padding: isMobileDevice ? '0px 10px 5px' : '0px 20px 20px',
            gap: isMobileDevice ? 5 : 10,
          })}
        >
          {choiceSets
            .filter(set => set.displayType !== 'checkbox')
            .map((choiceSet, index) => (
              <ChoiceSet
                choiceSets={choiceSets}
                choiceSet={choiceSet}
                onClick={onClick}
                setSelectedChoiceSet={newSet => setSelectedChoiceSet(newSet)}
                index={index}
                key={index}
              />
            ))}
        </div>
      )}
      {showNestedItem && selectedChoiceSet && (
        <NestedChoiceSet
          adjustChoice={adjustOpenPurchaseChoice}
          setImage={setImage}
          rootItem={rootItem}
          choiceSet={selectedChoiceSet}
          onModify={onModify}
        />
      )}
    </React.Fragment>
  );
}

const ChoiceSet = ({
  choiceSet,
  choiceSets,
  onClick,
  setSelectedChoiceSet,
  index,
}: {
  choiceSet: ValidatedChoiceSet;
  choiceSets: ValidatedChoiceSet[];
  setSelectedChoiceSet: (set: ValidatedChoiceSet) => void;
  onClick: () => void;
  index: number;
}) => {
  const choiceSetDiscountConfig = useAppSelector(getChoiceSetDiscountConfig);
  const { setShowNestedItem, stages, setStages, purchased, nestedChoiceSetsTotal } = useContext(
    NestedChoiceSetsContext,
  ) as INestedChoiceSetsContext;
  const choiceOrderingMethod = useAppSelector(state => state.ordering.config.choiceOrderingMethod);
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);
  const p = useAppSelector(getThemeLookup);

  const selected = isPurchasedChoiceSet(purchased, choiceSet.key, stages);
  let label = choiceSet.name;
  let choicesetPrice = 0;

  if (selected) {
    // choicesetPrice = calculateChoiceSetPrice(
    //   purchased ? purchased[choiceSet.key] : {},
    //   choiceSet,
    //   'nestedIngredients' in choiceSet,
    //   choiceOrderingMethod || 'cheapest',
    //   choiceSetDiscountConfig,
    // );

    choicesetPrice = calculateChoiceSelectionsTotals(
      purchased,
      [choiceSet],
      choiceOrderingMethod || 'cheapest',
      choiceSetDiscountConfig,
    )?.moneyPrice;

    const { choices: nestedItems } = choiceSet;
    for (let n of nestedItems) {
      const choiceSetSelections = purchased![choiceSet.key];
      if (
        n.id in choiceSetSelections ||
        (choiceSetSelections instanceof Array &&
          !!choiceSetSelections.find(co => co.id === n.id) &&
          choiceSetSelections.length === 1)
      ) {
        label += ` - ${n.name}`;
        break;
      }
    }
  }

  const price = useFormattedCurrency({ cents: choicesetPrice });
  const checkSize = isMobileDevice ? 30 : 35;

  const handleClick = (id: number) => {
    if ('nestedIngredients' in choiceSets[id]) setStages({ ...stages, '0': choiceSets[id].key });
    else setStages({ '0': choiceSets[id].key, '1': { [choiceSets[id].key]: [] } });
    setShowNestedItem(true);
    setSelectedChoiceSet(choiceSets[id]);
    onClick();
  };

  return (
    <TouchableOpacity
      style={combineStyles(
        styles.nestedChoiceSet,
        {
          padding: isMobileDevice ? '25px 20px' : 30,
        },
        p('defaultBorder', ['border']),
      )}
      key={index}
      onClick={() => handleClick(index)}
    >
      <div style={styles.leftContainer}>
        {selected ? (
          <RadioCheck size={checkSize} checked />
        ) : (
          <Text
            style={combineStyles(
              styles.choiceSetBullet,
              p('choiceSetCheckbox', ['backgroundColor']),
              p('choiceSetCheckbox', ['color']),
              { minWidth: checkSize, minHeight: checkSize },
            )}
          >
            {(index + 1).toString()}
          </Text>
        )}
        {label}
      </div>
      {choicesetPrice > 0 ? (
        <Text themeKey="menuItemPrice" style={styles.price}>
          {`+${price}`}
        </Text>
      ) : (
        ''
      )}
    </TouchableOpacity>
  );
};

const styles: Styles = {
  choiceSetBullet: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'left',
    borderRadius: '50%',
    textAlign: 'center',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  nestedChoiceSet: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 15,
  },
  price: {
    justifySelf: 'flex-end',
  },
};
