import { createAsyncThunk } from '@reduxjs/toolkit';
import { FAILURE_REASON } from '../constants';
import { setSaleDetails } from '../reducers/currentOrder/saleDetails';
import { getSelectedPaymentMethods } from '../selectors';
import Api, {
  ApiResponse,
  AuthenticationMethod,
  FetchParams,
} from '../utils/Api';
import { getOrderingProvider } from '../selectors/config';
import determineSaleCharged from '../utils/determineSaleCharged';
import processSale from '../utils/processors/processSale';
import { constructPaymentRequest } from './constructPaymentRequest';
import { duplicateSaleProtection } from './duplicateSaleProtection';
import { PAYMENT_METHOD } from '../constants/paymentMethod';
import { ORDERING_PROVIDER } from '../constants/orderingProvider';
import Logger from '../utils/Logger';
import { getEftposConfig } from '../selectors/config';
import getCurrentOrder from '../selectors/getCurrentOrder';
import moment from 'moment';

export const sale = createAsyncThunk(
  '$sale',
  async (
    data: {
      route: 'topup' | 'checkout';
      authenticationMethod?: AuthenticationMethod;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    const { route, authenticationMethod } = data;

    let rawSale: RawSale | undefined;
    let charged: any;
    let initialResponse: any;
    let reason: FAILURE_REASON | undefined;

    const subPayments = getSelectedPaymentMethods(
      getState() as EntireFrontendState,
    );

    try {
      const { body, path } = await dispatch(
        constructPaymentRequest({ route, authenticationMethod }),
      ).unwrap();

      const orderingProvider = getOrderingProvider(
        getState() as EntireFrontendState,
      );
      const eftposConfig = getEftposConfig(getState() as EntireFrontendState);
      const currentLocationId = getCurrentOrder(
        getState() as EntireFrontendState,
      ).locationId;

      Logger.log('-----  ----- Initiate Sale Body and Path ----- ----', 'info');
      Logger.log(JSON.stringify({ body, path }), 'info');
      Logger.log('----- ----- ----- ----- ----- -----', 'info');

      if (orderingProvider === ORDERING_PROVIDER.KIOSK) {
        Logger.logUpstream({
          DeviceID: eftposConfig.deviceId as string,
          StoreID: currentLocationId!,
          Action: 'SUBMIT_SALE',
          DateTime: new Date().toLocaleString('en', {
            timeZone: 'Australia/Sydney',
          }),
          Data: {
            Endpoint: path,
            SentBody: body,
            ResponseCode: null,
            ResponseBody: null,
            ResponseTime: null,
          },
        });
      }

      const timeStart = moment();

      const response = (await Api.fetch(
        {
          method: 'POST',
          body,
          path,
          applicationErrorAllowed: true,
        },
        authenticationMethod,
      )) as ApiResponse;

      const timeEnd = moment();
      const responseTime = timeEnd.diff(timeStart);

      Logger.log('-----  ----- Sale Response ----- ----', 'info');
      Logger.log(JSON.stringify({ body, path }), 'info');
      Logger.log(JSON.stringify(response), 'info');
      Logger.log('----- ----- ----- ----- ----- -----', 'info');

      if (orderingProvider === ORDERING_PROVIDER.KIOSK) {
        Logger.logUpstream({
          DeviceID: eftposConfig.deviceId as string,
          StoreID: currentLocationId!,
          Action: 'SUBMIT_SALE',
          DateTime: new Date().toLocaleString('en', {
            timeZone: 'Australia/Sydney',
          }),
          Data: {
            Endpoint: path,
            SentBody: body,
            ResponseCode: response && 200,
            ResponseBody: response,
            ResponseTime: responseTime,
          },
        });
      }

      initialResponse = response;

      // The custom store hours need to be validated from the BE
      if (response.success) {
        if (route === 'checkout') {
          rawSale = response.data;

          if (!rawSale) {
            throw new Error('missing sale details');
          }

          const processedSale = processSale(rawSale);

          console.log({ processedSale });
          //set sale details || why is this undefined for kiosk??
          dispatch(setSaleDetails(processedSale));
        }
      } else {
        Logger.log('-----  ----- Sale xx FAIL xx Response ----- ----', 'info');
        Logger.log(JSON.stringify(response), 'info');
        Logger.log('----- ----- ----- ----- ----- -----', 'info');

        if (response.error_code === 206) {
          return response;
        }
        if (route === 'checkout') {
          const orderingProvider = getOrderingProvider(
            getState() as EntireFrontendState,
          );
          if (orderingProvider === ORDERING_PROVIDER.KIOSK) {
            throw new Error(response.error);
          }
          //duplicate sale protection
          const saleProtection = await dispatch(
            duplicateSaleProtection({ authenticationMethod }),
          ).unwrap();

          if (saleProtection?.charged) {
            charged = saleProtection.charged;
          }
        }
        throw new Error(response.error);
      }

      return response;
    } catch (e) {
      console.log({ e });
      if ((e as any).message === 'Verify PIN does not match') {
        reason = FAILURE_REASON.BAD_GIFT_CARD;
      }

      const chargeStarted = subPayments?.some(subPayment => {
        if (PRE_CHARGE_METHODS.includes(subPayment.method)) {
          return true;
        }
      });

      const wasEftpos = subPayments?.some(subPayment => {
        if (PAYMENT_METHOD.EFTPOS === (subPayment.method as any)) {
          return true;
        }
      });

      if (charged !== false) {
        charged = determineSaleCharged(initialResponse);
      } else if (wasEftpos) {
        charged = true;
      }

      Logger.log(
        '==> @RedcatApiHandler: Something may have gone wrong',
        'info',
      );
      Logger.log(
        JSON.stringify({
          e,
          charged,
          chargeStarted,
          reason: reason ? reason : (e as any).message,
        }),
        'info',
      );

      return rejectWithValue({
        e,
        charged,
        chargeStarted,
        reason: reason ? reason : (e as any).message,
      });
    }
  },
);

//3ds required
export const finaliseSale = createAsyncThunk(
  '$finaliseSale',
  async (
    data: {
      additional_data: any;
      authenticationMethod?: AuthenticationMethod;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { additional_data, authenticationMethod = 'none' } = data;
      const packet: FetchParams = {
        path: {
          trusted: '/api/v1/authsale2/finalise',
          member: '/api/v1/sale/finalise',
          none: '/api/v1/sale/finalise/nonmember',
        }[authenticationMethod],
        method: 'POST',
        body: {
          ...additional_data,
        },
      };
      const response = await Api.fetch(packet, authenticationMethod);
      const processedSale = processSale(response.data);
      dispatch(setSaleDetails(processedSale));

      return response.data;
    } catch (e) {
      return rejectWithValue({
        e,
        charged: false,
        chargeStarted: true,
        reason: FAILURE_REASON.PAYMENT_AUTHENTICATION_FAILED,
      });
    }
  },
);

const PRE_CHARGE_METHODS = [
  PAYMENT_METHOD.APPLE_PAY,
  PAYMENT_METHOD.GOOGLE_PAY,
  PAYMENT_METHOD.CREDIT_CARD,
  PAYMENT_METHOD.EFTPOS,
];
