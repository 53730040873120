import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { Row, Col } from 'reactstrap';
import StandardButton from './StandardButton';
import { styled, createTheme, ThemeProvider } from '@mui/system';
import getThemeLookup from '../selectors/getThemeLookup';

const badgePadding = 2;
const BadgeOptionRow = styled(Row)(({ theme }) => ({
  borderRadius: '2.5em',
  border: theme.palette.toggleBorder || '1px solid #DFDFDF',
  padding: badgePadding,
  position: 'relative',
  background: 'transparent',
  display: 'flex',
  transition: '0.25s all ease-out',

  '&:hover': {
    background: '#EBEBEB',
  },

  '& > .col': {
    zIndex: '2',
  },

  // toggle part
  '&::after': {
    content: '" "',
    position: 'absolute',
    top: 0,
    left: 0,
    margin: badgePadding,
    height: `calc(100% - ${badgePadding * 2}px)`,
    background: theme.palette.toggleColor,
    width: `calc(50% - ${badgePadding}px)`,
    transform: 'translateX(100%)',
    borderRadius: 20,
    transition: '0.45s all',
    transitionTimingFunction: 'cubic-bezier(0.84, 0.11, 0.12, 0.94)',
  },

  '&.active::after': {
    transform: 'translateX(0%)',
  },
}));

const BadgeOptions = ({
  active,
  setActive,
  activeKey,
  inactiveKey,
}: {
  active: boolean;
  setActive: (active: boolean) => void;
  activeKey: string;
  inactiveKey: string;
}) => {
  const { t } = useTranslation();

  // Overiding the colors using the config, this will be passed into MUI's styled
  const p = useAppSelector(getThemeLookup);
  const theme = createTheme({
    palette: {
      toggleColor: p('badgeOptionActive', ['backgroundColor']).backgroundColor,
      toggleBorder: p('locationListToggle', ['border']).border,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BadgeOptionRow className={`text-center mx-0 ${active ? 'active' : ''}`}>
        <Col className="px-0">
          <StandardButton
            onClick={() => setActive(!active)}
            themeKey={active ? 'badgeOptionActive' : 'badgeOptionDefault'}
            label={t(activeKey)}
            style={styles.badgeButtons}
            labelStyle={styles.badgeButtonLabels}
          />
        </Col>
        <Col className="px-0">
          <StandardButton
            onClick={() => setActive(!active)}
            themeKey={!active ? 'badgeOptionActive' : 'badgeOptionDefault'}
            label={t(inactiveKey)}
            style={styles.badgeButtons}
            labelStyle={styles.badgeButtonLabels}
          />
        </Col>
      </BadgeOptionRow>
    </ThemeProvider>
  );
};

export default BadgeOptions;

const styles: Styles = {
  badgeButtons: {
    backgroundColor: 'transparent',
    //opacity: 0.3,
    transition: '0.4s all ease-in',
    height: '2.5em',
  },
  badgeButtonLabels: {
    transition: '0.4s all ease-in',
    transitionDelay: '0.1s',
  },
};
