import combineAddressParts from '../ordering/combineAddressParts';
import { SALE_TYPE } from '../../constants/saleType';
import { processAdvancedCustomHours } from './processAdvancedCustomHours';

export default function processLocationShort(
  rawLocation: RawLocationShort,
  advancedCustomHours: Array<AdvancedCustomHours> | null,
  deliveryTimeOpen: string | null,
  deliveryTimeClose: string | null,
): POSLocationShort {
  // Finding custom hours for this location
  // Checking store id === null because store id null means it applies to all stores
  const advancedCustomHoursForThisStore = advancedCustomHours
    ? advancedCustomHours.filter(
        custom =>
          custom.StoreID === Number(rawLocation.StoreID) ||
          custom.StoreID === null,
      )
    : null;

  // Getting the custom data modified
  const availableSaleTypesToday = processAdvancedCustomHours(
    rawLocation.OpeningTimeToday,
    rawLocation.ClosingTimeToday,
    deliveryTimeOpen,
    deliveryTimeClose,
    Object.keys(rawLocation?.SaleTypes),
    advancedCustomHoursForThisStore,
  );

  const brandIds = (rawLocation.VirtualBrands || []).map(String);
  let physicalBrand;

  if (rawLocation.BrandID != null) {
    physicalBrand = rawLocation.BrandID;
    brandIds.unshift(rawLocation.BrandID);
  }

  return {
    id: rawLocation.StoreID,
    name: rawLocation.LocationName,
    openNow: rawLocation.OpenNow,
    latitude: rawLocation.Latitude,
    longitude: rawLocation.Longitude,
    status: rawLocation.StoreStatus,
    phone: rawLocation.Phone,
    online: rawLocation.StoreStatus === 'Online',
    availableSaleTypesToday,
    supportedSaleTypes: Object.entries(rawLocation.SaleTypes ?? {}).reduce(
      (sum, [saleType, isAvailable]) => {
        isAvailable && sum.push(parseInt(saleType));
        return sum;
      },
      [] as SALE_TYPE[],
    ),
    hidden: false,

    address: {
      address1: rawLocation.Address1,
      address2: rawLocation.Address2,
      postCode: rawLocation.PostCode,
      state: rawLocation.State,
      suburb: rawLocation.Suburb,
      country: rawLocation.Country,
      combinedShort: combineAddressParts([
        rawLocation.Address1,
        rawLocation.Address2,
        rawLocation.Suburb,
      ]),
      combinedLong: combineAddressParts([
        rawLocation.Address1,
        rawLocation.Address2,
        rawLocation.Suburb,
        rawLocation.State,
        rawLocation.PostCode,
      ]),
    },

    brandIds,
    physicalBrand,
    averageOrderWaitTime: rawLocation.AvgOrderTime || 0,
    outOfSessionOrderingEnabled: Boolean(rawLocation.OrderAfterHours) ?? true,

    _isFullData: false,
  };
}
