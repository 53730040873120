import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../app/hooks';
import TouchableOpacity from './TouchableOpacity';
import Text from './Text';
import { useFormikContext } from 'formik';
import { setMapCenter } from '../slices/mapCenter';
import { useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { setDesiredDeliveryAddress } from '../slices/desiredDeliveryAddress';

const AddressItem = ({ location }: { location: GoogleMapsLocation }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<DeliveryDetailsModalForm>();
  const p = useAppSelector(getThemeLookup);

  return (
    <TouchableOpacity
      onClick={() => {
        setFieldValue('formatted_address', location.formatted_address);
        setFieldValue('street_name', location.street_name);
        setFieldValue('locality', location.locality);
        setFieldValue('lat', location.lat);
        setFieldValue('lng', location.lng);
        setFieldValue('buttonActive', true);
        dispatch(
          setMapCenter({
            latitude: location.lat,
            longitude: location.lng,
          }),
        );

        dispatch(
          setDesiredDeliveryAddress({
            formatted_address: location.formatted_address,
            street_name: location.street_name,
            locality: location.locality,
            lat: Number(location.lat),
            lng: Number(location.lng),
          }),
        );
      }}
      style={{
        border: '1px solid',
        borderRadius: 5,
        marginTop: 10,
        marginBottom: 10,
        ...p('defaultBorder', ['borderColor']),
        ...(values.formatted_address == location.formatted_address
          ? p('locationItemSelected', ['backgroundColor'])
          : {}),
      }}
    >
      <div className="p-3">
        <Text style={{ fontWeight: 'bold', fontSize: 14 }}>{location.street_name}</Text> <br />
        <Text style={{ fontSize: 12 }}>{location.locality}</Text>
      </div>
    </TouchableOpacity>
  );
};

export default AddressItem;
