import React from 'react';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import Text from '../components/Text';
import ReorderButton from '../components/ReorderButton';
import { AiTwotoneHeart } from 'react-icons/ai';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import TouchableOpacity from '../components/TouchableOpacity';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
import getProfile from '../selectors/getProfile';

export const FAVOURITES_SCREEN_ROUTE = '/favourites';

const { getFavouriteOrders } = OrderingSelectors;
const { deleteFavouriteOrder } = OrderingOperations;

const FavouriteOrder: React.FC<{ order: FavouriteOrder }> = ({ order }) => {
  const isOnlyLoyaltyEnabled = useAppSelector(state => state.config.enableOnlyLoyalty);
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();

  return (
    <div className="p-4" style={{ border: p('cardBorder', ['border']).border, borderRadius: 5 }}>
      <div style={styles.orderHeadingWrapper}>
        <div style={styles.orderHeadingText}>
          <Text themeKey="favouriteOrderName" variant="h4" value={order.name} />

          <TouchableOpacity
            onClick={() =>
              dispatch(deleteFavouriteOrder({ id: order.id }))
                .unwrap()
                .then(() => logEvent(EVENTS.DELETE_FAVOURITE))
            }
            style={styles.unfavouriteButton}
          >
            <AiTwotoneHeart style={combineStyles(p('activeHeartIcon', ['color']))} />
          </TouchableOpacity>
        </div>
      </div>

      <Row>
        <Col xs="8">
          <div>
            <div>
              {order.items.map((item, index) => (
                <div key={index}>
                  <Text themeKey="loyaltyOrderItems" variant="body" value={item.name} />{' '}
                  <Text
                    themeKey="loyaltyOrderItems"
                    variant="body"
                    value={'x' + item.quantity.toString()}
                  />
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col
          xs="4"
          className="position-relative"
          style={{
            paddingTop: '2em',
            minHeight: 70,
          }}
        >
          {!isOnlyLoyaltyEnabled && (
            <div className="position-absolute" style={{ bottom: 0, right: 10 }}>
              <ReorderButton themeKey="quickReorderButton" order={order} />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

const FavouritesScreen = () => {
  const favouriteOrders = useAppSelector(getFavouriteOrders);
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();
  const profile = useAppSelector(getProfile);

  return (
    <div>
      {favouriteOrders?.map(order => (
        <FavouriteOrder order={order} />
      ))}
    </div>
  );
};

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  orderHeadingText: {
    display: 'flex',
    gap: '0.5em',
    justifyContent: 'space-between',
  },
  unfavouriteButton: {
    fontSize: 32,
    display: 'flex',
  },
};

export default FavouritesScreen;
