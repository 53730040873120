import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import StandardButton, { IProps as StandButtonProps } from '../components/StandardButton';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getProfile from '../selectors/getProfile';
import { sendVerificationMedium } from '../thunks/sendVerificationMedium';
import combineStyles from '../utils/combineStyles';
import { enqueueErrorSnackbar } from '../utils/snackbar';

interface IProps extends StandButtonProps {
  containerStyle?: React.CSSProperties;
  disableSpinner?: boolean;
}

const SendVerificationButton: React.FC<IProps> = ({
  containerStyle,
  label = 'Resend Verification',
  disabled: disabledProp,
  onClick,
  disableSpinner,
  ...rest
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(getProfile);
  const [isSending, setIsSending] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const reCaptchaSiteKey = useAppSelector(state => state.config.reCaptchaSiteKey);
  const [reCaptchaToken, setReCaptchaToken] = useState<null | string>(null);
  const enableReCaptcha = useAppSelector(state => state.ordering.config.enableReCaptcha);
  const enableRecaptchaResendVerification = useAppSelector(
    state => state.ordering.config.enableRecaptchaResendVerification,
  );
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const verificationPrompt = (token?: string | null) => {
    if (onClick) onClick();
    setIsSending(true);
    dispatch(sendVerificationMedium(token || null))
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        setIsSending(false); // Reset the flag
        setReCaptchaToken(null); // Clear the token
      });
  };

  return (
    <div>
      <div
        style={combineStyles(
          {
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
          },
          containerStyle,
        )}
      >
        {!profile?.verified && (
          <StandardButton
            disabled={
              enableReCaptcha && reCaptchaToken == null && showCaptcha
                ? true
                : isSending || disabledProp
            }
            onClick={() => {
              if (enableReCaptcha && enableRecaptchaResendVerification) {
                if (!showCaptcha) {
                  setShowCaptcha(true);
                  return;
                }
              } else verificationPrompt();
            }}
            themeKey="transparentButton"
            containerStyle={styles.button}
            label={label}
            showSpinner={isSending && !disableSpinner}
            {...rest}
          />
        )}
      </div>
      <div
        style={combineStyles({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          marginTop: 15,
          justifyContent: 'center',
        })}
      >
        {!profile?.verified &&
          enableReCaptcha &&
          !reCaptchaToken &&
          showCaptcha &&
          enableRecaptchaResendVerification && (
            <ReCAPTCHA
              sitekey={reCaptchaSiteKey!}
              onChange={token => {
                setReCaptchaToken(token);
                setShowCaptcha(false);
                verificationPrompt(token);
              }}
              onErrored={() => {
                setShowCaptcha(false);
                setReCaptchaToken(null);
                enqueueErrorSnackbar(t('problemWithReCaptchaMessage'));
              }}
              size={deviceTypeMobile ? 'compact' : 'normal'}
            />
          )}
      </div>
    </div>
  );
};
const styles = {
  button: {
    borderRadius: 5,
  },
};

export default SendVerificationButton;
