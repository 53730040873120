// Imports
import moment from 'moment-timezone';
import { safeDate } from '../misc';
import { getTimeFromUTCOffset } from '../ordering/calculateFutureDatedOrderingWindow';
import { SALE_TYPE } from '../../constants/saleType';

export const processAdvancedCustomHours = (
  OpeningTimeToday: string | undefined,
  ClosingTimeToday: string | undefined,
  deliveryTimeOpen: string | null,
  deliveryTimeClose: string | null,
  SaleTypes: string[],
  customHoursForThisLocation: Array<AdvancedCustomHours> | null | undefined,
  utcoffset?: number,
) => {
  //   Store utc
  const storeUtc = utcoffset
    ? getTimeFromUTCOffset(utcoffset)
    : moment().format('Z');

  // Initial opening and closing time
  let openingTime = safeDate(OpeningTimeToday, storeUtc);
  let closingTime = safeDate(ClosingTimeToday, storeUtc);

  let deliveryOpeningTime = deliveryTimeOpen
    ? safeDate(deliveryTimeOpen, storeUtc)
    : openingTime;
  let deliveryClosingTime = deliveryTimeClose
    ? safeDate(deliveryTimeClose, storeUtc)
    : closingTime;

  const availableSaleTypesToday: any = {};

  // Initialisation
  SaleTypes.forEach(saleType => {
    const ot =
      Number(saleType) === SALE_TYPE.DELIVERY
        ? deliveryOpeningTime
        : openingTime;
    const ct =
      Number(saleType) === SALE_TYPE.DELIVERY
        ? deliveryClosingTime
        : closingTime;

    // Checking whether user is between ordering window
    const isBetweenOrderingWindow = moment().isBetween(moment(ot), moment(ct));

    availableSaleTypesToday[saleType] = {
      isAvailable:
        Number(saleType) === SALE_TYPE.CATERING
          ? true
          : isBetweenOrderingWindow,
      openingTime: ot,
      closingTime: ct,
    };
  });

  // Returning if there is not advanced custom hours
  if (!customHoursForThisLocation) {
    return availableSaleTypesToday;
  }

  // The day when user is ordering
  const today = moment(openingTime).utcOffset(storeUtc).format('YYYY-MM-DD');
  const todaysWeekDay = moment(openingTime).utcOffset(storeUtc).format('dddd');

  // Todays custom hours
  const todaysCustomHours = customHoursForThisLocation.filter(
    custom =>
      custom.AdjustedDate === today ||
      (custom.Dynamic && custom.Day === todaysWeekDay),
  );

  // Returning is there is no custom hours set for the day when user is ordering
  if (!todaysCustomHours) {
    return availableSaleTypesToday;
  }

  // Finding custom hours for all sale types
  // If sale type is null then that means the custom hours is same for all sale types
  const customHoursForAllSaleTypes = todaysCustomHours.find(
    custom => custom.SaleType === null,
  );

  if (customHoursForAllSaleTypes) {
    // Creating time
    openingTime = moment(`${today}T${customHoursForAllSaleTypes.OpenTime}`)
      .utcOffset(storeUtc, true)
      .toISOString(true);
    closingTime = moment(`${today}T${customHoursForAllSaleTypes.CloseTime}`)
      .utcOffset(storeUtc, true)
      .toISOString(true);

    SaleTypes.forEach(saleType => {
      // Checking whether user is between ordering window
      const isBetweenOrderingWindow = moment().isBetween(
        moment(openingTime),
        moment(closingTime),
      );

      availableSaleTypesToday[saleType] = {
        isAvailable:
          !customHoursForAllSaleTypes.Closed && isBetweenOrderingWindow,
        openingTime,
        closingTime,
      };
    });

    return availableSaleTypesToday;
  }

  // This means custom hours is set different for sale types so for example pickup and takeaways custom hours are not same
  todaysCustomHours.forEach(customHour => {
    // Creating time
    openingTime = moment(`${today}T${customHour.OpenTime}`)
      .utcOffset(storeUtc, true)
      .toISOString(true);
    closingTime = moment(`${today}T${customHour.CloseTime}`)
      .utcOffset(storeUtc, true)
      .toISOString(true);

    // Checking whether user is between ordering window
    const isBetweenOrderingWindow = moment().isBetween(
      moment(openingTime),
      moment(closingTime),
    );

    if (customHour.SaleType) {
      availableSaleTypesToday[String(customHour.SaleType)] = {
        isAvailable: !customHour.Closed && isBetweenOrderingWindow,
        openingTime,
        closingTime,
      };
    }
  });

  return availableSaleTypesToday;
};
