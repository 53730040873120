import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as any;

const slice = createSlice({
  name: '$advancedCustomHours',
  initialState,
  reducers: {
    setAdvancedCustomHours(
      state,
      action: PayloadAction<Array<AdvancedCustomHours>>,
    ) {
      return action.payload;
    },
  },
});

export const { setAdvancedCustomHours } = slice.actions;
export default slice.reducer;
