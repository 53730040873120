import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as lodash from 'lodash';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import { TEXT_PROPERTIES } from '../utils/theme';
import { OrderingSelectors, adjustedNestedSelections, OrderingHooks } from 'polygon-ordering';

import Text from './Text';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { enqueueWarningSnackbar } from '../utils/snackbar';
import {
  isPurchasedChoiceSet,
  NestedChoiceSetsContext,
  INestedChoiceSetsContext,
  NestedIngredientsStages,
  isChoiceInStagedPurchase,
} from './NestedChoiceSetsContext';
import QuantityControl from './QuantityControl';
import { TFunction } from 'i18next';
import CheckBox from './CheckBox';
import RadioCheck from './RadioCheck';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import Energy from './Energy';
import TouchableOpacity from './TouchableOpacity';
import RedcatImage from './RedcatImage';

const {
  getChoiceSetByKey,
  getStagedPurchases,
  getStockBalances,
  getOpenPurchasePreviouslyStaged,
  getOpenPurchase,
  getHideNegativeIngredients,
  getChoiceSetDiscountConfig,
} = OrderingSelectors;
const { useFormattedCurrency } = OrderingHooks;

const getChoiceSetIndividualQty = (
  choice: ChoiceWithQuantity,
  choiceSet: ValidatedChoiceSet,
  stages:
    | {
        [key: string]:
          | string
          | {
              [key: string]: ChoiceWithQuantity[];
            };
      }
    | undefined,
): number => {
  // console.log({ choice, choiceSet, stages });
  if (!stages) return 0;
  const steps = Object.keys(stages).length - 1;
  if (steps) {
    const choiceSelection = stages[steps.toString()] as { [key: string]: ChoiceWithQuantity[] };
    const setIndx = steps === 2 ? choiceSet.id : choiceSet.key;

    if (!choiceSelection[setIndx]) return 0;
    return choiceSelection[setIndx].filter(c => c.id === choice.id).length;
  }
  return 0;
};

const getChoiceSetQty = (
  choiceSet: ValidatedChoiceSet,
  stages:
    | {
        [key: string]:
          | string
          | {
              [key: string]: ChoiceWithQuantity[];
            };
      }
    | undefined,
): number => {
  if (!stages) return 0;
  const steps = Object.keys(stages).length - 1;
  if (steps) {
    const choiceSelection = stages[steps.toString()] as { [key: string]: ChoiceWithQuantity[] };
    const setIndx = steps === 2 ? choiceSet.id : choiceSet.key;

    if (!choiceSelection[setIndx]) return 0;
    return choiceSelection[setIndx].length;
  }
  return 0;
};

const getChoicePaidQuantity = (
  choice: ChoiceWithQuantity,
  choiceSet: ValidatedChoiceSet,
  stages:
    | {
        [key: string]:
          | string
          | {
              [key: string]: ChoiceWithQuantity[];
            };
      }
    | undefined,
): number => {
  if (!stages) return 0;

  const steps = Object.keys(stages).length - 1;
  if (steps) {
    const choiceSelection = stages[steps.toString()] as { [key: string]: ChoiceWithQuantity[] };
    const setIndx = steps === 2 ? choiceSet.id : choiceSet.key;

    const selections = choiceSelection[setIndx];
    if (!selections) return 0;

    // count only those selections after the free threshold cutoff
    return selections.filter((s, i) => s.id === choice.id && i >= choiceSet.free).length;
  }

  return 0;
};

const NestedChoiceSetTags: React.FC<{
  choiceSet: ValidatedChoiceSet;
  adjustChoice: (params: AdjustChoice) => void;
  collapsed: boolean;
  preSelect: SDict<ChoiceWithQuantity[]> | ChoiceWithQuantity[];
  onModify: () => void;
  stockBalanceDataMap?: SDict<StockBalanceData>;
  currentItem?: Item;
  tagChoiceSets: ValidatedChoiceSet[];
}> = ({
  choiceSet,
  adjustChoice,
  collapsed = false,
  preSelect,
  onModify,
  stockBalanceDataMap,
  currentItem,
  tagChoiceSets,
}) => {
  const { stages, setStages, purchased } = useContext(
    NestedChoiceSetsContext,
  ) as INestedChoiceSetsContext;
  const previouslyStaged = Boolean(useAppSelector(getOpenPurchasePreviouslyStaged));
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();
  const stagedPurchases = useAppSelector(getStagedPurchases);
  const openPurchase = useAppSelector(getOpenPurchase);
  const hideNegativeIngredients = useAppSelector(getHideNegativeIngredients);

  const choiceSetWithoutNegativePricedChoices = {
    ...choiceSet,
    choices: choiceSet.choices.filter((choice: Choice) => choice.baseMoneyPrice >= 0),
  };
  const [filteredChoiceSet, setFilteredChoiceSet] = useState(
    hideNegativeIngredients ? choiceSetWithoutNegativePricedChoices : choiceSet,
  );

  useEffect(() => {
    //pre selection
    const nested = Object.keys(stages || {}).length;
    const isPurchased = isPurchasedChoiceSet(purchased, undefined, stages);

    console.log({ isPurchased, preSelect, nested, stages });
    //@ts-ignore
    setStages((preStages: NestedIngredientsStages) => {
      const updated = {
        ...preStages,
        [nested - 1]:
          nested === 3
            ? {
                ...preSelect,
              }
            : { [stages!['0'] as string]: [...(preSelect as ChoiceWithQuantity[])] },
      };

      console.log({ updated });
      return updated as NestedIngredientsStages;
    });

    if (isPurchased) {
      if (stages) {
        const sets =
          nested === 3
            ? lodash.get(purchased, [stages[0] as string, stages[1] as string])
            : lodash.get(purchased, stages[0] as string);

        const updated2 = {
          ...stages,
          [nested - 1]: nested === 3 ? sets : { [stages['0'] as string]: sets },
        };

        console.log({ stages, sets, updated2 });
        setStages(updated2);
      }
    }
  }, [filteredChoiceSet]);

  const displayGrid = filteredChoiceSet.displayType === 'grid';

  return collapsed ? null : (
    <div style={{ ...styles.mainContainer, flexDirection: displayGrid ? 'row' : 'column' }}>
      {filteredChoiceSet.choices.map((choice: ChoiceWithQuantity) => (
        <Tag
          key={choice.id}
          choice={choice}
          choiceSet={filteredChoiceSet}
          adjustChoice={adjustChoice}
          previouslyStaged={previouslyStaged}
          t={t}
          p={p}
          stagedPurchases={stagedPurchases}
          stockBalanceData={(stockBalanceDataMap || {})[choice.plucode]}
          onModify={onModify}
          purchaseQuantity={openPurchase?.quantity || 1}
          currentItem={currentItem}
          actualChoiceSet={choiceSet}
          openPurchase={openPurchase}
          tagChoiceSets={tagChoiceSets}
        />
      ))}
    </div>
  );
};

//choice with check mark olo3.0
const Tag: React.FC<{
  choice: ChoiceWithQuantity;
  choiceSet: ValidatedChoiceSet;
  adjustChoice: (params: AdjustChoice) => void;
  previouslyStaged: boolean;
  t: TFunction;
  p: (key: string, properties: ThemeProperties) => React.CSSProperties;
  stagedPurchases: PurchaseWithTotals[];
  onModify: () => void;
  stockBalanceData: StockBalanceData | undefined;
  purchaseQuantity: number;
  currentItem?: Item;
  actualChoiceSet?: ValidatedChoiceSet;
  openPurchase: PurchaseWithTotals | undefined;
  tagChoiceSets: ValidatedChoiceSet[];
}> = ({
  choice,
  choiceSet,
  adjustChoice,
  previouslyStaged,
  t,
  p,
  stagedPurchases,
  onModify,
  stockBalanceData,
  purchaseQuantity = 1,
  currentItem,
  actualChoiceSet,
  openPurchase,
  tagChoiceSets,
}) => {
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);
  const { stages, setStages, dataForAdjust, setDataForAdjust, purchased } = useContext(
    NestedChoiceSetsContext,
  ) as INestedChoiceSetsContext;
  const choiceSetDiscountConfig = useAppSelector(getChoiceSetDiscountConfig);

  const nested = Object.keys(stages || {}).length - 1;
  const setIndx = nested === 2 ? choiceSet.id : choiceSet.key;
  const quantity = getChoiceSetIndividualQty(choice, choiceSet, stages);
  const setQuantity = getChoiceSetQty(choiceSet, stages);
  const rootChoiceSet = useAppSelector(getChoiceSetByKey(stages![0] as string));
  const dispatch = useAppDispatch();

  // const paidQuantity = getChoicePaidQuantity(choice, choiceSet, stages);

  // for some reason the minimum choiceset selection defaults to 0 when max is 1 sometimes?
  // Michael says required is not necessary and should be ignored, I ignored this advice
  const isRadioCheck = choiceSet.max === 1 && (choiceSet.min === 1 || choiceSet.required);

  // sometimes the individual max is set to 0 due to db changes during development
  // this should not happen anymore so ?? should be fine but in any case || works
  const effectiveIndividualMax = Math.min(
    choiceSet.individualMax || choiceSet.max || 1,
    choiceSet.max || 1,
  );
  const checkIfDisabled = stockBalanceData && stockBalanceData.soldOut;
  const [selected, setSelected] = useState(false);

  const showQuantityControl =
    selected &&
    !checkIfDisabled &&
    effectiveIndividualMax > 1 &&
    !(
      stockBalanceData &&
      stockBalanceData.cartAdjustedBalance !== undefined &&
      quantity * purchaseQuantity > stockBalanceData.cartAdjustedBalance
    );

  const isMaxQuantity = setQuantity === choiceSet.max || quantity === effectiveIndividualMax;

  const [isChoiceInPurchase] = isChoiceInStagedPurchase(
    choice.plucode,
    choiceSet.id,
    purchased,
    stages,
    Boolean(
      rootChoiceSet &&
        lodash.has(rootChoiceSet, 'nestedIngredients') &&
        (rootChoiceSet as NestedChoiceSet)['nestedIngredients'],
    ),
  );
  // modified from what was here before,
  // increase = true will increase the quantity if possible,
  // increase = false will decrease,
  // increase = undefined will toggle choice on/off
  const handleChoiceClick = (increase?: boolean) => {
    const preSelection = stages![nested] as SDict<ChoiceWithQuantity[]>;

    const quantityClicked = increase !== undefined;

    // complex logic, if you can understand this you deserve a medal
    // check git history this was once a large if statement
    const shouldClear = quantityClicked ? !increase && quantity === 1 : selected && !isRadioCheck;
    const shouldIncrease =
      ((increase === true && (!choiceSet.individualMax || quantity < choiceSet.individualMax)) ||
        (increase === undefined && quantity === 0) ||
        isRadioCheck) &&
      (!choiceSet.max || setQuantity < choiceSet.max || isRadioCheck);
    const noChange = !shouldClear && !quantityClicked && !shouldIncrease;

    if (
      !shouldClear &&
      shouldIncrease &&
      stockBalanceData &&
      stockBalanceData.cartAdjustedBalance !== undefined &&
      (quantity > 0 ? quantity * purchaseQuantity : purchaseQuantity) >
        stockBalanceData.cartAdjustedBalance &&
      !isChoiceInPurchase
    ) {
      enqueueWarningSnackbar(t('stagedChoiceSetItemQuantityWarningMessage'));
      return;
    }

    if (noChange) return;

    // so that the root level modal knows something has changed, this should really be in the context
    onModify();

    let selection = adjustedNestedSelections({
      max: choiceSet.max,
      individualMax: choiceSet.individualMax,
      previousChoiceSelections: preSelection,
      targetChoiceSetId: setIndx,
      targetChoice: choice,
      choiceSetDiscountConfig,
      decrease: !shouldIncrease,
      choiceSet: actualChoiceSet,
    });

    setDataForAdjust([
      ...dataForAdjust,
      {
        targetChoiceSetId: choiceSet.key,
        targetChoiceId: choice.id,
        clear: shouldClear,
        decrease: shouldClear ? false : !shouldIncrease,
        parentChoiceSetId: choiceSet.key !== rootChoiceSet?.key ? rootChoiceSet?.key : undefined,
        currentItemId: currentItem?.id,
        targetChoiceSetIdForModify: choiceSet?.id,
      },
    ]);

    if (shouldClear) {
      const newChoices = ((stages![nested] as SDict<ChoiceWithQuantity[]>)[setIndx] || []).filter(
        c => c.id !== choice.id,
      );
      const newStages: NestedIngredientsStages = lodash.cloneDeep(stages);
      (newStages![nested] as SDict<ChoiceWithQuantity[]>)[setIndx] = newChoices;
      setStages({ ...newStages });
    } else {
      setStages({ ...stages, [nested]: selection });
    }
  };

  const baseTextSize = { fontSize: isMobileDevice ? 14 : 16 };

  const baseTagStyle = combineStyles(
    p('tagButton', TEXT_PROPERTIES),
    Boolean(choice.quantity) && p('selectedTagButton', TEXT_PROPERTIES),
  );

  const primaryTagStyle = combineStyles(
    baseTagStyle,
    Boolean(choice.quantity) && {
      textShadow: `0px 0px 1px ${baseTagStyle.color || 'black'}`,
    },
  );

  const emphasised = { fontWeight: '900' }; // maximum

  const hiddenTagStyle = combineStyles(baseTagStyle, emphasised, { color: 'transparent' });

  const labelBase = String(choice.name);

  // const revertSoldOutThresholdToLowInStock =
  //   stockBalanceData.stockBalanceThreshold === 'STOCK_BALANCE_THRESHOLD_0' &&
  //   stockBalanceData.itemBalance !== 0
  //     ? 'STOCK_BALANCE_THRESHOLD_1'
  //     : stockBalanceData.stockBalanceThreshold;

  const thresholdLabelColor = combineStyles(
    stockBalanceData && stockBalanceData.stockBalanceThreshold
      ? p(stockBalanceData.stockBalanceThreshold!, ['color'])
      : '',
    Boolean(choice.quantity) && p('selectedTagButton', TEXT_PROPERTIES),
  );

  const priceShowing =
    (choice.baseMoneyPrice &&
      (!choiceSet.free ||
        ((!choiceSet.max || choiceSet.max > choiceSet.free) && setQuantity > choiceSet.free)) &&
      choice.baseMoneyPrice) ||
    0;

  const price = useFormattedCurrency({ cents: priceShowing });

  // NOTE: all this comlication is to ensure that the tag doesn't resize
  // as you select it/increase the quantity.

  // This is done by having hidden text that is approximately the same size,
  // which is removed when the quantity is > 0
  const displayGrid = choiceSet.displayType === 'grid';
  let label = (
    <div
      style={{
        // backgroundColor: '#00fa',
        ...(!displayGrid ? { display: 'flex', flexDirection: 'row', gap: 15 } : {}),
        ...baseTextSize,
      }}
    >
      <Text
        // style={combineStyles(
        //   primaryTagStyle,
        //   Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
        // )}
        style={baseTextSize}
      >
        {labelBase}
      </Text>
      {stockBalanceData && stockBalanceData.stockBalanceThreshold && (
        <Text style={combineStyles(primaryTagStyle, emphasised, thresholdLabelColor)}>
          {' - ' + t('stockBalanceThreshold.' + stockBalanceData.stockBalanceThreshold)}
        </Text>
      )}
      {!displayGrid && !!priceShowing && <Text themeKey="purchaseEditorText">{`+ ${price}`}</Text>}
    </div>
  );

  useEffect(() => {
    if (quantity < 1) {
      setSelected(false);
    } else {
      if (!selected) setSelected(true);
    }
  }, [quantity]);

  return nested === 1 ? (
    <ChoiceWithImage
      choice={choice}
      p={p}
      t={t}
      stockBalanceData={stockBalanceData}
      selected={selected}
      checkIfDisabled={Boolean(checkIfDisabled)}
      handleChoiceClick={handleChoiceClick}
      isMobileDevice={isMobileDevice}
      priceShowing={priceShowing}
    />
  ) : (
    <div
      style={{
        ...styles.tag,
        flexDirection: displayGrid ? 'column' : 'row',
        justifyContent: displayGrid ? 'flex-start' : 'space-between',
      }}
    >
      <div style={{ ...styles.tagLeft, flexDirection: displayGrid ? 'column' : 'row' }}>
        {isRadioCheck ? (
          <RadioCheck
            label={label}
            checked={Boolean(quantity)}
            onClick={() => handleChoiceClick()}
            choice={choice}
            displayType={choiceSet.displayType}
            disabled={Boolean(checkIfDisabled)}
            price={priceShowing}
          />
        ) : (
          <CheckBox
            choice={choice}
            label={label}
            checked={selected}
            onClick={() => handleChoiceClick()}
            displayType={choiceSet.displayType}
            disabled={Boolean(checkIfDisabled)}
            price={priceShowing}
          />
        )}
      </div>

      <div>
        {showQuantityControl ? (
          <QuantityControl
            quantity={quantity}
            containerStyle={{ gap: isMobileDevice ? 36 : 38 }}
            iconContainerStyle={{}}
            textStyle={baseTextSize}
            increaseQty={() => handleChoiceClick(true)}
            decreaseQty={() => handleChoiceClick(false)}
            increaseEnabled={!isMaxQuantity}
            maxQuantity={stockBalanceData?.maxAvailableQuantityChoice}
            minQuantity={0}
            buttonSize={isMobileDevice ? 25 : 30}
            containerStopPropogation
          />
        ) : choice.kilojoules ? (
          <Energy kilojouleCount={choice.kilojoules} />
        ) : null}
      </div>
    </div>
  );
};

const ChoiceWithImage: React.FC<{
  choice: ChoiceWithQuantity;
  stockBalanceData: StockBalanceData | undefined;
  p: (key: string, properties: ThemeProperties) => React.CSSProperties;
  t: TFunction;
  selected: boolean;
  isMobileDevice: boolean;
  checkIfDisabled: boolean;
  handleChoiceClick: () => void;
  priceShowing: number;
}> = ({
  choice,
  stockBalanceData,
  p,
  t,
  selected,
  isMobileDevice,
  checkIfDisabled,
  handleChoiceClick,
  priceShowing,
}) => {
  const price = useFormattedCurrency({ cents: priceShowing });
  return (
    <TouchableOpacity
      style={combineStyles(
        styles.nestedItem,
        p(selected ? 'selected' : 'defaultBorder', ['border']),
        {
          padding: isMobileDevice ? 10 : 15,
          gap: isMobileDevice ? 20 : 30,
          position: 'relative',
        },
        checkIfDisabled && { opacity: 0.7 },
      )}
      onClick={() => handleChoiceClick()}
      disabled={checkIfDisabled || false}
    >
      <RedcatImage
        alt={choice.name}
        imagePath={choice?.images?.default}
        size={60}
        containerStyle={combineStyles(
          styles.imageContainer,
          isMobileDevice ? { width: 108, height: 90 } : { width: 120, height: 100 },
        )}
        imageStyle={{ width: '100%', height: '100%', borderRadius: 10 }}
      />
      <div style={styles.choiceLabel}>
        {choice.name}
        {!!priceShowing && <Text style={{ textAlign: 'right' }}>{` + ${price}`}</Text>}
      </div>

      <div style={styles.buttonContainer}>{selected ? <RadioCheck size={40} checked /> : ''}</div>
      <div style={combineStyles(styles.stock, { width: isMobileDevice ? 108 : 120 })}>
        {stockBalanceData && stockBalanceData.stockBalanceThreshold && (
          <Text themeKey={stockBalanceData.stockBalanceThreshold} style={styles.stockBalanceText}>
            {t('stockBalanceThreshold.' + stockBalanceData.stockBalanceThreshold)}
          </Text>
        )}
      </div>
    </TouchableOpacity>
  );
};

const styles: Styles = {
  stock: {
    position: 'absolute',
    backgroundColor: 'white',
    textAlign: 'center',
  },
  choiceLabel: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  buttonContainer: {
    display: 'flex',
    padding: 15,
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: 60,
  },
  nestedItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    padding: 15,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px',
    gap: 10,
    flexWrap: 'wrap',
  },
  tag: {
    position: 'relative',
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 15,
  },

  clearButtonContainer: {
    position: 'absolute',
    borderRadius: 9999,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ChoiceSetTagOpacity: {
    // background: 'rgba(255, 255, 9, 0.3)',
    opacity: 0.5,
  },
  tagLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    gap: 15,
  },
};

export default NestedChoiceSetTags;
