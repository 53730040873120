export default function translateSubstitutionSelectionsBetweenItems(
  previousSelections: SubstitutionSelections,
  previousItem: Item | undefined,
  nextItem: Item,
): SubstitutionSelections {
  const nextSelections: SubstitutionSelections = {};

  nextItem?.substitutionSets.forEach(nextSet => {
    nextSelections[nextSet.key] = nextSet.targetId;
  });

  if (!previousSelections) return nextSelections;

  Object.keys(previousSelections).forEach(currentSetKey => {
    const previousSet = previousItem?.substitutionSets.find(
      set => set.key === currentSetKey,
    );

    const matchingSet =
      nextItem?.substitutionSets.find(set => set.key === previousSet?.key) ??
      nextItem?.substitutionSets.find(set => set.id === previousSet?.id) ??
      // hack fix for if reordering an unavailable item and switching to a menu where the menu is available
      nextItem?.substitutionSets.find(set => set.id === currentSetKey);

    if (matchingSet && (previousSet || !previousItem)) {
      nextSelections[matchingSet.key] = previousSelections[currentSetKey];
    }
  });

  return nextSelections;
}
