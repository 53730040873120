import lodash from 'lodash';

export default function identicalPurchase(
  purchaseA: _Purchase,
  purchaseB: _Purchase,
): boolean {
  // Destructuring
  const {
    itemId: itemA = null,
    choiceSelections: choicesA = null,
    sizeId: sizeA = null,
    substitutionSelections: substitutionsA = null,
  } = purchaseA;

  const {
    itemId: itemB = null,
    choiceSelections: choicesB = null,
    sizeId: sizeB = null,
    substitutionSelections: substitutionsB = null,
  } = purchaseB;

  const itemsHaveSameId = itemA === itemB;
  const itemsHaveSameChoiceSelections = lodash.isEqual(choicesA, choicesB);
  const itemsHaveSameSizes = lodash.isEqual(sizeA, sizeB);
  const itemsHaveSameSubstitutionSelections = lodash.isEqual(
    substitutionsA,
    substitutionsB,
  );

  return (
    itemsHaveSameId &&
    itemsHaveSameChoiceSelections &&
    itemsHaveSameSizes &&
    itemsHaveSameSubstitutionSelections
  );
}
