import { checkCategoryAvailability, OrderingSelectors } from 'polygon-ordering';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';
import RedcatImage from './RedcatImage';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';

const { getDayPartMenuConfig } = OrderingSelectors;
interface IProps {
  item: Brand | Category;
  isCurrent: boolean;
  setCurrentItemId: (id: string) => void;
  currentIndicatorColor?: string;
  labelThemeKey: string;
  currentLabelThemeKey: string;
  iconPathPrefix: string;
  iconSize: number;
  isBrand?: boolean;
}
const HorizontalMenuCell: React.FC<IProps> = props => {
  const p = useAppSelector(getThemeLookup);
  const ref = useRef<HTMLDivElement>();
  const isCurrentRef = useRef<boolean>(false);

  const {
    item,
    isCurrent,
    setCurrentItemId,
    currentIndicatorColor,
    labelThemeKey,
    currentLabelThemeKey,
    iconPathPrefix,
    iconSize = 25,
    isBrand,
  } = props;

  const [current, setCurrent] = useState(isCurrent);

  useEffect(() => {
    isCurrentRef.current = current;
    if (!isCurrentRef.current && isCurrent) {
      ref.current?.scrollIntoView({
        block: 'nearest', // otherwise the screen is scrolled vertially as well (to put this at the top)
        inline: 'center', // keep current item as close to the center as possible
      });
    }
    setCurrent(isCurrent);
  }, [isCurrent]);

  //@ts-ignore
  const imagePath = item.imagePath; //imagePath doesnt exist in type Category
  const { hideUnavailableCategories } = useAppSelector(getDayPartMenuConfig);
  const [daypartAvailability, menuAvailablity] = checkCategoryAvailability(item);

  return !daypartAvailability && hideUnavailableCategories ? null : (
    <div style={styles.categoryName}>
      <TouchableOpacity
        ref={ref as RefObject<HTMLDivElement>}
        style={combineStyles(
          styles.main,
          {
            borderBottom: `2px solid ${
              isCurrent && !isBrand ? currentIndicatorColor : 'transparent'
            }`,
          },
          isBrand && {
            flexGrow: 1,
          },
          isCurrent && { opacity: '1' },
        )}
        onClick={() => setCurrentItemId(item.id)}
        disabled={isCurrent}
        ariaLabel={item.name}
      >
        <div style={combineStyles(styles.inner)}>
          {imagePath && (
            <RedcatImage
              containerStyle={combineStyles(
                styles.tabImage,
                { marginRight: iconSize / 3 },
                isCurrent && p(currentLabelThemeKey, TEXT_PROPERTIES),
                isCurrent && { opacity: '1' },
              )}
              imagePath={imagePath}
              pathPrefix={iconPathPrefix}
              alt={item.name}
              size={iconSize}
            />
          )}

          <Text
            themeKey={labelThemeKey}
            value={item.name}
            style={combineStyles(
              styles.label,
              p(labelThemeKey, TEXT_PROPERTIES),
              isCurrent && p(currentLabelThemeKey, TEXT_PROPERTIES),
              isCurrent && { opacity: '1' },
            )}
          />
        </div>
      </TouchableOpacity>
      {(item as Category).availability && (
        <Text
          themeKey={labelThemeKey}
          style={combineStyles(
            styles.daypartAvailability,
            ref.current && {
              maxWidth: ref.current.clientWidth,
              textWrap: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          )}
          value={menuAvailablity}
        />
      )}
    </div>
  );
};

const styles: Styles = {
  daypartAvailability: {
    fontSize: 12,
    paddingTop: '0.5em',
    opacity: 0.6,
    minWidth: 110,
  },
  categoryName: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 10,
    marginRight: 10,
    height: '100%',
  },
  main: {
    opacity: 0.6,
    maxHeight: '100%',
    //flexGrow: 1,
  },
  inner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '0.1em',
    height: '100%',
  },
  label: {
    textAlign: 'center',
    whiteSpace: 'pre',
  },
  tabImage: {
    borderRadius: '25%',
    overflow: 'hidden',
  },
};

export default HorizontalMenuCell;
