import { createSelector } from 'reselect';
import lodash from 'lodash';

import getOrdering from './getOrdering';

export default createSelector(
  [getOrdering],
  (ordering): FavouriteOrder[] | undefined => {
    if (!ordering.favouriteOrders) {
      return undefined;
    }

    return lodash.orderBy(ordering.favouriteOrders, 'id', ['desc']);
  },
);
