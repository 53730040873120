import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import RedcatImage from '../components/RedcatImage';
import Text from '../components/Text';
import TouchableOpacity from '../components/TouchableOpacity';
import { setLoyaltySelectedOffer } from '../slices/loyaltySelectedOffer';
import { LOYALTY_OFFER_MODAL_ID } from '../modals/LoyaltyOfferModal';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';
import getProfile from '../selectors/getProfile';
import getThemeLookup from '../selectors/getThemeLookup';
import SendVerificationButton from '../components/SendVerificationButton';
import OffersCard from '../components/OffersCard';
import _fetchMemberOffers from '../thunks/fetchMemberOffers';
import { updateLoadingDependencies } from '../slices/loadingDependencies';
import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';
import {
  getShowExpiredCoupons,
  getShowRedeemedCoupons,
} from '../libs/polygon-ordering/src/selectors/config';
import { setCurrentModal } from '../thunks/setCurrentModal';

const { fetchMemberOffers } = OrderingOperations;
const { getMemberOffers } = OrderingSelectors;

export const OFFERS_ROUTE = '/offers';

const OffersScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const offers = useAppSelector(getMemberOffers);
  const profile = useAppSelector(getProfile);
  const { verified } = profile!;

  const showRedeemedCoupons = useAppSelector(getShowExpiredCoupons);
  const showExpiredCoupons = useAppSelector(getShowRedeemedCoupons);

  useEffect(() => {
    if (verified) {
      if (offers === undefined) {
        dispatch(updateLoadingDependencies({ fetchingOffers: true }));
      }

      setTimeout(() => {
        dispatch(fetchMemberOffers({ showRedeemedCoupons, showExpiredCoupons })).then(() => {
          dispatch(updateLoadingDependencies({ fetchingOffers: false }));
        });
      });
    }
  }, [verified]);

  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();

  return (
    <div className="text-center" style={{ marginTop: 10 }}>
      {
        <>
          {offers?.map((offer, i) => (
            <OffersCard offer={offer} key={`offer-${i}`} />
          ))}
        </>
      }
    </div>
  );
};

const styles: Styles = {};

export default OffersScreen;
