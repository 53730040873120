export const MOBILE_CLIENT = 1;
export const KIOSK_CLIENT = 2;
export const WEB_ORDERING_CLIENT = 3;
export const PLATFORM_IDS = { ios: 2, android: 1 };

export const AD_SECTIONS = {
  MENU_OVERLAY: 'menu_overlay', // For Web

  HOME_SCREEN: 'appHome', // For App
  PURCHASE_EDITOR: 'purchaseEditor', // For App
};

export const VIDEO_EXTENSIONS = ['.mp4', '.webm', '.ogg'];
export const IMAGE_EXTENSIONS = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.bmp',
  '.webp',
  '.tiff',
];

export const AD_MEDIA_TYPE = {
  video: 'video',
  image: 'image',
  unknown: 'unknown',
};
