import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// List of loading dependencies & their initial boolean state.
// Note: Be cautious, and make sure you set any new loading dependencies to false before every function exit
//              incliding try/catch, and before returns. This is to avoid infinite loading states.
const LOADING_DEPENDENCIES = {
  handleURLParamsInProgress: false,
  handleAdjustOrderInProgress: false,
  redirectRequiresLocationData: false,
  fullLocationDataFetching: false,
  adjustSaleTypeInProgress: false,
  fetchingMemberOffers: false,
  performingDeliveryEstimates: false,
  fetchingOffers: false,
};

type ValueOf<T> = keyof T;
const slice = createSlice({
  name: 'loadingDependencies',
  initialState: LOADING_DEPENDENCIES,

  reducers: {
    updateLoadingDependencies(
      state,
      action: PayloadAction<Partial<{ [T in ValueOf<typeof LOADING_DEPENDENCIES>]: boolean }>>,
    ) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateLoadingDependencies } = slice.actions;

export default slice.reducer;
