import calculateStockBalanceData from './calculateStockBalanceData';

export default function (choices, invalidQty) {
  let choiceSoldOut,
    choiceInvalid = false;

  if (choices.length) {
    choices.map(choice => {
      const choiceBalData = calculateStockBalanceData(choice.plucode);
      if (choiceBalData.soldOut) {
        choiceSoldOut = true;
      }
      if (
        invalidQty?.hasOwnProperty(choice.plucode) ||
        choice.quantity > choiceBalData.itemBalance
      ) {
        choiceInvalid = true;
      }
    });
  }

  return { choiceSoldOut, choiceInvalid };
}
