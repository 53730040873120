import lodash from 'lodash';
import choiceSetDiscountPlus from './choiceSetDiscountPlus';

export function adjustedNestedSelections(data: {
  max: number | undefined;
  individualMax: number | undefined;
  previousChoiceSelections: SDict<ChoiceWithQuantity[]>;
  targetChoiceSetId: string;
  targetChoice: ChoiceWithQuantity;
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig;
  decrease?: boolean;
  shouldClear?: boolean;
  choiceSet?: ValidatedChoiceSet;
}): SDict<ChoiceWithQuantity[]> {
  const {
    max,
    individualMax,
    previousChoiceSelections,
    targetChoiceSetId,
    targetChoice,
    choiceSetDiscountConfig,
    decrease,
    shouldClear,
    choiceSet,
  } = data;

  if (choiceSetDiscountConfig) {
    const { useChoiceSetDiscountMap, choiceSetDiscountMap, discountPlu } =
      choiceSetDiscountConfig;

    if (!useChoiceSetDiscountMap && discountPlu) {
      if (targetChoice.id === discountPlu) return previousChoiceSelections;
    }
    if (useChoiceSetDiscountMap && choiceSetDiscountMap) {
      const { plus } = choiceSetDiscountPlus(choiceSetDiscountMap);
      if (plus.includes(targetChoice.id)) return previousChoiceSelections;
    }
  }

  const targetSelections = previousChoiceSelections[targetChoiceSetId];

  if (shouldClear) {
    return {
      ...previousChoiceSelections,
      [targetChoiceSetId]: previousChoiceSelections[targetChoiceSetId].filter(
        c => c.id !== targetChoice.id,
      ),
    };
  }

  const targetQuantity =
    lodash.sumBy(targetSelections, c => (c.id === targetChoice.id ? 1 : 0)) +
    (decrease ? -1 : 1);

  if (individualMax && targetQuantity > individualMax) {
    return previousChoiceSelections;
  }
  //decrease quantity
  if (decrease) {
    const index = targetSelections.findIndex(
      selection => selection.id === targetChoice.id,
    );

    if (index !== -1) {
      const newTargetsWithRemoved = [...targetSelections];
      newTargetsWithRemoved.splice(index, 1);
      return {
        ...previousChoiceSelections,
        [targetChoiceSetId]: newTargetsWithRemoved,
      };
    }
    return {
      ...previousChoiceSelections,
      [targetChoiceSetId]: targetSelections,
    };
  }

  // get currect selections array from purchase and add a copy of the choice id to the array

  const newTargetSelections = [
    ...(previousChoiceSelections[targetChoiceSetId] || []),
    targetChoice,
  ];

  if (!max) {
    return {
      ...previousChoiceSelections,
      [targetChoiceSetId]: newTargetSelections,
    };
  }

  // if we are going to be clipping the array,
  // removing the oldest selection (for a different choice)
  // feels most natural to users
  if (
    newTargetSelections.length > max &&
    newTargetSelections[0].id === targetChoice.id
  ) {
    const index = newTargetSelections.findIndex(
      selection => selection.id !== targetChoice.id,
    );
    if (index !== -1) {
      newTargetSelections.splice(index, 1);
    }
  }

  return {
    ...previousChoiceSelections,
    [targetChoiceSetId]: newTargetSelections.slice(-max),
  };
}

export default function adjustedSelections(
  max: number | undefined,
  individualMax: number | undefined,
  previousChoiceSelections: ChoiceSelections,
  targetChoiceSetId: string,
  parentChoiceSetId: string | undefined,
  targetChoiceId: string,
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig,
  remove?: boolean,
  decrease?: boolean,
  clearParent?: boolean,
  targetChoiceSetIdForModify?: string,
  currentItemId?: string,
  previouslyStaged?: boolean,
): ChoiceSelections {
  // console.log({
  //   max,
  //   individualMax,
  //   previousChoiceSelections,
  //   targetChoiceSetId,
  //   parentChoiceSetId,
  //   targetChoiceId,
  //   choiceSetDiscountConfig,
  //   remove,
  //   decrease,
  //   clearParent,
  //   targetChoiceSetIdForModify,
  //   currentItemId,
  //   previouslyStaged,
  // });
  // Helper function to find the index of a selection by id
  const findSelectionIndex = (selections: any[], id: string) =>
    selections.findIndex(
      selection =>
        (typeof selection === 'string'
          ? selection
          : (selection as Choice).id) === id,
    );

  // Apply choice set discount rules if provided
  if (choiceSetDiscountConfig) {
    const { useChoiceSetDiscountMap, choiceSetDiscountMap, discountPlu } =
      choiceSetDiscountConfig;

    if (
      !useChoiceSetDiscountMap &&
      discountPlu &&
      targetChoiceId === discountPlu
    ) {
      return previousChoiceSelections;
    }

    if (useChoiceSetDiscountMap && choiceSetDiscountMap) {
      const { plus } = choiceSetDiscountPlus(choiceSetDiscountMap);
      if (plus.includes(targetChoiceId)) return previousChoiceSelections;
    }
  }

  if (clearParent) {
    return {
      ...previousChoiceSelections,
      [targetChoiceSetId]: [],
    };
  }

  let targetSelections: any = parentChoiceSetId
    ? previousChoiceSelections[parentChoiceSetId]?.[targetChoiceSetId as any] ||
      []
    : previousChoiceSelections[targetChoiceSetId] || [];

  console.log({ targetSelections });
  // This code is added to handle the modification case, when a user adds the item to the cart and later comes to the item to change it the data flow is different so this code will handle that
  if (previouslyStaged && !targetSelections.length) {
    if (
      parentChoiceSetId &&
      previousChoiceSelections[parentChoiceSetId] &&
      currentItemId
    ) {
      const selectedChoiceSets =
        //@ts-ignore
        previousChoiceSelections[parentChoiceSetId][currentItemId];

      if (selectedChoiceSets && !Array.isArray(selectedChoiceSets)) {
        if (
          targetChoiceSetIdForModify &&
          selectedChoiceSets[targetChoiceSetIdForModify]
        ) {
          targetSelections = selectedChoiceSets[targetChoiceSetIdForModify];
        } else {
          for (const [key, values] of Object.entries(selectedChoiceSets)) {
            //@ts-ignore
            const choice = values.find(ch => ch.id === targetChoiceId);

            if (choice) {
              //@ts-ignore
              targetSelections = values.map(val => val.id);
              break;
            }
          }
        }
      }
    }
  }

  console.log({ targetSelections });

  if (!targetSelections.every((item: any) => typeof item === 'string')) {
    targetSelections = targetSelections.map((t: any) => t.id);
  }

  console.log({ targetSelections });

  const targetQuantity =
    lodash.sumBy(targetSelections, id =>
      (typeof id === 'string' ? id : (id as Choice).id) === targetChoiceId
        ? 1
        : 0,
    ) + (decrease ? -1 : 1);

  // Validate against individual max limit
  if (individualMax && targetQuantity > individualMax) {
    return previousChoiceSelections;
  }

  // Handle decreasing selection quantity
  if (decrease) {
    const index = findSelectionIndex(
      targetSelections as string[],
      targetChoiceId,
    );
    const updatedSelections = [...targetSelections];

    if (index > -1) updatedSelections.splice(index, 1);

    return updateSelections(
      previousChoiceSelections,
      parentChoiceSetId,
      targetChoiceSetId,
      updatedSelections,
      clearParent,
      previouslyStaged,
      currentItemId,
      targetChoiceSetIdForModify,
    );
  }

  if (remove) {
    const updatedSelections = (targetSelections as string[]).filter(
      (selection: string) => selection !== targetChoiceId,
    );

    return updateSelections(
      previousChoiceSelections,
      parentChoiceSetId,
      targetChoiceSetId,
      updatedSelections,
      clearParent,
      previouslyStaged,
      currentItemId,
      targetChoiceSetIdForModify,
    );
  }

  // Handle increasing selection quantity
  const newTargetSelections = [...targetSelections, targetChoiceId];
  if (!max) {
    return updateSelections(
      previousChoiceSelections,
      parentChoiceSetId,
      targetChoiceSetId,
      newTargetSelections,
      clearParent,
      previouslyStaged,
      currentItemId,
      targetChoiceSetIdForModify,
    );
  }

  // Enforce max limit, removing the oldest selection if necessary
  if (newTargetSelections.length > max) {
    newTargetSelections.splice(0, 1);
  }

  return updateSelections(
    previousChoiceSelections,
    parentChoiceSetId,
    targetChoiceSetId,
    newTargetSelections,
    clearParent,
    previouslyStaged,
    currentItemId,
    targetChoiceSetIdForModify,
  );
}

// Helper function to update selections in previousChoiceSelections
function updateSelections(
  previousChoiceSelections: ChoiceSelections,
  parentChoiceSetId: string | undefined,
  targetChoiceSetId: string,
  newSelections: any[],
  clearParent?: boolean,
  previouslyStaged?: boolean,
  currentItemId?: string,
  targetChoiceSetIdForModify?: string,
): any {
  const parentObj: any = parentChoiceSetId
    ? Array.isArray(previousChoiceSelections[parentChoiceSetId])
      ? {}
      : previousChoiceSelections[parentChoiceSetId]
    : {};

  if (
    previouslyStaged &&
    currentItemId &&
    targetChoiceSetIdForModify &&
    parentChoiceSetId &&
    parentObj
  ) {
    return {
      ...previousChoiceSelections,
      [parentChoiceSetId]: {
        [currentItemId]: {
          ...parentObj[currentItemId],
          [targetChoiceSetIdForModify]: [...newSelections],
        },
      },
    };
  }

  if (parentChoiceSetId && parentObj) {
    return {
      ...previousChoiceSelections,
      [parentChoiceSetId]: {
        ...(clearParent ? {} : parentObj),
        [targetChoiceSetId]: [...newSelections],
      },
    };
  }

  return {
    ...previousChoiceSelections,
    [targetChoiceSetId]: newSelections,
  };
}
