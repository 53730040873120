import React from 'react';
import { useAppSelector } from '../app/hooks';
import RedcatImage from './RedcatImage';
import MenuItemDescriptions from './MenuItemDescriptions';
import combineStyles from '../utils/combineStyles';
import { OrderingSelectors } from 'polygon-ordering';
import { CONTAINER_PROPERTIES } from '../utils/theme';
import getThemeLookup from '../selectors/getThemeLookup';

const { getStockBalanceDataByItem, getOpenPurchase } = OrderingSelectors;

const MenuItemDesktop = ({
  item,
  availabilityTimeString = '',
}: {
  item: Item;
  availabilityTimeString: string;
}) => {
  const p = useAppSelector(getThemeLookup);
  const stockBalanceData = useAppSelector(
    getStockBalanceDataByItem(item, useAppSelector(getOpenPurchase) as PurchaseWithTotals),
  );
  const checkIfDisabled =
    stockBalanceData && (stockBalanceData.soldOut || stockBalanceData.soldOutByCartSelections);

  return (
    <div style={styles.menuItemContainerDesktop}>
      <div>
        <RedcatImage
          alt={item.name}
          imagePath={
            item?.images?.default ??
            item.sizes.find(s => s.id === item.default_size)?.images.default
          }
          containerStyle={combineStyles(
            Boolean(checkIfDisabled) && styles.menuItemOpacity,
            p('menuItemImage', [...CONTAINER_PROPERTIES, 'aspectRatio']),
          )}
          imageStyle={combineStyles(
            { borderRadius: 10 },
            p('menuItemImage', [...CONTAINER_PROPERTIES, 'aspectRatio', 'objectFit']),
          )}
          imgFluid
        />
      </div>

      <MenuItemDescriptions item={item} availabilityTimeString={availabilityTimeString} />
    </div>
  );
};

export default MenuItemDesktop;

const styles = {
  menuItemContainerDesktop: {
    marginBottom: '52px',
  },
  menuItemOpacity: {
    opacity: 0.5,
  },
};
