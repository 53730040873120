import React from 'react';
import { useAppSelector } from '../app/hooks';
import { OrderingHooks } from 'polygon-ordering';
import Text from './Text';

import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES } from '../utils/theme';
import combineStyles from '../utils/combineStyles';

const { useFormattedCurrency } = OrderingHooks;

const PastOrderItem = ({
  item,
  order,
  choices,
}: {
  item: SaleItem;
  order: EnhancedHistoricalOrder;
  choices: SaleItem[];
}) => {
  const deviceType = useAppSelector(state => state.deviceType);
  const price = useFormattedCurrency({ cents: item.moneyPrice });
  const p = useAppSelector(getThemeLookup);

  return (
    <tr style={p('tableRow', CONTAINER_PROPERTIES)}>
      <td
        className="border-0"
        style={{ ...styles.tableRow, width: deviceType === 1 ? '40%' : '80%' }}
      >
        <Text themeKey="tableRowHeader" variant="body" block>
          {item.name}
        </Text>

        <div style={{ marginLeft: '1.5em' }}>
          {choices.map(choice => (
            <Text themeKey="tableRowSubHeader" variant="body2" block>
              {choice.name.trimStart()}
            </Text>
          ))}
        </div>
      </td>
      {deviceType !== 2 && (
        <>
          <td className="border-0 p-0 text-center pt-2" style={{ fontSize: 11, width: '20%' }}>
            <Text themeKey="tableRowHeader" variant="body">
              {item.quantity}
            </Text>
          </td>
          {!!order.pointsAward && (
            <td className="border-0 p-0 pt-2" style={{ fontSize: 11, width: '20%' }}>
              <Text themeKey="tableRowHeader" variant="body">
                {item.redeemPoints ? item.redeemPoints / 10 : 0}
              </Text>
            </td>
          )}
        </>
      )}

      <td className="border-0 p-0 pt-2" style={{ fontSize: 11, width: '20%', textAlign: 'right' }}>
        <Text themeKey="tableRowHeader" variant="body">
          {price}
        </Text>
      </td>
    </tr>
  );
};

const styles: Styles = {
  tableRow: {
    padding: '0.75em 0',
  },
};

export default PastOrderItem;
