import { Form, Formik } from 'formik';
import moment from 'moment';
import { RedcatApiHandler } from 'polygon-utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useAppSelector } from '../app/hooks';
import FormDateFields from '../components/FormDateFields';
import FormSelect from '../components/FormSelect';
import FormTextField from '../components/FormTextField';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text';
import getDisplayableLocations from '../selectors/getDisplayableLocations';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';

export const CLAIM_MISSING_POINTS_ROUTE = '/claim-missing-points';

interface FormValues {
  StoreID: string;
  ReceiptNo: string;
  Total: string;
  TransactionDate: string | Date;
  TransactionTime: string;
}

const ClaimMissingPointsScreen: React.FC = () => {
  const { t } = useTranslation();
  const locations = useAppSelector(getDisplayableLocations);

  const handleFormSubmit = (
    { StoreID, ReceiptNo, Total, TransactionDate, TransactionTime }: FormValues,
    {
      setSubmitting,
      resetForm,
    }: { setSubmitting: (isSubmitting: boolean) => void; resetForm: () => void },
  ) => {
    const time = moment(TransactionTime, 'HH:mma').format('HH:mm:ss');
    const date = moment(TransactionDate).format('YYYY-MM-DD');
    const TransactionDateTime = moment(date + 'T' + time).format('YYYY-MM-DDTHH:mm:ss');

    RedcatApiHandler.authorisedFetch({
      path: '/api/v1/profile/points_retrieval',
      method: 'POST',
      body: { StoreID, ReceiptNo, Total, TransactionDateTime },
    })
      .then(res => {
        resetForm();
        enqueueSuccessSnackbar(res.data);
        setSubmitting(false);
      })
      .catch(err => {
        enqueueErrorSnackbar(err);
        resetForm();
        setSubmitting(false);
      });
  };

  const schemas = {
    StoreID: Yup.string().required(t('form.required')),
    ReceiptNo: Yup.string().required(t('form.required')),
    Total: Yup.number().required(t('form.required')),
    TransactionDate: Yup.string().required(t('form.required')),
    TransactionTime: Yup.string().required(t('form.required')),
  };
  const validationSchema = Yup.object().shape(schemas);

  const fields = [
    {
      name: 'StoreID',
      type: 'select',
      label: t('placeholder.storeID'),
      possibleValues: locations,
    },
    {
      name: 'TransactionDate',
      type: 'date',
      select: false,
      label: t('placeholder.transactionDate'),
      shrink: true,
    },
    {
      name: 'TransactionTime',
      type: 'time',
      select: false,
      label: t('placeholder.transactionTime'),
      shrink: true,
    },
    {
      name: 'ReceiptNo',
      type: 'text',
      select: false,
      label: t('placeholder.receiptNo'),
    },
    {
      name: 'Total',
      type: 'text',
      select: false,
      label: t('placeholder.saleTotal'),
    },
  ];

  const initialValues: FormValues = {
    StoreID: '',
    ReceiptNo: '',
    Total: '',
    TransactionDate: '',
    TransactionTime: '',
  };

  return (
    <div>
      <Text value={t('claimMissingPointsInfo')} themeKey="loyaltyHeaderInfo" />
      <div className="py-2">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
        >
          {({ values, handleSubmit, handleChange, submitForm, isValid, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              {fields.map(field => (
                <div key={field.label}>
                  {field.type === 'text' && (
                    <FormTextField
                      onChange={handleChange}
                      name={field.name}
                      type={field.type}
                      label={field.label}
                    />
                  )}
                  {field.type === 'select' && (
                    <FormSelect
                      onChange={handleChange}
                      name={field.name}
                      label={field.label}
                      menuItems={field?.possibleValues}
                    />
                  )}

                  {(field.type === 'date' || field.type === 'time') && (
                    <FormDateFields
                      type={field.type}
                      name={field.name}
                      label={field.label}
                      onChange={handleChange}
                    />
                  )}
                </div>
              ))}
              <div style={{ marginTop: 20 }}>
                <StandardButton
                  disabled={
                    !isValid || isSubmitting || Object.values(values).some(item => item === '')
                  }
                  onClick={() => submitForm()}
                  themeKey="loyaltyButton"
                  containerStyle={styles.button}
                  label={t('button.claimReceipt')}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const styles: Styles = {
  button: {
    borderRadius: 5,
    height: 40,
  },
};

export default ClaimMissingPointsScreen;
