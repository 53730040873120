import React from 'react';

import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES } from '../utils/theme';

import { setCurrentModal } from '../thunks/setCurrentModal';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import moment from 'moment';

//import { RiCloseCircleFill } from 'react-icons/ri';
import RedcatImage from '../components/RedcatImage';
import RedcatBarcode from '../components/RedcatBarcode';
import Modal from '../components/Modal';
import Text from '../components/Text';
import CloseIcon from '../components/icons/CloseIcon';

export const LOYALTY_OFFER_MODAL_ID = 'LOYALTY_OFFER_MODAL_ID';

const LoyaltyOfferModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);

  const offer = useAppSelector(state => state.loyaltySelectedOffer);

  return (
    <Modal
      desktopMaxWidth={500}
      hideFloatingHeader
      clickBackgroundToClose
      containerStyle={combineStyles(
        styles.offersContainer,
        isMobileDevice && styles.offersContainerMobile,
        p('offersDetails', CONTAINER_PROPERTIES),
      )}
    >
      <div style={styles.closeButton}>
        <CloseIcon
          boxSize={48}
          iconSize={33}
          onClick={() => dispatch(setCurrentModal(null))}
          style={p('modalCloseButton', CONTAINER_PROPERTIES)}
        />
      </div>

      <div style={styles.imageHeader}>
        {!!offer?.image && <RedcatImage imagePath={offer.image} imgFluid />}
      </div>

      <div
        style={combineStyles(
          styles.offersDetailsContent,
          !Boolean(offer?.image) && { paddingTop: 30 },
        )}
      >
        <Text value={offer?.name} variant={'h2'} themeKey="offersMoreModalName" />

        {!!offer?.barcode && (
          <RedcatBarcode barcodeOptions={{ value: offer?.barcode }} showText={true} />
        )}
        <div style={styles.offersDetailsBottom}>
          {!!offer?.startDate && !!offer?.expiryDate && (
            <Text
              value={
                'Valid ' +
                moment(offer?.startDate).format('DD/MM/YYYY') +
                ' - ' +
                moment(offer?.expiryDate).format('DD/MM/YYYY')
              }
              variant={'body2'}
              themeKey="offerDates"
            />
          )}

          <div style={styles.tnc}>
            <Text
              value={t('termsAndContition')}
              variant={'h3'}
              themeKey="offersMoreModalTermsHeading"
            />
            <Text
              block
              value={offer?.longDescription}
              variant={'body'}
              themeKey="offersMoreModalText"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const styles: Styles = {
  offersContainer: {
    borderRadius: 20,
    padding: 0,
    gap: 0,
  },
  offersContainerMobile: {
    // On mobile have a gap on the modal and have it shift down so it doesn't reveal the border radius
    // We want customers to also theme border radius too.
    translate: '0 20px',
  },
  imageHeader: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    zIndex: 99,
  },
  offersDetailsContent: {
    padding: '20px 30px 40px',
    textAlign: 'center',
    gap: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  offersDetailsBottom: {
    marginTop: '1em',
    gap: '1em',
  },
  tnc: {
    marginTop: '1em',
  },
};

export default LoyaltyOfferModal;
