import { createSelector } from 'reselect';
import moment from 'moment';

import getCurrentOrder from './getCurrentOrder';

export default createSelector([getCurrentOrder], currentOrder => {
  const { changedTime } = currentOrder;

  return changedTime ? moment(changedTime).format() : undefined;
});
