import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import StandardButton from '../components/StandardButton';
import getProfileFields from '../selectors/getProfileFields';
import getRegisterProfileFields from '../selectors/getRegisterProfileFields';
import { RedcatApiHandler } from 'polygon-utils';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { Formik, Form } from 'formik';
import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import combineStyles from '../utils/combineStyles';
import ScreenHero from '../components/ScreenHero';
import ScreenFloating from '../components/ScreenFloating';
import Text from '../components/Text';
import history from '../history';
import RegistrationFields from '../components/RegistrationFields';
import { SIGN_IN_MODAL_ID } from '../modals/SignInModal';
import { setCurrentModalId } from '../slices/currentModalId';
import { INPUT_FIELDS } from '../constants/inputfields';

export const NEW_PASSWORD_ROUTE = '/reset';

type ResetYourPasswordParams = {
  Code?: string;
  Password: string;
  ConfirmPassword?: string;
  reCaptchaToken?: string;
};

const NewPasswordScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const { pathname, search } = useLocation();
  const profileFields = useAppSelector(getProfileFields);
  const registrationFields = useAppSelector(getRegisterProfileFields);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const [reCaptchaToken, setReCaptchaToken] = useState<null | string>(null);
  const [resetPasswordHash, setResetPasswordHash] = useState<null | string>(null);
  const enableReCaptcha = useAppSelector(state => state.ordering.config.enableReCaptcha);
  const reCaptchaSiteKey = useAppSelector(state => state.config.reCaptchaSiteKey);
  const currentModalId = useAppSelector(state => state.currentModalId);
  const minimumPasswordLength = useAppSelector(state => state.config.minimumPasswordLength);
  const isConfirmPasswordRequired = Boolean(
    registrationFields?.visible.find(field => field.id === INPUT_FIELDS.ConfirmPassword),
  );

  useEffect(() => {
    if (currentModalId) {
      dispatch(setCurrentModalId(null));
    }
  }, []);

  useEffect(() => setResetPasswordHash(search), []);

  const confirmPasswordMode = profileFields?.find(
    field => field.id === INPUT_FIELDS.ConfirmPassword,
  )?.registerMode;

  const schemas: ResetYourPasswordSchema = {
    Password: Yup.string()
      .required(t('form.required'))
      .min(minimumPasswordLength, t('form.passwordTooShort')),
    ConfirmPassword: Yup.string()
      .test('required', t('form.required'), function (value) {
        if (confirmPasswordMode !== 'required') return true;
        return Boolean(value?.trim());
      })
      .test('too-short', t('form.passwordTooShort'), function (value) {
        if (confirmPasswordMode !== 'required') return true;
        return Boolean(value && value.length >= minimumPasswordLength);
      })
      .test('passwords-match', 'Passwords do not match', function (value) {
        if (confirmPasswordMode !== 'required') return true;
        return value === this.parent.Password;
      }),
  };

  const validationSchema = Yup.object().shape(schemas);
  const ScreenComponent = deviceTypeMobile ? ScreenHero : ScreenFloating;
  const columnStyles = combineStyles(styles.column, deviceTypeMobile && styles.columnMobile);

  const onSubmit = (
    { Password, ConfirmPassword }: { Password: string; ConfirmPassword: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    const params: ResetYourPasswordParams = {
      Password,
      // The API expects the ConfirmPassword param,
      // -> So we'll pass the Password value into the ConfirmPassword param so the BE doesn't yell at us
      ...(isConfirmPasswordRequired ? { ConfirmPassword } : { ConfirmPassword: Password }),
    };

    if (resetPasswordHash) {
      params.Code = decodeURIComponent(resetPasswordHash.split('=').pop() as string);
    }

    setSubmitting(false);
    resetPassword(params);
  };

  const resetPassword = (params: any) => {
    const path = '/api/v1/profile/reset_password';

    RedcatApiHandler.authorisedFetch({
      path,
      method: 'POST',
      body: params,
    })
      .then(response => {
        if (response.success) {
          enqueueSuccessSnackbar(t('updateSuccess'));
          history.push('/');
        }
      })
      .catch(err => {
        enqueueErrorSnackbar(err);
      });
  };

  return (
    <ScreenComponent>
      <div
        style={combineStyles(
          deviceTypeMobile ? styles.mainContainerMobile : styles.mainContainerDesktop,
          p('screen', ['backgroundColor']),
          p(deviceTypeMobile ? 'initialScreenMobile' : 'initialScreenDesktop', [
            'backgroundColor',
            'boxShadow',
          ]),
        )}
      >
        <div style={columnStyles}>
          <Text themeKey="sectionTitle" value={t('title.resetPassword')} />
          <div>{t('resetPasswordSubtitle')}</div>
          <Formik
            validationSchema={validationSchema}
            initialValues={{ Password: '', ConfirmPassword: '' }}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validateOnMount
          >
            {({ handleSubmit, isSubmitting, isValid, values }) => {
              return (
                <Form onSubmit={handleSubmit} style={styles.registrationForm}>
                  <>
                    {registrationFields!.visible
                      .filter((field: FormikFields) => field.id === 'Password')
                      .map((field: RegisterFields, index: any) => (
                        <RegistrationFields
                          key={index}
                          field={field}
                          showStrengthBar={isConfirmPasswordRequired ? false : true}
                        />
                      ))}

                    {profileFields!
                      .filter(
                        (field: FormikFields) =>
                          field.id === 'ConfirmPassword' && field.registerMode !== 'disabled',
                      )
                      .map((field: RegisterFields, index: any) => (
                        <RegistrationFields key={index} field={field} showStrengthBar={true} />
                      ))}
                    {enableReCaptcha && !reCaptchaToken && (
                      <div style={styles.captchaContainer}>
                        <ReCAPTCHA
                          sitekey={String(reCaptchaSiteKey)}
                          onChange={token => setReCaptchaToken(token)}
                          onErrored={() => {
                            setReCaptchaToken(null);
                            enqueueErrorSnackbar(t('problemWithReCaptchaMessage'));
                          }}
                          size={deviceTypeMobile ? 'compact' : 'normal'}
                        />
                      </div>
                    )}

                    <StandardButton
                      label={t('button.submit')}
                      containerStyle={styles.button}
                      disabled={Boolean(
                        isSubmitting ||
                          !isValid ||
                          (!isSubmitting && isValid && enableReCaptcha && !reCaptchaToken),
                      )}
                      themeKey="signInSubmitButton"
                      onClick={handleSubmit}
                    />
                  </>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </ScreenComponent>
  );
};

const styles: Styles = {
  mainContainerDesktop: {
    borderRadius: 2,
    padding: 35,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',

    margin: 'auto', // https://stackoverflow.com/a/33455342/8706204
  },
  mainContainerMobile: {
    flex: 1,
    padding: '10px 25px 100px 25px',
  },
  column: {
    minWidth: 250,
  },
  columnMobile: {
    padding: '20px 0 0 0',
  },
  button: {
    // borderRadius: 5,
    // height: 40,
    margin: '20px 20px',
  },
  verticalSeperator: {
    width: 1.5,
    marginLeft: 40,
    marginRight: 40,
  },
  registrationForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'strech',
    justifyContent: 'space-between',
    paddingTop: 40,
  },
};

export default NewPasswordScreen;
